import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import deepEqual from 'fast-deep-equal'
import { isEmpty } from 'ramda'
import { FileTemplate, PermissionArea } from '@pbt/pbt-ui-components'

import Expander from '~/components/common/lists/Expander'
import DialogNames from '~/constants/DialogNames'
import DocumentDialogStates from '~/constants/DocumentDialogStates'
import FeatureToggle from '~/constants/featureToggle'
import {
  deleteDocument,
  editDocument,
  fetchDocument,
  generateFileForDocumentTemplate,
} from '~/store/actions/documents'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getDocument,
  getDocumentsIsDeleting,
  getDocumentsIsGenerating,
  getDocumentsIsReceiving,
  getDocumentsIsSending,
  getGeneratedDocumentTemplateFile,
} from '~/store/reducers/documents'
import { Document as DocumentType } from '~/types'
import { getDeleteConfirmMessage } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import Document, { DocumentHandle } from './Document'
import DocumentPreview, { DocumentPreviewHandle } from './DocumentPreview'
import { createTemplateFromFile } from './documentUtils'
import { useDocumentDetailsDisabled } from './useDocumentDeatilsDisabled'

const useStyles = makeStyles(
  (theme) => ({
    previewContainer: {
      maxWidth: 656,
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
    previewContainerOld: {
      maxWidth: 656,
    },
  }),
  { name: 'DocumentDetails' },
)

export interface DocumentDetailsProps {
  itemId: string
  onClose: () => void
}

const DocumentDetails = ({ itemId, onClose }: DocumentDetailsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const document = useSelector(getDocument(itemId))
  const isSending = useSelector(getDocumentsIsSending)
  const isDeleting = useSelector(getDocumentsIsDeleting)
  const isGenerating = useSelector(getDocumentsIsGenerating)
  const isReceiving = useSelector(getDocumentsIsReceiving)
  const generatedDocumentTemplateFile = useSelector(
    getGeneratedDocumentTemplateFile,
  )
  const permissions = useSelector(getCRUDByArea(PermissionArea.FORM))

  const isDocumentFullScreenEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DOCUMENT_FULL_SCREEN),
  )

  const isInputFormsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.INPUT_FORMS_DOCUMENT),
  )

  const { t } = useTranslation('Common')

  const [openDocumentDialog] = useDialog(DialogNames.DOCUMENT)
  const [openDeleteDocumentAlert, closeDeleteDocumentAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const [documentCandidate, setDocumentCandidate] = useState(document)
  const [isDocumentChanged, setIsDocumentChanged] = useState(false)

  const setCloseOnDelete = useCloseAfterCreation(
    onClose,
    getDocumentsIsDeleting,
  )

  const documentRef = useRef<DocumentHandle>(null)
  const documentPreviewRef = useRef<DocumentPreviewHandle>(null)

  const disabled = useDocumentDetailsDisabled(document?.businessId)

  const getNewDocument = () =>
    ({
      ...documentRef.current?.get(),
      template: {
        ...documentPreviewRef.current?.getTemplate(),
        documentElements: isInputFormsEnabled ? [] : undefined,
      },
    }) as DocumentType

  const getUnsavedData = () => {
    const documentIsReady =
      Boolean(document) &&
      Boolean(documentCandidate?.template) &&
      Boolean(documentPreviewRef.current?.getTemplateReady()) &&
      !isEmpty(documentCandidate)

    return documentIsReady && !deepEqual(documentCandidate, getNewDocument())
  }

  const onDataChange = () => {
    setIsDocumentChanged(getUnsavedData())
  }

  useEffect(() => {
    if (itemId) {
      dispatch(fetchDocument(itemId))
    }
  }, [itemId])

  useEffect(() => {
    setDocumentCandidate(document)
  }, [document])

  const validate = () => documentRef.current?.validate()

  const onSaveRequested = () => {
    if (validate()) {
      const newDocument = getNewDocument()
      setIsDocumentChanged(false)
      dispatch(editDocument(newDocument))
    }
  }

  const onDeleteRequested = () => {
    openDeleteDocumentAlert({
      message: getDeleteConfirmMessage(t('Common:DOCUMENT_ONE').toLowerCase()),
      cancelButtonText: t('Common:NO_KEEP'),
      okButtonText: t('Common:YES_DELETE'),
      onCancel: () => closeDeleteDocumentAlert(),
      onOk: () => {
        setCloseOnDelete()
        dispatch(deleteDocument(documentCandidate!.id))
        closeDeleteDocumentAlert()
      },
    })
  }

  const onCloneRequested = () => {
    openDocumentDialog({
      PreviewProps: { hideEmail: true, hidePrint: true },
      document: {
        ...documentCandidate,
        name: `${documentCandidate!.name}_copy`,
      } as DocumentType,
    })
  }

  const onPreviewFileSelected = (file: FileTemplate) => {
    const template = createTemplateFromFile(file) as FileTemplate

    setIsDocumentChanged(true)
    setDocumentCandidate({ ...documentCandidate!, template })
  }

  const previewDocument = (documentToPreview: DocumentType) => {
    openDocumentDialog({
      document: documentToPreview,
      previewOnly: true,
      PreviewProps: {
        resolvePlaceholders: true,
        hideEmail: true,
        hidePrint: true,
        onFileSelected: onPreviewFileSelected,
      },
      step: DocumentDialogStates.PREVIEW,
      view: true,
    })
  }

  const onGeneratedFileForTemplate = () => {
    previewDocument({
      ...documentCandidate!,
      template: generatedDocumentTemplateFile!,
    })
  }

  const previewAfterTemplateGeneration = useCloseAfterCreation(
    onGeneratedFileForTemplate,
    getDocumentsIsGenerating,
  )

  const onPreviewRequested = () => {
    const isTemplate = !documentCandidate?.template?.fileUrl
    if (isTemplate) {
      previewAfterTemplateGeneration()
      dispatch(generateFileForDocumentTemplate(itemId))
    } else {
      previewDocument(documentCandidate)
    }
  }

  return (
    <Expander
      expandedItemClass={t('Common:DOCUMENT_ONE').toLowerCase()}
      getUnsavedData={getUnsavedData}
      hasUnsavedData={isDocumentChanged}
      isDeleting={isDeleting}
      isFetching={isReceiving}
      isSaving={isSending}
      showButtons={permissions.update}
      onBack={onClose}
      onCloneRequested={onCloneRequested}
      onDeleteRequested={disabled ? undefined : onDeleteRequested}
      onSaveRequested={onSaveRequested}
    >
      <Document
        view
        disabled={disabled}
        document={documentCandidate}
        ref={documentRef}
        onDocumentChange={onDataChange}
      />
      <Grid
        className={
          isDocumentFullScreenEnabled
            ? classes.previewContainer
            : classes.previewContainerOld
        }
      >
        <DocumentPreview
          view
          document={documentCandidate}
          editable={!disabled && permissions.update}
          previewDisabled={isGenerating}
          ref={documentPreviewRef}
          showChangeFile={!disabled && permissions.update}
          onFileSelected={onPreviewFileSelected}
          onPreview={onPreviewRequested}
          onPreviewChange={onDataChange}
        />
      </Grid>
    </Expander>
  )
}

export default DocumentDetails
