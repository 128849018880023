import React from 'react'
import { convertToHTML } from 'draft-convert'
import { EditorState } from 'draft-js'
import { CHECKABLE_LIST_ITEM } from 'draft-js-checkable-list-item'
import { isEmpty } from 'ramda'
import { Nil } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

import { LINK } from './decorator/link'
import { PLACEHOLDER } from './decorator/placeholder'
import { BLOCK_TYPE_SIGNATURE } from './decorator/signature'
import {
  getPlaceholderHtml,
  includePlaceholderChars,
  PlaceholderHTML,
} from './placeholderUtils'

const htmlDecodeFirstNode = (input: string) => {
  const element = document.createElement('div')
  element.innerHTML = input

  return element.childNodes[0].nodeValue
}

const signatureTableStyle = {
  border: '1px solid #EDECEC',
  padding: '8px',
}

export type PlaceholderData = {
  deserializedText?: string
  serializedText?: PlaceholderHTML | string
  time?: number
}

const converter = convertToHTML({
  blockToHTML: ({ type, text, data = {} }) => {
    if (isEmpty(text.trim()) && type === 'unstyled') {
      return <br />
    }
    // this block type should be defined to copy/paste <p> tags from websites
    if (type === 'section') {
      return {
        start: '',
        end: '',
      }
    }
    // NOTE: rewrite user-agent default styles for figure
    if (type === 'atomic') {
      return {
        start: '<figure style="margin:0;">',
        end: '</figure>',
      }
    }
    if (type === CHECKABLE_LIST_ITEM) {
      return {
        start:
          `<div class="${CHECKABLE_LIST_ITEM}">` +
          `<input${
            data.checked ? ' checked' : ''
          } style="margin: 0 8px" type="checkbox" />`,
        end: '</div>',
      }
    }
    return undefined
  },
  entityToHTML: (entity, originalText) => {
    if (entity.type === PLACEHOLDER) {
      const { serializedText } = (entity.data || {}) as PlaceholderData

      if (!serializedText) {
        return (
          <span className={PLACEHOLDER}>
            {htmlDecodeFirstNode(originalText)}
          </span>
        )
      }

      const fullString = includePlaceholderChars(serializedText)
        ? serializedText
        : getPlaceholderHtml(serializedText)

      return (
        <span className={PLACEHOLDER}>{htmlDecodeFirstNode(fullString)}</span>
      )
    }

    if (entity.type === BLOCK_TYPE_SIGNATURE) {
      return (
        <table
          className={BLOCK_TYPE_SIGNATURE}
          style={{ borderCollapse: 'collapse' }}
        >
          <tr>
            <td
              className={`${BLOCK_TYPE_SIGNATURE}_label`}
              style={signatureTableStyle}
            >
              {i18n.t<string>('Common:SIGNATURE_VIEW.LABEL')}
            </td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td
              className={`${BLOCK_TYPE_SIGNATURE}_signature`}
              style={{
                ...signatureTableStyle,
                width: '200px',
              }}
            />
          </tr>
        </table>
      )
    }

    if (entity.type === LINK) {
      return (
        <a className={LINK} href={entity.data.url}>
          {htmlDecodeFirstNode(originalText)}
        </a>
      )
    }

    return originalText
  },
})

export default (editorState: EditorState | Nil) => {
  const html = editorState ? converter(editorState.getCurrentContent()) : ''

  return html === '<br/>' ? '' : html
}
