import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import {
  CircularProgressOverlay,
  IFrameEvent,
  Nil,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { fetchClient } from '~/store/actions/clients'
import { fetchBillingAddresses } from '~/store/actions/payments'
import { updateUsers } from '~/store/actions/users'
import {
  clearWellnessPlanSignUpToken,
  fetchWellnessPlanSignUpToken,
} from '~/store/actions/wellnessPlans'
import {
  patientMembershipsActions,
  patientMembershipsSelectors,
} from '~/store/duck/patientMemberships'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getClientIsLoading } from '~/store/reducers/clients'
import { getPatientsList } from '~/store/reducers/patients'
import { getBillingAddresses } from '~/store/reducers/payments'
import { getUser } from '~/store/reducers/users'
import { getWellnessPlanSignUpToken } from '~/store/reducers/wellnessPlans'
import { Membership } from '~/types'
import { getKioskDomain, getKioskUrl } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import MembershipSignUpSuccessSection from './MembershipSignUpSuccessSection'

export enum SignUpStep {
  IFRAME = 'IFRAME',
  SUCCESS = 'SUCCESS',
}

interface MembershipSignUpIframeProps {
  className?: string
  clientId: string | Nil
  onClose?: ((event?: any) => void) | undefined
  onStateChange?: (state: SignUpStep) => void
  patientId?: string | Nil
  showFullDetails?: boolean
}

type PatientInfo = {
  patientId: string
  planId: string
}

const MembershipSignUpIframe = ({
  showFullDetails,
  clientId,
  patientId,
  onClose,
  className,
  onStateChange,
}: MembershipSignUpIframeProps) => {
  const dispatch = useDispatch()

  const businessId = useSelector(getCurrentBusinessId)
  const billingAddresses = useSelector(getBillingAddresses)
  const client = useSelector(getUser(clientId))
  const token = useSelector(getWellnessPlanSignUpToken)

  const [frameLoaded, setFrameLoaded] = useState(false)
  const [step, setStep] = useState(SignUpStep.IFRAME)
  const [selection, setSelection] = useState<PatientInfo[]>([])
  const [patientIds, setPatientIds] = useState<string[]>([])
  const [openSendHybridMembershipInviteDialog] = useDialog(
    DialogNames.SEND_HYBRID_MEMBERSHIP_INVITE,
  )

  const patientList = useSelector(getPatientsList(patientIds))
  const onClientFetched = () => {
    setStep(SignUpStep.SUCCESS)
  }

  useEffect(() => {
    onStateChange?.(step)
  }, [step])

  const setSuccessAfterClientFetched = useCloseAfterCreation(
    onClientFetched,
    getClientIsLoading,
  )

  useEffect(
    () => () => {
      dispatch(clearWellnessPlanSignUpToken())
    },
    [],
  )

  useEffect(() => {
    if (clientId) {
      dispatch(fetchBillingAddresses(clientId))
    }
  }, [clientId])

  useEffect(() => {
    if (clientId && businessId) {
      dispatch(fetchWellnessPlanSignUpToken(clientId, businessId))
    }
  }, [clientId, businessId])

  const list: string[] = useSelector(patientMembershipsSelectors.getList)

  const patientMemberships: Membership[] = useSelector(
    patientMembershipsSelectors.getMultipleItems(list),
  )

  useEffect(() => {
    if (step === SignUpStep.SUCCESS) {
      dispatch(
        patientMembershipsActions.refreshList(
          0,
          Math.max(patientMemberships.length - 1, 0),
          clientId,
        ),
      )
    }
  }, [step])

  const handleIframeMessage = (message: MessageEvent) => {
    if (message?.data?.type === 'MEMBERSHIP_SIGN_UP_SUCCESS') {
      setSelection(message?.data?.selection)
      setPatientIds(
        message?.data?.selection.map((it: PatientInfo) => it.patientId),
      )

      setSuccessAfterClientFetched()
      dispatch(fetchClient({ clientId }))
    }

    if (message?.data?.type === 'HANDLE_SEND_HYBRID_SIGN_UP_LINK') {
      if (message?.data?.updatedEmail) {
        const updatedUser = { ...client, email: message?.data?.updatedEmail }
        if (updatedUser?.id) {
          dispatch(updateUsers({ [updatedUser.id]: updatedUser }))
        }
      }
      openSendHybridMembershipInviteDialog({
        clientId,
        businessId,
        onSent: onClose,
      })
    }
  }

  const onFrameLoad = (event: IFrameEvent) => {
    const frame = event.target

    const message = {
      type: 'PROVIDE_CLIENT',
      billingAddresses,
      client,
    }

    // Sometimes the message is not posted properly. This is a workaround to send the message correctly
    Promise.resolve(
      setTimeout(() => {
        frame.contentWindow?.postMessage(message, getKioskDomain())
      }, 200),
    )
    setFrameLoaded(true)
  }

  useEffect(() => {
    if (frameLoaded) {
      window.addEventListener('message', handleIframeMessage)
    }
    return () => {
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [frameLoaded])

  const route = showFullDetails ? 'plan-details' : 'plan-overview'

  const KioskUrl = getKioskUrl(`membership/${route}`, {
    businessId,
    token,
    portalEmbed: true,
    showFullDetails,
    priorityPatientId: patientId,
  })

  return (
    <>
      {step === SignUpStep.IFRAME &&
        (token ? (
          // eslint-disable-next-line react/iframe-missing-sandbox
          <iframe
            className={className}
            frameBorder="0"
            src={KioskUrl}
            title="sign-up page"
            onLoad={onFrameLoad}
          />
        ) : (
          <Box height="100%" minHeight={120} position="relative">
            <CircularProgressOverlay open />
          </Box>
        ))}
      {step === SignUpStep.SUCCESS && (
        <MembershipSignUpSuccessSection
          clientId={clientId}
          patientNames={patientList.map((it) => it.name)}
          selection={selection}
          onClose={onClose}
        />
      )}
    </>
  )
}

export default MembershipSignUpIframe
