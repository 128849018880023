import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ButtonWithLoader, PermissionArea } from '@pbt/pbt-ui-components'

import { PrescriptionChewyCheckoutActiveRxActions } from '~/components/dashboard/prescription/prescription-editor/actions/PrescriptionChewyCheckoutActiveRxActions'
import { PrescriptionWorkflowType } from '~/constants/PrescriptionWorkflow'
import {
  getPrescriptionIsLoading,
  getPrescriptionIsUpdating,
} from '~/store/duck/prescriptions'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getMemberIsLoading } from '~/store/reducers/members'
import { getWorkflowType } from '~/utils/prescription'
import { usePrescriptionLoading } from '~/utils/usePrescriptionLoading'

import {
  PrescriptionActionContext,
  PrescriptionActionExpandedContext,
} from '../../PrescriptionContext'
import { useCanApproveAndSubmitChewyActiveRx } from '../hooks/useCanApproveAndSubmitChewyActiveRx'
import { PrescriptionBodyProps } from '../PrescriptionBody'
import { PrescriptionChewyActiveRxActions } from './PrescriptionChewyActiveRxActions'
import { PrescriptionChewyReactiveRxActions } from './PrescriptionChewyReactiveRxActions'

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: 120,
      height: 40,
    },
    saveButton: {
      width: 152,
    },
    declineButton: {
      width: 140,
    },
  }),
  { name: 'PrescriptionActions' },
)

export interface PrescriptionActionsProps
  extends Pick<PrescriptionBodyProps, 'currentDoctorHasDvmLicense'> {
  outsideSoap?: boolean
  workflow?: PrescriptionWorkflowType
}

const PrescriptionActions = ({
  currentDoctorHasDvmLicense,
  outsideSoap,
  workflow,
}: PrescriptionActionsProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Tooltips'])

  const memberIsLoading = useSelector(getMemberIsLoading)
  const prescriptionIsLoading = useSelector(getPrescriptionIsLoading)
  const prescriptionIsUpdating = useSelector(getPrescriptionIsUpdating)
  const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))

  const actionContext = useContext(PrescriptionActionContext)

  const {
    isDvmLicenseRequired,
    onEmail,
    onPrint,
    onSave,
    isEdit,
    isRetailOrderLineItem,
  } = actionContext

  const { isLoading, isSending, isEmailing, isPrinting } =
    usePrescriptionLoading(outsideSoap)
  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  const isChewyCheckoutPrescription = isEdit
    ? isRetailOrderLineItem
    : isChewyCheckoutEnabled

  const isLoadingOrUpdating =
    isLoading || prescriptionIsLoading || prescriptionIsUpdating
  const { isChewyReactiveRx, isChewyActiveRx, isOutsidePharmacy, isInHouseRx } =
    getWorkflowType(workflow)

  const needsDvmLicenseEntry =
    isDvmLicenseRequired &&
    (memberIsLoading || currentDoctorHasDvmLicense === false)

  const canApproveAndSubmitChewyRx =
    useCanApproveAndSubmitChewyActiveRx(needsDvmLicenseEntry)

  const needsDvmLicenseEntryTooltip =
    needsDvmLicenseEntry && !memberIsLoading
      ? t('Tooltips:PRESCRIPTION_NEED_DVM_LICENSE')
      : null

  const expandedContext = useMemo(
    () => ({
      ...actionContext,
      canApproveAndSubmitChewyRx,
      isLoadingOrUpdating,
      isSending: Boolean(isSending),
      needsDvmLicenseEntry,
      needsDvmLicenseEntryTooltip,
    }),
    [
      actionContext,
      canApproveAndSubmitChewyRx,
      isLoadingOrUpdating,
      isSending,
      needsDvmLicenseEntry,
      needsDvmLicenseEntryTooltip,
    ],
  )

  if (isChewyActiveRx) {
    return (
      <PrescriptionActionExpandedContext.Provider value={expandedContext}>
        {isChewyCheckoutPrescription ? (
          <PrescriptionChewyCheckoutActiveRxActions classes={classes} />
        ) : (
          <PrescriptionChewyActiveRxActions classes={classes} />
        )}
      </PrescriptionActionExpandedContext.Provider>
    )
  }

  if (isChewyReactiveRx) {
    return (
      <PrescriptionActionExpandedContext.Provider value={expandedContext}>
        <PrescriptionChewyReactiveRxActions
          classes={{
            button: classes.button,
            declineButton: classes.declineButton,
            saveButton: classes.saveButton,
          }}
        />
      </PrescriptionActionExpandedContext.Provider>
    )
  }

  return (
    <Grid display="flex" gap={2}>
      <ButtonWithLoader
        className={classNames(classes.button, classes.saveButton)}
        disabled={!permissions.update || isLoadingOrUpdating}
        key="save"
        loading={isSending}
        onClick={() => onSave()}
      >
        {t('Common:SAVE_AND_CLOSE_ACTION')}
      </ButtonWithLoader>

      {(isInHouseRx || isOutsidePharmacy) && (
        <ButtonWithLoader
          className={classes.button}
          disabled={!permissions.update || isLoadingOrUpdating}
          key="print"
          loading={isPrinting}
          onClick={onPrint}
        >
          {t('Common:PRINT_ACTION')}
        </ButtonWithLoader>
      )}

      {isOutsidePharmacy && (
        <ButtonWithLoader
          className={classes.button}
          disabled={!permissions.update || isLoadingOrUpdating}
          key="email"
          loading={isEmailing}
          onClick={onEmail}
        >
          {t('Common:EMAIL')}
        </ButtonWithLoader>
      )}
    </Grid>
  )
}

export default PrescriptionActions
