import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import { PuiCheckbox } from '@pbt/pbt-ui-components'
import { getConstantName } from '@pbt/pbt-ui-components/src/utils'

import Typography from '~/components/elements/Typography/Typography'
import DialogNames from '~/constants/DialogNames'
import { getDocumentInputAction } from '~/store/reducers/constants'
import {
  CheckboxState,
  DocumentElementStateItem,
  internalDisplayPreferenceLabel,
} from '~/types'
import useDialog from '~/utils/useDialog'

import { DocumentElementAccordion } from './DocumentElementAccordion'

interface DocumentCheckboxProps {
  documentElement: CheckboxState
  isDragging?: boolean
  onDelete?: () => void
  onUpdate?: (documentElement: Partial<DocumentElementStateItem>) => void
}

export const DocumentCheckbox = ({
  documentElement,
  isDragging,
  onUpdate,
  onDelete,
}: DocumentCheckboxProps) => {
  const { t } = useTranslation('Common')

  const DocumentInputAction = useSelector(getDocumentInputAction)

  const [openAddSingleCheckboxDialog] = useDialog(
    DialogNames.ADD_SINGLE_CHECKBOX,
  )

  const inputActionName = getConstantName(
    documentElement.option?.inputAction,
    DocumentInputAction,
  )

  const rhapsodyDisplay =
    documentElement.option?.internalName ||
    internalDisplayPreferenceLabel[
      documentElement.option?.internalDisplayPreference
    ]

  return (
    <Box mb={2} px={3}>
      <DocumentElementAccordion
        isDragging={isDragging}
        title={t('Common:SINGLE_CHECKBOX')}
        onDelete={onDelete}
        onEdit={
          onUpdate
            ? () =>
                openAddSingleCheckboxDialog({
                  onSave: onUpdate,
                  documentElement,
                })
            : undefined
        }
      >
        <Grid container p={2}>
          <Grid item pb={1} xs={12}>
            <Typography.H2>{documentElement.label || ''}</Typography.H2>
          </Grid>
          <Grid item xs={12}>
            <PuiCheckbox
              label={documentElement.option?.name || ''}
              labelProps={{ sx: { alignItems: 'start' } }}
              sx={{ paddingTop: 0 }}
            />
          </Grid>

          <Grid item pl={4} pt={1} xs={12}>
            <Typography.Paragraph color="grayGray2">
              {`${t('Common:RHAPSODY_DISPLAY')}: ${rhapsodyDisplay}`}
              {Boolean(inputActionName) &&
                ` | ${t('Common:MAPPED_TO_RHAPSODY_DATA_FIELD')}: ${inputActionName}`}
            </Typography.Paragraph>
          </Grid>
        </Grid>
      </DocumentElementAccordion>
    </Box>
  )
}
