import {
  DocumentElementType,
  FileTemplate,
  GenderRestriction,
  InternalDisplayPreference,
  Nil,
  User,
} from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

export const internalDisplayPreferenceLabel = {
  [InternalDisplayPreference.AS_WRITTEN]: i18n.t('Common:AS_WRITTEN'),
  [InternalDisplayPreference.SUMMARIZED]: i18n.t('Common:SUMMARIZED'),
  [InternalDisplayPreference.DO_NOT_DISPLAY]: i18n.t('Common:DO_NOT_DISPLAY'),
}

export const internalDisplayPreferenceSelectItems = [
  {
    id: InternalDisplayPreference.AS_WRITTEN,
    name: internalDisplayPreferenceLabel[InternalDisplayPreference.AS_WRITTEN],
  },
  {
    id: InternalDisplayPreference.SUMMARIZED,
    name: internalDisplayPreferenceLabel[InternalDisplayPreference.SUMMARIZED],
  },
  {
    id: InternalDisplayPreference.DO_NOT_DISPLAY,
    name: internalDisplayPreferenceLabel[
      InternalDisplayPreference.DO_NOT_DISPLAY
    ],
  },
]

export type DocumentElementStateItem =
  | RichTextWithPlaceholderState
  | CheckboxGroupState
  | CheckboxState
  | RadioGroupState
  | TextEntryState
  | PageBreakState
  | DatePickerState
  | DateAndTimePickerState

type DocumentElementItemEdit = {
  creationDate: string
  creatorId: string
  id: string
  modificationDate: string
  modifierId: string
  sequenceNumber: number
}

type RichTextWithPlaceholderCreate = {
  contentWithPlaceholders: string
  sequenceNumber: number
  type: DocumentElementType.TEXT
}

export type RichTextWithPlaceholderEdit = DocumentElementItemEdit & {
  contentWithPlaceholders: string
  type: DocumentElementType.TEXT
}

export type RichTextWithPlaceholderPreview = RichTextWithPlaceholderEdit & {
  content: string
}

export type RichTextWithPlaceholderState = RichTextWithPlaceholderCreate & {
  id: string
}

type PageBreakCreate = {
  sequenceNumber: number
  type: DocumentElementType.PAGE_BREAK
}

export type PageBreakState = {
  id?: string
  page: number
  sequenceNumber?: number
  totalPages: number
  type?: DocumentElementType.PAGE_BREAK
}

type SelectionOptionCreate = {
  internalDisplayPreference: InternalDisplayPreference
  internalName?: string
  name: string
  sequenceNumber: number
}

type CheckboxOptionCreate = {
  inputAction?: string
  internalDisplayPreference: InternalDisplayPreference
  internalName?: string
  name: string
  sequenceNumber: number
}

type CheckboxOptionEdit = DocumentElementItemEdit & CheckboxOptionCreate
type RadioOptionEdit = DocumentElementItemEdit & RadioOptionCreate

export type CheckboxOptionState = CheckboxOptionCreate & {
  id?: string
}

type CheckboxGroupCreate = {
  label: string
  options: CheckboxOptionCreate[]
  sequenceNumber: number
  type: DocumentElementType.MULTI_SELECT
}

type CheckboxGroupEdit = DocumentElementItemEdit & {
  label: string
  options: CheckboxOptionEdit[]
  type: DocumentElementType.MULTI_SELECT
}

type RadioGroupEdit = DocumentElementItemEdit & {
  label: string
  options: RadioOptionEdit[]
  type: DocumentElementType.SINGLE_SELECT
}

export type CheckboxGroupState = Omit<
  CheckboxGroupCreate,
  'sequenceNumber' | 'type' | 'option'
> & {
  id?: string
  options: CheckboxOptionState[]
  sequenceNumber?: number
  type?: DocumentElementType.MULTI_SELECT
}

export type CheckboxCreate = {
  label?: string
  option: CheckboxOptionCreate
  sequenceNumber: number
  type: DocumentElementType.SINGLE_CHECKBOX
}

export type CheckboxState = Omit<
  CheckboxCreate,
  'sequenceNumber' | 'option' | 'type'
> & {
  id?: string
  option: CheckboxOptionState
  sequenceNumber?: number
  type?: DocumentElementType.SINGLE_CHECKBOX
}

type CheckboxEdit = DocumentElementItemEdit & {
  label?: string
  option: CheckboxOptionEdit
  type: DocumentElementType.SINGLE_CHECKBOX
}

export type DatePickerCreate = {
  label: string
  type: DocumentElementType.DATE
}

export type DateAndTimePickerCreate = {
  label: string
  type: DocumentElementType.DATE_TIME
}

type DatePickerEdit = DocumentElementItemEdit & {
  label: string
  type: DocumentElementType.DATE
}

type DateAndTimePickerEdit = DocumentElementItemEdit & {
  label: string
  type: DocumentElementType.DATE_TIME
}

export type DatePickerState = Omit<
  DatePickerCreate,
  'sequenceNumber' | 'type'
> & {
  id?: string
  sequenceNumber?: number
  type?: DocumentElementType.DATE
}

export type DateAndTimePickerState = Omit<
  DateAndTimePickerCreate,
  'sequenceNumber' | 'type'
> & {
  id?: string
  sequenceNumber?: number
  type?: DocumentElementType.DATE_TIME
}

type RadioOptionCreate = SelectionOptionCreate

export type SelectionOptionState = SelectionOptionCreate & {
  id?: string
}

export type RadioOptionState = SelectionOptionState

type RadioGroupCreate = {
  label: string
  options: RadioOptionCreate[]
  sequenceNumber: number
  type: DocumentElementType.SINGLE_SELECT
}

export type RadioGroupState = Omit<
  RadioGroupCreate,
  'sequenceNumber' | 'type' | 'option'
> & {
  id?: string
  options: RadioOptionCreate[]
  sequenceNumber?: number
  type?: DocumentElementType.SINGLE_SELECT
}

type PageBreakEdit = DocumentElementItemEdit & {
  type: DocumentElementType.PAGE_BREAK
}

type SignatureBoxCreate = {
  sequenceNumber: number
  type: DocumentElementType.SIGNATURE
}

type SignatureBoxEdit = DocumentElementItemEdit & {
  type: DocumentElementType.SIGNATURE
}

export type TextEntryCreate = {
  label: string
  type: DocumentElementType.TEXT_FIELD
}

export type TextEntryState = Omit<
  TextEntryCreate,
  'sequenceNumber' | 'type'
> & {
  id?: string
  sequenceNumber?: number
  type?: DocumentElementType.TEXT_FIELD
}

type TextEntryEdit = DocumentElementItemEdit & {
  label: string
  type: DocumentElementType.TEXT_FIELD
}

export type DocumentElementEdit =
  | RichTextWithPlaceholderEdit
  | PageBreakEdit
  | CheckboxEdit
  | CheckboxGroupEdit
  | RadioGroupEdit
  | SignatureBoxEdit
  | TextEntryEdit
  | DatePickerEdit
  | DateAndTimePickerEdit

export type ExpirationPeriodSpecifier = 'Y' | 'M' | 'D'

// For this use case, we only consider periods with one specifier
// Ex: 'P1Y' or 'P3M' but not 'P1Y3M'
export type ExpirationPeriod = `P${number}${ExpirationPeriodSpecifier}`

export type Document = {
  active?: boolean
  appointmentTypeIds?: string[]
  author?: User
  businessId?: string
  creationDate?: string
  displayLocationIds?: string[]
  expirationDateOffset?: ExpirationPeriod | Nil
  extension?: string | Nil
  file?: FileTemplate
  fileUrl?: string | Nil
  genderRestrictions?: GenderRestriction[]
  id: string
  includeInKiosk?: boolean
  name: string
  required?: boolean
  soapId?: string | Nil
  speciesIds?: string[]
  subject?: string
  template?: {
    body?: string
    documentElements?: DocumentElementEdit[]
    extension?: string | Nil
    file?: any
    fileName?: string | Nil
    fileUrl?: string | Nil
  }
  type?: string
}

export type DocumentElementCreate =
  | RichTextWithPlaceholderCreate
  | PageBreakCreate
  | CheckboxCreate
  | CheckboxGroupCreate
  | RadioGroupCreate
  | SignatureBoxCreate
  | TextEntryCreate
  | DatePickerCreate
  | DateAndTimePickerCreate

export type DocumentCreate = Omit<Document, 'id' | 'template'> & {
  template?: Omit<Document['template'], 'documentElements'> & {
    documentElements?: DocumentElementCreate[]
  }
}

export type DocumentContext = {
  clientId?: string | Nil
  eventId?: string | Nil
  patientId?: string | Nil
  soapId?: string | Nil
}

export type DocumentToSend = {
  document: Blob
  file: any
  id: string
}
