import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Defaults, FileTemplate, Nil } from '@pbt/pbt-ui-components'

import { Document } from '~/types'
import { mergeArraysAtIndex } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import {
  CLEAR_RESOLVED_DOCUMENT,
  CREATE_DOCUMENT,
  CREATE_DOCUMENT_FAILURE,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_WITH_TEMPLATE,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_FAILURE,
  DELETE_DOCUMENT_SUCCESS,
  EDIT_DOCUMENT,
  EDIT_DOCUMENT_FAILURE,
  EDIT_DOCUMENT_SUCCESS,
  EDIT_DOCUMENT_WITH_TEMPLATE,
  FETCH_DOCUMENT,
  FETCH_DOCUMENT_FAILURE,
  FETCH_DOCUMENT_SUCCESS,
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_BY_TYPE,
  FETCH_DOCUMENTS_BY_TYPE_FAILURE,
  FETCH_DOCUMENTS_BY_TYPE_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
  FETCH_DOCUMENTS_LIST,
  FETCH_DOCUMENTS_LIST_FAILURE,
  FETCH_DOCUMENTS_LIST_SUCCESS,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST,
  FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST_SUCCESS,
  FETCH_RESOLVED_DOCUMENT,
  FETCH_RESOLVED_DOCUMENT_BODY,
  FETCH_RESOLVED_DOCUMENT_BODY_FAILURE,
  FETCH_RESOLVED_DOCUMENT_BODY_SUCCESS,
  FETCH_RESOLVED_DOCUMENT_FAILURE,
  FETCH_RESOLVED_DOCUMENT_SUCCESS,
  FETCH_RESOLVED_DOCUMENTS,
  FETCH_RESOLVED_DOCUMENTS_FAILURE,
  FETCH_RESOLVED_DOCUMENTS_SUCCESS,
  GENERATE_DOCUMENT_INSTANCES,
  GENERATE_DOCUMENT_INSTANCES_FAILURE,
  GENERATE_DOCUMENT_INSTANCES_SUCCESS,
  GENERATE_FILE_FOR_DOCUMENT_TEMPLATE,
  GENERATE_FILE_FOR_DOCUMENT_TEMPLATE_FAILURE,
  GENERATE_FILE_FOR_DOCUMENT_TEMPLATE_SUCCESS,
  RESET_DOCUMENTS,
  UPDATE_DOCUMENTS,
} from '../actions/types/documents'
import type { RootState } from '../index'

export type DocumentsState = {
  error: null | string
  generatedDocumentTemplateFile: FileTemplate | null
  isDeleting: boolean
  isGenerating: boolean
  isListFetching: boolean
  isReceiving: boolean
  isSending: boolean
  lastCreatedDocumentId: string | null
  lastGeneratedDocumentInstances: Document[]
  list: string[]
  map: Record<string, Document>
  resolvedDocument: Document | null
  resolvedDocumentBody: string | null
  totalCount: number
  typedList: string[]
}

export const INITIAL_STATE: DocumentsState = {
  list: [],
  map: {},
  lastGeneratedDocumentInstances: [],
  isDeleting: false,
  isReceiving: false,
  isSending: false,
  isGenerating: false,
  isListFetching: false,
  error: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  lastCreatedDocumentId: null,
  generatedDocumentTemplateFile: null,
  resolvedDocumentBody: null,
  resolvedDocument: null,
  typedList: [],
}

const documents = (
  state: DocumentsState = INITIAL_STATE,
  action: AnyAction,
): DocumentsState => {
  switch (action.type) {
    case FETCH_DOCUMENTS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isListFetching: false,
      }
    case FETCH_DOCUMENTS_LIST_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isListFetching: false,
      }
    case FETCH_DOCUMENTS_LIST:
      return {
        ...state,
        error: null,
        isListFetching: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        list: [],
      }
    case FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST:
      return { ...state, isListFetching: true, error: null }
    case FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isListFetching: false,
      }
    case FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isListFetching: false,
        totalCount: action.totalCount,
      }
    case UPDATE_DOCUMENTS:
      return {
        ...state,
        map: R.mergeDeepRight(state.map, action.documents),
        error: null,
      }
    case FETCH_DOCUMENTS_BY_TYPE:
      return { ...state, isReceiving: true, typedList: [], error: null }
    case FETCH_DOCUMENTS_BY_TYPE_SUCCESS:
      return { ...state, isReceiving: false, typedList: R.uniq(action.list) }
    case FETCH_DOCUMENTS_BY_TYPE_FAILURE:
      return {
        ...state,
        isReceiving: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_DOCUMENT:
    case FETCH_DOCUMENTS:
    case FETCH_RESOLVED_DOCUMENT_BODY:
    case FETCH_RESOLVED_DOCUMENTS:
    case FETCH_RESOLVED_DOCUMENT:
      return { ...state, isReceiving: true, error: null }
    case CREATE_DOCUMENT:
    case CREATE_DOCUMENT_WITH_TEMPLATE:
    case EDIT_DOCUMENT:
    case EDIT_DOCUMENT_WITH_TEMPLATE:
      return { ...state, isSending: true, error: null }
    case FETCH_DOCUMENT_SUCCESS:
    case FETCH_DOCUMENTS_SUCCESS:
    case FETCH_RESOLVED_DOCUMENTS_SUCCESS:
      return { ...state, isReceiving: false }
    case EDIT_DOCUMENT_SUCCESS:
      return { ...state, isSending: false }
    case FETCH_DOCUMENT_FAILURE:
    case FETCH_DOCUMENTS_FAILURE:
    case FETCH_RESOLVED_DOCUMENT_BODY_FAILURE:
    case FETCH_RESOLVED_DOCUMENTS_FAILURE:
    case FETCH_RESOLVED_DOCUMENT_FAILURE:
      return {
        ...state,
        isReceiving: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_DOCUMENT_FAILURE:
    case EDIT_DOCUMENT_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        map: R.omit([action.documentId], state.map),
        list: R.without([action.documentId], state.list),
        totalCount: Math.max(state.totalCount - 1, 0),
      }
    case DELETE_DOCUMENT:
      return { ...state, isDeleting: true, error: null }
    case CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isSending: false,
        lastCreatedDocumentId: action.documentId,
      }
    case FETCH_RESOLVED_DOCUMENT_BODY_SUCCESS:
      return { ...state, isReceiving: false, resolvedDocumentBody: action.body }
    case FETCH_RESOLVED_DOCUMENT_SUCCESS:
      return { ...state, isReceiving: false, resolvedDocument: action.document }
    case GENERATE_FILE_FOR_DOCUMENT_TEMPLATE:
      return { ...state, isGenerating: true, error: null }
    case GENERATE_FILE_FOR_DOCUMENT_TEMPLATE_FAILURE:
      return {
        ...state,
        isGenerating: false,
        error: getErrorMessage(action.error),
      }
    case GENERATE_FILE_FOR_DOCUMENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        isGenerating: false,
        generatedDocumentTemplateFile: action.document,
      }

    case GENERATE_DOCUMENT_INSTANCES:
      return { ...state, isGenerating: true, error: null }
    case GENERATE_DOCUMENT_INSTANCES_SUCCESS:
      return {
        ...state,
        isGenerating: false,
        lastGeneratedDocumentInstances: action.instances,
      }
    case GENERATE_DOCUMENT_INSTANCES_FAILURE:
      return {
        ...state,
        isGenerating: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_RESOLVED_DOCUMENT:
      return {
        ...state,
        resolvedDocument: null,
      }
    case RESET_DOCUMENTS:
      return INITIAL_STATE
    default:
      return state
  }
}

export default documents
export const getDocuments = (state: RootState): DocumentsState =>
  state.documents
export const getDocumentsList = (state: RootState) => getDocuments(state).list
export const getDocumentsMap = (state: RootState) => getDocuments(state).map
export const getDocument = (id: string | Nil) =>
  createSelector(getDocumentsMap, (map) => (id ? map[id] : undefined))
export const getMultipleDocuments = (ids: string[]) =>
  createSelector(getDocumentsMap, (map) => R.props(ids, map))
export const getDocumentsIsSending = (state: RootState) =>
  getDocuments(state).isSending
export const getDocumentsIsReceiving = (state: RootState) =>
  getDocuments(state).isReceiving
export const getDocumentsIsGenerating = (state: RootState) =>
  getDocuments(state).isGenerating
export const getDocumentsIsListFetching = (state: RootState) =>
  getDocuments(state).isListFetching
export const getDocumentsIsDeleting = (state: RootState) =>
  getDocuments(state).isDeleting
export const getDocumentsError = (state: RootState) => getDocuments(state).error
export const getDocumentsTotalCount = (state: RootState) =>
  getDocuments(state).totalCount
export const getLastCreatedDocumentId = (state: RootState) =>
  getDocuments(state).lastCreatedDocumentId
export const getResolvedDocumentBody = (state: RootState) =>
  getDocuments(state).resolvedDocumentBody
export const getResolvedDocument = (state: RootState) =>
  getDocuments(state).resolvedDocument
export const getGeneratedDocumentTemplateFile = (state: RootState) =>
  getDocuments(state).generatedDocumentTemplateFile
export const getLastGeneratedDocumentInstances = (state: RootState) =>
  getDocuments(state).lastGeneratedDocumentInstances
export const getDocumentsTypedList = (state: RootState) =>
  getDocuments(state).typedList
