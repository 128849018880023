import React, { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { CurrencyTextField, Field } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import DiscountTooltip from '~/components/dashboard/invoices/DiscountTooltip'
import {
  getIsRetailOrderLineItem,
  isGroupedInvoiceItem,
} from '~/components/dashboard/invoices/invoiceUtils'
import FeatureToggle from '~/constants/featureToggle'
import { discountEditRoleBlacklist } from '~/constants/roleNames'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { getFeatureToggle } from '~/store/reducers/constants'
import { InvoiceLineItem, InvoiceLineItemPayload } from '~/types'
import useHasRole from '~/utils/useHasRole'

import { useGetIsDeclinedLineItem } from '../../utils/useDeclineOrderItem'
import ChargeDetailsFieldWrapper from './ChargeDetailsFieldWrapper'

export interface ChargeFieldDiscountAmountProps {
  className?: string
  disabled: boolean
  discountAmountField: Field
  inputClassName?: string
  item: InvoiceLineItem | RetailOrderLineItem
  onEditInvoiceLineItem: (payload: InvoiceLineItemPayload) => void
}

const ChargeFieldDiscountAmount = ({
  className: classNameProp,
  disabled: disabledProp,
  discountAmountField,
  inputClassName,
  item,
  onEditInvoiceLineItem,
}: ChargeFieldDiscountAmountProps) => {
  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )
  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()
  const isRetailOrderLineItem =
    getIsRetailOrderLineItem(item) && isChewyCheckoutEnabled

  const canEditDiscount = !useHasRole(discountEditRoleBlacklist)
  const canHaveDiscount = getIsRetailOrderLineItem(item)
    ? false // RetailOrderLineItem has no concept of discountAllowed
    : item.discountAllowed

  const disabled =
    isRetailOrderLineItem ||
    !canEditDiscount ||
    (isEditPostedChargesEnabled
      ? disabledProp
      : disabledProp || isGroupedInvoiceItem(item)
        ? true
        : !canHaveDiscount) // RetailLineOrderItems will never be allowed to have a manual discount applied here
  const isDeclined = useGetIsDeclinedLineItem(item)

  if (isDeclined) {
    return null
  }

  return (
    <ChargeDetailsFieldWrapper
      disabled={disabled}
      label={discountAmountField.label}
    >
      {canHaveDiscount || item.discountAmount ? (
        <CurrencyTextField
          noLonelyAdornment
          selectZeroOnFocus
          InputProps={{ disableUnderline: !canHaveDiscount }}
          className={classNameProp}
          disabled={disabled}
          field={{
            ...discountAmountField,
            set: (event: ChangeEvent<HTMLInputElement>) => {
              const newValue = Number(event.target.value)
              discountAmountField.setValue(newValue)
              onEditInvoiceLineItem({ discountAmount: newValue })
            },
          }}
          fullWidth={false}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            'aria-labelledby': 'charge-field-discount-amount',
            className: inputClassName,
          }}
          margin="none"
        />
      ) : (
        <DiscountTooltip />
      )}
    </ChargeDetailsFieldWrapper>
  )
}

export default ChargeFieldDiscountAmount
