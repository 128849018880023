import React from 'react'
import { Grid } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

import { SoapForm } from '~/types'

import { getFormSelectedOptions } from '../../getFormSelectedOptions'

interface FormNameProps {
  form: SoapForm
}

const FormName = ({ form }: FormNameProps) => {
  if (!form) {
    return null
  }

  const options = getFormSelectedOptions(form)

  return (
    <Grid
      container
      alignItems="center"
      direction="row"
      display="flex"
      justifyContent="space-between"
      width="100%"
    >
      <Grid container item alignItems="center" height="40px" xs={12}>
        <Text noWrap variant="body2">
          {form.name}
        </Text>
      </Grid>
      {options?.map((option) => (
        <Grid
          container
          item
          alignItems="center"
          height="40px"
          key={option}
          paddingLeft="16px"
          xs={12}
        >
          <Text noWrap variant="body2">
            {option}
          </Text>
        </Grid>
      ))}
    </Grid>
  )
}

export default FormName
