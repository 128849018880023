import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material'
import { Grid, IconButton, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LanguageUtils, TextInteractive } from '@pbt/pbt-ui-components'

import FormStateLabel from '~/components/dashboard/soapV2/forms/FormStateLabel'
import { SoapForm } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    row: {
      flexShrink: 0,
    },
    iconButton: {
      padding: 0,
    },
    icon: {
      color: theme.colors.lowAccentText,
    },
  }),
  { name: 'FormsListItem' },
)

export interface FormsListItemProps {
  className?: string
  form?: SoapForm
  handleToggleActions: (event: React.MouseEvent, form?: SoapForm) => void
}

const FormsListItem = ({
  className,
  form,
  handleToggleActions,
}: FormsListItemProps) => {
  const classes = useStyles()
  const isLoading = !form

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(className, classes.row)}
      pl={2}
      pr={1}
      py={0.5}
      wrap="nowrap"
    >
      <Grid item xs>
        <Dotdotdot clamp={1}>
          <TextInteractive
            disableSkeletonAnimation
            isLoading={isLoading}
            loaderWidth={165}
            variant="body3"
          >
            {LanguageUtils.getTranslatedFieldName(form)}
          </TextInteractive>
        </Dotdotdot>
      </Grid>
      <Grid container item alignItems="center" px={0.5} xs={2.2}>
        {isLoading ? (
          <Skeleton animation={false} width={40} />
        ) : (
          <FormStateLabel form={form} variant="small" />
        )}
      </Grid>
      <IconButton
        className={classes.iconButton}
        onClick={(event) => handleToggleActions(event, form)}
      >
        {isLoading ? (
          <Skeleton
            animation={false}
            height={24}
            variant="circular"
            width={24}
          />
        ) : (
          <MoreHorizIcon className={classes.icon} />
        )}
      </IconButton>
    </Grid>
  )
}

export default FormsListItem
