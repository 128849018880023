import isPropValid from '@emotion/is-prop-valid'
import { KeyboardArrowRightRounded } from '@mui/icons-material'
import { SvgIconProps } from '@mui/material'
import { styled } from '@mui/material/styles'

type CollapseArrowProps = SvgIconProps & {
  expanded?: boolean
  expandedDirection?: 'up' | 'down'
}

export const CollapseArrow = styled(KeyboardArrowRightRounded, {
  shouldForwardProp: isPropValid,
})<CollapseArrowProps>`
  cursor: pointer;
  transition: ${({ theme }) =>
    theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    })};
  color: ${({ theme }) => theme.colors.grayGray1};
  transform: ${({ expanded = false, expandedDirection = 'up' }) =>
    expanded
      ? `rotate(${expandedDirection === 'up' ? '-' : ''}90deg)`
      : 'rotate(0deg)'};
`
