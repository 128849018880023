import React, { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { TextWithTooltip } from '@pbt/pbt-ui-components'

import {
  clearOrders,
  clearSearchOrders,
  fetchMoreForSearch,
  fetchMoreOrders,
  fetchOrders,
  searchOrders,
} from '~/store/actions/orders'
import { OrderListType, OrderSearchResult } from '~/types'
import useGetIsCatalogSelectableItem from '~/utils/useGetIsCatalogSelectableItem'

import ListWithFilters, {
  ListWithFiltersHandle,
  ListWithFiltersProps,
} from './ListWithFilters'

export interface OrderListWithFiltersProps
  extends Omit<ListWithFiltersProps, 'setEnableBackendFiltering'> {
  clientId?: string
  eventId?: string
  forShipments?: boolean
  includeOnHandQuantity?: boolean
  labVendorId?: string
  patientId?: string
  preselectedFilter?: string
  preselectedListType?: OrderListType
  searchResults?: OrderSearchResult[]
  setEnableBackendFiltering?: (value: boolean) => void
  showTooltip?: boolean
  withTasks?: boolean
}

const OrderListWithFilters = forwardRef<
  ListWithFiltersHandle,
  OrderListWithFiltersProps
>(function OrderListWithFilters(
  {
    searchResults,
    labVendorId,
    showTooltip = true,
    isSelectableItem,
    patientId,
    clientId,
    eventId,
    withTasks = false,
    forShipments = false,
    preselectedFilter,
    preselectedListType,
    includeOnHandQuantity,
    enableBackendFiltering: enableBackendFilteringProp,
    setEnableBackendFiltering: setEnableBackendFilteringProp,
    ...rest
  },
  ref,
) {
  const dispatch = useDispatch()

  const { t } = useTranslation(['Soap', 'Tooltips'])

  const [enableBackendFilteringState, setEnableBackendFilteringState] =
    useState(true)

  const enableBackendFiltering =
    enableBackendFilteringProp ?? enableBackendFilteringState
  const setEnableBackendFiltering =
    setEnableBackendFilteringProp ?? setEnableBackendFilteringState

  const getEntityType = (entityType: string) => preselectedFilter ?? entityType
  const getListType = (listType: OrderListType) =>
    preselectedListType ?? listType

  const defaultIsSelectableItem = useGetIsCatalogSelectableItem()

  return (
    <ListWithFilters
      showLabelState
      clearItems={() => dispatch(clearOrders())}
      clientId={clientId}
      enableBackendFiltering={enableBackendFiltering}
      filtersToggleLabel={
        <TextWithTooltip
          tooltipText={t('Tooltips:ORDER_LIST_WITH_FILTERS_TOGGLE')}
          variant="body2"
        >
          {t('Soap:LIST_WITH_FILTERS.ORDER_LIST_WITH_FILTERS_LABEL')}
        </TextWithTooltip>
      }
      includeOnHandQuantity={includeOnHandQuantity}
      isSelectableItem={isSelectableItem || defaultIsSelectableItem}
      loadMore={(options) => {
        if (options.searchTerm) {
          dispatch(
            fetchMoreForSearch({
              ...options,
              patientId,
              clientId,
              eventId,
              withTasks,
              labVendorId,
              applyPatientRestriction: enableBackendFiltering,
            }),
          )
        } else {
          dispatch(
            fetchMoreOrders({
              ...options,
              entityType: getEntityType(options.entityType),
              listType: getListType(options.listType),
              patientId,
              clientId,
              eventId,
              withTasks,
              forShipments,
              labVendorId,
              applyPatientRestriction: enableBackendFiltering,
            }),
          )
        }
      }}
      loadNewItems={(options) => {
        dispatch(
          fetchOrders({
            ...options,
            entityType: getEntityType(options.entityType),
            listType: getListType(options.listType),
            patientId,
            clientId,
            eventId,
            withTasks,
            forShipments,
            labVendorId,
            applyPatientRestriction: enableBackendFiltering,
          }),
        )
      }}
      patientId={patientId}
      ref={ref}
      searchItems={(params) => {
        dispatch(
          searchOrders({
            ...params,
            patientId,
            clientId,
            eventId,
            withTasks,
            labVendorId,
            forShipments,
            applyPatientRestriction: enableBackendFiltering,
          }),
        )
      }}
      searchResults={searchResults}
      setEnableBackendFiltering={setEnableBackendFiltering}
      showFiltersToggle={Boolean(patientId)}
      showTooltip={showTooltip}
      onSearchChange={() => dispatch(clearSearchOrders())}
      {...rest}
    />
  )
})

export default OrderListWithFilters
