import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { IconVariant } from '@pbt/pbt-ui-components/src/constants/icons'
import { Wellness as WellnessIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  () => ({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      fontSize: '1.9rem',
    },
    openBoopIcon: {
      fontSize: '2.7rem',
    },
  }),
  { name: 'MembershipIcon' },
)

interface MembershipIconProps {
  className?: string
  limitReached?: boolean
}

const MembershipIcon = ({ className, limitReached }: MembershipIconProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Tooltips')

  const Icon = (
    <span className={classes.container}>
      <WellnessIcon
        className={classNames(className, classes.icon)}
        data-testid="wplan-icon"
        variant={limitReached ? IconVariant.WARNING : IconVariant.SECONDARY}
      />
    </span>
  )

  if (limitReached) {
    return (
      <Tooltip
        placement="top"
        title={t('Tooltips:MEMBERSHIP_PLAN_LIMIT_REACHED')}
      >
        {Icon}
      </Tooltip>
    )
  }

  return Icon
}

export default MembershipIcon
