import React from 'react'

import { DocumentCloning } from './DocumentCloning'
import { DocumentCreation } from './DocumentCreation'
import { DocumentEdit } from './DocumentEdit'

export interface DocumentDetailsContainerProps {
  itemId: string
  onClose: () => void
}

export const DocumentDetailsContainer = ({
  itemId,
  onClose,
}: DocumentDetailsContainerProps) => {
  switch (itemId) {
    case 'new':
      return <DocumentCreation onClose={onClose} />
    case 'clone':
      return <DocumentCloning onClose={onClose} />
    default:
      return <DocumentEdit itemId={itemId} onClose={onClose} />
  }
}
