import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import EmailPasswordSuccess from '~/components/common/auth/EmailPasswordSuccess'
import ForgotPasswordForm from '~/components/common/auth/ForgotPasswordForm'
import RegisterButton from '~/components/common/auth/RegisterButton'
import TermsAndCondition from '~/components/common/auth/TermsAndCondition'
import { sendResetPasswordEmail } from '~/store/actions/auth'
import { getAuthError, getAuthIsResetEmailSending } from '~/store/reducers/auth'

interface ForgotPasswordPageProps {
  showRegisterButton?: boolean
  showTermsAndConditions?: boolean
}

const ForgotPasswordPage = ({
  showRegisterButton = false,
  showTermsAndConditions = true,
}: ForgotPasswordPageProps) => {
  const { t } = useTranslation('Auth')
  const dispatch = useDispatch()
  const isEmailSending = useSelector(getAuthIsResetEmailSending)
  const error = useSelector(getAuthError)

  const [isPasswordResetRequested, setIsPasswordResetRequested] =
    useState(false)

  const resetPassword = (email: string) => {
    dispatch(sendResetPasswordEmail(email))
    setIsPasswordResetRequested(true)
  }

  const errorMessage =
    error || t('Auth:FORGOT_PASSWORD_COMPONENT.ERROR_MESSAGE')

  return (
    <>
      {isPasswordResetRequested && !isEmailSending && !error ? (
        <EmailPasswordSuccess />
      ) : (
        <ForgotPasswordForm
          errorMessage={errorMessage || undefined}
          isFetching={isEmailSending}
          onSubmit={resetPassword}
        />
      )}
      {showRegisterButton && <RegisterButton />}
      {showTermsAndConditions && <TermsAndCondition />}
    </>
  )
}

export default ForgotPasswordPage
