import React from 'react'
import { SelectChangeEvent } from '@mui/material'
import { Field } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import YesNoSelect from '~/components/common/inputs/YesNoSelect'
import {
  getIsRetailOrderLineItem,
  isGroupedInvoiceItem,
} from '~/components/dashboard/invoices/invoiceUtils'
import { InvoiceLineItem, InvoiceLineItemPayload } from '~/types'

import { useGetIsDeclinedLineItem } from '../../utils/useDeclineOrderItem'
import ChargeDetailsFieldWrapper from './ChargeDetailsFieldWrapper'

export interface ChargeFieldTaxedProps {
  className?: string
  disabled: boolean
  item: InvoiceLineItem | RetailOrderLineItem
  onEditInvoiceLineItem: (payload: InvoiceLineItemPayload) => void
  taxField: Field
  taxedField: Field
}

const ChargeFieldTaxed = ({
  className: classNameProp,
  disabled: disabledProp,
  item,
  onEditInvoiceLineItem,
  taxField,
  taxedField,
}: ChargeFieldTaxedProps) => {
  const isRetailOrderLineItem = getIsRetailOrderLineItem(item)

  const disabled =
    disabledProp ||
    isGroupedInvoiceItem(item) ||
    (!isRetailOrderLineItem && !item.priceTaxable) ||
    isRetailOrderLineItem // Cannot edit taxes on RetailOrderLineItem
  const isDeclined = useGetIsDeclinedLineItem(item)

  const onChange = (event: SelectChangeEvent<unknown>) => {
    const newValue = event.target.value

    taxedField.setValue(newValue)

    if (newValue === '0') {
      taxField.setValue(newValue)
    }

    onEditInvoiceLineItem({ taxed: Boolean(Number(newValue)) })
  }

  if (isDeclined) {
    return null
  }

  return (
    <ChargeDetailsFieldWrapper disabled={disabled} label={taxedField.label}>
      <YesNoSelect
        className={classNameProp}
        disableUnderline={isRetailOrderLineItem || !item.priceTaxable}
        disabled={disabled}
        field={taxedField}
        fullWidth={false}
        margin="none"
        value={taxedField.value}
        onChange={onChange}
      />
    </ChargeDetailsFieldWrapper>
  )
}

export default ChargeFieldTaxed
