import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { createDocumentForm } from '~/store/actions/documents'
import { Document } from '~/types'

import { DocumentDetailsNew } from './DocumentDetailsNew'

interface DocumentDetailsLocationState {
  state: {
    newDocument: Document
  }
}

interface DocumentCreationProps {
  onClose: () => void
}

export const DocumentCreation = ({ onClose }: DocumentCreationProps) => {
  const location = useLocation() as DocumentDetailsLocationState
  const dispatch = useDispatch()

  const { t } = useTranslation('Common')

  const { newDocument } = location.state || {}
  // eslint-disable-next-line react/hook-use-state
  const [document] = useState(newDocument)
  const [hasUnsavedData, setHasUnsavedData] = useState(true)
  if (!document) return null

  const handleSave = (doc: Document) => {
    setHasUnsavedData(false)
    dispatch(createDocumentForm(doc))
  }
  return (
    <DocumentDetailsNew
      disableClone
      disableDelete
      document={document}
      hasUnsavedData={hasUnsavedData}
      saveButtonLabel={t('Common:CREATE_FORM')}
      onClose={onClose}
      onSave={handleSave}
    />
  )
}
