import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  DefaultDraftBlockRenderMap,
  Editor,
  EditorBlock,
  EditorCommand,
  EditorState,
  RichUtils,
} from 'draft-js'
import {
  blockRenderMap,
  CHECKABLE_LIST_ITEM,
  CheckableListItem,
  CheckableListItemUtils,
} from 'draft-js-checkable-list-item'
import { ClassesType, DocumentFile } from '@pbt/pbt-ui-components'

import { getPlaceholders } from '~/store/reducers/constants'

import convertFromHTML from './convertFromHTML'
import link from './decorator/link'
import placeholder from './decorator/placeholder'
import signature from './decorator/signature'
import {
  getLocalizedPlaceholders,
  injectSpanInPlaceholder,
  placeholderHasSpanTag,
} from './placeholderUtils'
import RichEditAttachment, {
  RichEditAttachmentsProps,
} from './RichEditAttachments'
import RichEditPanel from './RichEditPanel'

import 'draft-js/dist/Draft.css'
import 'draft-js-checkable-list-item/lib/CheckableListItem.css'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      overflowY: 'auto',
      border: theme.constants.tabBorder,
      borderRadius: 2,
    },
    editor: {
      cursor: 'text',
      minHeight: (props: UseStylesProps) => props.minEditorHeight || 20,
      maxHeight: (props: UseStylesProps) => props.maxEditorHeight,
      overflowY: 'auto',
      width: '100%',
      transition: '0.3s',
      '& figure': {
        margin: 0,
      },
      '&:focus': {
        border: '1px solid #FF0000',
      },
      '& .public-DraftEditorPlaceholder-inner': {
        padding: theme.spacing(1),
      },
      // Padding on the editor itself leads to jumps of content by click on the padding area
      '& .public-DraftEditor-content': {
        padding: theme.spacing(1),
      },
      color: theme.colors.secondaryText,
      flex: 1,
    },
    stickyControlPanel: {
      position: 'sticky',
      top: 0,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
    },
  }),
  { name: 'RichEdit' },
)

const ATOMIC_BLOCK = 'atomic'

const decorator = new CompositeDecorator([link, placeholder, signature])

const generateState = (htmlString: string, Placeholders: any) => {
  try {
    const content = convertFromHTML(htmlString, Placeholders)
    return EditorState.moveSelectionToEnd(
      EditorState.createWithContent(content, decorator),
    )
  } catch (e) {
    return EditorState.moveSelectionToEnd(EditorState.createEmpty(decorator))
  }
}

export interface RichEditProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  editorState: EditorState
  files?: DocumentFile['file'][]
  hidePanel?: boolean
  initialHTML?: string
  maxEditorHeight?: number
  maxLength?: number
  minEditorHeight?: number
  onAttachClick?: () => void
  onDeleteFile?: RichEditAttachmentsProps['onDeleteFile']
  onRemoveRequested?: () => void
  placeholder?: string
  setEditorState: (editorState: EditorState) => void
  showAddLink?: boolean
  showAttachment?: boolean
  showButtons?: boolean
  showCheckList?: boolean
  showDynamicText?: boolean
  showSignature?: boolean
  stickyControlPanel?: boolean
  wrapHeaderText?: boolean
}

export interface RichEditHandle {
  getPlainText: () => string | undefined
  regenerateEditorState: () => EditorState
}

interface UseStylesProps {
  classes?: ClassesType<typeof useStyles>
  maxEditorHeight?: RichEditProps['maxEditorHeight']
  minEditorHeight?: RichEditProps['minEditorHeight']
}

const RichEdit = forwardRef(function RichEdit(
  {
    classes: classesProp,
    className,
    hidePanel = false,
    initialHTML,
    editorState,
    setEditorState,
    disabled,
    showSignature,
    showButtons,
    showDynamicText,
    showAttachment = false,
    showCheckList = true,
    showAddLink = false,
    onRemoveRequested,
    onAttachClick,
    placeholder: placeholderProp,
    stickyControlPanel = false,
    files,
    onDeleteFile,
    maxLength,
    minEditorHeight,
    maxEditorHeight,
    wrapHeaderText = true,
  }: RichEditProps,
  ref: ForwardedRef<RichEditHandle>,
) {
  const useStylesProps: UseStylesProps = {
    classes: classesProp,
    minEditorHeight,
    maxEditorHeight,
  }
  const classes = useStyles(useStylesProps)
  const Placeholders = useSelector(getPlaceholders)

  const editorRef = useRef<Editor>(null)
  const [localizedHtml, setLocalizedHtml] = useState(initialHTML || '')

  useEffect(() => {
    const [localizedPlaceholders, normalizedArr] = getLocalizedPlaceholders(
      initialHTML || '',
      Placeholders,
    )
    const finalHtml = normalizedArr.reduce(
      (message, originalPlaceholderName, index) => {
        const hasSpanOnString = placeholderHasSpanTag(
          message,
          originalPlaceholderName,
        )

        return hasSpanOnString
          ? message.replace(
              originalPlaceholderName,
              localizedPlaceholders[index],
            )
          : injectSpanInPlaceholder(
              message,
              originalPlaceholderName,
              localizedPlaceholders[index],
            )
      },
      initialHTML || '',
    )
    setLocalizedHtml(finalHtml)
  }, [initialHTML])

  useEffect(() => {
    const newState = generateState(localizedHtml, Placeholders)
    setEditorState(newState)
  }, [localizedHtml])

  useImperativeHandle(ref, () => ({
    regenerateEditorState: () => generateState(localizedHtml, Placeholders),
    getPlainText: () => editorState?.getCurrentContent()?.getPlainText(),
  }))

  const handleKeyCommand = (command: EditorCommand, state: EditorState) => {
    const newState = RichUtils.handleKeyCommand(state, command)
    if (newState) {
      setEditorState(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  const blockRendererFn = useCallback(
    (block: ContentBlock) => {
      const type = block.getType()
      if (type === ATOMIC_BLOCK) {
        return {
          component: EditorBlock,
          editable: false,
        }
      }
      if (type === CHECKABLE_LIST_ITEM) {
        return {
          component: CheckableListItem,
          props: {
            onChangeChecked: () =>
              setEditorState(
                CheckableListItemUtils.toggleChecked(editorState, block),
              ),
            checked: Boolean(block.getData().get('checked')),
          },
        }
      }
      return undefined
    },
    [editorState],
  )

  const blockStyleFn = (block: ContentBlock) => {
    if (block.getType() === CHECKABLE_LIST_ITEM) {
      return CHECKABLE_LIST_ITEM
    }
    return ''
  }

  const focusEditor = useCallback(() => {
    if (editorRef.current) {
      editorRef.current.focus()
    }
  }, [editorRef.current])

  const onChange = (newEditorState: EditorState) => {
    if (!maxLength) {
      setEditorState(newEditorState)
      return
    }

    const contentState = newEditorState.getCurrentContent()
    const oldContent = editorState.getCurrentContent()
    if (
      contentState === oldContent ||
      contentState.getPlainText().length <= maxLength
    ) {
      setEditorState(newEditorState)
    } else {
      const focusedEditorState = EditorState.undo(
        EditorState.push(
          editorState,
          ContentState.createFromText(oldContent.getPlainText()),
          'delete-character',
        ),
      )
      setEditorState(focusedEditorState)
    }
  }

  const editor = (
    <Editor
      blockRenderMap={DefaultDraftBlockRenderMap.merge(blockRenderMap)}
      blockRendererFn={blockRendererFn}
      blockStyleFn={blockStyleFn}
      editorState={editorState}
      handleKeyCommand={handleKeyCommand}
      placeholder={placeholderProp}
      readOnly={disabled}
      ref={editorRef}
      onChange={onChange}
    />
  )

  return (
    <Grid
      container
      className={classNames(className, classes.root)}
      direction="column"
      wrap="nowrap"
    >
      {!hidePanel && (
        <Grid
          item
          className={classNames({
            [classes.stickyControlPanel]: stickyControlPanel,
          })}
        >
          {editorState && (
            <RichEditPanel
              disabled={disabled}
              editorState={editorState}
              setEditorState={setEditorState}
              showAddLink={showAddLink}
              showAttachment={showAttachment}
              showButtons={showButtons}
              showCheckList={showCheckList}
              showDynamicText={showDynamicText}
              showSignature={showSignature}
              wrapMenuDropdownButton={wrapHeaderText}
              onAttachClick={onAttachClick}
              onFocus={focusEditor}
              onRemoveRequested={onRemoveRequested}
            />
          )}
        </Grid>
      )}
      <Grid
        container
        item
        className={classes.editor}
        direction="column"
        wrap="nowrap"
        onClick={focusEditor}
      >
        {editorState && (
          <ErrorBoundary
            /* eslint-disable-next-line react/no-unstable-nested-components */
            FallbackComponent={() => editor}
          >
            {editor}
          </ErrorBoundary>
        )}
        {showAttachment && files && (
          <RichEditAttachment files={files} onDeleteFile={onDeleteFile} />
        )}
      </Grid>
    </Grid>
  )
})

export default RichEdit
