import React from 'react'
import { Grid } from '@mui/material'

interface FormInfoCellProps {
  children: JSX.Element | string
}

const FormInfoCell = ({ children }: FormInfoCellProps) => (
  <Grid container alignItems="start" direction="column" height="100%">
    <Grid container item alignItems="center" height="40px">
      {children}
    </Grid>
  </Grid>
)

export default FormInfoCell
