import React from 'react'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Divider, Grid, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Text } from '@pbt/pbt-ui-components'
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@pbt/pbt-ui-components/src/icons'

import PanelAccordion from '~/components/common/PanelAccordion'

const StyledPanelAccordion = styled(PanelAccordion, {
  shouldForwardProp: (props) => props !== 'isDragging',
})<{ isDragging?: boolean }>`
  && {
    border-radius: 4px 4px 0 0;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
  .MuiAccordionSummary-root {
    background-color: ${({ theme, isDragging }) =>
      isDragging ? theme.colors.brandSecondary : theme.colors.brandPrimary};
    min-height: 44px;
    border-radius: 3px 3px 0 0;
    margin: 0;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${({ theme }) => theme.colors.grayWhite};
  }
`

const StyledText = styled(Text)(({ theme }) => ({
  '&&': {
    color: theme.colors.grayWhite,
  },
}))

interface DocumentElementAccordionProps {
  children: JSX.Element
  isDragging?: boolean
  onDelete?: () => void
  onEdit?: () => void
  title: string
}

export const DocumentElementAccordion = ({
  children,
  onDelete,
  onEdit,
  title,
  isDragging,
}: DocumentElementAccordionProps) => (
  <StyledPanelAccordion
    defaultExpanded
    noPadding
    isDragging={isDragging}
    titleComponent={
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{
          color: (theme) => theme.colors.grayWhite,
        }}
      >
        <Grid container alignItems="center" width="auto">
          {(onDelete || onEdit) && (
            <DragIndicatorIcon
              sx={{
                color: (theme) => theme.colors.grayWhite,
              }}
            />
          )}
          <StyledText strong pl={1} variant="h5">
            {title}
          </StyledText>
        </Grid>
        <Grid container width="auto">
          {onDelete && (
            <IconButton
              size="small"
              sx={{
                color: (theme) => theme.colors.grayWhite,
              }}
              onClick={onDelete}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <Divider
            flexItem
            orientation="vertical"
            sx={{
              color: (theme) => theme.colors.grayWhite,
              margin: 0.5,
            }}
          />
          {onEdit && (
            <>
              <IconButton
                size="small"
                sx={{
                  color: (theme) => theme.colors.grayWhite,
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  onEdit()
                }}
              >
                <EditIcon />
              </IconButton>
              <Divider
                flexItem
                orientation="vertical"
                sx={{
                  color: (theme) => theme.colors.grayWhite,
                  margin: 0.5,
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
    }
  >
    {children}
  </StyledPanelAccordion>
)
