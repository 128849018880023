import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Nil } from '@pbt/pbt-ui-components'

import {
  ImportSettings,
  MigrationEntity,
  MigrationExceptionMap,
  MigrationPreprocessingStatus,
  MigrationSessionFile,
  MigrationStructure,
  NofixLog,
  SessionsMap,
  SupportedEntity,
} from '~/types/entities/migration'
import { getErrorMessage } from '~/utils/errors'

import * as MigrationTypes from '../actions/types/migration'
import type { RootState } from '../index'

type MigrationState = {
  activationToken: string
  error?: string | null
  exporterUrl: string
  focusedMappingRowIndex: number | null
  focusedMappingStructureIndex: number
  isActivationTokenLoading: boolean
  isApplyingMappings: boolean
  isLoading: boolean
  isLoadingExporterUrl: boolean
  isLoadingImportSettings: boolean
  isLoadingRelationsMappings: boolean
  isLoadingStartImport: boolean
  isLoadingStatus: boolean
  isLoadingStructures: boolean
  mappings: {
    isLoading: boolean
    list: string[]
    map: MigrationExceptionMap
    totalCount: number
  }
  nofixLogs: NofixLog[]
  preprocessingStatus: MigrationPreprocessingStatus
  progressEntities: MigrationEntity[]
  relationsMappings: object[] | null
  sessionFiles: MigrationSessionFile[]
  sessions: {
    currentSessionId: string | null
    isLoading: boolean
    list: string[]
    map: SessionsMap
  }
  settings: ImportSettings | null
  structures: MigrationStructure[]
  supportedEntities: SupportedEntity[]
}

const INITIAL_STATE = {
  sessions: {
    map: {},
    list: [],
    currentSessionId: null,
    isLoading: false,
  },
  mappings: {
    map: {} as MigrationExceptionMap,
    list: [],
    isLoading: false,
    totalCount: 0,
  },
  isLoadingImportSettings: false,
  isLoadingExporterUrl: false,
  isLoadingStartImport: false,
  isLoadingRelationsMappings: false,
  structures: [],
  isLoadingStructures: false,
  progressEntities: [],
  isApplyingMappings: false,
  focusedMappingRowIndex: null,
  focusedMappingStructureIndex: 0,
  exporterUrl: '',
  nofixLogs: [],
  activationToken: '',
  isActivationTokenLoading: false,
  sessionFiles: [],
  isLoading: false,
  preprocessingStatus: {},
  isLoadingStatus: false,
  settings: null,
  supportedEntities: [],
  relationsMappings: null,
}

const formatLogItem = (logItem: NofixLog) => {
  const { lineNumber, exceptionMessage } = logItem
  const lineNumberString = lineNumber && `Line ${lineNumber}`

  return [lineNumberString, exceptionMessage].filter(Boolean).join(' ')
}

export const migrationReducer = (
  state: MigrationState = INITIAL_STATE,
  action: AnyAction,
): MigrationState => {
  switch (action.type) {
    case MigrationTypes.FOCUS_MAPPING_STRUCTURE:
      return {
        ...state,
        focusedMappingStructureIndex: action.structureIndex,
      }
    case MigrationTypes.FOCUS_MAPPING_ROW:
      return {
        ...state,
        focusedMappingRowIndex: action.rowIndex,
      }
    case MigrationTypes.CLEAR_MAPPINGS:
      return {
        ...state,
        mappings: {
          ...state.mappings,
          map: {} as MigrationExceptionMap,
          list: [],
          isLoading: false,
          totalCount: 0,
        },
      }
    case MigrationTypes.APPLY_MAPPINGS:
      return {
        ...state,
        isApplyingMappings: true,
      }
    case MigrationTypes.APPLY_MAPPINGS_SUCCESS:
      return {
        ...state,
        isApplyingMappings: false,
      }
    case MigrationTypes.APPLY_MAPPINGS_FAILURE:
      return {
        ...state,
        isApplyingMappings: false,
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.EDIT_MAPPING:
      return {
        ...state,
        mappings: {
          ...state.mappings,
          isLoading: true,
        },
      }
    case MigrationTypes.EDIT_MAPPING_SUCCESS:
      return {
        ...state,
        mappings: {
          ...state.mappings,
          map: {
            ...state.mappings.map,
            [action.mapping.id]: action.mapping,
          },
          isLoading: false,
        },
      }
    case MigrationTypes.EDIT_MAPPING_FAILURE:
      return {
        ...state,
        mappings: {
          ...state.mappings,
          isLoading: false,
        },
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.FETCH_MAPPING_STRUCTURES:
      return {
        ...state,
        isLoadingStructures: true,
        focusedMappingStructureIndex: 0,
        structures: [],
      }
    case MigrationTypes.FETCH_MAPPING_STRUCTURES_SUCCESS:
      return {
        ...state,
        isLoadingStructures: false,
        structures: action.structures,
      }
    case MigrationTypes.FETCH_MAPPING_STRUCTURES_FAILURE:
      return {
        ...state,
        isLoadingStructures: false,
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.EDIT_IMPORT_SETTINGS:
      return {
        ...state,
        isLoadingImportSettings: true,
      }
    case MigrationTypes.EDIT_IMPORT_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoadingImportSettings: false,
        settings: action.settings,
      }
    case MigrationTypes.EDIT_IMPORT_SETTINGS_FAILURE:
      return {
        ...state,
        isLoadingImportSettings: false,
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.ACCEPT_UPDATED_SESSION:
      const { id, status, entitiesList } = action.session
      const currentSession = state.sessions.map[id] || {}
      return {
        ...state,
        sessions: {
          ...state.sessions,
          map: {
            ...state.sessions.map,
            [id]: {
              ...currentSession,
              status,
              entitiesList,
            },
          },
        },
      }
    case MigrationTypes.FETCH_MORE_MAPPINGS:
      return {
        ...state,
        error: null,
        mappings: {
          ...state.mappings,
          isLoading: true,
        },
      }
    case MigrationTypes.FETCH_MORE_MAPPINGS_SUCCESS:
      return {
        ...state,
        mappings: {
          map: { ...state.mappings.map, ...action.map },
          list: [...state.mappings.list, ...action.list],
          isLoading: false,
          totalCount: action.totalCount,
        },
      }
    case MigrationTypes.FETCH_MORE_MAPPINGS_FAILURE:
      return {
        ...state,
        mappings: {
          ...state.mappings,
          isLoading: false,
        },
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.UPDATE_SESSION_STATUS:
      return {
        ...state,
        error: null,
        mappings: {
          ...state.mappings,
          isLoading: true,
        },
      }
    case MigrationTypes.UPDATE_SESSION_STATUS_SUCCESS:
      return {
        ...state,
        mappings: {
          ...state.mappings,
          isLoading: false,
        },
      }
    case MigrationTypes.UPDATE_SESSION_STATUS_FAILURE:
      return {
        ...state,
        mappings: {
          ...state.mappings,
          isLoading: false,
        },
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.FETCH_EXPORTER_URL:
      return {
        ...state,
        isLoadingExporterUrl: true,
        exporterUrl: '',
      }
    case MigrationTypes.FETCH_EXPORTER_URL_SUCCESS:
      return {
        ...state,
        isLoadingExporterUrl: false,
        exporterUrl: action.url,
      }
    case MigrationTypes.FETCH_EXPORTER_URL_FAILURE:
      return {
        ...state,
        isLoadingExporterUrl: false,
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.CLEAR_EXPORTER_URL:
      return {
        ...state,
        exporterUrl: '',
      }
    case MigrationTypes.FETCH_MIGRATION_SESSIONS:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          list: [],
          map: {},
          isLoading: true,
        },
      }
    case MigrationTypes.FETCH_MIGRATION_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          list: action.list,
          map: action.map || {},
          isLoading: false,
        },
      }
    case MigrationTypes.FETCH_MIGRATION_SESSIONS_FAILURE:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoading: false,
        },
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.START_MIGRATION_ANALYZE:
      return {
        ...state,
        isLoadingStartImport: true,
        progressEntities: [],
      }
    case MigrationTypes.START_MIGRATION_ANALYZE_SUCCESS:
      return {
        ...state,
        isLoadingStartImport: false,
      }
    case MigrationTypes.START_MIGRATION_ANALYZE_FAILURE:
      return {
        ...state,
        isLoadingStartImport: false,
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.START_MIGRATION:
      return {
        ...state,
        isLoadingStartImport: true,
        progressEntities: [],
      }
    case MigrationTypes.START_MIGRATION_SUCCESS:
      return {
        ...state,
        isLoadingStartImport: false,
      }
    case MigrationTypes.START_MIGRATION_FAILURE:
      return {
        ...state,
        isLoadingStartImport: false,
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.FETCH_MIGRATION_ANALYZE_STATUS:
    case MigrationTypes.FETCH_MIGRATION_IMPORT_STATUS:
      return {
        ...state,
        isLoadingStatus: true,
      }
    case MigrationTypes.FETCH_MIGRATION_ANALYZE_STATUS_SUCCESS:
    case MigrationTypes.FETCH_MIGRATION_IMPORT_STATUS_SUCCESS:
      return {
        ...state,
        progressEntities: action.entities,
        isLoadingStatus: false,
      }
    case MigrationTypes.FETCH_MIGRATION_ANALYZE_STATUS_FAILURE:
    case MigrationTypes.FETCH_MIGRATION_IMPORT_STATUS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoadingStatus: false,
      }
    case MigrationTypes.FETCH_NOFIX_LOGS_SUCCESS:
      return {
        ...state,
        nofixLogs: action.logs,
      }
    case MigrationTypes.FETCH_NOFIX_LOGS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.CHOOSE_IMPORT_SESSION:
      const { sessionId: currentSessionId } = action
      return {
        ...state,
        sessions: {
          ...state.sessions,
          currentSessionId,
        },
        progressEntities: [],
        supportedEntities: [],
      }
    case MigrationTypes.FETCH_ACTIVATION_TOKEN:
      return {
        ...state,
        isActivationTokenLoading: true,
        activationToken: '',
      }
    case MigrationTypes.FETCH_ACTIVATION_TOKEN_SUCCESS:
      return {
        ...state,
        isActivationTokenLoading: false,
        activationToken: action.token,
      }
    case MigrationTypes.FETCH_ACTIVATION_TOKEN_FAILURE:
    case MigrationTypes.FETCH_ACTIVATION_TOKEN_SILENT_FAILURE:
      return {
        ...state,
        isActivationTokenLoading: false,
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.FETCH_SESSION_FILES:
      return {
        ...state,
        isLoading: true,
        sessionFiles: [],
      }
    case MigrationTypes.UPLOAD_SESSION_FILE:
    case MigrationTypes.DELETE_SESSION_FILE:
    case MigrationTypes.FETCH_SESSION_FILE_URL:
    case MigrationTypes.START_PREPROCESSING:
    case MigrationTypes.GENERATE_ACTIVATION_TOKEN:
    case MigrationTypes.FETCH_SESSION_SUPPORTED_ENTITIES:
      return {
        ...state,
        isLoading: true,
      }
    case MigrationTypes.FETCH_SESSION_FILES_SUCCESS:
    case MigrationTypes.UPLOAD_SESSION_FILE_SUCCESS:
    case MigrationTypes.DELETE_SESSION_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sessionFiles: action.files,
      }
    case MigrationTypes.FETCH_SESSION_FILES_FAILURE:
    case MigrationTypes.UPLOAD_SESSION_FILE_FAILURE:
    case MigrationTypes.DELETE_SESSION_FILE_FAILURE:
    case MigrationTypes.FETCH_SESSION_FILE_URL_FAILURE:
    case MigrationTypes.START_PREPROCESSING_FAILURE:
    case MigrationTypes.FETCH_PREPROCESSING_STATUS_FAILURE:
    case MigrationTypes.CREATE_IMPORT_SESSION_FAILURE:
    case MigrationTypes.GENERATE_ACTIVATION_TOKEN_FAILURE:
    case MigrationTypes.FETCH_SESSION_SUPPORTED_ENTITIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.FETCH_SESSION_FILE_URL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        exporterUrl: action.url,
      }
    case MigrationTypes.START_PREPROCESSING_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case MigrationTypes.FETCH_PREPROCESSING_STATUS:
      return {
        ...state,
        isLoading: true,
        preprocessingStatus: !R.isEmpty(state.preprocessingStatus)
          ? state.preprocessingStatus
          : {},
      }
    case MigrationTypes.FETCH_PREPROCESSING_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        preprocessingStatus: action.status,
      }
    case MigrationTypes.CREATE_IMPORT_SESSION:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoading: true,
        },
      }
    case MigrationTypes.CREATE_IMPORT_SESSION_SUCCESS:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoading: false,
        },
      }
    case MigrationTypes.FETCH_IMPORT_SETTINGS:
      return {
        ...state,
        isLoadingImportSettings: true,
        settings: null,
      }
    case MigrationTypes.FETCH_IMPORT_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoadingImportSettings: false,
        settings: action.settings,
      }
    case MigrationTypes.FETCH_IMPORT_SETTINGS_FAILURE:
      return {
        ...state,
        isLoadingImportSettings: false,
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.GENERATE_ACTIVATION_TOKEN_SUCCESS:
      return {
        ...state,
        activationToken: action.token,
        isLoading: false,
      }
    case MigrationTypes.FETCH_SESSION_SUPPORTED_ENTITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        supportedEntities: action.entities,
      }
    case MigrationTypes.FETCH_BUSINESS_RELATIONS_MAPPINGS:
      return {
        ...state,
        isLoadingRelationsMappings: true,
        relationsMappings: null,
      }
    case MigrationTypes.FETCH_BUSINESS_RELATIONS_MAPPINGS_SUCCESS:
      return {
        ...state,
        isLoadingRelationsMappings: false,
        relationsMappings: action.relationsMappings,
      }
    case MigrationTypes.FETCH_BUSINESS_RELATIONS_MAPPINGS_FAILURE:
      return {
        ...state,
        isLoadingRelationsMappings: false,
        error: getErrorMessage(action.error),
      }
    case MigrationTypes.CLEAR_BUSINESS_RELATIONS_MAPPINGS:
      return {
        ...state,
        isLoadingRelationsMappings: false,
        relationsMappings: null,
      }
    default:
      return state
  }
}

export default migrationReducer
export const getMigration = (state: RootState): MigrationState =>
  state.migration
export const getExporterUrl = (state: RootState) =>
  getMigration(state).exporterUrl
export const getProgressEntities = (state: RootState) =>
  getMigration(state).progressEntities
export const getNonFixableLogs = (state: RootState) =>
  getMigration(state).nofixLogs?.map(formatLogItem) || []
export const getIsApplyingMappings = (state: RootState) =>
  getMigration(state).isApplyingMappings
export const getFocusedMappingStructureIndex = (state: RootState) =>
  getMigration(state).focusedMappingStructureIndex
export const getFocusedMappingRowIndex = (state: RootState) =>
  getMigration(state).focusedMappingRowIndex
export const getMappingStructures = (state: RootState) =>
  getMigration(state).structures
export const getMappingStructuresIsLoading = (state: RootState) =>
  getMigration(state).isLoadingStructures
export const getIsStartingImport = (state: RootState) =>
  getMigration(state).isLoadingStartImport

export const getSessions = (state: RootState) => getMigration(state).sessions
export const getSessionsMap = (state: RootState) => getSessions(state).map
export const getSessionsList = (state: RootState) => getSessions(state).list
export const getSessionsIsLoading = (state: RootState) =>
  getSessions(state).isLoading
export const getSession = (id: string | Nil) =>
  createSelector(getSessionsMap, (map: SessionsMap) =>
    id ? map[id] : undefined,
  )
export const getMultipleSessions = (ids: string[]) =>
  createSelector(getSessionsMap, (map) => R.props(ids, map))
export const getIsLoadingImportSessions = (state: RootState) =>
  getSessions(state).isLoading
export const getCurrentSessionId = (state: RootState) =>
  getSessions(state).currentSessionId
export const getCurrentSession = createSelector(
  getCurrentSessionId,
  getSessionsMap,
  (currentSessionId: string | null, sessionsMap: SessionsMap) =>
    currentSessionId ? sessionsMap[currentSessionId] : null,
)
export const getCurrentSessionEntitiesList = (state: RootState) =>
  getCurrentSession(state)?.entitiesList || []
export const getIsLoadingStatus = (state: RootState) =>
  getMigration(state).isLoadingStatus

export const getMappings = (state: RootState) => getMigration(state).mappings
export const getMappingsMap = (state: RootState) => getMappings(state).map
export const getMappingsList = (state: RootState) => getMappings(state).list
export const getMappingsIsLoading = (state: RootState) =>
  getMappings(state).isLoading
export const getTotalMappings = (state: RootState) =>
  getMappings(state).totalCount
export const getMultipleMappings = (ids: string[]) =>
  createSelector(getMappingsMap, (map) => R.props(ids, map))
export const getIsActivationTokenLoading = (state: RootState) =>
  getMigration(state).isActivationTokenLoading
export const getActivationToken = (state: RootState) =>
  getMigration(state).activationToken

export const getIsLoading = (state: RootState) => getMigration(state).isLoading
export const getSessionFiles = (state: RootState) =>
  getMigration(state).sessionFiles
export const getPreprocessingStatus = (state: RootState) =>
  getMigration(state).preprocessingStatus
export const getIsLoadingStartImport = (state: RootState) =>
  getMigration(state).isLoadingStartImport

export const getError = (state: RootState) => getMigration(state).error

export const getIsLoadingImportSettings = (state: RootState) =>
  getMigration(state).isLoadingImportSettings
export const getImportSettings = (state: RootState) =>
  getMigration(state).settings
export const getMigrationSanitized = (state: RootState) => ({
  ...state.migration,
  activationToken: '*',
})

export const getSessionSupportedEntities = (state: RootState) =>
  getMigration(state).supportedEntities

export const getRelationsMappings = (state: RootState) =>
  getMigration(state).relationsMappings
export const getIsLoadingRelationsMappings = (state: RootState) =>
  getMigration(state).isLoadingRelationsMappings
