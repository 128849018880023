import { ApiError, AtLeast, Nil } from '@pbt/pbt-ui-components'

import {
  Document,
  Soap,
  SoapForm,
  TimetableEvent,
  WritableTimetableEvent,
} from '~/types'

import {
  ADD_ESTIMATE_TO_EVENT,
  ADD_FORM,
  ADD_FORM_FAILURE,
  ADD_FORM_SUCCESS,
  ADD_TIMETABLE_EVENT_SOAP,
  CANCEL_FUTURE_APPOINTMENTS,
  CANCEL_FUTURE_APPOINTMENTS_FAILURE,
  CANCEL_FUTURE_APPOINTMENTS_SUCCESS,
  CLEAR_APPOINTMENT_VALIDATION_ERROR,
  CLEAR_SCHEDULING_ASSIGNMENT,
  CREATE_APPOINTMENT,
  CREATE_APPOINTMENT_FAILURE,
  CREATE_APPOINTMENT_SUCCESS,
  CREATE_BUSY_TIME,
  CREATE_BUSY_TIME_FAILURE,
  CREATE_BUSY_TIME_SUCCESS,
  DELETE_APPOINTMENT,
  DELETE_APPOINTMENT_FAILURE,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_BUSY_TIME,
  DELETE_BUSY_TIME_FAILURE,
  DELETE_BUSY_TIME_SUCCESS,
  DELETE_FORM,
  DELETE_FORM_FAILURE,
  DELETE_FORM_SUCCESS,
  DISABLE_TIMETABLE_AUTO_REFRESH,
  EDIT_APPOINTMENT,
  EDIT_APPOINTMENT_FAILURE,
  EDIT_APPOINTMENT_SUCCESS,
  EDIT_BUSY_TIME,
  EDIT_BUSY_TIME_FAILURE,
  EDIT_BUSY_TIME_SUCCESS,
  ENABLE_TIMETABLE_AUTO_REFRESH,
  FETCH_APPOINTMENT,
  FETCH_APPOINTMENT_FAILURE,
  FETCH_APPOINTMENT_SUCCESS,
  FETCH_BUSY_TIME,
  FETCH_BUSY_TIME_FAILURE,
  FETCH_BUSY_TIME_SUCCESS,
  PATCH_APPOINTMENT,
  PATCH_APPOINTMENT_ABOLISH,
  PATCH_APPOINTMENT_FAILURE,
  PATCH_APPOINTMENT_SUCCESS,
  SET_DEFAULT_SCHEDULER_FILTERS,
  SET_DEFAULT_WHITEBOARD_FILTERS,
  SET_SCHEDULING_ASSIGNMENT,
  SET_TIMETABLE_FILTERS,
  TOGGLE_PET_PARENT_IS_AWARE_OF_CANCELLATION_POLICY_CHECKBOX,
  UPDATE_APPOINTMENT_NOTES,
  UPDATE_APPOINTMENT_NOTES_FAILURE,
  UPDATE_APPOINTMENT_NOTES_SUCCESS,
  UPDATE_APPOINTMENT_STATUS,
  UPDATE_APPOINTMENT_STATUS_FAILURE,
  UPDATE_APPOINTMENT_STATUS_SUCCESS,
  UPDATE_EVENTS,
  UPDATE_TIMETABLE_FILTERED_APPOINTMENT_TYPE_GROUPS,
  UPDATE_TIMETABLE_FILTERED_APPOINTMENT_TYPES,
  UPDATE_TIMETABLE_FILTERED_PERSON_GROUPS,
  UPDATE_TIMETABLE_FILTERED_PERSONS,
} from './types/timetable'

export const updateTimetableFilteredPersons = (persons: string[]) => ({
  type: UPDATE_TIMETABLE_FILTERED_PERSONS,
  persons,
})
export const updateTimetableFilteredPersonGroups = (
  personGroups: string[],
) => ({
  type: UPDATE_TIMETABLE_FILTERED_PERSON_GROUPS,
  personGroups,
})
export const updateTimetableFilteredAppointmentTypes = (
  appointmentTypes: string[],
) => ({
  type: UPDATE_TIMETABLE_FILTERED_APPOINTMENT_TYPES,
  appointmentTypes,
})
export const updateTimetableFilteredAppointmentTypeGroups = (
  appointmentTypeGroups: string[],
) => ({
  type: UPDATE_TIMETABLE_FILTERED_APPOINTMENT_TYPE_GROUPS,
  appointmentTypeGroups,
})

export const createAppointment = (appointment: TimetableEvent) => ({
  type: CREATE_APPOINTMENT,
  appointment,
})
export const createAppointmentSuccess = (appointmentId: string) => ({
  type: CREATE_APPOINTMENT_SUCCESS,
  appointmentId,
})
export const createAppointmentFailure = (error: ApiError) => ({
  type: CREATE_APPOINTMENT_FAILURE,
  error,
})

export const fetchAppointment = (appointmentId: string) => ({
  type: FETCH_APPOINTMENT,
  appointmentId,
})
export const fetchAppointmentSuccess = () => ({
  type: FETCH_APPOINTMENT_SUCCESS,
})
export const fetchAppointmentFailure = (error: ApiError) => ({
  type: FETCH_APPOINTMENT_FAILURE,
  error,
})

export const editAppointment = (
  appointment: TimetableEvent,
  param?: string,
) => ({
  type: EDIT_APPOINTMENT,
  appointment,
  param,
})
export const editAppointmentSuccess = (appointmentId: string) => ({
  type: EDIT_APPOINTMENT_SUCCESS,
  appointmentId,
})
export const editAppointmentFailure = (error: ApiError) => ({
  type: EDIT_APPOINTMENT_FAILURE,
  error,
})

export const patchAppointment = (
  appointment: AtLeast<WritableTimetableEvent, 'id'>,
  param?: string,
  debounced?: boolean,
) => ({
  type: PATCH_APPOINTMENT,
  appointment,
  param,
  debounced,
})
export const patchAppointmentSuccess = (appointmentId: string) => ({
  type: PATCH_APPOINTMENT_SUCCESS,
  appointmentId,
})
export const patchAppointmentFailure = (error: ApiError) => ({
  type: PATCH_APPOINTMENT_FAILURE,
  error,
})
export const patchAppointmentAbolish = () => ({
  type: PATCH_APPOINTMENT_ABOLISH,
})

export const deleteAppointment = (appointmentId: string, param?: string) => ({
  type: DELETE_APPOINTMENT,
  appointmentId,
  param,
})
export const deleteAppointmentSuccess = (appointmentId: string) => ({
  type: DELETE_APPOINTMENT_SUCCESS,
  appointmentId,
})
export const deleteAppointmentFailure = (error: ApiError) => ({
  type: DELETE_APPOINTMENT_FAILURE,
  error,
})

export const cancelFutureAppointments = (patientId: string) => ({
  type: CANCEL_FUTURE_APPOINTMENTS,
  patientId,
})

export const cancelFutureAppointmentsSuccess = () => ({
  type: CANCEL_FUTURE_APPOINTMENTS_SUCCESS,
})
export const cancelFutureAppointmentsFailure = (error: ApiError) => ({
  type: CANCEL_FUTURE_APPOINTMENTS_FAILURE,
  error,
})

export const createBusyTime = (busyTime: TimetableEvent) => ({
  type: CREATE_BUSY_TIME,
  busyTime,
})
export const createBusyTimeSuccess = (busyTimeId: string) => ({
  type: CREATE_BUSY_TIME_SUCCESS,
  busyTimeId,
})
export const createBusyTimeFailure = (error: ApiError) => ({
  type: CREATE_BUSY_TIME_FAILURE,
  error,
})

export const fetchBusyTime = (busyTimeId: string) => ({
  type: FETCH_BUSY_TIME,
  busyTimeId,
})
export const fetchBusyTimeSuccess = () => ({ type: FETCH_BUSY_TIME_SUCCESS })
export const fetchBusyTimeFailure = (error: ApiError) => ({
  type: FETCH_BUSY_TIME_FAILURE,
  error,
})

export const editBusyTime = (busyTime: TimetableEvent, param?: string) => ({
  type: EDIT_BUSY_TIME,
  busyTime,
  param,
})
export const editBusyTimeSuccess = (busyTimeId: string) => ({
  type: EDIT_BUSY_TIME_SUCCESS,
  busyTimeId,
})
export const editBusyTimeFailure = (error: ApiError) => ({
  type: EDIT_BUSY_TIME_FAILURE,
  error,
})

export const deleteBusyTime = (busyTimeId: string, param?: string) => ({
  type: DELETE_BUSY_TIME,
  busyTimeId,
  param,
})
export const deleteBusyTimeSuccess = (busyTimeId: string) => ({
  type: DELETE_BUSY_TIME_SUCCESS,
  busyTimeId,
})
export const deleteBusyTimeFailure = (error: ApiError) => ({
  type: DELETE_BUSY_TIME_FAILURE,
  error,
})

export const updateEvents = (events: Record<string, TimetableEvent>) => ({
  type: UPDATE_EVENTS,
  events,
})

export const addEventSoap = (id: string | Nil, soap: Partial<Soap>) => ({
  type: ADD_TIMETABLE_EVENT_SOAP,
  id,
  soap,
})

export const addEstimateToEvent = (estimateId: string, eventId: string) => ({
  type: ADD_ESTIMATE_TO_EVENT,
  estimateId,
  eventId,
})

export const clearAppointmentValidationError = () => ({
  type: CLEAR_APPOINTMENT_VALIDATION_ERROR,
})

export const updateAppointmentStatus = (
  appointment: TimetableEvent,
  oldStatus?: string,
  newStatus?: string,
) => ({
  type: UPDATE_APPOINTMENT_STATUS,
  appointment,
  oldStatus,
  newStatus,
})
export const updateAppointmentStatusSuccess = () => ({
  type: UPDATE_APPOINTMENT_STATUS_SUCCESS,
})
export const updateAppointmentStatusFailure = (
  error: ApiError,
  appointment: TimetableEvent,
  oldStatus?: string,
  newStatus?: string,
) => ({
  type: UPDATE_APPOINTMENT_STATUS_FAILURE,
  error,
  appointment,
  oldStatus,
  newStatus,
})

export const enableTimetableAutoRefresh = () => ({
  type: ENABLE_TIMETABLE_AUTO_REFRESH,
})
export const disableTimetableAutoRefresh = () => ({
  type: DISABLE_TIMETABLE_AUTO_REFRESH,
})

export const setTimetableFilters = (isWhiteboard?: boolean) => ({
  type: SET_TIMETABLE_FILTERS,
  isWhiteboard,
})

export const setDefaultSchedulerFilters = () => ({
  type: SET_DEFAULT_SCHEDULER_FILTERS,
})
export const setDefaultWhiteboardFilters = () => ({
  type: SET_DEFAULT_WHITEBOARD_FILTERS,
})

export const setSchedulingAssignment = (
  clientId: string,
  patientId: string,
  redirectUrl?: string | Nil,
) => ({
  type: SET_SCHEDULING_ASSIGNMENT,
  clientId,
  patientId,
  redirectUrl,
})
export const clearSchedulingAssignment = () => ({
  type: CLEAR_SCHEDULING_ASSIGNMENT,
})

export const updateAppointmentNotes = ({
  id,
  notes,
  debounced,
}: {
  debounced?: boolean
  id: string
  notes: string
}) => ({
  type: UPDATE_APPOINTMENT_NOTES,
  id,
  notes,
  debounced,
})
export const updateAppointmentNotesSuccess = ({
  id,
  notes,
}: {
  id: string
  notes: string
}) => ({
  type: UPDATE_APPOINTMENT_NOTES_SUCCESS,
  id,
  notes,
})
export const updateAppointmentNotesFailure = (error: ApiError) => ({
  type: UPDATE_APPOINTMENT_NOTES_FAILURE,
  error,
})
export const togglePetParentIsAwareOfCancellationPolicyCheckBox = (
  isPetParentIsAwareOfCancellationPolicyChecked: boolean,
) => ({
  type: TOGGLE_PET_PARENT_IS_AWARE_OF_CANCELLATION_POLICY_CHECKBOX,
  payload: isPetParentIsAwareOfCancellationPolicyChecked,
})

export const addForm = (documents: Document[], appointmentId: string) => ({
  type: ADD_FORM,
  documents,
  appointmentId,
})

export const addFormSuccess = (forms: SoapForm[], appointmentId: string) => ({
  type: ADD_FORM_SUCCESS,
  forms,
  appointmentId,
})

export const addFormFailure = (error: ApiError) => ({
  type: ADD_FORM_FAILURE,
  error,
})

export const deleteForm = (documentId: string, appointmentId: string) => ({
  type: DELETE_FORM,
  documentId,
  appointmentId,
})

export const deleteFormSuccess = (formId: string, appointmentId: string) => ({
  type: DELETE_FORM_SUCCESS,
  formId,
  appointmentId,
})

export const deleteFormFailure = (error: ApiError) => ({
  type: DELETE_FORM_FAILURE,
  error,
})
