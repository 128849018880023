import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { ClassesType, Nil } from '@pbt/pbt-ui-components'

import OrderListWithFilters from '~/components/dashboard/soap/list-with-filters/OrderListWithFilters'
import OrderTooltip from '~/components/dashboard/soap/OrderTooltip'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import { fetchAppointmentInvoiceItems } from '~/store/actions/finance'
import { editOrder } from '~/store/actions/orders'
import {
  useGetDisabledOrderWeakMap,
  useGetIsCheckedItem,
  useGetIsSendingOrder,
  useGetSelectedOrderWithNotes,
  useOrderCheckAllListItems,
  useOrderCheckItem,
  useOrderUncheckItem,
} from '~/store/hooks/orders'
import { getClientIsLoading } from '~/store/reducers/clients'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getAppointmentLineItemsMap } from '~/store/reducers/finance'
import {
  getAppliedPatientRestrictions,
  getOrdersIsFetchingOrdersCountByType,
  getOrdersIsLoading,
  getOrdersIsReceiving,
  getOrdersList,
  getOrdersTotalCount,
  getSelectedOrders,
} from '~/store/reducers/orders'
import {
  getAppointmentId,
  getClientId,
  getOrderFilters,
  getPatientId,
  getSOAPisFetchingSoapOrders,
  getSOAPisLoading,
} from '~/store/reducers/soap'
import { BaseChargeTabProps, Order, OrderListType } from '~/types'
import { findOrderItemByItem, getItemColor } from '~/utils/orderUtils'

const useStyles = makeStyles(
  () => ({
    container: {
      height: '100%',
      margin: 'auto 0',
      minHeight: 200,
      maxHeight: ({ maxHeight }: UseStylesProps) => maxHeight,
      overflow: 'auto',
    },
    contentContainer: {
      height: 'auto',
    },
    infiniteScroll: {
      position: 'relative',
    },
    listWithFiltersContainer: {
      border: '0 !important',
      backgroundColor: 'inherit !important',
    },
  }),
  { name: 'ChargeListWithFilters' },
)

interface UseStylesProps {
  maxHeight: number
}

export interface ChargeListWithFiltersProps extends BaseChargeTabProps {
  appointmentId?: string
  classes?: ClassesType<typeof useStyles>
  clientId?: string | Nil
  includeSubItemsPrices?: boolean
  patientId?: string
  preselectedFilter: OrderType
  preselectedListType: OrderListType
}

const ChargeListWithFilters = ({
  classes: classesProp,
  disabled,
  handleEditListItem,
  handleRefill,
  handleSavePrescription,
  includeSubItemsPrices,
  logItemStateGetter,
  openPrescriptionDialog,
  preselectedFilter,
  preselectedListType,
  clientId: clientIdProp,
  patientId: patientIdProp,
  appointmentId: appointmentIdProp,
}: ChargeListWithFiltersProps) => {
  const panelContainerRef = useRef<HTMLDivElement>(null)

  const classes = useStyles({
    maxHeight: panelContainerRef.current
      ? //  100vh - element top position - margin
        Math.floor(
          window.innerHeight -
            panelContainerRef.current.getBoundingClientRect().top -
            30,
        )
      : 440,
  })

  const dispatch = useDispatch()

  const appointmentLineItemsMap = useSelector(getAppointmentLineItemsMap)
  const soapClientId = useSelector(getClientId)
  const soapPatientId = useSelector(getPatientId)
  const soapAppointmentId = useSelector(getAppointmentId)
  const orders = useSelector(getOrdersList)
  const orderFilters = useSelector(getOrderFilters)
  const isReceivingOrders = useSelector(getOrdersIsReceiving)
  const isLoadingOrders = useSelector(getOrdersIsLoading)
  const isFetchingOrdersCountByType = useSelector(
    getOrdersIsFetchingOrdersCountByType,
  )
  const isLoadingClient = useSelector(getClientIsLoading)
  const isLoadingSOAP = useSelector(getSOAPisLoading)
  const isLoadingSoapOrders = useSelector(getSOAPisFetchingSoapOrders)
  const ordersTotalCount = useSelector(getOrdersTotalCount)
  const selectedOrders = useSelector(getSelectedOrders)
  const appliedPatientRestrictions = useSelector(getAppliedPatientRestrictions)
  const includeOnHandQuantity = useSelector(
    getFeatureToggle(FeatureToggle.ON_HAND_INVENTORY_AWARENESS),
  )

  const clientId = soapClientId || clientIdProp
  const patientId = soapPatientId || patientIdProp
  const appointmentId = soapAppointmentId || appointmentIdProp

  const getSelectedOrderWithNotes = useGetSelectedOrderWithNotes()
  const disabledWeakMap = useGetDisabledOrderWeakMap(
    orders.map(getSelectedOrderWithNotes),
  )
  const isCheckedItem = useGetIsCheckedItem()
  const handleListItemCheck = useOrderCheckItem({
    handleSavePrescription,
    logItemStateGetter,
    openPrescriptionDialog,
  })
  const handleCheckAllListItems = useOrderCheckAllListItems()
  const handleListItemUncheck = useOrderUncheckItem()

  useEffect(() => {
    if (appointmentId && !appointmentLineItemsMap) {
      dispatch(fetchAppointmentInvoiceItems(appointmentId))
    }
  }, [appointmentId, appointmentLineItemsMap])

  const hasMoreItems = orders.length < ordersTotalCount
  const ordersWithNotes = orders.map(getSelectedOrderWithNotes)
  const filters = orderFilters.filter(
    (filter) => filter.type === preselectedFilter,
  )

  const getIsSendingOrder = useGetIsSendingOrder()
  const isSendingOrder = ordersWithNotes.some(({ items }) =>
    items?.some(getIsSendingOrder),
  )

  const handleUpdateItem = (order: Order) => {
    const fullOrder = findOrderItemByItem(order, selectedOrders)
    dispatch(editOrder({ ...fullOrder, ...order }))
  }

  if (!clientId || !patientId) {
    return null
  }

  return (
    <Grid ref={panelContainerRef}>
      <OrderListWithFilters
        hideFilters
        hideSearchBar
        showItemFilterPath
        showTooltip
        useChargesWidgetView
        withTasks
        ItemTooltipComponent={OrderTooltip}
        ListItemProps={{
          showListItemActions: true,
          onRefillSelect: handleRefill,
        }}
        classes={{
          container: classNames(classes.container, classesProp?.container),
          contentContainer: classNames(
            classes.contentContainer,
            classesProp?.contentContainer,
          ),
          listContainer: classNames(
            classes.listWithFiltersContainer,
            classesProp?.listWithFiltersContainer,
          ),
        }}
        clientId={clientId}
        disabledWeakMap={disabledWeakMap}
        editDisabled={disabled}
        enableBackendFiltering={appliedPatientRestrictions}
        eventId={appointmentId}
        filters={filters}
        getItemColor={getItemColor(
          appointmentLineItemsMap || {},
          selectedOrders,
        )}
        hasMore={hasMoreItems}
        includeOnHandQuantity={includeOnHandQuantity}
        includePrice={!includeSubItemsPrices}
        includeSubItemsPrices={includeSubItemsPrices}
        infiniteScrollClassName={classes.infiniteScroll}
        isCheckedItem={isCheckedItem}
        isLoading={
          isReceivingOrders ||
          isLoadingOrders ||
          isLoadingSOAP ||
          isLoadingSoapOrders ||
          isSendingOrder ||
          isFetchingOrdersCountByType ||
          isLoadingClient
        }
        items={ordersWithNotes}
        patientId={patientId}
        preselectedFilter={preselectedFilter}
        preselectedListType={preselectedListType}
        showFiltersToggle={false}
        onCheckAll={handleCheckAllListItems}
        onCheckItem={handleListItemCheck}
        onEditItem={handleEditListItem}
        onUncheckItem={handleListItemUncheck}
        onUpdateItem={handleUpdateItem}
      />
    </Grid>
  )
}

export default ChargeListWithFilters
