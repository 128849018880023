import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import {
  AlertIconType,
  LanguageUtils,
  Nil,
  RoleName,
  SentenceFormatter,
  StateLabelProps,
  User,
  Utils,
} from '@pbt/pbt-ui-components'
import { getConstantName } from '@pbt/pbt-ui-components/src/utils'

import { RxPrescriptionOrigin } from '~/api/graphql/generated/types'
import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import { CompoundingReasonTypes } from '~/constants/compoundingReason'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  MESSAGE_CAN_NOT_REMOVE_PARENT_PRESCRIPTION,
  PrescriptionType,
} from '~/constants/prescription'
import SnackNotificationType from '~/constants/SnackNotificationType'
import { OrderType, PrescriptionItemState } from '~/constants/SOAPStates'
import i18n from '~/locales/i18n'
import {
  createUnifiedOrder,
  removeOrder,
  removeUnifiedOrder,
  updateUnifiedOrder,
} from '~/store/actions/orders'
import { addUiNotification } from '~/store/duck/uiNotifications'
import { getOrdersError, getOrdersIsSending } from '~/store/reducers/orders'
import { getUser } from '~/store/reducers/users'
import { CRUDOrderOptions, Order, Prescription, PrescriptionV2 } from '~/types'
import { removeServerErrorPrefix } from '~/utils/errors'
import {
  getPrescriptionOrderId,
  getPrescriptionWorkflowType,
} from '~/utils/prescription'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useHasRole from '~/utils/useHasRole'

import { refetchChargeSheet } from '../duck/clientFinanceData'
import { deleteActiveRx } from '../duck/prescriptions'
import { registerWarnAlert } from '../duck/uiAlerts'
import { getCurrentUser } from '../reducers/auth'
import {
  getDrugDeliveryMethod,
  getFeatureToggle,
  getPrescriptionCompoundingReason,
  getPrescriptionProductSizeUnit,
  getPrescriptionStates,
} from '../reducers/constants'
import { useIsChewyCheckoutEnabled } from './business'

export const useGetCompoundingReasonTypeById = () => {
  const prescriptionCompoundingReason = useSelector(
    getPrescriptionCompoundingReason,
  )
  return (compoundingReasonId: string | Nil) => {
    const compoundingReasonName = (
      prescriptionCompoundingReason.find(
        ({ id }) => id === compoundingReasonId,
      ) || {}
    ).name
    return {
      isAllergiesIntolerances:
        compoundingReasonName === CompoundingReasonTypes.ALLERGIES_INTOLERANCES,
      isOther: compoundingReasonName === CompoundingReasonTypes.OTHER,
    }
  }
}

export const useCompoundingReasonLabelById = (
  compoundingReasonId: string | Nil,
): string | undefined => {
  const prescriptionCompoundingReason = useSelector(
    getPrescriptionCompoundingReason,
  )
  return LanguageUtils.getConstantTranslatedName(
    compoundingReasonId,
    prescriptionCompoundingReason,
  )
}

export const useGetIsCompoundingReasonDetailsRequired = () => {
  const getCompoundingReasonTypeById = useGetCompoundingReasonTypeById()
  return (compoundingReasonId: string | Nil) => {
    const { isAllergiesIntolerances, isOther } =
      getCompoundingReasonTypeById(compoundingReasonId)
    return isAllergiesIntolerances || isOther
  }
}

export const useGetPrescriptionStateType = () => {
  const prescriptionStates = useSelector(getPrescriptionStates)

  return (prescriptionStateId?: string | Nil) => {
    const stateName = getConstantName(prescriptionStateId, prescriptionStates)
    return {
      isApproved: stateName === PrescriptionItemState.APPROVED,
      isCanceled: stateName === PrescriptionItemState.CANCELED,
      isDeclined: stateName === PrescriptionItemState.DECLINED,
      isDraft: stateName === PrescriptionItemState.DRAFT,
      isOrdered: stateName === PrescriptionItemState.ORDERED,
      isPrescribed: stateName === PrescriptionItemState.PRESCRIBED,
      isSubmitted: stateName === PrescriptionItemState.SUBMITTED,
    }
  }
}

export const useIsChewyActiveRxReadOnly = ({
  isChewyActiveRx,
  stateId,
  isInvoice,
}: {
  isChewyActiveRx: boolean
  isInvoice?: boolean
  stateId: string | undefined
}) => {
  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()
  const currentUser = useSelector(getCurrentUser)
  const userCanEditChewyRx = useHasRole(
    [RoleName.ChewyAuthorization, RoleName.Veterinarian],
    currentUser,
  )

  const { isDraft, isApproved } = useGetPrescriptionStateType()(stateId)
  return (
    (isChewyActiveRx &&
      Boolean(stateId) &&
      (isChewyCheckoutEnabled
        ? (!isDraft && !isApproved) || !userCanEditChewyRx
        : !isDraft)) ||
    Boolean(isInvoice)
  )
}

// In https://petabyte.atlassian.net/browse/PR-685,
// updateChargeSheetItemBatch will be called even if usedQuantity == 0,
// but for now we use this hook to differentiate
export const useIsUsedQuantityEmpty = (order: Order) =>
  'usedQuantity' in order && order.usedQuantity === 0

export const useIsDeclinableOrDeletable = ({
  orderType,
  origin,
  prescriptionType,
  stateId,
}: {
  orderType: OrderType
  origin?: RxPrescriptionOrigin | Nil
  prescriptionType: PrescriptionType | undefined
  stateId: string | undefined
}) => {
  const { isDeclined, isDraft, isApproved } =
    useGetPrescriptionStateType()(stateId)

  if (!stateId) {
    return false
  }

  const { isChewyActiveRx } = getPrescriptionWorkflowType({
    orderType,
    prescriptionType,
    origin,
  })

  if (isChewyActiveRx) {
    return isDeclined || isDraft || isApproved
  }

  return true
}

export const useGetPrescriptionStateIdByName = () => {
  const prescriptionStates = useSelector(getPrescriptionStates)
  return (stateName: string) =>
    Utils.findConstantIdByName(stateName, prescriptionStates)
}

export const useChewyPrescriptionStateLabelProps = (
  prescription: Prescription | Order,
  cancellationReason?: string | Nil,
): StateLabelProps => {
  const { stateId, rejectionReason } = prescription

  const prescriptionStates = useSelector(getPrescriptionStates)

  const stateName = Utils.getConstantName(stateId, prescriptionStates)
  const stateTranslatedName = LanguageUtils.getConstantTranslatedName(
    stateId,
    prescriptionStates,
  )

  const { isChewyReactiveRx, isChewyActiveRx } = getPrescriptionWorkflowType({
    orderType: prescription.type,
    prescriptionType: prescription.prescriptionType,
    origin: prescription.origin,
  })

  const isDraft = stateName === PrescriptionItemState.DRAFT
  const isDeclined = stateName === PrescriptionItemState.DECLINED
  const isCanceled = stateName === PrescriptionItemState.CANCELED

  const chewyLabel =
    isChewyReactiveRx || isChewyActiveRx ? `${i18n.t('Common:CHEWY_RX')}: ` : ''
  const rejectionReasonLabel =
    isDeclined && rejectionReason ? ` (${rejectionReason})` : ''
  const cancellationReasonLabel =
    cancellationReason && isChewyActiveRx && isCanceled
      ? `(${cancellationReason})`
      : ''

  const children = LanguageUtils.getSentence(
    [
      chewyLabel,
      stateTranslatedName,
      rejectionReasonLabel,
      cancellationReasonLabel,
    ],
    SentenceFormatter.REGULAR,
  )

  const error = isDeclined || isCanceled
  const warning = isDraft

  return { children, error, warning }
}

export const useCreateOrUpdatePrescription = (isChargeSheet?: boolean) => {
  const { t } = useTranslation(['Tasks', 'Common'])

  const dispatch = useDispatch()

  const prescriptionError = useSelector(getOrdersError)
  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)
  const showActionNotification = (message: string) => {
    dispatch(
      addUiNotification({
        id: uuid(),
        message,
        type: SnackNotificationType.INFO,
      }),
    )
  }

  const afterSave = useCloseAfterCreation(
    ({ message }: { message: string | null }) => {
      if (prescriptionError) {
        openAlert({
          iconType: AlertIconType.WARN,
          content: (
            <Grid mb={3} mt={1}>
              {removeServerErrorPrefix(prescriptionError)}
            </Grid>
          ),
          onOk: closeAlert,
        })
      } else if (message) {
        showActionNotification(message)
      }
    },
    getOrdersIsSending,
  )

  const refetchChargeSheetAfterSave = useCloseAfterCreation(
    () => dispatch(refetchChargeSheet()),
    getOrdersIsSending,
  )

  return (prescription: Order, options: CRUDOrderOptions = {}) => {
    if (!prescription.id) {
      dispatch(createUnifiedOrder(prescription, options))
    } else {
      dispatch(updateUnifiedOrder(prescription, options))
    }

    if (isChargeSheet) {
      refetchChargeSheetAfterSave()
    }

    if (options.isActiveRxSaveDraft) {
      afterSave({ message: t('Common:PRESCRIPTION_NOTIFICATIONS.DRAFT_SAVED') })
    } else if (options.isActiveRxApprove) {
      afterSave({
        message: isChewyCheckoutEnabled
          ? t('Common:PRESCRIPTION_NOTIFICATIONS.APPROVED')
          : t('Common:PRESCRIPTION_NOTIFICATIONS.APPROVED_AND_PENDING'),
      })
    } else if (options.isActiveRxApproveAndSubmit) {
      afterSave({ message: t('Common:PRESCRIPTION_NOTIFICATIONS.SUBMITTED') })
    }
  }
}

export const useHasChewyActiveRxApproved = (
  chewyActiveRxList: PrescriptionV2[],
) => {
  const getPrescriptionStateType = useGetPrescriptionStateType()

  return chewyActiveRxList.some((prescription) => {
    const { isApproved } = getPrescriptionStateType(prescription.state?.id)
    return isApproved
  })
}

type UseDeleteOrder = {
  hasRefills: boolean
  isChewyActiveRx?: never
  isFromSoap: boolean
  name: string | undefined
  onDelete?: () => void
  order: Order | Nil
  prescriptionId?: never
  retailOrderLineItemLogId?: string
}

type UseDeletePrescription = {
  hasRefills: boolean
  isChewyActiveRx: boolean
  isFromSoap?: never
  name: string | undefined
  onDelete?: () => void
  order?: never
  prescriptionId: string
  retailOrderLineItemLogId?: never
}

export const useDeletePrescription = ({
  hasRefills,
  isChewyActiveRx,
  isFromSoap,
  name,
  order,
  prescriptionId,
  retailOrderLineItemLogId,
  onDelete: onDeleteProp,
}: UseDeletePrescription | UseDeleteOrder) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.SOAP_RAIL_ORDER,
  })

  const onDeletePrescription = useCloseAfterCreation(
    onDeleteProp,
    getOrdersIsSending,
  )

  const handleDelete = () => {
    if (hasRefills) {
      dispatch(registerWarnAlert(MESSAGE_CAN_NOT_REMOVE_PARENT_PRESCRIPTION))
    } else {
      openConfirmAlert({
        message: t('Common:YOU_WILL_REMOVE_SOMETHING', {
          something: name,
        }),
        onConfirm: (proceed) => {
          if (!proceed) {
            return
          }

          if (order && !retailOrderLineItemLogId) {
            const id = getPrescriptionOrderId(order)
            const removeOrderAction = isFromSoap
              ? removeOrder
              : removeUnifiedOrder
            dispatch(removeOrderAction({ ...order, id }))
          }

          if (isChewyActiveRx && prescriptionId) {
            dispatch(deleteActiveRx({ prescriptionId }))
          } else if (retailOrderLineItemLogId) {
            dispatch(
              deleteActiveRx({ prescriptionId: retailOrderLineItemLogId }),
            )
          }

          if (onDeleteProp) {
            onDeletePrescription()
          }
        },
      })
    }
  }

  return handleDelete
}

export const useGetApprovalOnBehalfOfString = ({
  doctor,
  showDoctorOnly = false,
  signerId,
}: {
  doctor: User | Nil
  showDoctorOnly?: boolean
  signerId: string | Nil
}) => {
  const { t } = useTranslation('Common')

  const isApprovedOnBehalf = signerId && doctor && signerId !== doctor?.id

  const signer = useSelector(getUser(signerId))

  const doctorString = Utils.getPersonString(doctor)
  const signerString = Utils.getPersonString(signer)

  if (!doctor) {
    return ''
  }

  if (!isApprovedOnBehalf || showDoctorOnly || !signer) {
    return doctorString
  }

  return t('Common:APPROVED_ON_BEHALF_OF', {
    doctor: doctorString,
    signer: signerString,
  })
}

export const useGetPrescriptionWithPopulatedInventoryFields = () => {
  const isAutoPopulateDrugRxEnabled = useSelector(
    getFeatureToggle(FeatureToggle.AUTO_POPULATE_DRUG_RX),
  )
  const PrescriptionProductSizeUnit = useSelector(
    getPrescriptionProductSizeUnit,
  )
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)

  return useCallback(
    (prescription: Prescription): Prescription => {
      const {
        id,
        variation: {
          perPackageUnitsId: defaultUnitsId,
          deliveryMethodId: defaultRouteId,
        } = {},
      } = prescription

      const isAccesibleDefaultUnitsId = PrescriptionProductSizeUnit.some(
        R.propEq('id', defaultUnitsId),
      )
      const isAccesibleDefaultRouteId = DrugDeliveryMethod.some(
        R.propEq('id', defaultRouteId),
      )
      const isCreate = !id

      if (isAutoPopulateDrugRxEnabled && isCreate) {
        return {
          ...prescription,
          totalQuantityUnitId: isAccesibleDefaultUnitsId
            ? defaultUnitsId
            : undefined,
          administrationQuantityUnitId: isAccesibleDefaultUnitsId
            ? defaultUnitsId
            : undefined,
          administrationRouteId: isAccesibleDefaultRouteId
            ? defaultRouteId
            : undefined,
        }
      }

      return prescription
    },
    [
      isAutoPopulateDrugRxEnabled,
      PrescriptionProductSizeUnit,
      DrugDeliveryMethod,
    ],
  )
}
