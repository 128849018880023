import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Nil, Text } from '@pbt/pbt-ui-components'
import {
  Delete as DeleteIcon,
  Eye as EyeIcon,
} from '@pbt/pbt-ui-components/src/icons'

import ActionsPopper from '~/components/common/ActionsPopper'
import DialogNames from '~/constants/DialogNames'
import DocumentDialogStates from '~/constants/DocumentDialogStates'
import { PDF } from '~/constants/extensions'
import { deleteForm } from '~/store/actions/timetable'
import { Document, SoapForm, TimetableEvent } from '~/types'
import useDialog from '~/utils/useDialog'

import FormsListItem from './FormsListItem'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingTop: theme.spacing(1),
    },
    items: {
      position: 'relative',
      height: 160,
      overflowY: 'auto',
      '& > *': {
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
      backgroundColor: theme.colors.dialogHeaderBackground,
    },
    evenItem: {
      backgroundColor: theme.colors.divider,
    },
    title: {
      fontSize: '1.4rem',
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1.5),
    },
  }),
  { name: 'FormsList' },
)

export interface FormsListProps {
  appointment: TimetableEvent | Nil
}

const FormsList = ({ appointment }: FormsListProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common'])
  const dispatch = useDispatch()

  const forms = appointment?.documentInstances || []
  const totalCount = forms.length
  const [actionsAnchorEl, setActionsAnchorEl] = useState<HTMLElement | null>(
    null,
  )
  const [activeActionItem, setActiveActionItem] = useState<SoapForm>()

  const [openDocumentDialog] = useDialog(DialogNames.DOCUMENT)

  const isPdfGeneratedForActiveItem = Boolean(activeActionItem?.pdfUrl)

  const handleView = () => {
    openDocumentDialog({
      previewOnly: true,
      document: {
        extension: PDF,
        name: activeActionItem?.name,
        fileUrl: activeActionItem?.pdfUrl,
      } as Document,
      step: DocumentDialogStates.PREVIEW,
      disableCloseAfterUpdate: true,
      PreviewProps: {
        hideTopButtons: true,
        view: true,
        showChangeFile: false,
        hideEmail: true,
        hidePrint: true,
      },
    })
  }

  const handleDelete = () => {
    if (activeActionItem) {
      dispatch(deleteForm(activeActionItem.id, appointment?.id || ''))
    }
  }

  const getActions = () => [
    ...(isPdfGeneratedForActiveItem
      ? [
          {
            id: 'view',
            label: t('Common:VIEW_ACTION'),
            Icon: EyeIcon,
            onClick: () => handleView(),
          },
        ]
      : []),

    {
      id: 'delete',
      label: t('Common:DELETE_FORM'),
      Icon: DeleteIcon,
      onClick: () => handleDelete(),
    },
  ]

  const handleToggleActions = (event: React.MouseEvent, item?: SoapForm) => {
    const targetButton = (event.target as HTMLElement).closest('button')
    setActionsAnchorEl(actionsAnchorEl ? null : targetButton)
    setActiveActionItem(activeActionItem ? undefined : item)
  }

  if (forms.length === 0 || totalCount === 0) {
    return null
  }

  return (
    <Grid className={classes.root}>
      <Grid item>
        <Text className={classes.title} variant="h2">
          {t('Common:FORMS')}
        </Text>
      </Grid>
      <Grid
        container
        item
        className={classes.items}
        direction="column"
        wrap="nowrap"
      >
        {forms.map((form, index) => (
          <FormsListItem
            className={classNames({
              [classes.evenItem]: index % 2,
            })}
            form={form}
            handleToggleActions={handleToggleActions}
            key={form.id}
          />
        ))}
        <ActionsPopper
          actions={activeActionItem ? getActions() : []}
          anchorEl={actionsAnchorEl}
          disablePortal={false}
          onClose={() => {
            setActionsAnchorEl(null)
            setActiveActionItem(undefined)
          }}
        />
      </Grid>
    </Grid>
  )
}

export default FormsList
