import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { ClassesType, DateUtils } from '@pbt/pbt-ui-components'

import { SoapForm } from '~/types'

import FormInfoCell from './FormInfoCell'
import FormName from './FormName'
import FormStateLabel from './FormStateLabel'
import MoreFormInfoDropdown from './MoreFormInfoDropdown'

export interface UseFormsWidgetColumnsProps {
  actionsAnchorElement: HTMLElement | null
  activeActionItem: string | null
  classes: ClassesType<any>
  setActionsAnchorElement: (element: HTMLElement | null) => void
  setActiveActionItem: (item: string | null) => void
}

const useFormsWidgetColumns = ({
  actionsAnchorElement,
  activeActionItem,
  setActionsAnchorElement,
  setActiveActionItem,
  classes,
}: UseFormsWidgetColumnsProps) => {
  const { t } = useTranslation('Common')

  const columns = [
    {
      id: 'forms_name_column',
      label: t('Common:NAME'),
      value: (form: SoapForm) => <FormName form={form} />,
      classNameRow: classNames(
        classes.cell,
        classes.nameCell,
        classes.cellBordered,
      ),
      classNameHeader: classes.nameHeaderCell,
    },
    {
      id: 'forms_expires_column',
      label: t('Common:EXPIRES'),
      value: (form: SoapForm) =>
        form?.expiryDate ? (
          <FormInfoCell>{DateUtils.formatDate(form?.expiryDate)}</FormInfoCell>
        ) : (
          ''
        ),
      width: '10%',
      classNameRow: classNames(classes.cellBordered, classes.cell),
      classNameHeader: classes.spacingCell,
    },
    {
      id: 'form_status_column',
      label: t('Common:STATUS'),
      value: (form: SoapForm) => (
        <FormInfoCell>
          <FormStateLabel form={form} />
        </FormInfoCell>
      ),
      width: '10%',
      classNameRow: classNames(classes.cellBordered, classes.cell),
      classNameHeader: classes.spacingCell,
    },
    {
      label: '',
      id: 'estm_more_column',
      value: (form: SoapForm) => (
        <FormInfoCell>
          <MoreFormInfoDropdown
            actionsAnchorElement={actionsAnchorElement}
            activeActionItem={activeActionItem}
            form={form}
            setActionsAnchorElement={setActionsAnchorElement}
            setActiveActionItem={setActiveActionItem}
          />
        </FormInfoCell>
      ),
      width: '5%',
      classNameRow: classes.cell,
      classNameHeader: classes.spacingCell,
    },
  ]

  return columns
}

export default useFormsWidgetColumns
