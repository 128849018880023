import { gql } from '@apollo/client/core'

import { PATIENT_DTO_FIELDS } from './clientAndPatient'
import { CONTACT_FIELDS, PERSON_SHORTEN_WITH_ALERT_DTO_FIELDS } from './user'

export const MESSAGE_FIELDS = gql`
  fragment MessageFields on Message {
    id
    businessId
    way
    body
    timestamp
    type
    status
    files {
      id
      messageId
      name
      extension
      fileUrl
      creationDate
      description
    }
    creatorId
  }
`

export const NOTIFICATION_FIELDS = gql`
  fragment NotificationFields on Notification {
    id
    personId
    businessId
    typeId
    areaId
    read
    deleted
    title
    message
    creationDate
    creatorId
  }
`

export const RECIPIENT_FIELDS = gql`
  fragment RecipientFields on Recipient {
    clientId
    contactId
    businessId
    phoneNumber
    email
    name
    type
  }
`

const CONVERSATION_DTO_RELATION_FIELDS = gql`
  ${PERSON_SHORTEN_WITH_ALERT_DTO_FIELDS}
  ${PATIENT_DTO_FIELDS}
  ${NOTIFICATION_FIELDS}
  ${CONTACT_FIELDS}
  ${RECIPIENT_FIELDS}
  fragment ConversationDtoRelationFields on ConversationDto {
    id
    client {
      ...PersonShortenWithAlertDtoFields
    }
    patient {
      ...PatientDtoFields
    }
    contact {
      ...ContactFields
    }
    recipient {
      ...RecipientFields
    }
    notifications {
      ...NotificationFields
    }
  }
`

export const CONVERSATION_DTO_FIELDS = gql`
  ${CONVERSATION_DTO_RELATION_FIELDS}
  fragment ConversationDtoFields on ConversationDto {
    id
    assigneeId
    businessId
    eventId
    assigneeName
    state
    title
    from
    recentActivity
    recentActivityDate
    creationDate
    lastMessageDate
    lastMessageUpdateDate
    transport
    ...ConversationDtoRelationFields
  }
`

export const SHORT_CONVERSATION_DTO_FIELDS = gql`
  ${NOTIFICATION_FIELDS}
  ${RECIPIENT_FIELDS}
  fragment ShortConversationDtoFields on ShortConversationDto {
    id
    assigneeId
    assigneeName
    businessId
    title
    to
    status
    state
    transport
    lastMessageDate
    lastMessageUpdateDate
    recipient {
      ...RecipientFields
    }
    notifications {
      ...NotificationFields
    }
  }
`
export const DOWNLOAD_ATTACHMENT_DTO_FIELDS = gql`
  fragment DownloadAttachmentDtoFields on DownloadAttachmentDto {
    fileUrl
    name
    extension
  }
`
