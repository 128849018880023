import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, GridDirection } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Property } from 'csstype'
import { LanguageUtils, NamedEntity, Text, Utils } from '@pbt/pbt-ui-components'
import { ChewySubmark } from '@pbt/pbt-ui-components/src/icons'

import { removeChewyPrefix } from '~/components/dashboard/invoices/invoiceUtils'
import { AutoshipInfo } from '~/components/dashboard/prescription/elements/AutoshipInfo/AutoshipInfo'
import { AutoshipFrequencyOption } from '~/constants/autoship'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { getAutoshipUnit } from '~/store/reducers/constants'
import { Order } from '~/types'

import OrderMembershipIcon from '../../order/OrderMembershipIcon'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      position: 'relative',
      padding: theme.spacing(1, 2, 2, 0),
    },
    chewyIcon: {
      marginBottom: 'auto',
      marginRight: theme.spacing(0.5),
      height: '24x',
      width: '24px',
    },
    autoshipIconWrapper: {
      display: 'flex',
    },
    detailsSection: {
      marginLeft: theme.spacing(1.5),
    },
    clickableTitle: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    titleContainer: {
      position: 'relative',
    },
    membershipIcon: {
      position: 'absolute',
      top: 0,
      left: -28,
    },
    actionsContainer: {
      marginBottom: 'auto',
      flex: 1,
      minWidth: 112,
      maxWidth: 116,
    },
  }),
  { name: 'ItemWithBadges' },
)

export interface ItemWithBadgesProps {
  actions: React.ReactNode
  alignItems?: Property.AlignItems
  buttonSection?: React.ReactNode
  children?: React.ReactNode
  direction?: GridDirection
  onNameClick?: () => void
  order: Order | NamedEntity
}

const ItemWithBadges = ({
  children,
  onNameClick,
  order,
  actions,
  buttonSection,
  direction = 'row',
  alignItems = 'center',
}: ItemWithBadgesProps) => {
  const classes = useStyles()
  const AutoshipUnits = useSelector(getAutoshipUnit)
  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  const retailOrderLineItem = (order as Order)?.retailOrderLineItem
  const displayAsRetailOrderLineItem =
    retailOrderLineItem && isChewyCheckoutEnabled
  let translatedFieldName = LanguageUtils.getTranslatedFieldName(order)

  if (displayAsRetailOrderLineItem) {
    translatedFieldName = removeChewyPrefix(translatedFieldName)
  }

  const getId = (arg: typeof order) => ('logId' in arg ? arg.logId! : arg.id)

  return (
    <div className={classes.root} id={getId(order)}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        mb={1}
        wrap="nowrap"
      >
        <Grid item className={classes.titleContainer}>
          <OrderMembershipIcon
            className={classes.membershipIcon}
            order={order as Order}
          />
          <div className={classes.autoshipIconWrapper}>
            {displayAsRetailOrderLineItem && (
              <ChewySubmark className={classes.chewyIcon} />
            )}
            <Text
              className={classNames(onNameClick && classes.clickableTitle)}
              variant="h5"
              onClick={onNameClick || (() => {})}
            >
              {translatedFieldName}
            </Text>
          </div>
        </Grid>
        <Grid container item className={classes.actionsContainer}>
          {actions}
        </Grid>
      </Grid>
      <div className={classes.detailsSection}>
        {displayAsRetailOrderLineItem &&
        retailOrderLineItem.autoshipUnitId &&
        retailOrderLineItem.autoshipFrequency ? (
          <AutoshipInfo
            frequency={
              {
                uom: Utils.findById(
                  retailOrderLineItem.autoshipUnitId,
                  AutoshipUnits,
                ),
                value: retailOrderLineItem.autoshipFrequency,
              } as AutoshipFrequencyOption
            }
          />
        ) : null}
        <Grid
          container
          alignItems={alignItems}
          direction={direction}
          wrap="nowrap"
        >
          {children}
        </Grid>
        {buttonSection}
      </div>
    </div>
  )
}

export default ItemWithBadges
