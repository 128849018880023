import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { Text, TextWithTooltip, Utils } from '@pbt/pbt-ui-components'

import { AutoshipFrequencyOption } from '~/constants/autoship'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { getAutoshipUnit } from '~/store/reducers/constants'
import { Prescription } from '~/types'
import { getPrescriptionType, getRxType } from '~/utils/prescription'

import { AutoshipInfo } from '../elements/AutoshipInfo/AutoshipInfo'

interface PrescriptionChewyOrderInfoProps {
  prescription: Prescription
}

const PrescriptionChewyOrderInfo = ({
  prescription,
}: PrescriptionChewyOrderInfoProps) => {
  const { t } = useTranslation('Dialogs')

  const AutoshipUnit = useSelector(getAutoshipUnit)

  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()
  const { isVetDiet } = getRxType(prescription)
  const { isChewyActiveRx } = getPrescriptionType(
    prescription.prescriptionType,
    prescription.origin,
  )
  const isMissingOrderInfo = !prescription.orderNumber || !prescription.rxNo

  const autoshipUnitId =
    prescription.autoshipUnitId ||
    prescription.retailOrderLineItem?.autoshipUnitId
  const autoshipFrequency =
    prescription.autoshipFrequency ||
    prescription.retailOrderLineItem?.autoshipFrequency

  const isChewyCheckoutEnabledAndHasAutoship =
    isChewyCheckoutEnabled && autoshipUnitId && autoshipFrequency

  return (
    <Box>
      <TextWithTooltip
        allowWrap
        strong
        tooltipText={
          <>
            {t('Dialogs:PRESCRIPTION_DIALOG.CHEWY_ORDER_INFO_TOOLTIP')}
            <br />
            {t(
              'Dialogs:PRESCRIPTION_DIALOG.CHEWY_ORDER_INFO_TOOLTIP_PHONE_NUMBER',
            )}
          </>
        }
        variant="subheading3"
      >
        {t('Dialogs:PRESCRIPTION_DIALOG.CHEWY_ORDER_INFO')}
      </TextWithTooltip>
      {isChewyActiveRx && isMissingOrderInfo && (
        <Text variant="body2">
          {t('Dialogs:PRESCRIPTION_DIALOG.CHEWY_ORDER_INFO_MISSING')}
        </Text>
      )}
      <Text variant="body2">
        {t('Dialogs:PRESCRIPTION_DIALOG.CHEWY_ORDER_NUMBER')}:{' '}
        {prescription.orderNumber || '—'}
      </Text>
      <Text variant="body2">
        {isVetDiet
          ? t('Dialogs:PRESCRIPTION_DIALOG.CHEWY_RECIPE_NUMBER')
          : t('Dialogs:PRESCRIPTION_DIALOG.CHEWY_PRESCRIPTION_NUMBER')}
        {': '}
        {prescription.rxNo || '—'}
      </Text>
      {isChewyCheckoutEnabledAndHasAutoship && (
        <AutoshipInfo
          frequency={
            {
              uom: Utils.getConstantName(autoshipUnitId, AutoshipUnit),
              value: autoshipFrequency,
            } as AutoshipFrequencyOption
          }
        />
      )}
    </Box>
  )
}

export default PrescriptionChewyOrderInfo
