import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import {
  AlertIconType,
  BasePuiDialogProps,
  ButtonWithLoader,
  CurrencyTextField,
  CustomFieldValidator,
  Defaults,
  ErrorTooltip,
  PuiDialog,
  PuiSelect,
  PuiTextArea,
  Text,
  TextInteractive,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import { CreditAdjustmentInput } from '~/api/graphql/generated/types'
import DialogNames from '~/constants/DialogNames'
import { createCreditAdjustment } from '~/store/actions/payments'
import {
  fetchInvoicePage,
  getHasUnpaidInvoices,
  getUnpaidInvoiceLoading,
  getUnpaidInvoicePageData,
} from '~/store/duck/clientBillingActivityData'
import { getCurrentUser } from '~/store/reducers/auth'
import {
  getCreditAdjustmentReason,
  getPaymentMethod,
} from '~/store/reducers/constants'
import {
  getPaymentsError,
  getPaymentsHasConcurrentError,
  getPaymentsIsSaving,
} from '~/store/reducers/payments'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import {
  CREDIT_ADJUSTMENT_ROW_HEIGHT,
  UnpaidInvoicesTable,
} from '../../../clients/balance/table/invoices/UnpaidInvoicesTable'
import { getInvoiceDueToPayNoFee } from '../../invoiceUtils'

const useStyles = makeStyles(
  (theme) => ({
    actions: {
      padding: theme.spacing(2, 2, 2, 2),
      margin: theme.spacing(0, -2),
    },
    button: {
      padding: theme.spacing(1.25, 2),
    },
    creditAmount: {
      margin: theme.spacing(0, 2, 0, 1),
      width: 87,
    },
    creditReason: {
      width: 230,
    },
    currencyInput: {
      textAlign: 'right',
    },
    paper: {
      width: 1024,
      maxWidth: 1024,
      padding: theme.spacing(2, 2, 1, 2),
    },
    table: {
      width: '100%',
      minHeight: CREDIT_ADJUSTMENT_ROW_HEIGHT,
      maxHeight: CREDIT_ADJUSTMENT_ROW_HEIGHT * 10,
      overflow: 'auto',
    },
    title: {
      padding: theme.spacing(0, 0, 1, 2),
      margin: theme.spacing(0, -2),
    },
  }),
  { name: 'CreditAdjustmentDialog' },
)

export interface CreditAdjustmentDialogProps extends BasePuiDialogProps {
  clientId: string
}

export const CreditAdjustmentDialog = ({
  clientId,
  open,
  onClose,
}: CreditAdjustmentDialogProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs', 'Validations'])

  const currentUser = useSelector(getCurrentUser)
  const PaymentMethod = useSelector(getPaymentMethod)
  const CreditAdjustmentReason = useSelector(getCreditAdjustmentReason)
  const isLoading = useSelector(getUnpaidInvoiceLoading)
  const isSaving = useSelector(getPaymentsIsSaving)
  const hasError = useSelector(getPaymentsError)
  const unpaidInvoices = useSelector(getUnpaidInvoicePageData)
  const hasConcurrentError = useSelector(getPaymentsHasConcurrentError)
  const errorMessage = useSelector(getPaymentsError)

  const [openWarningDialog, onCloseWarningDialog] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )
  const [openAlertDialog, onCloseAlertDialog] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )
  const adjustmentPaymentMethod = Utils.findConstantIdByName(
    'Other',
    PaymentMethod,
  )
  const hasUnpaidInvoices = useSelector(getHasUnpaidInvoices)
  const showSubheading = !isLoading && !hasUnpaidInvoices

  const totalInvoiceValidator: CustomFieldValidator['validator'] = ({
    state,
    value,
  }) => {
    const { invoiceIds } = state

    if (R.isEmpty(invoiceIds)) {
      return true
    }

    const selectedUnpaidInvoices = unpaidInvoices.filter((invoice) =>
      invoiceIds.includes(invoice.id),
    )
    const totalDueToPay = Number(
      selectedUnpaidInvoices.reduce((total, invoice) => {
        total += getInvoiceDueToPayNoFee(invoice) ?? 0
        return total
      }, 0),
    ).toFixed(2)

    const isValid = value <= totalDueToPay

    return isValid
  }

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'creditAmount',
        type: 'number',
        label: t('Common:PAYMENTS.CREDIT_AMOUNT'),
        initialValue: 0,
        validators: [
          ...(hasUnpaidInvoices
            ? [
                {
                  validator: totalInvoiceValidator,
                  validatorName: 'totalInvoiceValidator',
                },
              ]
            : []),
          'greaterThanZero',
        ],
        messages: {
          totalInvoiceValidator: t('Validations:CREDIT_ADJUSTMENT'),
        },
      },
      {
        name: 'creditAdjustmentReasonId',
        type: 'select',
        label: t('Common:CREDIT_ADJUSTMENT_REASON'),
        validators: ['required'],
      },
      {
        name: 'notes',
        type: 'text',
        label: `${t('Common:INTERNAL_NOTE')}*`,
        initialValue: '',
        validators: ['required'],
      },
      {
        name: 'recordedBy',
        type: 'text',
        initialValue: currentUser.id,
        label: `${t('Common:PAYMENTS.RECORDED_BY')}: ${Utils.getPersonString(
          currentUser,
        )}`,
        validators: ['required'],
      },
      {
        name: 'invoiceIds',
        type: 'select',
        initialValue: [],
        validators: hasUnpaidInvoices ? ['required'] : [],
      },
    ],
    false,
  )

  const setCloseAfterCreation = useCloseAfterCreation(() => {
    if (hasError) {
      return
    }
    reset()
    onClose?.()
  }, getPaymentsIsSaving)

  const {
    creditAmount,
    creditAdjustmentReasonId,
    notes,
    recordedBy,
    invoiceIds,
  } = fields

  const showInvoiceError = invoiceIds.errors.length > 0 && invoiceIds.open

  const checkMultipleInvoicesHasMinAmount = () => {
    const invoiceIdsValue = invoiceIds.value

    if (invoiceIdsValue.length <= 1) {
      return true
    }

    const selectedUnpaidInvoicesDueToPayNoFee = unpaidInvoices
      .filter((invoice) => invoiceIdsValue.includes(invoice.id))
      .map(
        (filteredInvoice) =>
          Number(getInvoiceDueToPayNoFee(filteredInvoice)?.toFixed(2)) ?? 0,
      )

    const minDueToPayNoFee = Math.min(...selectedUnpaidInvoicesDueToPayNoFee)

    return creditAmount.value > minDueToPayNoFee
  }

  const proceed = () => {
    const adjustmentToRecord: CreditAdjustmentInput = {
      amount: creditAmount.value,
      paymentMethodId: adjustmentPaymentMethod,
      reasonId: creditAdjustmentReasonId.value,
      notes: notes.value,
      invoiceIds: invoiceIds.value,
    }
    setCloseAfterCreation()
    dispatch(
      createCreditAdjustment({
        clientId,
        adjustmentToRecord,
      }),
    )
  }

  const recordAdjustment = () => {
    if (!validate()) {
      return
    }

    if (checkMultipleInvoicesHasMinAmount()) {
      proceed()
    } else {
      openWarningDialog({
        onOk: () => {
          proceed()
          onCloseWarningDialog()
        },
        message: t('Validations:MIN_AMOUNT_FOR_MULTIPLE_INVOICES'),
      })
    }
  }

  const onCheck = (invoiceId: string) => {
    const newInvoiceIds = Utils.toggleListItem(invoiceId, invoiceIds.value)
    invoiceIds.setValue(newInvoiceIds)
  }

  useEffect(() => {
    dispatch(
      fetchInvoicePage({
        clientId,
        unpaid: true,
        limit: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        offset: 0,
        refundStateIds: [],
        stateIds: [],
      }),
    )
  }, [clientId])

  useEffect(() => {
    if (errorMessage) {
      const onProceed = () => {
        if (hasConcurrentError && clientId) {
          dispatch(
            fetchInvoicePage({
              clientId,
              unpaid: true,
              limit: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
              offset: 0,
              refundStateIds: [],
              stateIds: [],
            }),
          )
        }
        onCloseAlertDialog()
      }

      openAlertDialog({
        iconType: AlertIconType.WARN,
        message: errorMessage,
        okButtonText: t('Common:CONTINUE_ACTION'),
        onOk: onProceed,
        onClose: onProceed,
      })
    }
  }, [errorMessage, hasConcurrentError, clientId])

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading || isSaving}
          loading={isSaving}
          onClick={recordAdjustment}
        >
          {t('Dialogs:CREDIT_ADJUSTMENT_DIALOG.ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="credit-adjustment-dialog"
      classes={{
        actions: classes.actions,
        paper: classes.paper,
        dialogTitle: classes.title,
      }}
      open={open}
      title={t('Common:CREDIT_ADJUSTMENT')}
      onClose={onClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        mb={showSubheading ? 4 : 2}
        mt={2}
      >
        <Text variant="h4">{t('Common:PAYMENTS.CREDIT_AMOUNT')}</Text>
        {showSubheading && (
          <Text variant="body3">
            {t('Dialogs:CREDIT_ADJUSTMENT_DIALOG.SUBHEADING')}
          </Text>
        )}
      </Box>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="flex-start"
        mb={3}
      >
        <Text strong>{creditAmount.label}:</Text>
        <CurrencyTextField
          className={classes.creditAmount}
          field={creditAmount}
          fullWidth={false}
          inputProps={{
            className: classes.currencyInput,
            'data-testid': 'credit-amount-input',
          }}
        />
        <PuiSelect
          SelectDisplayProps={{
            // @ts-ignore
            'data-testid': 'credit-adjustment-reason-input',
          }}
          className={classes.creditReason}
          field={creditAdjustmentReasonId}
          items={CreditAdjustmentReason}
          placeholder={t('Common:CREDIT_ADJUSTMENT_REASON')}
        />
      </Box>
      {(isLoading || hasUnpaidInvoices) && (
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          mb={3}
        >
          <TextInteractive isLoading={isLoading} variant="h4">
            {t('Dialogs:CREDIT_ADJUSTMENT_DIALOG.APPLY_TO_AN_INVOICE')}
          </TextInteractive>
          <TextInteractive isLoading={isLoading} mb={1} variant="body3">
            {t('Dialogs:CREDIT_ADJUSTMENT_DIALOG.APPLY_TO_INVOICE_EXPLANATION')}
          </TextInteractive>

          <ErrorTooltip
            arrow={false}
            message={t('Validations:SELECT_AT_LEAST_ONE_INVOICE')}
            open={showInvoiceError}
            placement="bottom-start"
          >
            <Box className={classes.table}>
              <UnpaidInvoicesTable
                hasMissingSelectedInvoiceError={showInvoiceError}
                isLoading={isLoading}
                selectedInvoiceIds={invoiceIds.value}
                unpaidInvoices={unpaidInvoices}
                onCheck={onCheck}
              />
            </Box>
          </ErrorTooltip>
        </Box>
      )}
      <PuiTextArea
        InputProps={{
          inputProps: { maxLength: 2000, 'data-testid': 'credit-notes-input' },
        }}
        field={notes}
        label={notes.label}
        labelProps={{ mb: -1 }}
        minRows={2}
        placeholder={t('Common:NOTES')}
      />
      <Text mb={2} mt={1} variant="body3">
        {recordedBy.label}
      </Text>
    </PuiDialog>
  )
}
