import React from 'react'
import { useTranslation } from 'react-i18next'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import NotesIcon from '@mui/icons-material/Notes'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import ViewStreamIcon from '@mui/icons-material/ViewStream'
import { styled } from '@mui/material/styles'
import { DocumentElementType } from '@pbt/pbt-ui-components'
import {
  Calendar,
  CheckList,
  Signature,
} from '@pbt/pbt-ui-components/src/icons'

import ActionsButton from '~/components/common/buttons/ActionsButton'
import DialogNames from '~/constants/DialogNames'
import {
  CheckboxGroupState,
  CheckboxState,
  DatePickerState,
  DocumentElementStateItem,
  RadioGroupState,
  TextEntryState,
} from '~/types'
import useDialog from '~/utils/useDialog'

const StyledActionsButton = styled(ActionsButton)`
  && {
    width: 125px;
    justify-content: space-around;
    padding-left: ${({ theme }) => theme.spacing(4)};
    padding-right: ${({ theme }) => theme.spacing(4)};
  }
`

interface AddDocumentElementMenuProps {
  addDocumentElement: ({
    type,
    data,
  }: {
    data?: DocumentElementStateItem
    type: DocumentElementType
  }) => void
  disabled: boolean
}

export const AddDocumentElementMenu = ({
  addDocumentElement,
  disabled,
}: AddDocumentElementMenuProps) => {
  const { t } = useTranslation('Common')

  const [openAddSingleCheckboxDialog] = useDialog(
    DialogNames.ADD_SINGLE_CHECKBOX,
  )

  const [openMultipleSelectDialog] = useDialog(
    DialogNames.MULTIPLE_SELECT_DIALOG,
  )

  const [openDocumentRadioGroupDialog] = useDialog(
    DialogNames.DOCUMENT_RADIO_GROUP_DIALOG,
  )

  const [openDocumentDateTimePickerDialog] = useDialog(
    DialogNames.ADD_DATE_TIME_PICKER,
  )

  const [openTextEntryDialog] = useDialog(DialogNames.TEXT_ENTRY)

  const getActions = () => [
    {
      title: t('Common:CONTENT'),
      id: 'content',
      isGroup: true,
      items: [
        {
          id: 'page-break',
          label: t('Common:PAGE_BREAK'),
          Icon: ViewStreamIcon,
          onClick: () =>
            addDocumentElement({
              type: DocumentElementType.PAGE_BREAK,
            }),
        },
        {
          id: 'rich-text',
          label: t('Common:RICH_TEXT'),
          Icon: NotesIcon,
          onClick: () =>
            addDocumentElement({
              type: DocumentElementType.TEXT,
            }),
        },
      ],
    },
    {
      title: t('Common:USER_INPUT'),
      id: 'user-input',
      isGroup: true,
      items: [
        {
          id: 'date',
          label: t('Common:DATE_TIME'),
          Icon: Calendar,
          onClick: () => {
            openDocumentDateTimePickerDialog({
              isDateOnlyDialog: true,
              onSave: (data: DatePickerState) =>
                addDocumentElement({
                  type: DocumentElementType.DATE,
                  data,
                }),
            })
          },
        },
        {
          id: 'date-time',
          label: t('Common:DATE_AND_TIME'),
          Icon: Calendar,
          onClick: () => {
            openDocumentDateTimePickerDialog({
              onSave: (data: DatePickerState) =>
                addDocumentElement({
                  type: DocumentElementType.DATE_TIME,
                  data,
                }),
            })
          },
        },
        {
          id: 'multiple-choice',
          label: t('Common:MULTIPLE_CHOICE'),
          Icon: RadioButtonCheckedIcon,
          onClick: () => {
            openDocumentRadioGroupDialog({
              onSave: (data: RadioGroupState) =>
                addDocumentElement({
                  type: DocumentElementType.SINGLE_SELECT,
                  data,
                }),
            })
          },
        },
        {
          id: 'check-boxes',
          label: t('Common:CHECK_BOXES'),
          Icon: CheckList,
          onClick: () => {
            openMultipleSelectDialog({
              onSave: (data: CheckboxGroupState) =>
                addDocumentElement({
                  type: DocumentElementType.MULTI_SELECT,
                  data,
                }),
            })
          },
        },
        {
          id: 'single-checkbox',
          label: t('Common:SINGLE_CHECKBOX'),
          Icon: CheckBoxIcon,
          onClick: () => {
            openAddSingleCheckboxDialog({
              onSave: (data: CheckboxState) =>
                addDocumentElement({
                  type: DocumentElementType.SINGLE_CHECKBOX,
                  data,
                }),
            })
          },
        },
        {
          id: 'signature',
          label: t('Common:SIGNATURE'),
          Icon: Signature,
          onClick: () =>
            addDocumentElement({
              type: DocumentElementType.SIGNATURE,
            }),
        },
        {
          id: 'text-field',
          label: t('Common:TEXT_FIELD'),
          Icon: TextFieldsIcon,
          onClick: () => {
            openTextEntryDialog({
              onSave: (data: TextEntryState) =>
                addDocumentElement({
                  type: DocumentElementType.TEXT_FIELD,
                  data,
                }),
            })
          },
        },
      ],
    },
  ]

  return (
    <StyledActionsButton
      useNormalButton
      actions={getActions()}
      disabled={disabled}
    >
      {t('Common:ADD_ACTION')}
    </StyledActionsButton>
  )
}
