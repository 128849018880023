import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DocumentElementType } from '@pbt/pbt-ui-components'

import { HTML } from '~/constants/extensions'
import { editDocument, fetchDocument } from '~/store/actions/documents'
import { getDocument } from '~/store/reducers/documents'
import { Document, DocumentElementStateItem, PageBreakState } from '~/types'

import DocumentDetails from './DocumentDetails'
import { DocumentDetailsNew } from './DocumentDetailsNew'
import { useIsFormTypeDocument } from './utils'

export interface DocumentEditProps {
  itemId: string
  onClose: () => void
}

const getInitialDocumentElementsList = (document: Document | undefined) =>
  document?.template?.documentElements
    ? [...document.template.documentElements]
        .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
        .map((item) => String(item.id))
    : undefined

const getInitialPageList = (
  document: Document | undefined,
): string[] | undefined =>
  document?.template?.documentElements
    ? [...document.template.documentElements]
        .filter((item) => item.type === DocumentElementType.PAGE_BREAK)
        .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
        .map((item) => String(item.id))
    : undefined

const getInitialDocumentElements = (document: Document | undefined) => {
  if (!document?.template?.documentElements) return {}

  const pageList = getInitialPageList(document) || []

  const documentElements = document.template.documentElements.reduce(
    (acc, item) => {
      // Sort options if the item has options
      if (
        item.type === DocumentElementType.MULTI_SELECT ||
        item.type === DocumentElementType.SINGLE_SELECT
      ) {
        const sortedOptions = item.options
          ? [...item.options].sort(
              (a, b) => a.sequenceNumber - b.sequenceNumber,
            )
          : undefined
        return { ...acc, [item.id]: { ...item, options: sortedOptions } }
      }

      return { ...acc, [item.id]: item }
    },
    {},
  ) as unknown as Record<string, DocumentElementStateItem>

  // Set page and totalPages for PAGE_BREAK type
  pageList.forEach((pageId, index) => {
    const item = documentElements[pageId] as unknown as PageBreakState
    documentElements[pageId] = {
      ...item,
      page: index + 1,
      totalPages: pageList.length,
    }
  })

  return documentElements
}

export const DocumentEdit = ({ itemId, onClose }: DocumentEditProps) => {
  const dispatch = useDispatch()

  const document = useSelector(getDocument(itemId))
  const isForm = useIsFormTypeDocument(itemId)
  const template = document?.template
  const isHTML = template?.extension === HTML
  const isNewVersion = isForm && isHTML && !template?.body
  const documentIsReady = Boolean(template)

  useEffect(() => {
    dispatch(fetchDocument(itemId))
  }, [itemId])

  if (!documentIsReady) return null

  return isNewVersion ? (
    <DocumentDetailsNew
      document={document!}
      initialDocumentElements={getInitialDocumentElements(document)}
      initialDocumentElementsList={getInitialDocumentElementsList(document)}
      initialPageList={getInitialPageList(document)}
      onClose={onClose}
      onSave={(doc: Document) => dispatch(editDocument(doc))}
    />
  ) : (
    <DocumentDetails itemId={itemId} onClose={onClose} />
  )
}
