import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { createDocumentForm } from '~/store/actions/documents'
import {
  Document,
  DocumentElementCreate,
  DocumentElementEdit,
  DocumentElementStateItem,
} from '~/types'

import { DocumentDetailsNew } from './DocumentDetailsNew'

interface DocumentCloningLocationState {
  state: {
    cloneData: {
      initialDocumentElements: Record<string, DocumentElementStateItem>
      initialDocumentElementsList: string[]
      initialPageList: string[]
      newDocument: Document
    }
  }
}

interface DocumentCloningProps {
  onClose: () => void
}

export const DocumentCloning = ({ onClose }: DocumentCloningProps) => {
  const location = useLocation() as DocumentCloningLocationState
  const dispatch = useDispatch()

  const { t } = useTranslation('Common')

  const { cloneData } = location.state || {}
  // eslint-disable-next-line react/hook-use-state
  const [data] = useState(cloneData)
  const [hasUnsavedData, setHasUnsavedData] = useState(true)
  if (!data) return null

  const handleSave = (doc: Document) => {
    const removeIds = (element: DocumentElementEdit): DocumentElementCreate => {
      // Remove the id from the main element
      const { id: elementId, ...elementWithoutId } = element

      // Check if the element has options (an array) and remove ids from each option
      if (
        'options' in elementWithoutId &&
        Array.isArray(elementWithoutId.options)
      ) {
        // @ts-ignore
        elementWithoutId.options = elementWithoutId.options.map((option) => ({
          ...option,
          id: undefined,
        }))
      }

      // Check if the element has a single option and remove its id
      if ('option' in elementWithoutId) {
        // @ts-ignore
        elementWithoutId.option = { ...elementWithoutId.option, id: undefined } // Replace with option without id
      }

      return elementWithoutId
    }

    // Process each document element, removing ids from elements and nested options
    const updatedDocument = {
      ...doc,
      template: {
        ...doc.template,
        documentElements: doc.template?.documentElements?.map(removeIds),
      },
    }

    setHasUnsavedData(false)
    dispatch(createDocumentForm(updatedDocument))
  }

  return (
    <DocumentDetailsNew
      disableClone
      disableDelete
      document={data.newDocument}
      hasUnsavedData={hasUnsavedData}
      initialDocumentElements={data.initialDocumentElements}
      initialDocumentElementsList={data.initialDocumentElementsList}
      initialPageList={data.initialPageList}
      saveButtonLabel={t('Common:CREATE_FORM')}
      onClose={onClose}
      onSave={handleSave}
    />
  )
}
