import React from 'react'
import { TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { NumberUtils, Text } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import { getIsRetailOrderLineItem } from '~/components/dashboard/invoices/invoiceUtils'
import { FINANCE_CELL_WIDTH } from '~/constants/financeTable'
import { InvoiceLineItem } from '~/types'

const { SUBTOTAL } = FINANCE_CELL_WIDTH

const useStyles = makeStyles(
  () => ({
    subtotalCell: {
      minWidth: SUBTOTAL,
      width: SUBTOTAL,
    },
  }),
  { name: 'FinanceItemSubTotalCellBody' },
)

export interface FinanceItemSubTotalCellBodyProps {
  isFullyRefunded?: boolean
  item: InvoiceLineItem | RetailOrderLineItem
  tableCellClassName: string
}

const FinanceItemSubTotalCellBody = ({
  item,
  isFullyRefunded = false,
  tableCellClassName,
}: FinanceItemSubTotalCellBodyProps) => {
  const classes = useStyles()
  const displayTotal = getIsRetailOrderLineItem(item)
    ? item.totalPrice
    : item.subTotal

  return (
    <TableCell
      align="right"
      className={classNames(tableCellClassName, classes.subtotalCell)}
    >
      {!item.declined && (
        <Text variant={isFullyRefunded ? 'lowAccent2' : 'body2'}>
          {NumberUtils.formatMoney(displayTotal)}
        </Text>
      )}
    </TableCell>
  )
}

export default FinanceItemSubTotalCellBody
