import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  BigDecimal: { input: any; output: any }
  DateTime: { input: any; output: any }
  DateUnit: { input: any; output: any }
  DayOfWeek: { input: any; output: any }
  JSON: { input: any; output: any }
  LocalDate: { input: any; output: any }
  LocalTime: { input: any; output: any }
  Long: { input: any; output: any }
  PageLimit: { input: any; output: any }
  PageOffset: { input: any; output: any }
  Path: { input: any; output: any }
  QueryText: { input: any; output: any }
  Upload: { input: any; output: any }
}

export enum ActionType {
  MedicalHistoryPdfCreate = 'MEDICAL_HISTORY_PDF_CREATE',
  ReportCardPdfCreate = 'REPORT_CARD_PDF_CREATE',
}

export type ActiveRxAllowance = {
  __typename?: 'ActiveRxAllowance'
  business: Scalars['Boolean']['output']
  client: Scalars['Boolean']['output']
  patient: Scalars['Boolean']['output']
}

export type Address = {
  __typename?: 'Address'
  city: Scalars['String']['output']
  email1?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  postcode: Scalars['String']['output']
  state: Scalars['String']['output']
  street1: Scalars['String']['output']
  street2?: Maybe<Scalars['String']['output']>
}

export enum AdminCourseType {
  AsNeeded = 'AS_NEEDED',
  Custom = 'CUSTOM',
  UntilGone = 'UNTIL_GONE',
}

export enum AdministrationCourseType {
  AsNeeded = 'AS_NEEDED',
  Custom = 'CUSTOM',
  UntilGone = 'UNTIL_GONE',
}

export type Age = {
  __typename?: 'Age'
  amount: Scalars['Int']['output']
  unitId: Scalars['ID']['output']
}

export type Answer = {
  __typename?: 'Answer'
  id: Scalars['ID']['output']
  inputs?: Maybe<Array<Maybe<Input>>>
  text?: Maybe<Scalars['String']['output']>
}

export type Appointment = Event &
  TimelineEntry & {
    __typename?: 'Appointment'
    appointmentCancellationReason?: Maybe<Scalars['String']['output']>
    appointmentCancellationReasonId?: Maybe<Scalars['ID']['output']>
    appointmentReason?: Maybe<AppointmentReason>
    assignedVetId?: Maybe<Scalars['ID']['output']>
    businessAppointmentType?: Maybe<BusinessAppointmentType>
    businessId: Scalars['ID']['output']
    checkedIn?: Maybe<Scalars['Boolean']['output']>
    client: Client
    clientId: Scalars['ID']['output']
    clientInstructions?: Maybe<Scalars['String']['output']>
    confirmed: Scalars['Boolean']['output']
    createdAt: Scalars['DateTime']['output']
    deleted?: Maybe<Scalars['Boolean']['output']>
    dialIn?: Maybe<Scalars['String']['output']>
    documents?: Maybe<Array<Maybe<SoapFile>>>
    id: Scalars['ID']['output']
    invoices: Array<Invoice>
    meetingLink?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
    netPromoterScore?: Maybe<NetPromoterScore>
    notes?: Maybe<Scalars['String']['output']>
    patient: Patient
    patientId: Scalars['ID']['output']
    resources: Array<EventResource>
    savedAmount?: Maybe<Scalars['Float']['output']>
    scheduledEndDatetime: Scalars['DateTime']['output']
    scheduledStartDatetime: Scalars['DateTime']['output']
    soaps: Array<Soap>
    state: ConstantEntity
    timelineDate?: Maybe<Scalars['DateTime']['output']>
    type: ConstantEntity
    updatedAt: Scalars['DateTime']['output']
    wplanCharged?: Maybe<Scalars['Boolean']['output']>
  }

export type AppointmentDocumentsArgs = {
  fileExtension?: InputMaybe<Scalars['String']['input']>
}

export type AppointmentCancellationReason = NamedEntity & {
  __typename?: 'AppointmentCancellationReason'
  clientFacing?: Maybe<Scalars['Boolean']['output']>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export enum AppointmentCommunicationSection {
  CalendarInvite = 'CALENDAR_INVITE',
  CheckInLink = 'CHECK_IN_LINK',
  ClientInstructions = 'CLIENT_INSTRUCTIONS',
  PrimaryComplaint = 'PRIMARY_COMPLAINT',
}

export type AppointmentConfirmationInput = {
  message?: InputMaybe<Scalars['String']['input']>
  recipients: Array<RecipientDto>
  sections?: InputMaybe<Array<InputMaybe<AppointmentCommunicationSection>>>
  subject: Scalars['String']['input']
  transport: ConversationTransport
}

export type AppointmentPage = Page & {
  __typename?: 'AppointmentPage'
  data: Array<Appointment>
  totalCount: Scalars['Int']['output']
}

export type AppointmentReason = {
  __typename?: 'AppointmentReason'
  appointmentType: ConstantEntity
  coveredFor?: Maybe<Array<Maybe<Patient>>>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  wplanCharged?: Maybe<Scalars['Boolean']['output']>
}

export type AssignedMonitorState = SpaceMonitorState & {
  __typename?: 'AssignedMonitorState'
  appointments?: Maybe<Array<Appointment>>
  attachments?: Maybe<Array<SoapFile>>
  charges?: Maybe<GroupedLineItems>
  client?: Maybe<Client>
  currentWeight?: Maybe<CurrentWeight>
  deviceName: Scalars['String']['output']
  estimates?: Maybe<Array<Estimate>>
  futureAppointments?: Maybe<Array<Appointment>>
  imagingOrders?: Maybe<Array<ImagingOrder>>
  labTestEntries?: Maybe<Array<DashboardLabTestEntry>>
  patientPreference?: Maybe<PatientPreference>
  patients?: Maybe<Array<Patient>>
  prescriptions?: Maybe<Array<Prescription>>
  questions?: Maybe<Array<Question>>
  receivedAt: Scalars['DateTime']['output']
  reminders?: Maybe<Array<Reminder>>
  space: Space
}

export type AssignedMonitorStateAttachmentsArgs = {
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  patientId: Scalars['ID']['input']
}

export type AssignedMonitorStateChargesArgs = {
  patientId: Scalars['ID']['input']
}

export type AssignedMonitorStateCurrentWeightArgs = {
  patientId: Scalars['ID']['input']
}

export type AssignedMonitorStateEstimatesArgs = {
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  patientId: Scalars['ID']['input']
}

export type AssignedMonitorStateFutureAppointmentsArgs = {
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  patientId: Scalars['ID']['input']
}

export type AssignedMonitorStateImagingOrdersArgs = {
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  patientId: Scalars['ID']['input']
}

export type AssignedMonitorStateLabTestEntriesArgs = {
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  patientId: Scalars['ID']['input']
}

export type AssignedMonitorStatePatientPreferenceArgs = {
  patientId: Scalars['ID']['input']
}

export type AssignedMonitorStatePatientsArgs = {
  limit?: InputMaybe<Scalars['PageLimit']['input']>
}

export type AssignedMonitorStatePrescriptionsArgs = {
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  patientId: Scalars['ID']['input']
}

export type AssignedMonitorStateQuestionsArgs = {
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  patientId: Scalars['ID']['input']
}

export type AssignedMonitorStateRemindersArgs = {
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  patientId: Scalars['ID']['input']
}

export type Attachment = {
  __typename?: 'Attachment'
  businessId: Scalars['ID']['output']
  creationDate: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  extension: Scalars['String']['output']
  fileUrl: Scalars['String']['output']
  id: Scalars['ID']['output']
  messageId: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type AttachmentDto = {
  __typename?: 'AttachmentDto'
  businessId: Scalars['ID']['output']
  creationDate: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  extension: Scalars['String']['output']
  fileUrl: Scalars['String']['output']
  id: Scalars['ID']['output']
  messageId: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type AttachmentHistoryGroupDto = {
  __typename?: 'AttachmentHistoryGroupDto'
  attachmentHistories: Array<AttachmentHistoryV3>
  authorId: Scalars['ID']['output']
  businessId: Scalars['ID']['output']
  date: Scalars['DateTime']['output']
  diagnosedBy?: Maybe<Scalars['String']['output']>
  diagnosedIn?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  notes?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type AttachmentHistoryV3 = {
  __typename?: 'AttachmentHistoryV3'
  creationDate: Scalars['DateTime']['output']
  extension: Scalars['String']['output']
  fileUrl: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  originalFileName: Scalars['String']['output']
}

export type AttachmentOrigin = {
  description?: InputMaybe<Scalars['String']['input']>
  extension?: InputMaybe<Scalars['String']['input']>
  fileUrl?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type AvailabilityRule = {
  __typename?: 'AvailabilityRule'
  businessId: Scalars['ID']['output']
  endDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  personId?: Maybe<Scalars['ID']['output']>
  recurrenceParams?: Maybe<Array<Scalars['DayOfWeek']['output']>>
  recurrenceType: AvailabilityRuleRecurrenceType
  scheduledEndTime: Scalars['LocalTime']['output']
  scheduledStartTime: Scalars['LocalTime']['output']
  startDate: Scalars['LocalDate']['output']
  timeZone: Scalars['String']['output']
  type: AvailabilityRuleType
}

export type AvailabilityRuleInput = {
  businessId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['LocalDate']['input']>
  name: Scalars['String']['input']
  personId?: InputMaybe<Scalars['ID']['input']>
  recurrenceParams?: InputMaybe<Array<Scalars['DayOfWeek']['input']>>
  recurrenceType: AvailabilityRuleRecurrenceType
  scheduledEndTime: Scalars['LocalTime']['input']
  scheduledStartTime: Scalars['LocalTime']['input']
  startDate: Scalars['LocalDate']['input']
  timeZone: Scalars['String']['input']
}

export enum AvailabilityRuleRecurrenceType {
  DaysOfWeek = 'DAYS_OF_WEEK',
  Once = 'ONCE',
  Weekly = 'WEEKLY',
}

export enum AvailabilityRuleType {
  HoursOfOperation = 'HOURS_OF_OPERATION',
  StaffWorkingHours = 'STAFF_WORKING_HOURS',
}

export type Benefit = {
  __typename?: 'Benefit'
  appointmentTypeIds?: Maybe<Array<Scalars['ID']['output']>>
  businessId: Scalars['ID']['output']
  cover?: Maybe<Array<Maybe<CoverOption>>>
  coverAppointmentType?: Maybe<BenefitAppointmentType>
  coverCatalog?: Maybe<Scalars['Boolean']['output']>
  createdById?: Maybe<Scalars['ID']['output']>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  globalBenefitId?: Maybe<Scalars['ID']['output']>
  groupId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  level?: Maybe<Scalars['Int']['output']>
  limit?: Maybe<Scalars['Int']['output']>
  limitCustomizable?: Maybe<Scalars['Boolean']['output']>
  limitReached?: Maybe<Scalars['Boolean']['output']>
  limitType?: Maybe<BenefitLimitType>
  limitTypeId?: Maybe<Scalars['ID']['output']>
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  modifiedById?: Maybe<Scalars['ID']['output']>
  name: Scalars['String']['output']
  order?: Maybe<Scalars['Int']['output']>
  planId: Scalars['ID']['output']
  planType?: Maybe<PlanType>
  planTypeId?: Maybe<Scalars['ID']['output']>
  type?: Maybe<BenefitType>
  typeId?: Maybe<Scalars['ID']['output']>
  usageCount?: Maybe<Scalars['BigDecimal']['output']>
  usages?: Maybe<Array<Maybe<BenefitUsage>>>
  versionId: Scalars['ID']['output']
}

export enum BenefitAppointmentType {
  None = 'NONE',
  Regular = 'REGULAR',
  Telehealth = 'TELEHEALTH',
}

export enum BenefitLimitType {
  Limited = 'LIMITED',
  Unlimited = 'UNLIMITED',
}

export enum BenefitType {
  Access = 'ACCESS',
  Cover = 'COVER',
  Reward = 'REWARD',
}

export type BenefitUsage = {
  __typename?: 'BenefitUsage'
  appointmentDate?: Maybe<Scalars['DateTime']['output']>
  benefitId?: Maybe<Scalars['Int']['output']>
  category?: Maybe<Scalars['String']['output']>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  invoiceLineItemId?: Maybe<Scalars['Int']['output']>
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  name?: Maybe<Scalars['String']['output']>
  priceId?: Maybe<Scalars['Int']['output']>
  wplanCoverId?: Maybe<Scalars['Int']['output']>
  wplanLogId?: Maybe<Scalars['Int']['output']>
}

export type BigDecimalValueRange = {
  __typename?: 'BigDecimalValueRange'
  high?: Maybe<Scalars['BigDecimal']['output']>
  low?: Maybe<Scalars['BigDecimal']['output']>
}

export type BigDecimalVitalValue = VitalValue & {
  __typename?: 'BigDecimalVitalValue'
  notes?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['BigDecimal']['output']>
}

export type BigDecimalVitalValueInput = {
  notes?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['BigDecimal']['input']>
}

export enum BillableEntityType {
  Inventory = 'INVENTORY',
  LabTest = 'LAB_TEST',
  Prescription = 'PRESCRIPTION',
  Procedure = 'PROCEDURE',
}

export type BooleanVitalValue = VitalValue & {
  __typename?: 'BooleanVitalValue'
  notes?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Boolean']['output']>
}

export type BooleanVitalValueInput = {
  notes?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['Boolean']['input']>
}

export type BoopChat = {
  __typename?: 'BoopChat'
  assignee?: Maybe<PersonInfo>
  /** @deprecated use assignee isntead */
  assigneeName?: Maybe<Scalars['String']['output']>
  business: Business
  channelSID: Scalars['String']['output']
  client: Client
  creationDate: Scalars['DateTime']['output']
  from: Scalars['String']['output']
  id: Scalars['ID']['output']
  isClosed?: Maybe<Scalars['Boolean']['output']>
  isRead?: Maybe<Scalars['Boolean']['output']>
  lastMessageDate?: Maybe<Scalars['DateTime']['output']>
  messages: ChatMessagePage
  messagesCount: Scalars['Int']['output']
  patient?: Maybe<Patient>
  title: Scalars['String']['output']
  to: Scalars['String']['output']
}

export type BoopChatMessagesArgs = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type BoopSignUpMessageResult = {
  __typename?: 'BoopSignUpMessageResult'
  createdConversations?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
}

export type BoopTokenMetadataInput = {
  deviceModel: Scalars['String']['input']
  deviceOs: Scalars['String']['input']
}

export type Breed = NamedEntity & {
  __typename?: 'Breed'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  speciesId: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type Bundle = {
  __typename?: 'Bundle'
  id: Scalars['ID']['output']
}

export type BundleSnapshot = {
  __typename?: 'BundleSnapshot'
  additionalDiscount?: Maybe<Scalars['BigDecimal']['output']>
  bundle?: Maybe<Bundle>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Business = {
  __typename?: 'Business'
  activeWellnessPlansVersion?: Maybe<WPlanVersion>
  address1?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  children: Array<Business>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  creationDate: Scalars['DateTime']['output']
  email?: Maybe<Scalars['String']['output']>
  facebook?: Maybe<Scalars['String']['output']>
  fax?: Maybe<Scalars['String']['output']>
  googleReviewLink?: Maybe<Scalars['String']['output']>
  hideWellnessPromotion?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  instagram?: Maybe<Scalars['String']['output']>
  location?: Maybe<Point>
  logo?: Maybe<Scalars['String']['output']>
  modificationDate: Scalars['DateTime']['output']
  name: Scalars['String']['output']
  openBoop?: Maybe<Scalars['Boolean']['output']>
  openingHours?: Maybe<Array<Maybe<WeeklySchedule>>>
  parentId: Scalars['ID']['output']
  payPreferences?: Maybe<BusinessPayPreferences>
  practiceTypes: Array<ConstantEntity>
  primaryPhone?: Maybe<Scalars['String']['output']>
  secondaryPhone?: Maybe<Scalars['String']['output']>
  settings: BusinessSettings
  soapWidgets: Array<SoapWidget>
  ssoConfigured: Scalars['Boolean']['output']
  state?: Maybe<State>
  supportedLanguages?: Maybe<Array<Language>>
  taxIdentificationNumber?: Maybe<Scalars['String']['output']>
  twitter?: Maybe<Scalars['String']['output']>
  unassignedDevices?: Maybe<Array<Maybe<SpaceMonitor>>>
  updatedAt: Scalars['DateTime']['output']
  waitlistEnabled?: Maybe<Scalars['Boolean']['output']>
  website?: Maybe<Scalars['String']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

export type BusinessAppointmentType = {
  __typename?: 'BusinessAppointmentType'
  businessId: Scalars['ID']['output']
  color?: Maybe<Scalars['String']['output']>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  creatorId?: Maybe<Scalars['ID']['output']>
  defaultDuration?: Maybe<Scalars['LocalTime']['output']>
  defaultType: Scalars['Boolean']['output']
  enabled: Scalars['Boolean']['output']
  eventTypeId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  modifierId?: Maybe<Scalars['ID']['output']>
  name: Scalars['String']['output']
  onlineSchedulingAllowed: Scalars['Boolean']['output']
}

export type BusinessAppointmentTypePage = Page & {
  __typename?: 'BusinessAppointmentTypePage'
  data: Array<Maybe<BusinessAppointmentType>>
  totalCount: Scalars['Int']['output']
}

export type BusinessAppointmentTypeSearchInput = {
  colors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  eventTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  from: Scalars['Int']['input']
  onlineSchedulingAllowed?: InputMaybe<Scalars['Boolean']['input']>
  orderOnlyByNameAscending?: InputMaybe<Scalars['Boolean']['input']>
  to: Scalars['Int']['input']
}

export type BusinessAppointmentTypeShortInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type BusinessInput = {
  address1: Scalars['String']['input']
  address2?: InputMaybe<Scalars['String']['input']>
  city: Scalars['String']['input']
  country: Scalars['String']['input']
  email: Scalars['String']['input']
  location?: InputMaybe<PointInput>
  name: Scalars['String']['input']
  primaryPhone: Scalars['String']['input']
  state: State
  timeZone: Scalars['String']['input']
  website?: InputMaybe<Scalars['String']['input']>
  zip: Scalars['String']['input']
}

export type BusinessPayPreferences = {
  __typename?: 'BusinessPayPreferences'
  goEnabled?: Maybe<Scalars['Boolean']['output']>
  payEnabled?: Maybe<Scalars['Boolean']['output']>
  payGoPreferences?: Maybe<PayGoPreferences>
  serviceFee?: Maybe<Scalars['Float']['output']>
}

export type BusinessRole = {
  __typename?: 'BusinessRole'
  business: Business
  expirationDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type BusinessRoleInput = {
  businessId: Scalars['ID']['input']
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
}

export type BusinessScopedPersonData = {
  __typename?: 'BusinessScopedPersonData'
  businessId: Scalars['ID']['output']
  wplanSignupSelection?: Maybe<SignupTokenData>
}

export type BusinessSettings = {
  __typename?: 'BusinessSettings'
  appointmentCancellationReasonEnabled: Scalars['Boolean']['output']
  catalogCodeCountryId?: Maybe<Scalars['ID']['output']>
  countryCode: Scalars['String']['output']
  estimateNotes?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  invoiceNotes?: Maybe<Scalars['String']['output']>
  invoiceShowDeclinedItems: Scalars['Boolean']['output']
  measurementType?: Maybe<ConstantEntity>
  posPayEnabled: Scalars['Boolean']['output']
  taxRateTotal: Scalars['BigDecimal']['output']
  taxes?: Maybe<Array<BusinessTax>>
  timeZone?: Maybe<Scalars['String']['output']>
}

export type BusinessTax = {
  __typename?: 'BusinessTax'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  rateValue: Scalars['BigDecimal']['output']
}

export type BusinessVariationListDto = {
  __typename?: 'BusinessVariationListDto'
  id?: Maybe<Scalars['ID']['output']>
  name?: Maybe<Scalars['String']['output']>
  prices: Array<PriceDto>
  speciesIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
}

export type BusinessVariationListDtoPage = Page & {
  __typename?: 'BusinessVariationListDtoPage'
  data: Array<BusinessVariationListDto>
  totalCount: Scalars['Int']['output']
}

export type BusyTime = Event & {
  __typename?: 'BusyTime'
  businessId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  deleted?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  notes?: Maybe<Scalars['String']['output']>
  resources: Array<EventResource>
  scheduledEndDatetime: Scalars['DateTime']['output']
  scheduledStartDatetime: Scalars['DateTime']['output']
  state: ConstantEntity
  title?: Maybe<Scalars['String']['output']>
  type: ConstantEntity
  updatedAt: Scalars['DateTime']['output']
}

export type BusyTimePage = Page & {
  __typename?: 'BusyTimePage'
  data: Array<BusyTime>
  totalCount: Scalars['Int']['output']
}

export type CardPaymentMethod = {
  __typename?: 'CardPaymentMethod'
  cardHolderName?: Maybe<Scalars['String']['output']>
  expirationMonth?: Maybe<Scalars['String']['output']>
  expirationYear?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  lastFour?: Maybe<Scalars['String']['output']>
  paymentCardType: ConstantEntity
  primary?: Maybe<Scalars['Boolean']['output']>
}

export type ChargeSheet = {
  __typename?: 'ChargeSheet'
  amount: Scalars['BigDecimal']['output']
  amountNoFee: Scalars['BigDecimal']['output']
  bundleAdditionalDiscount: Scalars['BigDecimal']['output']
  businessId: Scalars['ID']['output']
  client: Client
  clientId: Scalars['ID']['output']
  estimateAdditionalDiscount: Scalars['BigDecimal']['output']
  events: Array<Appointment>
  id: Scalars['ID']['output']
  itemDiscount: Scalars['BigDecimal']['output']
  modificationDate: Scalars['DateTime']['output']
  persons: Array<SimplePerson>
  prevBalance?: Maybe<Scalars['BigDecimal']['output']>
  sectionAdditionalDiscount: Scalars['BigDecimal']['output']
  sections: Array<ChargesSection>
  serviceFee?: Maybe<Scalars['BigDecimal']['output']>
  serviceFeeAmount: Scalars['BigDecimal']['output']
  showBalance?: Maybe<Scalars['Boolean']['output']>
  subTotal: Scalars['BigDecimal']['output']
  totalDiscount: Scalars['BigDecimal']['output']
  totalItems: Scalars['Int']['output']
  totalTax: Scalars['BigDecimal']['output']
  type: Scalars['String']['output']
  wellnessCoverage?: Maybe<WellnessCoverage>
}

export type ChargeSheetEmailDto = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  expandedGroups?: InputMaybe<Array<GroupIdentifier>>
  includeServiceFee?: InputMaybe<Scalars['Boolean']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  recipients: Array<RecipientDto>
  subject?: InputMaybe<Scalars['String']['input']>
}

export type Charges = ChargeSheet | Invoice

export type ChargesEntity = {
  id?: Maybe<Scalars['ID']['output']>
}

export type ChargesSection = ChargesEntity & {
  __typename?: 'ChargesSection'
  additionalDiscount: Scalars['BigDecimal']['output']
  bundleAdditionalDiscount: Scalars['BigDecimal']['output']
  businessId: Scalars['ID']['output']
  clientId: Scalars['ID']['output']
  declined: Scalars['Boolean']['output']
  discount: Scalars['BigDecimal']['output']
  estimateAdditionalDiscount: Scalars['BigDecimal']['output']
  event?: Maybe<Appointment>
  /** @deprecated use #event instead */
  eventId?: Maybe<Scalars['ID']['output']>
  groupedItems: Array<GroupedItems>
  id?: Maybe<Scalars['ID']['output']>
  itemDiscount: Scalars['BigDecimal']['output']
  modificationDate: Scalars['DateTime']['output']
  patient: Patient
  /** @deprecated use #patient instead */
  patientId: Scalars['ID']['output']
  soap?: Maybe<Soap>
  soapId?: Maybe<Scalars['ID']['output']>
  subTotal: Scalars['BigDecimal']['output']
  taxAmount: Scalars['BigDecimal']['output']
  totalAmount: Scalars['BigDecimal']['output']
  wellnessCoverage?: Maybe<WellnessCoverage>
}

export type ChatMessage = {
  __typename?: 'ChatMessage'
  body: Scalars['String']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  files: Array<ChatMessageAttachment>
  id: Scalars['ID']['output']
}

export type ChatMessageAttachment = {
  __typename?: 'ChatMessageAttachment'
  creationDate?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  extension?: Maybe<Scalars['String']['output']>
  fileUrl?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  messageId: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type ChatMessagePage = Page & {
  __typename?: 'ChatMessagePage'
  data: Array<ChatMessage>
  totalCount: Scalars['Int']['output']
}

export type ChatPage = Page & {
  __typename?: 'ChatPage'
  data: Array<BoopChat>
  totalCount: Scalars['Int']['output']
}

export type ChewyAccountBalancePaymentMethod = {
  __typename?: 'ChewyAccountBalancePaymentMethod'
  balance: Scalars['BigDecimal']['output']
  id: Scalars['String']['output']
}

export type ChewyCatalogItem = {
  __typename?: 'ChewyCatalogItem'
  count?: Maybe<Scalars['String']['output']>
  inStock: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  partNumber: Scalars['String']['output']
  price?: Maybe<Scalars['BigDecimal']['output']>
  strength?: Maybe<Scalars['String']['output']>
}

export type ChewyPet = {
  __typename?: 'ChewyPet'
  dateOfBirth?: Maybe<Scalars['String']['output']>
  gender?: Maybe<Scalars['ID']['output']>
  isDeceased?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  rhapsodyId?: Maybe<Scalars['ID']['output']>
  species?: Maybe<Scalars['ID']['output']>
}

export type ChewyPetInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>
  gender?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  rhapsodyId?: InputMaybe<Scalars['ID']['input']>
}

export type ChewyPetMatchData = {
  __typename?: 'ChewyPetMatchData'
  matchedPets: Array<PetProfileMatch>
  unmatchedPatients: Array<PatientSummary>
  unmatchedPets: Array<ChewyPet>
}

export type ChewyPrescriptionClientTasksInput = {
  dueDateTime: Scalars['DateTime']['input']
  name: Scalars['String']['input']
  occurrencesCount?: InputMaybe<Scalars['Int']['input']>
  recurrenceEndDateTime?: InputMaybe<Scalars['DateTime']['input']>
  recurrenceOffset?: InputMaybe<TimeOffsetInput>
}

export type Client = OwnerEntity &
  Person & {
    __typename?: 'Client'
    active?: Maybe<Scalars['Boolean']['output']>
    additionalEmail?: Maybe<Scalars['String']['output']>
    address1?: Maybe<Scalars['String']['output']>
    address2?: Maybe<Scalars['String']['output']>
    alertColorId?: Maybe<Scalars['ID']['output']>
    alertText?: Maybe<Scalars['String']['output']>
    /** @deprecated use billingActivitySummary#balance */
    balance?: Maybe<Scalars['BigDecimal']['output']>
    billingActivitySummary?: Maybe<ClientBillingSummary>
    businessId: Scalars['ID']['output']
    businessRoles?: Maybe<Array<Maybe<BusinessRole>>>
    businessScopedPersonData?: Maybe<Array<BusinessScopedPersonData>>
    businesses: Array<Maybe<Business>>
    /** @deprecated use query chargeSheet(clientId: ID) */
    chargeSheet: ChargeSheet
    /** @deprecated use query chargeSheetItemsCount(clientId: ID) */
    chargeSheetItemsCount: Scalars['Int']['output']
    /** @deprecated use query charges(soapId: ID!, clientId: ID!) */
    charges: Charges
    /** @deprecated use query chargesList(soapId: ID!) */
    chargesList: GroupedLineItems
    city?: Maybe<Scalars['String']['output']>
    country?: Maybe<Scalars['String']['output']>
    createdAt: Scalars['DateTime']['output']
    creationDate: Scalars['DateTime']['output']
    deleted?: Maybe<Scalars['Boolean']['output']>
    displayId?: Maybe<Scalars['ID']['output']>
    email?: Maybe<Scalars['String']['output']>
    emergencyContact?: Maybe<EmergencyContact>
    externalId?: Maybe<Scalars['String']['output']>
    firstName?: Maybe<Scalars['String']['output']>
    homePhone?: Maybe<Scalars['String']['output']>
    homePhoneNew?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isBoopUser?: Maybe<Scalars['Boolean']['output']>
    lastName?: Maybe<Scalars['String']['output']>
    middleName?: Maybe<Scalars['String']['output']>
    mobilePhone?: Maybe<Scalars['String']['output']>
    modificationDate: Scalars['DateTime']['output']
    needsUpdate?: Maybe<Scalars['Boolean']['output']>
    otherPhone?: Maybe<Scalars['String']['output']>
    patients: Array<Patient>
    paymentMethods: Array<PaymentMethod>
    photo?: Maybe<Scalars['String']['output']>
    photoThumbnail?: Maybe<Scalars['String']['output']>
    preference?: Maybe<ClientPreference>
    preferredContactMethodId?: Maybe<Scalars['ID']['output']>
    shippingAddresses: Array<ShippingAddress>
    signatureUrl?: Maybe<Scalars['String']['output']>
    state?: Maybe<State>
    tag?: Maybe<ConstantEntity>
    taxIdentificationNumber?: Maybe<Scalars['String']['output']>
    unpaidInvoicesCount?: Maybe<Scalars['Int']['output']>
    updatedAt: Scalars['DateTime']['output']
    wPlanSavings?: Maybe<WPlanClientSavings>
    workPhone?: Maybe<Scalars['String']['output']>
    zip?: Maybe<Scalars['String']['output']>
  }

export type ClientChargesArgs = {
  soapId: Scalars['ID']['input']
}

export type ClientChargesListArgs = {
  soapId: Scalars['ID']['input']
}

export type ClientPatientsArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type ClientWPlanSavingsArgs = {
  timezone?: InputMaybe<Scalars['String']['input']>
}

export type ClientBillingSummary = {
  __typename?: 'ClientBillingSummary'
  balance?: Maybe<Scalars['BigDecimal']['output']>
  deposits: SummaryValue
  pendingRefunds: SummaryValue
  unappliedPayments: SummaryValue
  unpaidInvoices: SummaryValue
}

export type ClientPage = Page & {
  __typename?: 'ClientPage'
  data: Array<Client>
  totalCount: Scalars['Int']['output']
}

export type ClientPreference = {
  __typename?: 'ClientPreference'
  attitudeTowardsStaff?: Maybe<ConstantEntity>
  cadenceOfVisit?: Maybe<Array<Maybe<ConstantEntity>>>
  careComment?: Maybe<Scalars['String']['output']>
  clientChronicallyLate?: Maybe<Scalars['Boolean']['output']>
  clientChronicallyNoShow?: Maybe<Scalars['Boolean']['output']>
  clientId: Scalars['ID']['output']
  concernForNonpayment?: Maybe<Scalars['Boolean']['output']>
  creatorId?: Maybe<Scalars['ID']['output']>
  dayOfWeek?: Maybe<Array<Maybe<ConstantEntity>>>
  doctors?: Maybe<Array<Maybe<PersonNameInfo>>>
  extentOfCare?: Maybe<ConstantEntity>
  financialComfort?: Maybe<ConstantEntity>
  financialComment?: Maybe<Scalars['String']['output']>
  genderIdentity?: Maybe<ConstantEntity>
  locationOfPerformedTreatment?: Maybe<ConstantEntity>
  modifierId?: Maybe<Scalars['ID']['output']>
  needsExtendedAppointmentTime?: Maybe<ConstantEntity>
  paymentMethod?: Maybe<ConstantEntity>
  schedulingComment?: Maybe<Scalars['String']['output']>
  servicePreference?: Maybe<ConstantEntity>
  spaces?: Maybe<Array<Maybe<Space>>>
  timeOfDay?: Maybe<Array<Maybe<ConstantEntity>>>
  topicsToAvoid?: Maybe<Scalars['String']['output']>
}

export type ClientPreferenceInput = {
  attitudeTowardsStaffId?: InputMaybe<Scalars['ID']['input']>
  cadenceOfVisitIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  careComment?: InputMaybe<Scalars['String']['input']>
  clientChronicallyLate?: InputMaybe<Scalars['Boolean']['input']>
  clientChronicallyNoShow?: InputMaybe<Scalars['Boolean']['input']>
  concernForNonpayment?: InputMaybe<Scalars['Boolean']['input']>
  dayOfWeekIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  doctors?: InputMaybe<Array<InputMaybe<PersonNameInfoInput>>>
  extentOfCareId?: InputMaybe<Scalars['ID']['input']>
  financialComfortId?: InputMaybe<Scalars['ID']['input']>
  financialComment?: InputMaybe<Scalars['String']['input']>
  genderIdentityId?: InputMaybe<Scalars['ID']['input']>
  locationOfPerformedTreatmentId?: InputMaybe<Scalars['ID']['input']>
  needsExtendedAppointmentTimeId?: InputMaybe<Scalars['ID']['input']>
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>
  schedulingComment?: InputMaybe<Scalars['String']['input']>
  servicePreferenceId?: InputMaybe<Scalars['ID']['input']>
  spaces?: InputMaybe<Array<InputMaybe<SpaceNameInfoInput>>>
  timeOfDayIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  topicsToAvoid?: InputMaybe<Scalars['String']['input']>
}

export type ClientsSearchQuery = {
  email?: InputMaybe<Scalars['QueryText']['input']>
  firstName?: InputMaybe<Scalars['QueryText']['input']>
  homePhone?: InputMaybe<Scalars['QueryText']['input']>
  lastName?: InputMaybe<Scalars['QueryText']['input']>
  mobilePhone?: InputMaybe<Scalars['QueryText']['input']>
  patientName?: InputMaybe<Scalars['QueryText']['input']>
}

export type ConstantEntity = NamedEntity & {
  __typename?: 'ConstantEntity'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ConstantEntityVitalValue = VitalValue & {
  __typename?: 'ConstantEntityVitalValue'
  notes?: Maybe<Scalars['String']['output']>
  value?: Maybe<ConstantEntity>
}

export type ConstantEntityVitalValueInput = {
  notes?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['ID']['input']>
}

export type Constants = {
  __typename?: 'Constants'
  answerInputTypes: Array<ConstantEntity>
  appointmentCancellationReasons: Array<AppointmentCancellationReason>
  appointmentStates: Array<ConstantEntity>
  appointmentTypes: Array<ConstantEntity>
  autoshipUnits: Array<ConstantEntity>
  breeds: Array<Breed>
  busyTimeStates: Array<ConstantEntity>
  busyTimeTypes: Array<ConstantEntity>
  capillaryRefillTimeUnits: Array<ConstantEntity>
  clientTags: Array<ConstantEntity>
  contactMethods: Array<ConstantEntity>
  creditAdjustmentReasons: Array<ConstantEntity>
  currencies: Array<ConstantEntity>
  diagnosisProblemStates: Array<ProblemState>
  drugAdministrationFrequencies: Array<ConstantEntity>
  drugDeliveryMethods: Array<ConstantEntity>
  environments: Array<ConstantEntity>
  featureToggles: Array<Maybe<FeatureToggle>>
  findingProblemStates: Array<ProblemState>
  fitnessLevels: Array<ConstantEntity>
  genders: Array<ConstantEntity>
  idexxOrderStatuses: Array<ConstantEntity>
  imagingModalities: Array<ImagingModality>
  inventoryStates: Array<ConstantEntity>
  invoiceStates: Array<Maybe<InvoiceState>>
  labTestStates: Array<ConstantEntity>
  languages: Array<Language>
  mentationUnits: Array<ConstantEntity>
  mucousMembraneColorUnits: Array<ConstantEntity>
  mucousMembraneMoistureUnits: Array<ConstantEntity>
  namedTimeOffset: Array<NamedTimeOffset>
  paymentCardTypes: Array<ConstantEntity>
  paymentMethods: Array<ConstantEntity>
  paymentTypes: Array<ConstantEntity>
  personResponsibilities: Array<ConstantEntity>
  practiceStatuses: Array<PatientWaitlistStatus>
  practiceTypes: Array<ConstantEntity>
  preferenceAttitudeTowardsStaff: Array<ConstantEntity>
  preferenceCadenceOfVisits: Array<ConstantEntity>
  preferenceCaution: Array<ConstantEntity>
  preferenceClientPreferencePaymentMethod: Array<ConstantEntity>
  preferenceDayOfWeek: Array<ConstantEntity>
  preferenceDrugPreference: Array<ConstantEntity>
  preferenceExtentOfCare: Array<ConstantEntity>
  preferenceFinancialComfort: Array<ConstantEntity>
  preferenceGenderIdentity: Array<ConstantEntity>
  preferenceLabWorkFrequency: Array<ConstantEntity>
  preferenceLocationOfPerformedTreatments: Array<ConstantEntity>
  preferenceMedicalAlert: Array<ConstantEntity>
  preferenceNeedsExtendedAppointmentTime: Array<ConstantEntity>
  preferencePerformedTreatmentsLocation: Array<ConstantEntity>
  preferencePremedicationVaccineReaction: Array<ConstantEntity>
  preferenceSedation: Array<ConstantEntity>
  preferenceServicePreferences: Array<ConstantEntity>
  preferenceTimeOfDay: Array<ConstantEntity>
  preferenceVenipuncture: Array<ConstantEntity>
  prescriptionProductSizeUnits: Array<ConstantEntity>
  prescriptionStates: Array<ConstantEntity>
  priceUnits: Array<ConstantEntity>
  problemBodySystemStates: Array<ConstantEntity>
  procedureStates: Array<ConstantEntity>
  productForms: Array<ConstantEntity>
  pulseCharacterUnits: Array<ConstantEntity>
  purchasedFromTypes: Array<ConstantEntity>
  recumbencyUnits: Array<ConstantEntity>
  refundDisabledReasons: Array<ConstantEntity>
  refundInvoiceStates: Array<ConstantEntity>
  refundReasons: Array<ConstantEntity>
  reminderStates: Array<ConstantEntity>
  reminderTypes: Array<ConstantEntity>
  retailOrderStates: Array<ConstantEntity>
  serviceDesignations: Array<ConstantEntity>
  skinTurgorUnits: Array<ConstantEntity>
  soapAttachmentTypes: Array<ConstantEntity>
  sortedSpecies: Array<SortedSpecies>
  species: Array<Species>
  taskAutomationEntityTypes: Array<TaskAutomationEntityType>
  taskStates: Array<Maybe<TaskState>>
  taskTemplateOptions: Array<TaskTemplateOptions>
  taskTimeUnits: Array<Maybe<TaskTimeUnit>>
  taskTypes: Array<Maybe<ConstantEntity>>
  wellnessPriceTypes: Array<WellnessPriceType>
}

export type ConstantsAppointmentCancellationReasonsArgs = {
  clientFacing?: InputMaybe<Scalars['Boolean']['input']>
}

export type Contact = {
  __typename?: 'Contact'
  address?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  inReferralList?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  notes?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  suite?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['ID']['output']>
  website?: Maybe<Scalars['String']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

export enum ConversationCategory {
  Inbox = 'INBOX',
  Sent = 'SENT',
}

export type ConversationDto = {
  __typename?: 'ConversationDto'
  assigneeId?: Maybe<Scalars['ID']['output']>
  assigneeName?: Maybe<Scalars['String']['output']>
  businessId?: Maybe<Scalars['ID']['output']>
  client?: Maybe<PersonShortenWithAlertDto>
  contact?: Maybe<Contact>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  event?: Maybe<Appointment>
  /** @deprecated use event */
  eventId?: Maybe<Scalars['ID']['output']>
  from?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastMessageDate?: Maybe<Scalars['DateTime']['output']>
  lastMessageUpdateDate?: Maybe<Scalars['DateTime']['output']>
  notifications?: Maybe<Array<Maybe<Notification>>>
  patient?: Maybe<PatientDto>
  recentActivity?: Maybe<Scalars['String']['output']>
  recentActivityDate?: Maybe<Scalars['DateTime']['output']>
  recipient?: Maybe<Recipient>
  state?: Maybe<ConversationState>
  title?: Maybe<Scalars['String']['output']>
  transport?: Maybe<ConversationTransport>
}

export type ConversationOriginDto = {
  eventId?: InputMaybe<Scalars['ID']['input']>
  isDraft?: InputMaybe<Scalars['Boolean']['input']>
  message: OutboundMessageDto
  recipients: Array<RecipientDto>
  soapId?: InputMaybe<Scalars['ID']['input']>
  title: Scalars['String']['input']
  transport: ConversationTransport
}

export enum ConversationState {
  Archived = 'ARCHIVED',
  DeliveryFailed = 'DELIVERY_FAILED',
  Open = 'OPEN',
  Unread = 'UNREAD',
}

export enum ConversationStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  DeliveryFailed = 'DELIVERY_FAILED',
  Open = 'OPEN',
  Unread = 'UNREAD',
}

export enum ConversationTransport {
  Boop = 'BOOP',
  Email = 'EMAIL',
  LogPhoneCall = 'LOG_PHONE_CALL',
  Sms = 'SMS',
}

export type ConversationUpdateDto = {
  assigneeId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  eventId?: InputMaybe<Scalars['ID']['input']>
  patientId?: InputMaybe<Scalars['ID']['input']>
  state?: InputMaybe<ConversationState>
  title?: InputMaybe<Scalars['String']['input']>
}

export type CoverOption = {
  __typename?: 'CoverOption'
  benefitId?: Maybe<Scalars['ID']['output']>
  createdById?: Maybe<Scalars['ID']['output']>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  itemId?: Maybe<Scalars['ID']['output']>
  itemType?: Maybe<CoverOptionType>
  itemTypeId?: Maybe<Scalars['ID']['output']>
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  modifiedById?: Maybe<Scalars['ID']['output']>
  name?: Maybe<Scalars['String']['output']>
  versionId?: Maybe<Scalars['ID']['output']>
}

export enum CoverOptionType {
  Bundle = 'BUNDLE',
  GlobalInventoryItem = 'GLOBAL_INVENTORY_ITEM',
  GlobalInventoryItemVariation = 'GLOBAL_INVENTORY_ITEM_VARIATION',
  InventoryItem = 'INVENTORY_ITEM',
  InventoryItemVariation = 'INVENTORY_ITEM_VARIATION',
  LabTest = 'LAB_TEST',
  Price = 'PRICE',
  Procedure = 'PROCEDURE',
}

export type CreateAppointmentInput = {
  businessAppointmentType?: InputMaybe<BusinessAppointmentTypeShortInput>
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId: Scalars['ID']['input']
  clientInstructions?: InputMaybe<Scalars['String']['input']>
  isClientScheduling?: InputMaybe<Scalars['Boolean']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  patientId: Scalars['ID']['input']
  reasonId?: InputMaybe<Scalars['ID']['input']>
  resources?: InputMaybe<Array<EventResourceInput>>
  scheduledEndDatetime: Scalars['DateTime']['input']
  scheduledStartDatetime: Scalars['DateTime']['input']
  soap?: InputMaybe<CreateSoapInput>
  stateId: Scalars['ID']['input']
  typeId: Scalars['ID']['input']
}

export type CreateAttachmentHistoryGroupInput = {
  attachmentHistories: Array<CreateAttachmentHistoryV3Input>
  authorId: Scalars['ID']['input']
  businessId: Scalars['ID']['input']
  date: Scalars['DateTime']['input']
  diagnosedBy?: InputMaybe<Scalars['String']['input']>
  diagnosedIn?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type CreateAttachmentHistoryV3Input = {
  extension: Scalars['String']['input']
  fileUrl: Scalars['String']['input']
  name: Scalars['String']['input']
  originalFileName: Scalars['String']['input']
}

export type CreateBoopChatInput = {
  assigneeId?: InputMaybe<Scalars['ID']['input']>
  businessId: Scalars['ID']['input']
  message?: InputMaybe<Scalars['String']['input']>
  patientId?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type CreateBusinessAppointmentTypeInput = {
  color?: InputMaybe<Scalars['String']['input']>
  defaultDuration: Scalars['LocalTime']['input']
  enabled: Scalars['Boolean']['input']
  eventTypeId: Scalars['ID']['input']
  name: Scalars['String']['input']
  onlineSchedulingAllowed: Scalars['Boolean']['input']
}

export type CreateBusyTimeInput = {
  businessAppointmentType?: InputMaybe<BusinessAppointmentTypeShortInput>
  notes?: InputMaybe<Scalars['String']['input']>
  resources: Array<EventResourceInput>
  scheduledEndDatetime: Scalars['DateTime']['input']
  scheduledStartDatetime: Scalars['DateTime']['input']
  stateId: Scalars['ID']['input']
  title?: InputMaybe<Scalars['String']['input']>
  typeId: Scalars['ID']['input']
}

export type CreateChargeSheetItemInput = {
  bundleId?: InputMaybe<Scalars['ID']['input']>
  eventId?: InputMaybe<Scalars['ID']['input']>
  group?: InputMaybe<Scalars['ID']['input']>
  logType: Scalars['String']['input']
  name: Scalars['String']['input']
  patientId: Scalars['ID']['input']
  prepaid?: InputMaybe<Scalars['Boolean']['input']>
  priceId: Scalars['ID']['input']
  producerId?: InputMaybe<Scalars['ID']['input']>
  quantity: Scalars['BigDecimal']['input']
  soapId?: InputMaybe<Scalars['ID']['input']>
  usedQuantity?: InputMaybe<Scalars['BigDecimal']['input']>
}

export type CreateClientInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  address1?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  businessRoles?: InputMaybe<Array<InputMaybe<BusinessRoleInput>>>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  externalId?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  homePhone?: InputMaybe<Scalars['String']['input']>
  lastName: Scalars['String']['input']
  mobilePhone?: InputMaybe<Scalars['String']['input']>
  preferredContactMethodId?: InputMaybe<Scalars['ID']['input']>
  state?: InputMaybe<State>
  tagId?: InputMaybe<Scalars['ID']['input']>
  taxIdentificationNumber?: InputMaybe<Scalars['String']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type CreateLabTestLogFileGroupInput = {
  authorId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  diagnosedIn?: InputMaybe<Scalars['String']['input']>
  files: Array<CreateLabTestLogFileInput>
  title?: InputMaybe<Scalars['String']['input']>
  uploadDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type CreateLabTestLogFileInput = {
  extension: Scalars['String']['input']
  fileUrl: Scalars['String']['input']
  name: Scalars['String']['input']
  originalFileName: Scalars['String']['input']
}

export type CreatePatientInput = {
  acquiredDate?: InputMaybe<Scalars['DateTime']['input']>
  active?: InputMaybe<Scalars['Boolean']['input']>
  allergies?: InputMaybe<Scalars['String']['input']>
  approximateDateOfBirth?: InputMaybe<Scalars['Boolean']['input']>
  breedIds?: InputMaybe<Array<Scalars['ID']['input']>>
  color?: InputMaybe<Scalars['String']['input']>
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>
  deceased?: InputMaybe<Scalars['Boolean']['input']>
  deceasedDate?: InputMaybe<Scalars['DateTime']['input']>
  diet?: InputMaybe<Scalars['String']['input']>
  environmentId?: InputMaybe<Scalars['ID']['input']>
  externalId?: InputMaybe<Scalars['String']['input']>
  fitnessLevelId?: InputMaybe<Scalars['ID']['input']>
  gender?: InputMaybe<Gender>
  insurancePolicyNumber?: InputMaybe<Scalars['String']['input']>
  insuranceProvider?: InputMaybe<Scalars['String']['input']>
  license?: InputMaybe<Scalars['String']['input']>
  markingsTattoo?: InputMaybe<Scalars['String']['input']>
  microchipNumber?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  photo?: InputMaybe<Scalars['String']['input']>
  photoThumbnail?: InputMaybe<Scalars['String']['input']>
  purchasedFromId?: InputMaybe<Scalars['ID']['input']>
  rabiesTag?: InputMaybe<Scalars['String']['input']>
  rabiesTagExpiration?: InputMaybe<Scalars['DateTime']['input']>
  schedule?: InputMaybe<Scalars['String']['input']>
  serviceDesignationId?: InputMaybe<Scalars['ID']['input']>
  spayedNeuteredStatus?: InputMaybe<SpayedNeuteredStatus>
  speciesId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateProblemBodySystemLogRequestInput = {
  bodySystemId: Scalars['ID']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  problemLogs?: InputMaybe<Array<InputMaybe<CreateProblemLogRequestInput>>>
  stateId: Scalars['ID']['input']
}

export type CreateProblemEnumLogRequestInput = {
  enumId: Scalars['ID']['input']
  enumValueId: Scalars['ID']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  parentEnumValueId?: InputMaybe<Scalars['ID']['input']>
  problemLogId: Scalars['ID']['input']
}

export type CreateProblemLogRequestInput = {
  notes?: InputMaybe<Scalars['String']['input']>
  problemId: Scalars['ID']['input']
  stateId: Scalars['ID']['input']
}

export type CreateProblemLogRequestInput2 = {
  notes?: InputMaybe<Scalars['String']['input']>
  problemId: Scalars['ID']['input']
  stateId: Scalars['ID']['input']
}

export type CreateReminderInput = {
  dueDatetime: Scalars['DateTime']['input']
  expireDatetime?: InputMaybe<Scalars['DateTime']['input']>
  name: Scalars['String']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  patientId: Scalars['ID']['input']
  stateId: Scalars['ID']['input']
}

export type CreateRetailOrderLineItemInput = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>
  autoshipFrequency?: InputMaybe<Scalars['Int']['input']>
  autoshipUnitId?: InputMaybe<Scalars['ID']['input']>
  businessId: Scalars['ID']['input']
  clientId: Scalars['ID']['input']
  declined: Scalars['Boolean']['input']
  invoiceId: Scalars['ID']['input']
  logId: Scalars['ID']['input']
  logType: Scalars['String']['input']
  name: Scalars['String']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  partNumber: Scalars['String']['input']
  patientId: Scalars['ID']['input']
  price: Scalars['BigDecimal']['input']
  producerId?: InputMaybe<Scalars['ID']['input']>
  quantity: Scalars['BigDecimal']['input']
  sectionId?: InputMaybe<Scalars['ID']['input']>
  soapId?: InputMaybe<Scalars['ID']['input']>
  taxAmount: Scalars['BigDecimal']['input']
  viewOrderNumber?: InputMaybe<Scalars['Int']['input']>
  viewSectionOrderNumber?: InputMaybe<Scalars['Int']['input']>
}

export type CreateSoapFileGroupInput = {
  authorId: Scalars['ID']['input']
  businessId: Scalars['ID']['input']
  date: Scalars['DateTime']['input']
  description: Scalars['String']['input']
  diagnosedIn?: InputMaybe<Scalars['String']['input']>
  patientId: Scalars['ID']['input']
  soapFiles: Array<CreateSoapFileInput>
  soapId: Scalars['ID']['input']
  title?: InputMaybe<Scalars['String']['input']>
}

export type CreateSoapFileInput = {
  dateSigned?: InputMaybe<Scalars['DateTime']['input']>
  extension: Scalars['String']['input']
  fileUrl: Scalars['String']['input']
  includeInKiosk?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  originalFileName: Scalars['String']['input']
  required?: InputMaybe<Scalars['Boolean']['input']>
  signer?: InputMaybe<Scalars['String']['input']>
  typeId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateSoapInput = {
  dischargeNotes?: InputMaybe<Scalars['String']['input']>
  medicalNotes?: InputMaybe<Scalars['String']['input']>
}

export type CreateTaskInput = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>
  assigneeId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  contactId?: InputMaybe<Scalars['ID']['input']>
  dueDatetime: Scalars['DateTime']['input']
  name: Scalars['String']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  patientId?: InputMaybe<Scalars['ID']['input']>
  stateId: Scalars['ID']['input']
  typeId: Scalars['ID']['input']
}

export type CreditAdjustmentInput = {
  amount: Scalars['BigDecimal']['input']
  invoiceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  notes: Scalars['String']['input']
  paymentMethodId: Scalars['ID']['input']
  reasonId: Scalars['ID']['input']
}

export type CurrentVitals = {
  __typename?: 'CurrentVitals'
  bodyConditionScore?: Maybe<Scalars['Int']['output']>
  weight?: Maybe<Scalars['BigDecimal']['output']>
}

export type CurrentWeight = {
  __typename?: 'CurrentWeight'
  date?: Maybe<Scalars['DateTime']['output']>
  value?: Maybe<Scalars['BigDecimal']['output']>
}

export type DailyRecurrenceParams = {
  __typename?: 'DailyRecurrenceParams'
  times?: Maybe<Array<Maybe<Scalars['DateTime']['output']>>>
}

export type DashboardLabTestEntry = {
  __typename?: 'DashboardLabTestEntry'
  orderDate: Scalars['DateTime']['output']
  patient?: Maybe<Patient>
  testLogs?: Maybe<Array<LabTestLog>>
  vet?: Maybe<Employee>
}

export type DateOffset = {
  __typename?: 'DateOffset'
  amount: Scalars['Int']['output']
  unit: Scalars['DateUnit']['output']
}

export type DateOffsetInput = {
  amount: Scalars['Int']['input']
  unit: Scalars['DateUnit']['input']
}

export type DateRangeInput = {
  from: Scalars['DateTime']['input']
  to: Scalars['DateTime']['input']
}

export type DateTimeRangeInput = {
  from: Scalars['DateTime']['input']
  to: Scalars['DateTime']['input']
}

export type DeleteChargeSheetItemInput = {
  expectedModificationDate: Scalars['DateTime']['input']
  id: Scalars['ID']['input']
}

export type DiagnosisHistory = TimelineEntry & {
  __typename?: 'DiagnosisHistory'
  businessId: Scalars['ID']['output']
  creationDate: Scalars['DateTime']['output']
  date?: Maybe<Scalars['DateTime']['output']>
  diagnosedBy?: Maybe<Scalars['String']['output']>
  diagnosedIn?: Maybe<Scalars['String']['output']>
  diagnosisId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  modificationDate: Scalars['DateTime']['output']
  notes?: Maybe<Scalars['String']['output']>
  timelineDate?: Maybe<Scalars['DateTime']['output']>
}

export type DiagnosisLog = TimelineEntry & {
  __typename?: 'DiagnosisLog'
  businessId: Scalars['ID']['output']
  creationDate: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  diagnosisNotes?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  locationId?: Maybe<Scalars['ID']['output']>
  modificationDate: Scalars['DateTime']['output']
  notes?: Maybe<Scalars['String']['output']>
  soapId?: Maybe<Scalars['ID']['output']>
  statusId?: Maybe<Scalars['ID']['output']>
  timelineDate?: Maybe<Scalars['DateTime']['output']>
  vetId?: Maybe<Scalars['ID']['output']>
}

export type DownloadAttachmentDto = {
  __typename?: 'DownloadAttachmentDto'
  extension?: Maybe<Scalars['String']['output']>
  fileUrl?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export enum DraftFilter {
  All = 'ALL',
  Draft = 'DRAFT',
  NotDraft = 'NOT_DRAFT',
}

export type DrugCatalogMappingItem = InventoryCatalogItem & {
  __typename?: 'DrugCatalogMappingItem'
  aahaRevCodeMain?: Maybe<Scalars['String']['output']>
  aahaRevCodeSub?: Maybe<Scalars['String']['output']>
  activeIngredient?: Maybe<Scalars['String']['output']>
  ageMinimum?: Maybe<Scalars['Int']['output']>
  ageUnitId?: Maybe<Scalars['Int']['output']>
  brandName?: Maybe<Scalars['String']['output']>
  category?: Maybe<ConstantEntity>
  controlled?: Maybe<Scalars['Boolean']['output']>
  controlledSubstanceScheduleId?: Maybe<Scalars['ID']['output']>
  countryId?: Maybe<Scalars['Int']['output']>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  gender?: Maybe<ConstantEntity>
  generic?: Maybe<Scalars['Boolean']['output']>
  id?: Maybe<Scalars['ID']['output']>
  isCompoundable?: Maybe<Scalars['Boolean']['output']>
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  modifier?: Maybe<PersonInfo>
  name?: Maybe<Scalars['String']['output']>
  obsolete?: Maybe<Scalars['Boolean']['output']>
  person?: Maybe<PersonInfo>
  requiresPrescription?: Maybe<Scalars['Boolean']['output']>
  rhapsodyInternalCode?: Maybe<Scalars['String']['output']>
  spayedNeuteredStatusId?: Maybe<Scalars['ID']['output']>
  speciesId?: Maybe<Scalars['ID']['output']>
  subCategory?: Maybe<ConstantEntity>
  type?: Maybe<Scalars['String']['output']>
  vetOnly?: Maybe<Scalars['Boolean']['output']>
  weightMinimum?: Maybe<Scalars['BigDecimal']['output']>
}

export type DrugDeliveryMethodEntity = NamedEntity & {
  __typename?: 'DrugDeliveryMethodEntity'
  action: Scalars['String']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type EmailInput = {
  content: Scalars['String']['input']
  replyTo: Scalars['String']['input']
}

export type EmailPreviewConfig = {
  content?: InputMaybe<Scalars['String']['input']>
  includeHeaderAndFooter: Scalars['Boolean']['input']
}

export type EmailSupportMultipleAccountsInput = {
  email?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  mobilePhone?: InputMaybe<Scalars['String']['input']>
}

export type EmailSupportNoAccountsInput = {
  email?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  guessedBusinessAddress: Scalars['String']['input']
  guessedBusinessName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  mobilePhone?: InputMaybe<Scalars['String']['input']>
}

export type EmailSupportVetNotOffersWPlansInput = {
  businessId: Scalars['ID']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  mobilePhone?: InputMaybe<Scalars['String']['input']>
}

export type EmergencyContact = {
  __typename?: 'EmergencyContact'
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  mobilePhone: Scalars['String']['output']
  relationship?: Maybe<Scalars['String']['output']>
}

export type EmergencyContactInput = {
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  mobilePhone: Scalars['String']['input']
  relationship?: InputMaybe<Scalars['String']['input']>
}

export type Employee = Person & {
  __typename?: 'Employee'
  active?: Maybe<Scalars['Boolean']['output']>
  businessId: Scalars['ID']['output']
  businessRoles?: Maybe<Array<Maybe<BusinessRole>>>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  creationDate: Scalars['DateTime']['output']
  deleted?: Maybe<Scalars['Boolean']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  mobilePhone?: Maybe<Scalars['String']['output']>
  modificationDate: Scalars['DateTime']['output']
  photo?: Maybe<Scalars['String']['output']>
  photoThumbnail?: Maybe<Scalars['String']['output']>
  schedule: Array<Slot>
  signatureUrl?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type EmployeeScheduleArgs = {
  dateTimeFrom: Scalars['DateTime']['input']
  dateTimeTo: Scalars['DateTime']['input']
  type: SlotType
}

export type EmployeeInput = {
  businessRoles: Array<BusinessRoleInput>
  email: Scalars['String']['input']
  mobilePhone?: InputMaybe<Scalars['String']['input']>
}

export type EmployeePage = Page & {
  __typename?: 'EmployeePage'
  data: Array<Employee>
  totalCount: Scalars['Int']['output']
}

export type EnrichedProblemBodySystemLog = {
  __typename?: 'EnrichedProblemBodySystemLog'
  bodySystemId: Scalars['ID']['output']
  createdBy?: Maybe<PersonShortDto>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  isCopied: Scalars['Boolean']['output']
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  modifiedBy?: Maybe<PersonShortDto>
  notes?: Maybe<Scalars['String']['output']>
  problemLogs?: Maybe<Array<Maybe<ProblemLogCrcContainer>>>
  soapId: Scalars['ID']['output']
  stateId: Scalars['ID']['output']
}

export type EnrichedProblemLog = {
  __typename?: 'EnrichedProblemLog'
  bodySystemLogId: Scalars['ID']['output']
  createdBy?: Maybe<PersonShortDto>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  enumLogs: Array<ProblemEnumLogCrcContainer>
  id: Scalars['ID']['output']
  isCopied: Scalars['Boolean']['output']
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  modifiedBy?: Maybe<PersonShortDto>
  notes?: Maybe<Scalars['String']['output']>
  problemId: Scalars['ID']['output']
  stateId: Scalars['ID']['output']
}

export type EnrichedVital = {
  __typename?: 'EnrichedVital'
  persons: Array<SimplePerson>
  vital: Vital
}

export type EnrichedVitalsPage = {
  __typename?: 'EnrichedVitalsPage'
  persons: Array<SimplePerson>
  vitalsPage: VitalPage
}

export enum EntityType {
  Inventory = 'INVENTORY',
  LabTest = 'LAB_TEST',
  Procedure = 'PROCEDURE',
}

export type Estimate = InvoiceEntity & {
  __typename?: 'Estimate'
  additionalDiscount?: Maybe<Scalars['BigDecimal']['output']>
  amount?: Maybe<BigDecimalValueRange>
  amountNoFee?: Maybe<BigDecimalValueRange>
  assignedSoapDetails?: Maybe<Array<EstimateAssignedSoapDetail>>
  /** @deprecated we should use assignedSoapDetails */
  assignedSoaps?: Maybe<Array<EstimateAssignedSoap>>
  businessId: Scalars['ID']['output']
  clientId: Scalars['ID']['output']
  creationDate: Scalars['DateTime']['output']
  currencyId?: Maybe<Scalars['ID']['output']>
  dueToPay?: Maybe<Scalars['BigDecimal']['output']>
  dueToPayNoFee?: Maybe<Scalars['BigDecimal']['output']>
  eventId?: Maybe<Scalars['ID']['output']>
  events?: Maybe<Array<EventForEstimate>>
  expirationDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  innerModificationDate?: Maybe<Scalars['DateTime']['output']>
  internalNote?: Maybe<Scalars['String']['output']>
  invoiceDate?: Maybe<Scalars['DateTime']['output']>
  invoiceIds: Array<Scalars['ID']['output']>
  invoiceNo?: Maybe<Scalars['String']['output']>
  invoiceType?: Maybe<InvoiceType>
  isClinicFromSpain?: Maybe<Scalars['Boolean']['output']>
  modificationDate: Scalars['DateTime']['output']
  name?: Maybe<Scalars['String']['output']>
  paid?: Maybe<Scalars['Boolean']['output']>
  paidAmount?: Maybe<Scalars['BigDecimal']['output']>
  paidAmountNoFee?: Maybe<Scalars['BigDecimal']['output']>
  paidFeeAmount?: Maybe<Scalars['BigDecimal']['output']>
  patient?: Maybe<PatientView>
  patientId: Scalars['ID']['output']
  payments?: Maybe<Array<Maybe<Payment>>>
  personId: Scalars['ID']['output']
  posted?: Maybe<Scalars['Boolean']['output']>
  postedAt?: Maybe<Scalars['DateTime']['output']>
  postedBy?: Maybe<PersonShorten>
  postedById?: Maybe<Scalars['ID']['output']>
  requiredDeposit?: Maybe<Scalars['BigDecimal']['output']>
  requiredDepositAmount?: Maybe<Scalars['BigDecimal']['output']>
  requiredDepositAmountNoFee?: Maybe<Scalars['BigDecimal']['output']>
  savedAmount?: Maybe<BigDecimalValueRange>
  serviceFee?: Maybe<Scalars['BigDecimal']['output']>
  serviceFeeAmount?: Maybe<BigDecimalValueRange>
  signatureUrl?: Maybe<Scalars['String']['output']>
  signedDate?: Maybe<Scalars['DateTime']['output']>
  signer?: Maybe<PersonShorten>
  signerId?: Maybe<Scalars['ID']['output']>
  signerName?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  stateId?: Maybe<Scalars['ID']['output']>
  subtotal?: Maybe<BigDecimalValueRange>
  taxBase?: Maybe<BigDecimalValueRange>
  totalDiscount?: Maybe<BigDecimalValueRange>
  totalTax?: Maybe<BigDecimalValueRange>
}

export type EstimateAssignedSoap = {
  __typename?: 'EstimateAssignedSoap'
  eventId?: Maybe<Scalars['ID']['output']>
  soapIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
}

export type EstimateAssignedSoapDetail = {
  __typename?: 'EstimateAssignedSoapDetail'
  eventId?: Maybe<Scalars['ID']['output']>
  itemsCopied?: Maybe<Scalars['Boolean']['output']>
  soapId?: Maybe<Scalars['ID']['output']>
}

export type EstimatePage = Page & {
  __typename?: 'EstimatePage'
  data: Array<Estimate>
  totalCount: Scalars['Int']['output']
}

export type Event = {
  businessId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  deleted?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  resources: Array<EventResource>
  scheduledEndDatetime: Scalars['DateTime']['output']
  scheduledStartDatetime: Scalars['DateTime']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type EventForEstimate = {
  __typename?: 'EventForEstimate'
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  scheduledEndDatetime?: Maybe<Scalars['DateTime']['output']>
  scheduledStartDatetime?: Maybe<Scalars['DateTime']['output']>
  state?: Maybe<StateEntity>
  type?: Maybe<StateEntity>
}

export type EventResource = {
  __typename?: 'EventResource'
  employee: Employee
  responsibility: ConstantEntity
  responsibilityRole?: Maybe<BusinessRole>
}

export type EventResourceInput = {
  employeeId: Scalars['ID']['input']
  responsibilityId: Scalars['ID']['input']
}

export type FeatureToggle = {
  __typename?: 'FeatureToggle'
  enabled: Scalars['Boolean']['output']
  name: Scalars['String']['output']
}

export enum FilterableEntityType {
  Bundle = 'BUNDLE',
  Inventory = 'INVENTORY',
  LabTest = 'LAB_TEST',
  Prepaid = 'PREPAID',
  Prescription = 'PRESCRIPTION',
  Procedure = 'PROCEDURE',
  Refill = 'REFILL',
  Reminder = 'REMINDER',
  Task = 'TASK',
  Wplans = 'WPLANS',
}

export type FoodCatalogMappingItem = InventoryCatalogItem & {
  __typename?: 'FoodCatalogMappingItem'
  aahaExpenseCode?: Maybe<Scalars['String']['output']>
  aahaRevCode?: Maybe<Scalars['String']['output']>
  ageMinimum?: Maybe<Scalars['Int']['output']>
  brand?: Maybe<Scalars['String']['output']>
  breedId?: Maybe<Scalars['ID']['output']>
  category?: Maybe<ConstantEntity>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  formula?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  gender?: Maybe<ConstantEntity>
  healthConditions?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  id?: Maybe<Scalars['ID']['output']>
  information?: Maybe<Scalars['String']['output']>
  keyTerm?: Maybe<Scalars['String']['output']>
  lifeStageId?: Maybe<Scalars['ID']['output']>
  line?: Maybe<Scalars['String']['output']>
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  modifier?: Maybe<PersonInfo>
  name?: Maybe<Scalars['String']['output']>
  productTerms?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  rhapsodyInternalCode?: Maybe<Scalars['String']['output']>
  subCategory?: Maybe<ConstantEntity>
  type?: Maybe<Scalars['String']['output']>
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
}

export type GenderRestriction = {
  __typename?: 'GenderRestriction'
  genderId: Scalars['ID']['output']
  spayedNeuteredStatusId: Scalars['ID']['output']
}

export enum GeneratedMessageAttachmentSubcategory {
  MedicalHistory = 'MEDICAL_HISTORY',
  Soap = 'SOAP',
}

export type GeneratedMessageAttachmentsSubsection = {
  __typename?: 'GeneratedMessageAttachmentsSubsection'
  addedDate?: Maybe<Scalars['DateTime']['output']>
  checked: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  subcategory?: Maybe<GeneratedMessageAttachmentSubcategory>
}

export type GeneratedMessageConfig = MedicalHistoryConfig | ReportCardConfig

export type GeneratedMessageConfigInput = {
  medicalHistory?: InputMaybe<MedicalHistoryConfigInput>
  reportCard?: InputMaybe<ReportCardConfigInput>
}

export type GeneratedMessageInput = {
  updateMembershipPayment?: InputMaybe<UpdateMembershipPaymentInput>
}

export type GeneratedMessagePreviewInput = {
  medicalHistory?: InputMaybe<MedicalHistoryPreviewInput>
  reportCard?: InputMaybe<ReportCardPreviewInput>
}

export type GeneratedMessageSectionAttachmentsInput = {
  id: Scalars['ID']['input']
  subcategory?: InputMaybe<GeneratedMessageAttachmentSubcategory>
}

export type GlobalClientPreferences = {
  __typename?: 'GlobalClientPreferences'
  communicationSmsOptIn?: Maybe<Scalars['Boolean']['output']>
}

export type GlobalClientPreferencesInput = {
  communicationSmsOptIn?: InputMaybe<Scalars['Boolean']['input']>
}

export type GlobalItemVariation = {
  __typename?: 'GlobalItemVariation'
  chewyItems?: Maybe<Array<Maybe<ChewyCatalogItem>>>
  deliveryMethod?: Maybe<ConstantEntity>
  flavor?: Maybe<Scalars['String']['output']>
  form?: Maybe<ConstantEntity>
  globalInventoryMapping?: Maybe<InventoryCatalogItem>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  obsolete?: Maybe<Scalars['Boolean']['output']>
  strength?: Maybe<Scalars['BigDecimal']['output']>
  strengthColor?: Maybe<ConstantEntity>
  strengthCombo?: Maybe<Scalars['String']['output']>
  strengthUnit?: Maybe<ConstantEntity>
  weightMaximum?: Maybe<Scalars['BigDecimal']['output']>
  weightMinimum?: Maybe<Scalars['BigDecimal']['output']>
}

export type GoPayment = PaymentEntity & {
  __typename?: 'GoPayment'
  amount: Scalars['BigDecimal']['output']
  businessId: Scalars['ID']['output']
  cardType?: Maybe<ConstantEntity>
  clientId: Scalars['ID']['output']
  creationDate: Scalars['DateTime']['output']
  currency?: Maybe<ConstantEntity>
  employee: Employee
  employeeId: Scalars['ID']['output']
  entityType?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  method?: Maybe<ConstantEntity>
  modificationDate: Scalars['DateTime']['output']
  notes?: Maybe<Scalars['String']['output']>
  reversed?: Maybe<Scalars['Boolean']['output']>
  state?: Maybe<Scalars['String']['output']>
  transactionStateName?: Maybe<Scalars['String']['output']>
  type?: Maybe<ConstantEntity>
}

export type GroupIdentifier = {
  group: Scalars['ID']['input']
  patientId: Scalars['ID']['input']
  soapId?: InputMaybe<Scalars['ID']['input']>
}

export type GroupedItem = {
  __typename?: 'GroupedItem'
  additionalDiscount: Scalars['BigDecimal']['output']
  chargedPrice: Scalars['BigDecimal']['output']
  declined: Scalars['Boolean']['output']
  discount: Scalars['BigDecimal']['output']
  extendedPrice: Scalars['BigDecimal']['output']
  groupId: Scalars['ID']['output']
  groupName?: Maybe<Scalars['String']['output']>
  groupSnapshot?: Maybe<BundleSnapshot>
  groupSnapshotId?: Maybe<Scalars['ID']['output']>
  /** @deprecated use groupId or groupSnapshotId instead */
  id: Scalars['ID']['output']
  itemDiscount: Scalars['BigDecimal']['output']
  itemSubTotal: Scalars['BigDecimal']['output']
  items: Array<InvoiceLineItem>
  subTotal: Scalars['BigDecimal']['output']
  taxAmount: Scalars['BigDecimal']['output']
  /** @deprecated use subTotal instead */
  totalAmount: Scalars['BigDecimal']['output']
}

export type GroupedItems = GroupedItem | InvoiceLineItem | RetailOrderLineItem

export type GroupedLineItemArrangementInput = {
  lineItemId: Scalars['ID']['input']
  lineItemType: LineItemType
  viewOrderNumber?: InputMaybe<Scalars['Int']['input']>
  viewSectionOrderNumber?: InputMaybe<Scalars['Int']['input']>
}

export type GroupedLineItemItemArrangement = {
  __typename?: 'GroupedLineItemItemArrangement'
  lineItemId: Scalars['ID']['output']
  lineItemType: LineItemType
  viewOrderNumber?: Maybe<Scalars['Int']['output']>
  viewSectionOrderNumber?: Maybe<Scalars['Int']['output']>
}

export type GroupedLineItems = {
  __typename?: 'GroupedLineItems'
  items: Array<GroupedItems>
  patient: Patient
  totalAmount: Scalars['BigDecimal']['output']
}

export type GroupedRefundItem = {
  __typename?: 'GroupedRefundItem'
  chargedPrice?: Maybe<Scalars['BigDecimal']['output']>
  discount?: Maybe<Scalars['BigDecimal']['output']>
  extendedPrice?: Maybe<Scalars['BigDecimal']['output']>
  groupSnapshot: BundleSnapshot
  itemDiscount?: Maybe<Scalars['BigDecimal']['output']>
  itemSubTotal?: Maybe<Scalars['BigDecimal']['output']>
  items: Array<RefundInvoiceLineItem>
  subTotal?: Maybe<Scalars['BigDecimal']['output']>
  taxAmount?: Maybe<Scalars['BigDecimal']['output']>
}

export type GroupedRefundItemCalculation = {
  __typename?: 'GroupedRefundItemCalculation'
  additionalDiscount?: Maybe<Scalars['BigDecimal']['output']>
  discount?: Maybe<Scalars['BigDecimal']['output']>
  groupSnapshot?: Maybe<BundleSnapshot>
  items: Array<RefundInvoiceLineItemCalculation>
  subTotal?: Maybe<Scalars['BigDecimal']['output']>
  taxAmount?: Maybe<Scalars['BigDecimal']['output']>
}

export type GroupedRefundItems = GroupedRefundItem | RefundInvoiceLineItem

export type GroupedRefundItemsCalculation =
  | GroupedRefundItemCalculation
  | RefundInvoiceLineItemCalculation

export type HistoryProblemEnumLog = {
  __typename?: 'HistoryProblemEnumLog'
  enumId: Scalars['ID']['output']
  enumValueId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  notes?: Maybe<Scalars['String']['output']>
  parentEnumValueId?: Maybe<Scalars['ID']['output']>
  problemLogId: Scalars['ID']['output']
}

export type HistoryProblemLog = {
  __typename?: 'HistoryProblemLog'
  date?: Maybe<Scalars['DateTime']['output']>
  doctor?: Maybe<PersonShortDto>
  enumLogs?: Maybe<Array<Maybe<HistoryProblemEnumLog>>>
  notes?: Maybe<Scalars['String']['output']>
  stateId: Scalars['ID']['output']
}

export type HistoryProblemLogPage = Page & {
  __typename?: 'HistoryProblemLogPage'
  data: Array<HistoryProblemLog>
  totalCount: Scalars['Int']['output']
}

export type Html = {
  __typename?: 'Html'
  content?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type ImagingModality = NamedEntity & {
  __typename?: 'ImagingModality'
  code: Scalars['String']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  fullName: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ImagingOrder = {
  __typename?: 'ImagingOrder'
  createAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  modality: ImagingModality
  name?: Maybe<Scalars['String']['output']>
  patient?: Maybe<Patient>
  resultsReceivedDate?: Maybe<Scalars['DateTime']['output']>
  status: ConstantEntity
  updatedAt: Scalars['DateTime']['output']
  vet?: Maybe<Employee>
}

export type ImagingOrderPage = Page & {
  __typename?: 'ImagingOrderPage'
  data: Array<ImagingOrder>
  totalCount: Scalars['Int']['output']
}

export enum IncrementType {
  Absolute = 'ABSOLUTE',
  Percent = 'PERCENT',
}

export type Input = {
  __typename?: 'Input'
  id: Scalars['ID']['output']
  inputType: ConstantEntity
  /** @deprecated Use inputType instead */
  inputTypeId: Scalars['ID']['output']
  value?: Maybe<Scalars['String']['output']>
}

export type InputPriceDto = {
  id?: InputMaybe<Scalars['ID']['input']>
  value?: InputMaybe<Scalars['BigDecimal']['input']>
}

export type IntegerVitalValue = VitalValue & {
  __typename?: 'IntegerVitalValue'
  notes?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Int']['output']>
}

export type IntegerVitalValueInput = {
  notes?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['Int']['input']>
}

export type InventoryCatalogItem = {
  category?: Maybe<ConstantEntity>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  id?: Maybe<Scalars['ID']['output']>
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  modifier?: Maybe<PersonInfo>
  name?: Maybe<Scalars['String']['output']>
  subCategory?: Maybe<ConstantEntity>
  type?: Maybe<Scalars['String']['output']>
}

export type Invoice = InvoiceEntity &
  TimelineEntry & {
    __typename?: 'Invoice'
    additionalDiscount?: Maybe<Scalars['BigDecimal']['output']>
    amount: Scalars['BigDecimal']['output']
    amountNoFee: Scalars['BigDecimal']['output']
    /** @deprecated appointmentId is now ambiguous. Use items.eventId instead */
    appointmentId: Scalars['ID']['output']
    balanceAfterPaid?: Maybe<Scalars['BigDecimal']['output']>
    business: Business
    businessId: Scalars['ID']['output']
    client: Client
    clientId: Scalars['ID']['output']
    createdAt: Scalars['DateTime']['output']
    creationDate: Scalars['DateTime']['output']
    currency: ConstantEntity
    currencyId?: Maybe<Scalars['ID']['output']>
    deleted: Scalars['Boolean']['output']
    discountReasonId?: Maybe<Scalars['ID']['output']>
    discountReasonName?: Maybe<Scalars['String']['output']>
    dueToPay: Scalars['BigDecimal']['output']
    dueToPayNoFee: Scalars['BigDecimal']['output']
    employeeId: Scalars['ID']['output']
    events: Array<Appointment>
    grandTotal: Totals
    hasPrepaids: Scalars['Boolean']['output']
    id: Scalars['ID']['output']
    innerModificationDate: Scalars['DateTime']['output']
    internalNote?: Maybe<Scalars['String']['output']>
    invoiceDate: Scalars['DateTime']['output']
    invoiceIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
    invoiceNo: Scalars['String']['output']
    invoices?: Maybe<Array<Maybe<Invoice>>>
    items: Array<InvoiceLineItem>
    modificationDate: Scalars['DateTime']['output']
    name?: Maybe<Scalars['String']['output']>
    paid: Scalars['Boolean']['output']
    paidAmount: Scalars['BigDecimal']['output']
    paidAmountNoFee: Scalars['BigDecimal']['output']
    paidDate?: Maybe<Scalars['DateTime']['output']>
    paidFeeAmount: Scalars['BigDecimal']['output']
    /** @deprecated patientId is now ambiguous. Use items.patientId instead */
    patientId: Scalars['ID']['output']
    patients: Array<Patient>
    payments: Array<Payment>
    pendingRefundInvoice?: Maybe<RefundInvoice>
    personId: Scalars['ID']['output']
    persons: Array<SimplePerson>
    posted: Scalars['Boolean']['output']
    postedAt?: Maybe<Scalars['DateTime']['output']>
    postedById?: Maybe<Scalars['ID']['output']>
    potentialSavedAmount?: Maybe<Scalars['Float']['output']>
    prevBalance?: Maybe<Scalars['BigDecimal']['output']>
    refundDisabledReason?: Maybe<ConstantEntity>
    refunds: Array<RefundInvoice>
    requiredDeposit?: Maybe<Scalars['BigDecimal']['output']>
    requiredDepositAmount?: Maybe<Scalars['BigDecimal']['output']>
    retailOrder?: Maybe<RetailOrder>
    savedAmount: Scalars['BigDecimal']['output']
    sections: Array<ChargesSection>
    serviceFee?: Maybe<Scalars['BigDecimal']['output']>
    serviceFeeAmount: Scalars['BigDecimal']['output']
    showBalance: Scalars['Boolean']['output']
    state: Scalars['String']['output']
    stateId: Scalars['ID']['output']
    subtotal: Scalars['BigDecimal']['output']
    taxBase: Scalars['BigDecimal']['output']
    timelineDate?: Maybe<Scalars['DateTime']['output']>
    totalDiscount: Scalars['BigDecimal']['output']
    totalTax: Scalars['BigDecimal']['output']
    type: Scalars['String']['output']
    updatedAt: Scalars['DateTime']['output']
    wellnessCoverage?: Maybe<WellnessCoverage>
  }

export type InvoiceInvoiceNoArgs = {
  complete?: InputMaybe<Scalars['Boolean']['input']>
}

export type InvoiceRefundsArgs = {
  stateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type InvoiceDiscountReason = {
  __typename?: 'InvoiceDiscountReason'
  active: Scalars['Boolean']['output']
  business: Business
  common: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type InvoiceDiscountReasonInput = {
  businessId: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type InvoiceDiscountReasonSettings = {
  __typename?: 'InvoiceDiscountReasonSettings'
  discountReasonMandatory: Scalars['Boolean']['output']
  invoiceDiscountReasons?: Maybe<Array<InvoiceDiscountReason>>
}

export type InvoiceEmailDto = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  expandedGroups?: InputMaybe<Array<GroupIdentifier>>
  includeServiceFee?: InputMaybe<Scalars['Boolean']['input']>
  invoiceId?: InputMaybe<Scalars['ID']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  recipients: Array<RecipientDto>
  subject?: InputMaybe<Scalars['String']['input']>
}

export type InvoiceEntity = {
  creationDate: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  modificationDate: Scalars['DateTime']['output']
}

export type InvoiceEntityPage = {
  __typename?: 'InvoiceEntityPage'
  data: Array<InvoiceEntity>
  totalCount: Scalars['Int']['output']
}

export type InvoiceEntitySearchInput = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  estimateStateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  invoiceStateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  patientIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  refundStateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  updatedFrom?: InputMaybe<Scalars['DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type InvoiceLineItem = InvoiceLineItemEntity & {
  __typename?: 'InvoiceLineItem'
  /** @deprecated appointmentId is no longer non-nullable. Use eventId instead */
  appointmentId: Scalars['ID']['output']
  businessId: Scalars['ID']['output']
  chargedPrice: Scalars['BigDecimal']['output']
  clientId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  creationDate: Scalars['DateTime']['output']
  declined: Scalars['Boolean']['output']
  deleted: Scalars['Boolean']['output']
  discount: Scalars['BigDecimal']['output']
  discountAllowed: Scalars['Boolean']['output']
  discountAmount?: Maybe<Scalars['BigDecimal']['output']>
  discountPerc?: Maybe<Scalars['BigDecimal']['output']>
  dispensingFee?: Maybe<Scalars['BigDecimal']['output']>
  employeeId: Scalars['ID']['output']
  eventId?: Maybe<Scalars['ID']['output']>
  extendedPrice: Scalars['BigDecimal']['output']
  groupSnapshotId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  inventoryCategoryId?: Maybe<Scalars['ID']['output']>
  inventoryControlled?: Maybe<Scalars['Boolean']['output']>
  invoiceId: Scalars['ID']['output']
  labTestOrderId?: Maybe<Scalars['ID']['output']>
  labTestSpecimen?: Maybe<Scalars['String']['output']>
  labTestTestFor?: Maybe<Scalars['String']['output']>
  labTestType?: Maybe<Scalars['String']['output']>
  labTestTypeId?: Maybe<Scalars['ID']['output']>
  labTestVendorCode?: Maybe<Scalars['String']['output']>
  labTestVendorId?: Maybe<Scalars['ID']['output']>
  labTestVendorName?: Maybe<Scalars['String']['output']>
  logId?: Maybe<Scalars['ID']['output']>
  logType: Scalars['String']['output']
  minCharge?: Maybe<Scalars['BigDecimal']['output']>
  modificationDate: Scalars['DateTime']['output']
  name: Scalars['String']['output']
  notes?: Maybe<Scalars['String']['output']>
  orderNotes?: Maybe<Scalars['String']['output']>
  origin?: Maybe<RxPrescriptionOrigin>
  patientId: Scalars['ID']['output']
  prepaid: Scalars['Boolean']['output']
  prepaidRemaining?: Maybe<Scalars['BigDecimal']['output']>
  prescriptionType?: Maybe<PrescriptionType>
  price: Scalars['BigDecimal']['output']
  priceId: Scalars['ID']['output']
  priceItemId: Scalars['ID']['output']
  priceMaxTimeUnitSize?: Maybe<Scalars['BigDecimal']['output']>
  priceMinTimeUnitSize?: Maybe<Scalars['BigDecimal']['output']>
  priceTaxable: Scalars['Boolean']['output']
  priceTemp: Scalars['Boolean']['output']
  priceUnits: Scalars['String']['output']
  priceUnitsSize?: Maybe<Scalars['BigDecimal']['output']>
  procedureCategoryId?: Maybe<Scalars['ID']['output']>
  procedureCode?: Maybe<Scalars['String']['output']>
  procedureDescription?: Maybe<Scalars['String']['output']>
  procedureId?: Maybe<Scalars['ID']['output']>
  procedureModalityId?: Maybe<Scalars['ID']['output']>
  procedureOrderId?: Maybe<Scalars['ID']['output']>
  producerId?: Maybe<Scalars['ID']['output']>
  quantity: Scalars['BigDecimal']['output']
  refundLineItems: Array<RefundInvoiceLineItem>
  soapId?: Maybe<Scalars['ID']['output']>
  soapLogModificationDate?: Maybe<Scalars['DateTime']['output']>
  state?: Maybe<Scalars['String']['output']>
  stateEntity?: Maybe<ConstantEntity>
  subTotal: Scalars['BigDecimal']['output']
  taxAmount: Scalars['BigDecimal']['output']
  taxRate?: Maybe<Scalars['BigDecimal']['output']>
  taxed: Scalars['Boolean']['output']
  tempValue: Scalars['Boolean']['output']
  timeUnitId?: Maybe<Scalars['ID']['output']>
  timeUnitSize?: Maybe<Scalars['BigDecimal']['output']>
  totalRefundEligibleQty?: Maybe<Scalars['BigDecimal']['output']>
  totalRestockEligibleQty?: Maybe<Scalars['BigDecimal']['output']>
  unitId?: Maybe<Scalars['ID']['output']>
  updatedAt: Scalars['DateTime']['output']
  usedQuantity?: Maybe<Scalars['BigDecimal']['output']>
  variationCustomForm?: Maybe<Scalars['String']['output']>
  variationDeliveryMethodId?: Maybe<Scalars['ID']['output']>
  variationFormId?: Maybe<Scalars['ID']['output']>
  variationPackageTypeId?: Maybe<Scalars['ID']['output']>
  variationPerPackageAmount?: Maybe<Scalars['BigDecimal']['output']>
  variationPerPackageUnitsId?: Maybe<Scalars['ID']['output']>
  variationStrength?: Maybe<Scalars['BigDecimal']['output']>
  variationStrengthUnitsId?: Maybe<Scalars['ID']['output']>
  wplanCharged: Scalars['Boolean']['output']
  wplanCoverId?: Maybe<Scalars['ID']['output']>
  wplanCoverPercent?: Maybe<Scalars['BigDecimal']['output']>
  wplanLogId?: Maybe<Scalars['ID']['output']>
}

export type InvoiceLineItemArrangement = {
  __typename?: 'InvoiceLineItemArrangement'
  invoiceLineItemId: Scalars['ID']['output']
  viewOrderNumber?: Maybe<Scalars['Int']['output']>
  viewSectionOrderNumber?: Maybe<Scalars['Int']['output']>
}

export type InvoiceLineItemArrangementInput = {
  invoiceLineItemId: Scalars['ID']['input']
  viewOrderNumber?: InputMaybe<Scalars['Int']['input']>
  viewSectionOrderNumber?: InputMaybe<Scalars['Int']['input']>
}

export type InvoiceLineItemEntity = {
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type InvoiceLineItemPage = Page & {
  __typename?: 'InvoiceLineItemPage'
  data: Array<InvoiceLineItem>
  totalCount: Scalars['Int']['output']
}

export type InvoiceLineItemProducerInput = {
  expectedModification: Scalars['DateTime']['input']
  id: Scalars['ID']['input']
  producerId: Scalars['ID']['input']
}

export type InvoiceLineItemProducerInputDeprecated = {
  producerId: Scalars['ID']['input']
}

export type InvoicePage = Page & {
  __typename?: 'InvoicePage'
  data: Array<Invoice>
  totalCount: Scalars['Int']['output']
}

export type InvoiceSearchInput = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  refundStateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  stateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  updatedFrom?: InputMaybe<Scalars['DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type InvoiceState = NamedEntity & {
  __typename?: 'InvoiceState'
  color?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type InvoiceStateAndInternalNoteInput = {
  internalNote?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
}

export enum InvoiceType {
  Batch = 'BATCH',
  ChargeSheet = 'CHARGE_SHEET',
  Estimate = 'ESTIMATE',
  Invoice = 'INVOICE',
}

export type Invoices = Estimate | Invoice | RefundInvoice

export type JoinWaitlistInput = {
  businessAppointmentTypeId?: InputMaybe<Scalars['ID']['input']>
  businessId: Scalars['ID']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  patientId: Scalars['ID']['input']
  reasonId?: InputMaybe<Scalars['ID']['input']>
  scheduledEndDatetime?: InputMaybe<Scalars['DateTime']['input']>
  scheduledStartDatetime?: InputMaybe<Scalars['DateTime']['input']>
  veterinarianId?: InputMaybe<Scalars['ID']['input']>
}

export type LabTestLog = TimelineEntry & {
  __typename?: 'LabTestLog'
  businessId: Scalars['ID']['output']
  clientId: Scalars['ID']['output']
  date?: Maybe<Scalars['DateTime']['output']>
  eventId?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  labType?: Maybe<Scalars['String']['output']>
  labTypeId?: Maybe<Scalars['ID']['output']>
  labVendor?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  orderId?: Maybe<Scalars['ID']['output']>
  otc?: Maybe<Scalars['Boolean']['output']>
  patientId: Scalars['ID']['output']
  soapId?: Maybe<Scalars['ID']['output']>
  statusId?: Maybe<Scalars['ID']['output']>
  timelineDate?: Maybe<Scalars['DateTime']['output']>
  vendorId?: Maybe<Scalars['ID']['output']>
  vendorTestIdentifier?: Maybe<Scalars['String']['output']>
  vetId?: Maybe<Scalars['ID']['output']>
}

export type LabTestLogFile = {
  __typename?: 'LabTestLogFile'
  extension: Scalars['String']['output']
  fileUrl: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  originalFileName: Scalars['String']['output']
}

export type LabTestLogFileGroup = {
  __typename?: 'LabTestLogFileGroup'
  author: Employee
  creationDate?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  diagnosedIn?: Maybe<Scalars['String']['output']>
  files: Array<LabTestLogFile>
  id: Scalars['ID']['output']
  title?: Maybe<Scalars['String']['output']>
  uploadDate?: Maybe<Scalars['DateTime']['output']>
}

export type LabTestResultEmailDto = {
  message: Scalars['String']['input']
  orderId: Scalars['ID']['input']
  recipients: Array<RecipientDto>
  subject: Scalars['String']['input']
  vendorId: Scalars['ID']['input']
}

export type Language = {
  __typename?: 'Language'
  code: Scalars['String']['output']
  fullName: Scalars['String']['output']
  id?: Maybe<Scalars['ID']['output']>
  name: Scalars['String']['output']
}

export type LineItemPostDataInput = {
  groupSnapshotId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  modificationDate: Scalars['DateTime']['input']
  patientId: Scalars['ID']['input']
  soapId?: InputMaybe<Scalars['ID']['input']>
}

export enum LineItemType {
  InvoiceLineItem = 'InvoiceLineItem',
  RetailOrderLineItem = 'RetailOrderLineItem',
}

export type MedicalHistoryAttachmentDto = {
  from?: InputMaybe<Scalars['DateTime']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  patientId: Scalars['ID']['input']
  sections?: InputMaybe<Array<InputMaybe<ReportSectionDto>>>
  to?: InputMaybe<Scalars['DateTime']['input']>
}

export type MedicalHistoryAttachmentsSection = MedicalHistorySection &
  MessagingConfigSection & {
    __typename?: 'MedicalHistoryAttachmentsSection'
    attachments: Array<GeneratedMessageAttachmentsSubsection>
    checked: Scalars['Boolean']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    section: MedicalHistorySectionType
  }

export type MedicalHistoryBaseSection = MedicalHistorySection &
  MessagingConfigSection & {
    __typename?: 'MedicalHistoryBaseSection'
    checked: Scalars['Boolean']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    section: MedicalHistorySectionType
  }

export type MedicalHistoryConfig = {
  __typename?: 'MedicalHistoryConfig'
  message?: Maybe<Scalars['String']['output']>
  sections: Array<MedicalHistorySection>
  subject?: Maybe<Scalars['String']['output']>
}

export type MedicalHistoryConfigInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>
  patientId: Scalars['ID']['input']
  to?: InputMaybe<Scalars['DateTime']['input']>
}

export type MedicalHistoryEmailDto = {
  from?: InputMaybe<Scalars['DateTime']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  patientId: Scalars['ID']['input']
  recipients: Array<RecipientDto>
  sections?: InputMaybe<Array<InputMaybe<ReportSectionDto>>>
  subject: Scalars['String']['input']
  to?: InputMaybe<Scalars['DateTime']['input']>
}

export type MedicalHistoryPreviewInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  patientId: Scalars['ID']['input']
  sections?: InputMaybe<Array<InputMaybe<MedicalHistorySectionInput>>>
  subject: Scalars['String']['input']
  to?: InputMaybe<Scalars['DateTime']['input']>
}

export type MedicalHistorySection = {
  checked: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  section: MedicalHistorySectionType
}

export type MedicalHistorySectionInput = {
  attachments?: InputMaybe<Array<GeneratedMessageSectionAttachmentsInput>>
  section: MedicalHistorySectionType
}

export enum MedicalHistorySectionType {
  Alerts = 'ALERTS',
  Appointments = 'APPOINTMENTS',
  Attachments = 'ATTACHMENTS',
  Diagnoses = 'DIAGNOSES',
  Imaging = 'IMAGING',
  ImportedHistory = 'IMPORTED_HISTORY',
  Labs = 'LABS',
  Notes = 'NOTES',
  OtcSale = 'OTC_SALE',
  PrescriptionsDrugs = 'PRESCRIPTIONS_DRUGS',
  Questions = 'QUESTIONS',
  Reminders = 'REMINDERS',
  Surgery = 'SURGERY',
  Vaccinations = 'VACCINATIONS',
  Vitals = 'VITALS',
}

export type Membership = {
  __typename?: 'Membership'
  businessId?: Maybe<Scalars['ID']['output']>
  member?: Maybe<Scalars['Boolean']['output']>
  memberSince?: Maybe<Scalars['DateTime']['output']>
  patient?: Maybe<PatientShorten>
  plans?: Maybe<Array<Maybe<MembershipPlan>>>
  termsAndConditions?: Maybe<TermsAndConditionsFile>
  termsAndConditionsInProgress?: Maybe<Scalars['Boolean']['output']>
}

export type MembershipPeriod = {
  __typename?: 'MembershipPeriod'
  endDate?: Maybe<Scalars['DateTime']['output']>
  startDate?: Maybe<Scalars['DateTime']['output']>
}

export type MembershipPlan = {
  __typename?: 'MembershipPlan'
  alreadyCancelled?: Maybe<Scalars['Boolean']['output']>
  autoRenew?: Maybe<Scalars['Boolean']['output']>
  benefits?: Maybe<Array<Maybe<Benefit>>>
  cancelAtDate?: Maybe<Scalars['DateTime']['output']>
  cancellationDate?: Maybe<Scalars['DateTime']['output']>
  cancellationNotes?: Maybe<Scalars['String']['output']>
  cancellationReason?: Maybe<Scalars['String']['output']>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  error?: Maybe<Scalars['JSON']['output']>
  nextPaymentDue?: Maybe<Scalars['DateTime']['output']>
  paymentMethod?: Maybe<WPaymentMethod>
  period?: Maybe<MembershipPeriod>
  planId?: Maybe<Scalars['ID']['output']>
  planLevel?: Maybe<Scalars['Int']['output']>
  planLogId?: Maybe<Scalars['ID']['output']>
  planName?: Maybe<Scalars['String']['output']>
  planType?: Maybe<PlanType>
  price?: Maybe<MembershipPrice>
  refundAmount?: Maybe<Scalars['Float']['output']>
  state?: Maybe<Scalars['String']['output']>
  termsAndConditions?: Maybe<TermsAndConditionsFile>
  tier?: Maybe<Scalars['String']['output']>
  versionId?: Maybe<Scalars['ID']['output']>
}

export type MembershipPrice = {
  __typename?: 'MembershipPrice'
  basePrice?: Maybe<Scalars['Float']['output']>
  basePriceType?: Maybe<PriceType>
  price?: Maybe<Scalars['Float']['output']>
  priceType?: Maybe<PriceType>
  versionActive?: Maybe<Scalars['Boolean']['output']>
}

export type Message = {
  __typename?: 'Message'
  body?: Maybe<Scalars['String']['output']>
  businessId: Scalars['ID']['output']
  category?: Maybe<MessageCategory>
  creatorId?: Maybe<Scalars['ID']['output']>
  files?: Maybe<Array<Maybe<AttachmentDto>>>
  id: Scalars['ID']['output']
  status?: Maybe<MessageStatus>
  timestamp?: Maybe<Scalars['DateTime']['output']>
  type?: Maybe<MessageType>
  way?: Maybe<Way>
}

export enum MessageCategory {
  AdHoc = 'AD_HOC',
  AdHocInbound = 'AD_HOC_INBOUND',
  Appointment = 'APPOINTMENT',
  AppointmentConfirmation = 'APPOINTMENT_CONFIRMATION',
  AppointmentInfo = 'APPOINTMENT_INFO',
  AppointmentVirtualLink = 'APPOINTMENT_VIRTUAL_LINK',
  AutomaticReplyAfterBusinessHours = 'AUTOMATIC_REPLY_AFTER_BUSINESS_HOURS',
  AutomaticReplyDuringBusinessHours = 'AUTOMATIC_REPLY_DURING_BUSINESS_HOURS',
  EmailVerification = 'EMAIL_VERIFICATION',
  ForgotPassword = 'FORGOT_PASSWORD',
  Invitation = 'INVITATION',
  Invoice = 'INVOICE',
  LabTest = 'LAB_TEST',
  PatientHistory = 'PATIENT_HISTORY',
  Payment = 'PAYMENT',
  Prescription = 'PRESCRIPTION',
  PrescriptionSoap = 'PRESCRIPTION_SOAP',
  Reminder = 'REMINDER',
  ReminderInfo = 'REMINDER_INFO',
  ReportCard = 'REPORT_CARD',
  SoapFinalized = 'SOAP_FINALIZED',
}

export type MessageOrigin = {
  body: Scalars['String']['input']
  clientId: Scalars['ID']['input']
  recipients: Array<InputMaybe<RecipientOrigin>>
  subject: Scalars['String']['input']
  transport: Scalars['String']['input']
}

export type MessagePage = Page & {
  __typename?: 'MessagePage'
  data: Array<Message>
  totalCount: Scalars['Int']['output']
}

export type MessagePreview = {
  __typename?: 'MessagePreview'
  message: Scalars['String']['output']
  subject?: Maybe<Scalars['String']['output']>
}

export enum MessageStatus {
  Delivered = 'DELIVERED',
  Draft = 'DRAFT',
  Failed = 'FAILED',
  FormingFailed = 'FORMING_FAILED',
  Sent = 'SENT',
}

export enum MessageType {
  InternalNote = 'INTERNAL_NOTE',
  Json = 'JSON',
  Plain = 'PLAIN',
}

export type MessageUpdate = {
  files?: InputMaybe<Array<InputMaybe<AttachmentOrigin>>>
  status: MessageStatus
  text?: InputMaybe<Scalars['String']['input']>
}

export type MessagingConfigSection = {
  checked: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ModifiedChargeSheetLineItemInput = {
  declined?: InputMaybe<Scalars['Boolean']['input']>
  discountAmount?: InputMaybe<Scalars['BigDecimal']['input']>
  discountPerc?: InputMaybe<Scalars['BigDecimal']['input']>
  expectedModification: Scalars['DateTime']['input']
  expectedSoapLogModification?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  prepaid?: InputMaybe<Scalars['Boolean']['input']>
  producerId?: InputMaybe<Scalars['ID']['input']>
  quantity?: InputMaybe<Scalars['BigDecimal']['input']>
  taxed?: InputMaybe<Scalars['Boolean']['input']>
  usedQuantity?: InputMaybe<Scalars['BigDecimal']['input']>
}

export enum MonitorType {
  InRoom = 'IN_ROOM',
  OutRoom = 'OUT_ROOM',
}

export type Mutation = {
  __typename?: 'Mutation'
  addItemsToChargeSheet: Array<InvoiceLineItem>
  addVital: EnrichedVital
  approveActiveRx: Prescription
  approveReactiveRx: Prescription
  autoRenewalWellnessPlan?: Maybe<WellnessPlan>
  batchUpdateBodySystemLog: ProblemBodySystemLogContainer
  batchUpdateSectionsAdditionalDiscount: Array<ChargesSection>
  checkInToAppointment: Appointment
  createAppointment: Appointment
  createAttachment: UploadedAttachment
  createAttachmentHistoryGroup: AttachmentHistoryGroupDto
  createAvailabilityRule: AvailabilityRule
  createBoopChat?: Maybe<BoopChat>
  createBusiness: Business
  createBusinessAppointmentType: BusinessAppointmentType
  createBusyTime: BusyTime
  createClient: Client
  createConversation?: Maybe<Array<Maybe<ConversationDto>>>
  createCreditAdjustment: Payment
  createEmployee: Employee
  createInvoiceDiscountReason: InvoiceDiscountReason
  createLabTestLogFileGroup: LabTestLogFileGroup
  createOrGetBodySystemLog: ProblemBodySystemLogContainer
  createOrGetProblemEnumLog: ProblemLogContainer
  createOrGetProblemLog: ProblemBodySystemLogContainer
  createOrUpdateClientPreference: ClientPreference
  createOrUpdatePatientPreference: PatientPreference
  createPatient: Patient
  createReminder: Reminder
  createRetailOrderLineItem: RetailOrderLineItem
  createSoap: Soap
  createSoapFileGroup: SoapFileGroup
  createSsoIdpSettings: SsoIdpSettings
  createTask: Task
  createTaskTemplate: TaskTemplate
  createVital: Vital
  createWellnessPlanPaymentMethod?: Maybe<WPaymentMethodCreateResult>
  declineActiveRx: Prescription
  declineReactiveRx: Prescription
  deleteActiveRx: Scalars['ID']['output']
  deleteAppointment: Scalars['ID']['output']
  deleteAttachment: Scalars['ID']['output']
  deleteBodySystemLog: ProblemBodySystemLogContainer
  deleteBusyTime: Scalars['ID']['output']
  deleteChargeSheetItems: Array<Scalars['ID']['output']>
  deleteClient: Scalars['ID']['output']
  deleteClientPreference: Scalars['ID']['output']
  deleteConversation: Scalars['ID']['output']
  deleteConversationMessage: Scalars['ID']['output']
  deleteGroupRole: Scalars['ID']['output']
  deleteInvoiceDiscountReason?: Maybe<Scalars['ID']['output']>
  deleteLabTestLogFile: Scalars['ID']['output']
  deleteMe: Scalars['ID']['output']
  deletePatient: Scalars['ID']['output']
  deletePatientPreference: Scalars['ID']['output']
  deletePriceBulkUpdateSession?: Maybe<Scalars['ID']['output']>
  deleteProblemEnumLog: ProblemLogContainer
  deleteReminder: Scalars['ID']['output']
  deleteRetailOrderLineItem: Scalars['ID']['output']
  deleteSsoIdpSettings: Scalars['ID']['output']
  deleteTask: Scalars['ID']['output']
  deleteTaskTemplate: Scalars['ID']['output']
  deleteVital: Scalars['ID']['output']
  dismissNetPromoterScore: NetPromoterScore
  emailConversationPreview: Scalars['String']['output']
  emailLabResult?: Maybe<Array<Maybe<ShortConversationDto>>>
  emailOutsideSoapPrescription?: Maybe<Array<Maybe<ShortConversationDto>>>
  emailPrescription?: Maybe<Array<Maybe<ShortConversationDto>>>
  emailSupportMultipleAccountsFound?: Maybe<Scalars['String']['output']>
  emailSupportNoAccountsFound?: Maybe<Scalars['String']['output']>
  emailSupportVetNotOffersWPlans?: Maybe<Scalars['String']['output']>
  generateCdnDownloadOtt: OneTimeToken
  generateEstimatePdfOtt: OneTimeToken
  generateHtmlForChargeSheet?: Maybe<Html>
  generateHtmlForInvoice?: Maybe<Html>
  generatePdfForChargeSheet?: Maybe<DownloadAttachmentDto>
  generatePdfForInvoice?: Maybe<DownloadAttachmentDto>
  generatePdfForMedicalHistory?: Maybe<Scalars['Boolean']['output']>
  generatePdfForReportCard?: Maybe<Scalars['Boolean']['output']>
  generateSurveyLink?: Maybe<SurveyLink>
  joinWaitlistForAppointment: Appointment
  linkChewyPets?: Maybe<Scalars['Boolean']['output']>
  linkChewyUser: Scalars['ID']['output']
  linkReactiveRx: Prescription
  linkUnappliedPayment: Array<PaymentLink>
  linkUnappliedPayments: Array<PaymentLink>
  moveItemsToChewyCart: RetailOrder
  pdfFilePrescription?: Maybe<DownloadAttachmentDto>
  postInvoice: Invoice
  postRefundInvoice: RefundInvoice
  rearrangeGroupedLineItems: Array<Maybe<GroupedLineItemItemArrangement>>
  /** @deprecated use rearrangeGroupedLineItems instead */
  rearrangeInvoiceLineItems: Array<Maybe<InvoiceLineItemArrangement>>
  removeNotificationsRegistrationToken: Scalars['Boolean']['output']
  removeWPlanSelection?: Maybe<Scalars['Int']['output']>
  replacePaymentMethodInMembership?: Maybe<Membership>
  requestPaymentLinkToken?: Maybe<Scalars['String']['output']>
  resetSoapTemplateToDefault: SoapTemplate
  saveActiveRx: Prescription
  saveInventoryPriceBulkUpdateChange?: Maybe<Scalars['ID']['output']>
  saveLabTestPriceBulkUpdateChange?: Maybe<Scalars['ID']['output']>
  saveNotificationsRegistrationToken: Scalars['Int']['output']
  savePriceBulkUpdateManualChanges?: Maybe<Scalars['ID']['output']>
  saveProcedurePriceBulkUpdateChange?: Maybe<Scalars['ID']['output']>
  saveReactiveRx: Prescription
  sendAppointmentConfirmation: Array<Maybe<ConversationDto>>
  sendBoopSignUpMessage?: Maybe<BoopSignUpMessageResult>
  sendChargeSheetEmail?: Maybe<Array<Maybe<ShortConversationDto>>>
  sendContactUsEmail?: Maybe<Scalars['ID']['output']>
  sendConversationDraftMessage?: Maybe<Message>
  sendConversationMessage?: Maybe<Message>
  sendEmailPayment?: Maybe<Array<Maybe<ConversationDto>>>
  sendGeneratedMessage: Array<ConversationDto>
  sendInvoiceEmail?: Maybe<Array<Maybe<ShortConversationDto>>>
  sendMedicalHistoryEmail?: Maybe<Scalars['Boolean']['output']>
  sendReportCardEmail?: Maybe<Scalars['Boolean']['output']>
  signSoapDocument?: Maybe<SoapFile>
  signUp?: Maybe<Client>
  startMembership?: Maybe<Array<Maybe<Membership>>>
  startNewPriceBulkUpdateSession?: Maybe<Scalars['ID']['output']>
  startPriceBulkUpdate?: Maybe<Scalars['ID']['output']>
  submitActiveRx: Prescription
  submitAllActiveRxForChargeSheet: Scalars['Boolean']['output']
  submitAllActiveRxForSoap: Scalars['Boolean']['output']
  submitRetailOrder: RetailOrder
  unApplyInvoicePayment: Scalars['Boolean']['output']
  undeclineActiveRx: Prescription
  unlinkChewyUserAndPets?: Maybe<Scalars['ID']['output']>
  updateAppointment: Appointment
  updateBodySystemLog: ProblemBodySystemLogContainer
  updateBusinessAppointmentType: BusinessAppointmentType
  updateBusyTime: BusyTime
  updateChargeSheetItemBatch: Array<InvoiceLineItem>
  updateChargeSheetLineItemProducerBatch: Array<InvoiceLineItem>
  updateChargeSheetRetailOrderLineItemProducerBatch: Array<RetailOrderLineItem>
  updateChat?: Maybe<BoopChat>
  updateClient: Client
  updateConversation?: Maybe<ConversationDto>
  updateConversationMessage?: Maybe<Message>
  updateGroupRoles: Array<Maybe<Scalars['ID']['output']>>
  updateInvoiceLineItem: InvoiceLineItem
  /** @deprecated use updateInvoiceLineItemProducerBatch instead */
  updateInvoiceLineItemProducer: InvoiceLineItem
  updateInvoiceLineItemProducerBatch: Array<InvoiceLineItem>
  updateInvoiceStateAndInternalNote: Invoice
  updateMonitorsAssignments: Array<SpaceMonitor>
  updateNetPromoterScore: NetPromoterScore
  /** @deprecated Use `updatePatientsBatch` */
  updatePatient: Patient
  updatePatientsBatch: Array<Patient>
  updateProblemEnumLog: ProblemLogContainer
  updateProblemLog: ProblemBodySystemLogContainer
  updateReminder: Reminder
  updateReminderSettings: ReminderBusinessSettings
  updateRetailOrder: RetailOrder
  updateRetailOrderLineItem: RetailOrderLineItem
  updateRetailOrderLineProducerBatch: Array<RetailOrderLineItem>
  /** @deprecated Use `batchUpdateSectionsAdditionalDiscount`. Can be removed after https://chewyinc.atlassian.net/browse/RHAP-1876 is deployed to the prod */
  updateSectionsAdditionalDiscount: Array<ChargesSection>
  updateSoap: Soap
  updateSoapTemplate: SoapTemplate
  updateSsoIdpSettings: SsoIdpSettings
  updateStatusInvoiceDiscountReason: InvoiceDiscountReason
  updateTask: Task
  updateTaskTemplate: TaskTemplate
  updateVital: Vital
  updateWellnessPlanPaymentMethod?: Maybe<WPaymentMethod>
  uploadClientAvatar: Client
  uploadPatientAvatar: Patient
  voidInvoice: Invoice
  voidRefundInvoice: RefundInvoice
}

export type MutationAddItemsToChargeSheetArgs = {
  clientId: Scalars['ID']['input']
  createItemInputs: Array<CreateChargeSheetItemInput>
}

export type MutationAddVitalArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  input: VitalInput
  patientId: Scalars['ID']['input']
  soapId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationApproveActiveRxArgs = {
  input: RxInput
  prescriptionId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationApproveReactiveRxArgs = {
  input?: InputMaybe<RxInput>
  prescriptionId: Scalars['ID']['input']
}

export type MutationAutoRenewalWellnessPlanArgs = {
  input: WPlanRenewalLog
}

export type MutationBatchUpdateBodySystemLogArgs = {
  bodySystemLogBatchRequest: UpdateBodySystemLogBatchRequestInput
  soapId: Scalars['ID']['input']
}

export type MutationBatchUpdateSectionsAdditionalDiscountArgs = {
  modifierId: Scalars['ID']['input']
  updateSectionsDiscountInputs: Array<UpdateSectionDiscountInput>
}

export type MutationCheckInToAppointmentArgs = {
  appointmentId: Scalars['ID']['input']
  arrived?: InputMaybe<Scalars['Boolean']['input']>
  businessId: Scalars['ID']['input']
}

export type MutationCreateAppointmentArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  input: CreateAppointmentInput
}

export type MutationCreateAttachmentArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  extension?: InputMaybe<Scalars['String']['input']>
  file: Scalars['Upload']['input']
  name?: InputMaybe<Scalars['String']['input']>
  patientId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationCreateAttachmentHistoryGroupArgs = {
  attachmentHistoryGroup: CreateAttachmentHistoryGroupInput
  patientId: Scalars['ID']['input']
}

export type MutationCreateAvailabilityRuleArgs = {
  input: AvailabilityRuleInput
}

export type MutationCreateBoopChatArgs = {
  input: CreateBoopChatInput
}

export type MutationCreateBusinessArgs = {
  input: BusinessInput
}

export type MutationCreateBusinessAppointmentTypeArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  input: CreateBusinessAppointmentTypeInput
}

export type MutationCreateBusyTimeArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  input: CreateBusyTimeInput
}

export type MutationCreateClientArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  input: CreateClientInput
}

export type MutationCreateConversationArgs = {
  input: ConversationOriginDto
}

export type MutationCreateCreditAdjustmentArgs = {
  clientId: Scalars['ID']['input']
  input: CreditAdjustmentInput
}

export type MutationCreateEmployeeArgs = {
  input: EmployeeInput
}

export type MutationCreateInvoiceDiscountReasonArgs = {
  input: InvoiceDiscountReasonInput
}

export type MutationCreateLabTestLogFileGroupArgs = {
  input: CreateLabTestLogFileGroupInput
  labTestLogId: Scalars['ID']['input']
}

export type MutationCreateOrGetBodySystemLogArgs = {
  problemBodySystemLogRequest: CreateProblemBodySystemLogRequestInput
  soapId: Scalars['ID']['input']
}

export type MutationCreateOrGetProblemEnumLogArgs = {
  problemEnumLogRequest: CreateProblemEnumLogRequestInput
  problemLogId: Scalars['ID']['input']
}

export type MutationCreateOrGetProblemLogArgs = {
  bodySystemLogId: Scalars['ID']['input']
  problemLogRequest: CreateProblemLogRequestInput2
  soapId: Scalars['ID']['input']
}

export type MutationCreateOrUpdateClientPreferenceArgs = {
  clientId: Scalars['ID']['input']
  input: ClientPreferenceInput
}

export type MutationCreateOrUpdatePatientPreferenceArgs = {
  input: PatientPreferenceInput
  patientId: Scalars['ID']['input']
}

export type MutationCreatePatientArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId: Scalars['ID']['input']
  input: CreatePatientInput
}

export type MutationCreateReminderArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  input: CreateReminderInput
}

export type MutationCreateRetailOrderLineItemArgs = {
  input: CreateRetailOrderLineItemInput
}

export type MutationCreateSoapArgs = {
  appointmentId: Scalars['ID']['input']
  businessId?: InputMaybe<Scalars['ID']['input']>
  input: CreateSoapInput
}

export type MutationCreateSoapFileGroupArgs = {
  input: CreateSoapFileGroupInput
}

export type MutationCreateSsoIdpSettingsArgs = {
  ssoIdpSettings: SsoIdpSettingsInput
}

export type MutationCreateTaskArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  input: CreateTaskInput
}

export type MutationCreateTaskTemplateArgs = {
  input: TaskTemplateInput
}

export type MutationCreateVitalArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  input: VitalInput
}

export type MutationCreateWellnessPlanPaymentMethodArgs = {
  currencyId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationDeclineActiveRxArgs = {
  prescriptionId: Scalars['ID']['input']
}

export type MutationDeclineReactiveRxArgs = {
  input?: InputMaybe<RxInput>
  prescriptionId: Scalars['ID']['input']
}

export type MutationDeleteActiveRxArgs = {
  prescriptionId: Scalars['ID']['input']
}

export type MutationDeleteAppointmentArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationDeleteAttachmentArgs = {
  fileId: Scalars['ID']['input']
}

export type MutationDeleteBodySystemLogArgs = {
  logId: Scalars['ID']['input']
  soapId: Scalars['ID']['input']
}

export type MutationDeleteBusyTimeArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationDeleteChargeSheetItemsArgs = {
  deleteItemInputs: Array<DeleteChargeSheetItemInput>
  modifierId: Scalars['ID']['input']
}

export type MutationDeleteClientArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationDeleteClientPreferenceArgs = {
  clientId: Scalars['ID']['input']
}

export type MutationDeleteConversationArgs = {
  conversationId: Scalars['ID']['input']
}

export type MutationDeleteConversationMessageArgs = {
  messageId: Scalars['ID']['input']
}

export type MutationDeleteGroupRoleArgs = {
  groupId: Scalars['ID']['input']
  roleId: Scalars['ID']['input']
}

export type MutationDeleteInvoiceDiscountReasonArgs = {
  discountReasonId: Scalars['ID']['input']
}

export type MutationDeleteLabTestLogFileArgs = {
  fileId: Scalars['ID']['input']
  groupId: Scalars['ID']['input']
  labTestLogId: Scalars['ID']['input']
}

export type MutationDeletePatientArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationDeletePatientPreferenceArgs = {
  patientId: Scalars['ID']['input']
}

export type MutationDeletePriceBulkUpdateSessionArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteProblemEnumLogArgs = {
  crc: Scalars['Long']['input']
  problemEnumLogId: Scalars['ID']['input']
  problemLogId: Scalars['ID']['input']
}

export type MutationDeleteReminderArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationDeleteRetailOrderLineItemArgs = {
  itemId: Scalars['ID']['input']
}

export type MutationDeleteSsoIdpSettingsArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteTaskArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationDeleteTaskTemplateArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteVitalArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationDismissNetPromoterScoreArgs = {
  appointmentId: Scalars['ID']['input']
}

export type MutationEmailConversationPreviewArgs = {
  input: EmailPreviewConfig
}

export type MutationEmailLabResultArgs = {
  input: LabTestResultEmailDto
}

export type MutationEmailOutsideSoapPrescriptionArgs = {
  input: PrescriptionEmailDto
}

export type MutationEmailPrescriptionArgs = {
  input: PrescriptionEmailDto
}

export type MutationEmailSupportMultipleAccountsFoundArgs = {
  input: EmailSupportMultipleAccountsInput
}

export type MutationEmailSupportNoAccountsFoundArgs = {
  input: EmailSupportNoAccountsInput
}

export type MutationEmailSupportVetNotOffersWPlansArgs = {
  input: EmailSupportVetNotOffersWPlansInput
}

export type MutationGenerateCdnDownloadOttArgs = {
  extension?: InputMaybe<Scalars['String']['input']>
  fileUrl: Scalars['String']['input']
}

export type MutationGenerateEstimatePdfOttArgs = {
  estimateId: Scalars['ID']['input']
}

export type MutationGenerateHtmlForChargeSheetArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  expandedGroups?: InputMaybe<Array<GroupIdentifier>>
  includeServiceFee?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationGenerateHtmlForInvoiceArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  expandedGroups?: InputMaybe<Array<GroupIdentifier>>
  includeServiceFee?: InputMaybe<Scalars['Boolean']['input']>
  invoiceId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationGeneratePdfForChargeSheetArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  expandedGroups?: InputMaybe<Array<GroupIdentifier>>
  includeServiceFee?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationGeneratePdfForInvoiceArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  expandedGroups?: InputMaybe<Array<GroupIdentifier>>
  includeServiceFee?: InputMaybe<Scalars['Boolean']['input']>
  invoiceId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationGeneratePdfForMedicalHistoryArgs = {
  input?: InputMaybe<MedicalHistoryAttachmentDto>
}

export type MutationGeneratePdfForReportCardArgs = {
  input?: InputMaybe<ReportCardAttachmentDto>
}

export type MutationGenerateSurveyLinkArgs = {
  clientId: Scalars['ID']['input']
  referenceEntityType: ReferenceEntityType
  referenceId: Scalars['ID']['input']
}

export type MutationJoinWaitlistForAppointmentArgs = {
  input: JoinWaitlistInput
}

export type MutationLinkChewyPetsArgs = {
  clientId: Scalars['ID']['input']
  petPatientMatches: Array<PetProfileMatchInput>
}

export type MutationLinkChewyUserArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId: Scalars['ID']['input']
  email: Scalars['String']['input']
}

export type MutationLinkReactiveRxArgs = {
  clientId: Scalars['ID']['input']
  patientId: Scalars['ID']['input']
  prescriptionId: Scalars['ID']['input']
}

export type MutationLinkUnappliedPaymentArgs = {
  invoiceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  paymentId: Scalars['ID']['input']
}

export type MutationLinkUnappliedPaymentsArgs = {
  invoiceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  paymentIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type MutationMoveItemsToChewyCartArgs = {
  retailOrderId: Scalars['ID']['input']
}

export type MutationPdfFilePrescriptionArgs = {
  input: PrescriptionAttachmentDto
}

export type MutationPostInvoiceArgs = {
  input: PostInvoiceInput
}

export type MutationPostRefundInvoiceArgs = {
  input: RefundInvoiceInput
}

export type MutationRearrangeGroupedLineItemsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  input: Array<InputMaybe<GroupedLineItemArrangementInput>>
  invoiceId: Scalars['ID']['input']
}

export type MutationRearrangeInvoiceLineItemsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  input: Array<InputMaybe<InvoiceLineItemArrangementInput>>
  invoiceId: Scalars['ID']['input']
}

export type MutationRemoveNotificationsRegistrationTokenArgs = {
  clientId: Scalars['Int']['input']
  token: Scalars['String']['input']
}

export type MutationReplacePaymentMethodInMembershipArgs = {
  membershipId: Scalars['ID']['input']
  paymentMethodId: Scalars['ID']['input']
}

export type MutationRequestPaymentLinkTokenArgs = {
  businessId: Scalars['ID']['input']
  invoiceId: Scalars['ID']['input']
}

export type MutationResetSoapTemplateToDefaultArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationSaveActiveRxArgs = {
  input: RxInput
  prescriptionId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationSaveInventoryPriceBulkUpdateChangeArgs = {
  categoryId: Scalars['ID']['input']
  includePrices: Scalars['Boolean']['input']
  incrementType: IncrementType
  incrementValue: Scalars['BigDecimal']['input']
  priceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  roundUp?: InputMaybe<Scalars['Boolean']['input']>
  sessionId: Scalars['ID']['input']
  subcategoryId: Scalars['ID']['input']
}

export type MutationSaveLabTestPriceBulkUpdateChangeArgs = {
  includePrices: Scalars['Boolean']['input']
  incrementType: IncrementType
  incrementValue: Scalars['BigDecimal']['input']
  labVendorId: Scalars['ID']['input']
  priceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  roundUp?: InputMaybe<Scalars['Boolean']['input']>
  sessionId: Scalars['ID']['input']
}

export type MutationSaveNotificationsRegistrationTokenArgs = {
  metadata?: InputMaybe<BoopTokenMetadataInput>
  token: Scalars['String']['input']
}

export type MutationSavePriceBulkUpdateManualChangesArgs = {
  entityType: EntityType
  prices: Array<InputMaybe<InputPriceDto>>
  sessionId: Scalars['ID']['input']
}

export type MutationSaveProcedurePriceBulkUpdateChangeArgs = {
  categoryId: Scalars['ID']['input']
  includePrices: Scalars['Boolean']['input']
  incrementType: IncrementType
  incrementValue: Scalars['BigDecimal']['input']
  priceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  roundUp?: InputMaybe<Scalars['Boolean']['input']>
  sessionId: Scalars['ID']['input']
}

export type MutationSaveReactiveRxArgs = {
  input?: InputMaybe<RxInput>
  prescriptionId: Scalars['ID']['input']
}

export type MutationSendAppointmentConfirmationArgs = {
  appointmentId: Scalars['ID']['input']
  input: AppointmentConfirmationInput
}

export type MutationSendBoopSignUpMessageArgs = {
  message: MessageOrigin
}

export type MutationSendChargeSheetEmailArgs = {
  chargeSheetEmailDto?: InputMaybe<ChargeSheetEmailDto>
}

export type MutationSendContactUsEmailArgs = {
  input: EmailInput
}

export type MutationSendConversationDraftMessageArgs = {
  conversationId: Scalars['ID']['input']
  input: OutboundMessageDto
}

export type MutationSendConversationMessageArgs = {
  conversationId: Scalars['ID']['input']
  input: OutboundMessageDto
}

export type MutationSendEmailPaymentArgs = {
  input: PaymentEmail
}

export type MutationSendGeneratedMessageArgs = {
  input: GeneratedMessageInput
  recipients: Array<RecipientDto>
  transport: ConversationTransport
}

export type MutationSendInvoiceEmailArgs = {
  invoiceEmailDto?: InputMaybe<InvoiceEmailDto>
}

export type MutationSendMedicalHistoryEmailArgs = {
  input?: InputMaybe<MedicalHistoryEmailDto>
}

export type MutationSendReportCardEmailArgs = {
  input?: InputMaybe<ReportCardEmailDto>
}

export type MutationSignSoapDocumentArgs = {
  body?: InputMaybe<Scalars['String']['input']>
  fileId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationSignUpArgs = {
  clientId: Scalars['ID']['input']
}

export type MutationStartMembershipArgs = {
  membershipSelectionList?: InputMaybe<
    Array<InputMaybe<PatientMembershipLevel>>
  >
  paymentMethodId: Scalars['ID']['input']
  planVersionId: Scalars['ID']['input']
  signupSource?: InputMaybe<Scalars['String']['input']>
  soldById?: InputMaybe<Scalars['ID']['input']>
}

export type MutationStartNewPriceBulkUpdateSessionArgs = {
  entityType: EntityType
  force?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationStartPriceBulkUpdateArgs = {
  sessionId: Scalars['ID']['input']
}

export type MutationSubmitActiveRxArgs = {
  input: RxInput
  prescriptionId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationSubmitAllActiveRxForChargeSheetArgs = {
  businessId: Scalars['ID']['input']
  clientId: Scalars['ID']['input']
}

export type MutationSubmitAllActiveRxForSoapArgs = {
  soapId: Scalars['ID']['input']
}

export type MutationSubmitRetailOrderArgs = {
  input: SubmitRetailOrderInput
}

export type MutationUnApplyInvoicePaymentArgs = {
  invoiceId: Scalars['ID']['input']
  paymentId: Scalars['ID']['input']
}

export type MutationUndeclineActiveRxArgs = {
  prescriptionId: Scalars['ID']['input']
}

export type MutationUnlinkChewyUserAndPetsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId: Scalars['ID']['input']
}

export type MutationUpdateAppointmentArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  input: UpdateAppointmentInput
}

export type MutationUpdateBodySystemLogArgs = {
  logId: Scalars['ID']['input']
  problemBodySystemLogRequest: UpdateProblemBodySystemLogRequestInput
  soapId: Scalars['ID']['input']
}

export type MutationUpdateBusinessAppointmentTypeArgs = {
  businessId: Scalars['ID']['input']
  id: Scalars['ID']['input']
  input: UpdateBusinessAppointmentTypeInput
}

export type MutationUpdateBusyTimeArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  input: UpdateBusyTimeInput
}

export type MutationUpdateChargeSheetItemBatchArgs = {
  updateItemInput: Array<ModifiedChargeSheetLineItemInput>
}

export type MutationUpdateChargeSheetLineItemProducerBatchArgs = {
  input: Array<InvoiceLineItemProducerInput>
}

export type MutationUpdateChargeSheetRetailOrderLineItemProducerBatchArgs = {
  input: Array<RetailOrderLineItemProducerInput>
}

export type MutationUpdateChatArgs = {
  id: Scalars['ID']['input']
  input?: InputMaybe<UpdateBoopChatInput>
}

export type MutationUpdateClientArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  input: UpdateClientInput
}

export type MutationUpdateConversationArgs = {
  conversationId: Scalars['ID']['input']
  input: ConversationUpdateDto
}

export type MutationUpdateConversationMessageArgs = {
  input: MessageUpdate
  messageId: Scalars['ID']['input']
}

export type MutationUpdateGroupRolesArgs = {
  groupId: Scalars['ID']['input']
  roleIds: Array<Scalars['ID']['input']>
}

export type MutationUpdateInvoiceLineItemArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  input: UpdateInvoiceLineItemInput
}

export type MutationUpdateInvoiceLineItemProducerArgs = {
  expectedModification: Scalars['DateTime']['input']
  id: Scalars['ID']['input']
  input: InvoiceLineItemProducerInputDeprecated
}

export type MutationUpdateInvoiceLineItemProducerBatchArgs = {
  input: Array<InvoiceLineItemProducerInput>
}

export type MutationUpdateInvoiceStateAndInternalNoteArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  expectedModification: Scalars['DateTime']['input']
  input: InvoiceStateAndInternalNoteInput
  invoiceId: Scalars['ID']['input']
}

export type MutationUpdateMonitorsAssignmentsArgs = {
  input: Array<UpdateAssignmentsDto>
}

export type MutationUpdateNetPromoterScoreArgs = {
  appointmentId: Scalars['ID']['input']
  input?: InputMaybe<NetPromoterScoreInput>
}

export type MutationUpdatePatientArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  input: UpdatePatientInputOld
}

export type MutationUpdatePatientsBatchArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId: Scalars['ID']['input']
  input: Array<UpdatePatientInput>
}

export type MutationUpdateProblemEnumLogArgs = {
  problemEnumLogId: Scalars['ID']['input']
  problemEnumLogRequest: UpdateProblemEnumLogRequestInput
  problemLogId: Scalars['ID']['input']
}

export type MutationUpdateProblemLogArgs = {
  logId: Scalars['ID']['input']
  problemLogRequest: UpdateProblemLogRequestInput2
  soapId: Scalars['ID']['input']
}

export type MutationUpdateReminderArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  input: UpdateReminderInput
}

export type MutationUpdateReminderSettingsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  settings: ReminderBusinessSettingsInput
}

export type MutationUpdateRetailOrderArgs = {
  input: UpdateRetailOrderInput
}

export type MutationUpdateRetailOrderLineItemArgs = {
  input: UpdateRetailOrderLineItemInput
}

export type MutationUpdateRetailOrderLineProducerBatchArgs = {
  input: Array<RetailOrderLineItemProducerInput>
}

export type MutationUpdateSectionsAdditionalDiscountArgs = {
  modifierId: Scalars['ID']['input']
  updateSectionsDiscountInputs: Array<UpdateSectionDiscountInput>
}

export type MutationUpdateSoapArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  input: UpdateSoapInput
}

export type MutationUpdateSoapTemplateArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  input: SoapTemplateInput
}

export type MutationUpdateSsoIdpSettingsArgs = {
  ssoIdpSettings: SsoIdpSettingsInput
}

export type MutationUpdateStatusInvoiceDiscountReasonArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  businessId: Scalars['ID']['input']
  discountReasonId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationUpdateTaskArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  input: UpdateTaskInput
}

export type MutationUpdateTaskTemplateArgs = {
  id: Scalars['ID']['input']
  input: TaskTemplateInput
}

export type MutationUpdateVitalArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  input: VitalInput
}

export type MutationUpdateWellnessPlanPaymentMethodArgs = {
  billingDetails?: InputMaybe<Scalars['JSON']['input']>
  paymentMethodId: Scalars['ID']['input']
}

export type MutationUploadClientAvatarArgs = {
  clientId: Scalars['ID']['input']
  file?: InputMaybe<Scalars['Upload']['input']>
}

export type MutationUploadPatientAvatarArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>
  patientId: Scalars['ID']['input']
}

export type MutationVoidInvoiceArgs = {
  invoiceId: Scalars['ID']['input']
  voidedById: Scalars['ID']['input']
}

export type MutationVoidRefundInvoiceArgs = {
  refundInvoiceId: Scalars['ID']['input']
}

export type NamedEntity = {
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type NamedTimeOffset = NamedEntity & {
  __typename?: 'NamedTimeOffset'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  timeOffset?: Maybe<TimeOffset>
  updatedAt: Scalars['DateTime']['output']
}

export type NetPromoterScore = {
  __typename?: 'NetPromoterScore'
  available?: Maybe<Scalars['Boolean']['output']>
  boopTimestamp?: Maybe<Scalars['DateTime']['output']>
  dismissed?: Maybe<Scalars['Boolean']['output']>
  eventId: Scalars['ID']['output']
  score?: Maybe<Scalars['Int']['output']>
}

export type NetPromoterScoreInput = {
  boopTimestamp: Scalars['DateTime']['input']
  score?: InputMaybe<Scalars['Int']['input']>
}

export type Notification = {
  __typename?: 'Notification'
  areaId?: Maybe<Scalars['ID']['output']>
  businessId?: Maybe<Scalars['ID']['output']>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  creatorId?: Maybe<Scalars['ID']['output']>
  deleted?: Maybe<Scalars['Boolean']['output']>
  id?: Maybe<Scalars['ID']['output']>
  message?: Maybe<Scalars['String']['output']>
  personId?: Maybe<Scalars['ID']['output']>
  read?: Maybe<Scalars['Boolean']['output']>
  title?: Maybe<Scalars['String']['output']>
  typeId?: Maybe<Scalars['ID']['output']>
}

export type NotificationPage = Page & {
  __typename?: 'NotificationPage'
  data: Array<ShortConversationDto>
  totalCount: Scalars['Int']['output']
  unreadNotificationsTotalCount: Scalars['Int']['output']
}

export type OneTimeToken = {
  __typename?: 'OneTimeToken'
  value: Scalars['String']['output']
}

export type OrderFilter = {
  categoryId?: Maybe<Scalars['ID']['output']>
  children?: Maybe<Array<Maybe<OrderFilter>>>
  name?: Maybe<Scalars['String']['output']>
  refineGroup?: Maybe<Scalars['String']['output']>
  subcategoryId?: Maybe<Scalars['ID']['output']>
  type?: Maybe<FilterableEntityType>
}

export type OrderFilterImp = OrderFilter & {
  __typename?: 'OrderFilterImp'
  categoryId?: Maybe<Scalars['ID']['output']>
  children?: Maybe<Array<Maybe<OrderFilter>>>
  name?: Maybe<Scalars['String']['output']>
  refineGroup?: Maybe<Scalars['String']['output']>
  subcategoryId?: Maybe<Scalars['ID']['output']>
  type?: Maybe<FilterableEntityType>
}

export type OrderFilterWplan = OrderFilter & {
  __typename?: 'OrderFilterWplan'
  alreadyCancelled?: Maybe<Scalars['Boolean']['output']>
  autoRenew?: Maybe<Scalars['Boolean']['output']>
  cancelAtDate?: Maybe<Scalars['DateTime']['output']>
  cancellationDate?: Maybe<Scalars['DateTime']['output']>
  categoryId?: Maybe<Scalars['ID']['output']>
  children?: Maybe<Array<Maybe<OrderFilter>>>
  name?: Maybe<Scalars['String']['output']>
  refineGroup?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  subcategoryId?: Maybe<Scalars['ID']['output']>
  type?: Maybe<FilterableEntityType>
}

export type OrderItemOptions = {
  __typename?: 'OrderItemOptions'
  optionType: ReminderItemOptionType
  orderItemId: Scalars['ID']['output']
  orderItemType: ReminderOrderItemType
}

export type OrderItemOptionsInput = {
  optionType: ReminderItemOptionType
  orderItemId: Scalars['ID']['input']
  orderItemType: ReminderOrderItemType
}

export type OutboundMessageDto = {
  body: Scalars['String']['input']
  category?: InputMaybe<MessageCategory>
  files?: InputMaybe<Array<InputMaybe<AttachmentOrigin>>>
  type: MessageType
}

export type OutstandingFinalizationStates = {
  __typename?: 'OutstandingFinalizationStates'
  existsPendingActiveRxToBeFinalized?: Maybe<Scalars['Boolean']['output']>
  existsPendingImagingRequestsToBeFinalized?: Maybe<
    Scalars['Boolean']['output']
  >
  existsPendingLabTestsToBeFinalized?: Maybe<Scalars['Boolean']['output']>
}

export type OwnerEntity = {
  address1?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  deleted?: Maybe<Scalars['Boolean']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  homePhone?: Maybe<Scalars['String']['output']>
  homePhoneNew?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  mobilePhone?: Maybe<Scalars['String']['output']>
  state?: Maybe<State>
  updatedAt: Scalars['DateTime']['output']
  zip?: Maybe<Scalars['String']['output']>
}

export type Ownership = {
  __typename?: 'Ownership'
  createdAt: Scalars['DateTime']['output']
  owner: OwnerEntity
  percentage: Scalars['Float']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type Page = {
  totalCount: Scalars['Int']['output']
}

export type Patient = {
  __typename?: 'Patient'
  acquiredDate?: Maybe<Scalars['DateTime']['output']>
  acquiredType?: Maybe<Scalars['String']['output']>
  active?: Maybe<Scalars['Boolean']['output']>
  alertColorId?: Maybe<Scalars['ID']['output']>
  alertTypeIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
  allergies?: Maybe<Scalars['String']['output']>
  appointments: AppointmentPage
  approximateDateOfBirth: Scalars['Boolean']['output']
  breeds?: Maybe<Array<Breed>>
  businessId: Scalars['ID']['output']
  clientId: Scalars['ID']['output']
  color?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  currentVitals: CurrentVitals
  currentWeight?: Maybe<CurrentWeight>
  customAlertType?: Maybe<Scalars['String']['output']>
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>
  deceased: Scalars['Boolean']['output']
  deceasedDate?: Maybe<Scalars['DateTime']['output']>
  deleted?: Maybe<Scalars['Boolean']['output']>
  diet?: Maybe<Scalars['String']['output']>
  displayId?: Maybe<Scalars['ID']['output']>
  environment?: Maybe<ConstantEntity>
  externalId?: Maybe<Scalars['String']['output']>
  fitnessLevel?: Maybe<ConstantEntity>
  /** @deprecated Use genderEntity instead */
  gender?: Maybe<Gender>
  genderEntity?: Maybe<ConstantEntity>
  id: Scalars['ID']['output']
  imagingOrders: ImagingOrderPage
  insurancePolicyNumber?: Maybe<Scalars['String']['output']>
  insuranceProvider?: Maybe<Scalars['String']['output']>
  license?: Maybe<Scalars['String']['output']>
  markingsTattoo?: Maybe<Scalars['String']['output']>
  memberships?: Maybe<Array<Maybe<Membership>>>
  microchipNumber?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  notes?: Maybe<Scalars['String']['output']>
  owners: Array<Ownership>
  photo?: Maybe<Scalars['String']['output']>
  photoThumbnail?: Maybe<Scalars['String']['output']>
  /** @deprecated Use wellnessPlans instead */
  plans?: Maybe<Array<Maybe<WPlan>>>
  preference?: Maybe<PatientPreference>
  prescriptions: PrescriptionPage
  rabiesTag?: Maybe<Scalars['String']['output']>
  rabiesTagExpiration?: Maybe<Scalars['DateTime']['output']>
  reminders: ReminderPage
  schedule?: Maybe<Scalars['String']['output']>
  serviceDesignation?: Maybe<ConstantEntity>
  spayedNeutered?: Maybe<Scalars['Boolean']['output']>
  spayedNeuteredStatus?: Maybe<SpayedNeuteredStatus>
  species?: Maybe<Species>
  updatedAt: Scalars['DateTime']['output']
  vitals: VitalPage
  wellnessPlans?: Maybe<Array<Maybe<WellnessPlan>>>
}

export type PatientAppointmentsArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  scheduledFrom?: InputMaybe<Scalars['DateTime']['input']>
  scheduledTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type PatientImagingOrdersArgs = {
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  statusesIds?: Array<Scalars['ID']['input']>
}

export type PatientOwnersArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type PatientPrescriptionsArgs = {
  limit: Scalars['Int']['input']
  modifiedFrom?: InputMaybe<Scalars['DateTime']['input']>
  modifiedTo?: InputMaybe<Scalars['DateTime']['input']>
  offset: Scalars['Int']['input']
  stateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type PatientRemindersArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
}

export type PatientVitalsArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
}

export type PatientDto = {
  __typename?: 'PatientDto'
  acquiredDate?: Maybe<Scalars['DateTime']['output']>
  acquiredType?: Maybe<Scalars['String']['output']>
  active?: Maybe<Scalars['Boolean']['output']>
  activityLevel?: Maybe<Scalars['ID']['output']>
  alertTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  allergies?: Maybe<Scalars['String']['output']>
  approximateAge?: Maybe<Scalars['Boolean']['output']>
  breeds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
  color?: Maybe<Scalars['String']['output']>
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>
  deceased?: Maybe<Scalars['Boolean']['output']>
  deceasedDate?: Maybe<Scalars['DateTime']['output']>
  diet?: Maybe<Scalars['String']['output']>
  environment?: Maybe<Scalars['ID']['output']>
  externalPatientId?: Maybe<Scalars['String']['output']>
  externalSource?: Maybe<Scalars['String']['output']>
  gender?: Maybe<Scalars['ID']['output']>
  id?: Maybe<Scalars['ID']['output']>
  insurancePolicyNumber?: Maybe<Scalars['String']['output']>
  insuranceProvider?: Maybe<Scalars['String']['output']>
  microchipNumber?: Maybe<Scalars['String']['output']>
  microchipped?: Maybe<Scalars['Boolean']['output']>
  name?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  photo?: Maybe<Scalars['String']['output']>
  photoThumbnail?: Maybe<Scalars['String']['output']>
  rabiesTag?: Maybe<Scalars['String']['output']>
  rabiesTagExpiration?: Maybe<Scalars['DateTime']['output']>
  referralSourceId?: Maybe<Scalars['ID']['output']>
  referralSourceTypeId?: Maybe<Scalars['ID']['output']>
  sendReminders?: Maybe<Scalars['Boolean']['output']>
  serviceAnimalType?: Maybe<Scalars['ID']['output']>
  size?: Maybe<Scalars['ID']['output']>
  spayedNeutered?: Maybe<Scalars['Boolean']['output']>
  species?: Maybe<Scalars['ID']['output']>
}

export type PatientMembershipLevel = {
  level?: InputMaybe<Scalars['Int']['input']>
  patientId: Scalars['ID']['input']
  planId?: InputMaybe<Scalars['ID']['input']>
  planPriceId?: InputMaybe<Scalars['ID']['input']>
}

export type PatientMembershipLevelSelection = {
  __typename?: 'PatientMembershipLevelSelection'
  patientId: Scalars['ID']['output']
  planId?: Maybe<Scalars['ID']['output']>
  planPriceId?: Maybe<Scalars['ID']['output']>
}

export type PatientPage = Page & {
  __typename?: 'PatientPage'
  data: Array<Patient>
  totalCount: Scalars['Int']['output']
}

export type PatientPreference = {
  __typename?: 'PatientPreference'
  businessId: Scalars['ID']['output']
  caution?: Maybe<Array<Maybe<ConstantEntity>>>
  creatorId: Scalars['ID']['output']
  drugPreference?: Maybe<Array<Maybe<ConstantEntity>>>
  drugSensitivityComment?: Maybe<Scalars['String']['output']>
  generalComment?: Maybe<Scalars['String']['output']>
  importantComment?: Maybe<Scalars['String']['output']>
  labWorkFrequency?: Maybe<Array<Maybe<ConstantEntity>>>
  medicalAlert?: Maybe<Array<Maybe<ConstantEntity>>>
  medicalComment?: Maybe<Scalars['String']['output']>
  modifierId: Scalars['ID']['output']
  namePronunciation?: Maybe<Scalars['String']['output']>
  patientId: Scalars['ID']['output']
  performedTreatmentsLocation?: Maybe<ConstantEntity>
  premedicationVaccineReaction?: Maybe<Array<Maybe<ConstantEntity>>>
  sedation?: Maybe<Array<Maybe<ConstantEntity>>>
  staffGender?: Maybe<ConstantEntity>
  treats?: Maybe<Scalars['String']['output']>
  venipuncture?: Maybe<ConstantEntity>
}

export type PatientPreferenceInput = {
  cautionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  drugPreferenceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  drugSensitivityComment?: InputMaybe<Scalars['String']['input']>
  generalComment?: InputMaybe<Scalars['String']['input']>
  importantComment?: InputMaybe<Scalars['String']['input']>
  labWorkFrequencyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  medicalAlertIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  medicalComment?: InputMaybe<Scalars['String']['input']>
  namePronunciation?: InputMaybe<Scalars['String']['input']>
  performedTreatmentsLocationId?: InputMaybe<Scalars['ID']['input']>
  premedicationVaccineReactionIds?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>>
  >
  sedationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  staffGenderId?: InputMaybe<Scalars['ID']['input']>
  treats?: InputMaybe<Scalars['String']['input']>
  venipunctureId?: InputMaybe<Scalars['ID']['input']>
}

export type PatientShorten = {
  __typename?: 'PatientShorten'
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  photo?: Maybe<Scalars['String']['output']>
  photoThumbnail?: Maybe<Scalars['String']['output']>
  speciesId?: Maybe<Scalars['ID']['output']>
}

export type PatientSummary = {
  __typename?: 'PatientSummary'
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>
  gender?: Maybe<Scalars['ID']['output']>
  isActive?: Maybe<Scalars['Boolean']['output']>
  isDeceased?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  rhapsodyId: Scalars['ID']['output']
  species?: Maybe<Scalars['ID']['output']>
}

export type PatientSummaryInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>
  gender?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  rhapsodyId: Scalars['ID']['input']
}

export type PatientView = {
  __typename?: 'PatientView'
  color?: Maybe<Scalars['String']['output']>
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>
  gender?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  spayedNeutered?: Maybe<Scalars['Boolean']['output']>
  species?: Maybe<Scalars['ID']['output']>
  weight?: Maybe<PatientWeight>
}

export type PatientWaitlistStatus = {
  __typename?: 'PatientWaitlistStatus'
  businessId: Scalars['ID']['output']
  statusId?: Maybe<Scalars['ID']['output']>
}

export type PatientWeight = {
  __typename?: 'PatientWeight'
  date?: Maybe<Scalars['DateTime']['output']>
  value?: Maybe<Scalars['BigDecimal']['output']>
}

export type PayGoPreferences = {
  __typename?: 'PayGoPreferences'
  cardProcessorId?: Maybe<Scalars['String']['output']>
  cardUrl?: Maybe<Scalars['String']['output']>
  merchantGoId?: Maybe<Scalars['String']['output']>
  transcenterId?: Maybe<Scalars['String']['output']>
}

export type PayPalPaymentMethod = {
  __typename?: 'PayPalPaymentMethod'
  email: Scalars['String']['output']
  id: Scalars['String']['output']
  primary?: Maybe<Scalars['Boolean']['output']>
}

export type Payment = PaymentEntity & {
  __typename?: 'Payment'
  amount: Scalars['BigDecimal']['output']
  businessId: Scalars['ID']['output']
  cardType?: Maybe<ConstantEntity>
  clientId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  creationDate: Scalars['DateTime']['output']
  creditAdjustmentReason?: Maybe<ConstantEntity>
  currency?: Maybe<ConstantEntity>
  deleted: Scalars['Boolean']['output']
  employee: Employee
  employeeId: Scalars['ID']['output']
  entityType?: Maybe<Scalars['String']['output']>
  estimatesRequiredDepositAmountNoFee: Scalars['BigDecimal']['output']
  goTxId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  invoicesTotalAmountNoFee: Scalars['BigDecimal']['output']
  lastFour?: Maybe<Scalars['String']['output']>
  links?: Maybe<Array<PaymentLink>>
  method?: Maybe<ConstantEntity>
  modificationDate: Scalars['DateTime']['output']
  notes?: Maybe<Scalars['String']['output']>
  originPayment?: Maybe<Payment>
  /** @deprecated use originPayment.id instead */
  originPaymentId?: Maybe<Scalars['ID']['output']>
  paidByPerson?: Maybe<Employee>
  paidByPersonId?: Maybe<Scalars['ID']['output']>
  paidByPersonName?: Maybe<Scalars['String']['output']>
  posTxId?: Maybe<Scalars['ID']['output']>
  refundPayments: Array<Payment>
  refundableAmount?: Maybe<Scalars['BigDecimal']['output']>
  reversed?: Maybe<Scalars['Boolean']['output']>
  serviceFeeIncAmount?: Maybe<Scalars['BigDecimal']['output']>
  signedAmount: Scalars['BigDecimal']['output']
  signedSplitAmount?: Maybe<Scalars['BigDecimal']['output']>
  split: Scalars['Boolean']['output']
  splitAmount?: Maybe<Scalars['BigDecimal']['output']>
  splitServiceFeeIncAmount?: Maybe<Scalars['BigDecimal']['output']>
  type?: Maybe<ConstantEntity>
  unappliedAmount: Scalars['BigDecimal']['output']
  undone?: Maybe<Scalars['Boolean']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type PaymentEmail = {
  balancePayment: Scalars['Boolean']['input']
  clientId: Scalars['ID']['input']
  invoiceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  message: Scalars['String']['input']
  recipients: Array<RecipientDto>
  request: Request
  subject: Scalars['String']['input']
  transport: ConversationTransport
}

export type PaymentEntity = {
  creationDate: Scalars['DateTime']['output']
  entityType?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  modificationDate: Scalars['DateTime']['output']
}

export type PaymentEntityPage = {
  __typename?: 'PaymentEntityPage'
  data: Array<PaymentEntity>
  totalCount: Scalars['Int']['output']
}

export type PaymentLink = {
  __typename?: 'PaymentLink'
  invoice: Invoices
  invoiceNo: Scalars['String']['output']
  invoiceTotalAmountNoFee: Scalars['BigDecimal']['output']
  paidAmount: Scalars['BigDecimal']['output']
  paidAmountNoFee: Scalars['BigDecimal']['output']
  serviceFeeIncAmount: Scalars['BigDecimal']['output']
  split: Scalars['Boolean']['output']
}

export type PaymentMethod =
  | CardPaymentMethod
  | ChewyAccountBalancePaymentMethod
  | PayPalPaymentMethod

export type PaymentPage = Page & {
  __typename?: 'PaymentPage'
  data: Array<Payment>
  totalCount: Scalars['Int']['output']
}

export type Person = {
  active?: Maybe<Scalars['Boolean']['output']>
  businessRoles?: Maybe<Array<Maybe<BusinessRole>>>
  creationDate: Scalars['DateTime']['output']
  deleted?: Maybe<Scalars['Boolean']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  mobilePhone?: Maybe<Scalars['String']['output']>
  modificationDate: Scalars['DateTime']['output']
  photo?: Maybe<Scalars['String']['output']>
  photoThumbnail?: Maybe<Scalars['String']['output']>
  signatureUrl?: Maybe<Scalars['String']['output']>
}

export type PersonInfo = {
  __typename?: 'PersonInfo'
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  photo?: Maybe<Scalars['String']['output']>
  photoThumbnail?: Maybe<Scalars['String']['output']>
}

export type PersonNameInfo = {
  __typename?: 'PersonNameInfo'
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
}

export type PersonNameInfoInput = {
  id: Scalars['ID']['input']
}

export type PersonShortDto = {
  __typename?: 'PersonShortDto'
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
}

export type PersonShorten = {
  __typename?: 'PersonShorten'
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  mobilePhone?: Maybe<Scalars['String']['output']>
  photo?: Maybe<Scalars['String']['output']>
  photoThumbnail?: Maybe<Scalars['String']['output']>
}

export type PersonShortenWithAlertDto = {
  __typename?: 'PersonShortenWithAlertDto'
  alertColorId?: Maybe<Scalars['ID']['output']>
  alertText?: Maybe<Scalars['String']['output']>
  businessId?: Maybe<Scalars['ID']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  mobilePhone?: Maybe<Scalars['String']['output']>
  photo?: Maybe<Scalars['String']['output']>
  photoThumbnail?: Maybe<Scalars['String']['output']>
}

export type PetProfileMatch = {
  __typename?: 'PetProfileMatch'
  chewyPet: ChewyPet
  rhapsodyPatient: PatientSummary
}

export type PetProfileMatchInput = {
  chewyPet: ChewyPetInput
  rhapsodyPatient: PatientSummaryInput
}

export enum PlanType {
  /** @deprecated Field no longer supported */
  Addon = 'ADDON',
  Base = 'BASE',
  Extra = 'EXTRA',
  Package = 'PACKAGE',
  Tiered = 'TIERED',
}

export type Point = {
  __typename?: 'Point'
  latitude?: Maybe<Scalars['Float']['output']>
  longitude?: Maybe<Scalars['Float']['output']>
}

export type PointInput = {
  latitude?: InputMaybe<Scalars['Float']['input']>
  longitude?: InputMaybe<Scalars['Float']['input']>
}

export type PostInvoiceInput = {
  additionalDiscount: Scalars['BigDecimal']['input']
  chargeSheetId: Scalars['ID']['input']
  clientBalance: Scalars['BigDecimal']['input']
  discountReasonId?: InputMaybe<Scalars['ID']['input']>
  discountReasonName?: InputMaybe<Scalars['String']['input']>
  expectedModificationDate: Scalars['DateTime']['input']
  itemsToPost: Array<LineItemPostDataInput>
  override: Scalars['Boolean']['input']
  postedById: Scalars['ID']['input']
  retailItemsToPost?: Array<LineItemPostDataInput>
  showBalance: Scalars['Boolean']['input']
}

export type Prescription = TimelineEntry & {
  __typename?: 'Prescription'
  administrationCourseLength?: Maybe<Scalars['BigDecimal']['output']>
  administrationCourseType?: Maybe<AdministrationCourseType>
  /** @deprecated Use administrationRoute */
  administrationDeliveryMethod?: Maybe<ConstantEntity>
  administrationFrequency?: Maybe<ConstantEntity>
  administrationFrequencyNotes?: Maybe<Scalars['String']['output']>
  administrationQuantity?: Maybe<Scalars['BigDecimal']['output']>
  administrationQuantityCustomUnit?: Maybe<Scalars['String']['output']>
  administrationQuantityUnit?: Maybe<ConstantEntity>
  administrationRoute?: Maybe<ConstantEntity>
  business?: Maybe<Business>
  businessId: Scalars['ID']['output']
  categoryId?: Maybe<Scalars['ID']['output']>
  client?: Maybe<Client>
  /** @deprecated Use client.id instead */
  clientId?: Maybe<Scalars['ID']['output']>
  compoundingReason?: Maybe<Scalars['String']['output']>
  compoundingReasonState?: Maybe<ConstantEntity>
  createdAt: Scalars['DateTime']['output']
  deleted: Scalars['Boolean']['output']
  doctor?: Maybe<Employee>
  /** @deprecated Use doctor.id instead */
  doctorId?: Maybe<Scalars['ID']['output']>
  drugExpirationDate?: Maybe<Scalars['DateTime']['output']>
  drugInfo?: Maybe<PrescriptionDrugInfo>
  /** @deprecated Use drugInfo { masterName } */
  drugMasterName?: Maybe<Scalars['String']['output']>
  /** @deprecated Use drugInfo { name } */
  drugVariationName?: Maybe<Scalars['String']['output']>
  globalFoodCatalogId?: Maybe<Scalars['ID']['output']>
  globalFoodCatalogVariationId?: Maybe<Scalars['ID']['output']>
  globalInventoryId?: Maybe<Scalars['ID']['output']>
  globalInventoryMapping?: Maybe<InventoryCatalogItem>
  globalVariationId?: Maybe<Scalars['ID']['output']>
  globalVariationMapping?: Maybe<GlobalItemVariation>
  id: Scalars['ID']['output']
  inventoryId?: Maybe<Scalars['ID']['output']>
  mismatchWarning?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  numberRefills?: Maybe<Scalars['Int']['output']>
  orderNumber?: Maybe<Scalars['String']['output']>
  origin?: Maybe<RxPrescriptionOrigin>
  partNumber?: Maybe<Scalars['String']['output']>
  patient?: Maybe<Patient>
  /** @deprecated Use patient.id instead */
  patientId?: Maybe<Scalars['ID']['output']>
  pdfUrl?: Maybe<Scalars['String']['output']>
  prescribedDate: Scalars['DateTime']['output']
  price?: Maybe<Price>
  /** @deprecated Use drugInfo { form } */
  productForm?: Maybe<ConstantEntity>
  quantityPerRefill?: Maybe<Scalars['BigDecimal']['output']>
  quantityPerRefillCustomUnit?: Maybe<Scalars['String']['output']>
  quantityPerRefillUnit?: Maybe<ConstantEntity>
  refillExpirationDate?: Maybe<Scalars['DateTime']['output']>
  refills?: Maybe<Array<Maybe<Prescription>>>
  refillsLeft?: Maybe<Scalars['Int']['output']>
  rejectionReason?: Maybe<Scalars['String']['output']>
  retailOrderLineItem?: Maybe<RetailOrderLineItem>
  rxNo?: Maybe<Scalars['String']['output']>
  rxType?: Maybe<RxType>
  signatureUrl?: Maybe<Scalars['String']['output']>
  signedDate?: Maybe<Scalars['DateTime']['output']>
  signer?: Maybe<Employee>
  soapId?: Maybe<Scalars['ID']['output']>
  state?: Maybe<ConstantEntity>
  timelineDate?: Maybe<Scalars['DateTime']['output']>
  totalQuantity?: Maybe<Scalars['BigDecimal']['output']>
  totalQuantityCustomUnit?: Maybe<Scalars['String']['output']>
  totalQuantityUnit?: Maybe<ConstantEntity>
  type?: Maybe<PrescriptionType>
  updatedAt: Scalars['DateTime']['output']
}

export type PrescriptionAttachmentDto = {
  message?: InputMaybe<Scalars['String']['input']>
  orderId: Scalars['ID']['input']
}

export type PrescriptionDrugInfo = {
  __typename?: 'PrescriptionDrugInfo'
  compounded?: Maybe<Scalars['Boolean']['output']>
  count?: Maybe<Scalars['Int']['output']>
  customCompound?: Maybe<Scalars['Boolean']['output']>
  customForm?: Maybe<Scalars['String']['output']>
  deliveryMethod?: Maybe<DrugDeliveryMethodEntity>
  description?: Maybe<Scalars['String']['output']>
  flavor?: Maybe<Scalars['String']['output']>
  form?: Maybe<ConstantEntity>
  masterName?: Maybe<Scalars['String']['output']>
  packageType?: Maybe<ConstantEntity>
  perPackageAmount?: Maybe<Scalars['BigDecimal']['output']>
  perPackageUnits?: Maybe<ConstantEntity>
  strength?: Maybe<Scalars['BigDecimal']['output']>
  strengthColor?: Maybe<ConstantEntity>
  strengthUnits?: Maybe<ConstantEntity>
  variationName?: Maybe<Scalars['String']['output']>
}

export type PrescriptionEmailDto = {
  message: Scalars['String']['input']
  orderId: Scalars['ID']['input']
  recipients: Array<RecipientDto>
  subject: Scalars['String']['input']
}

export type PrescriptionPage = Page & {
  __typename?: 'PrescriptionPage'
  data: Array<Prescription>
  totalCount: Scalars['Int']['output']
}

export enum PrescriptionType {
  Chewy = 'CHEWY',
  InHouse = 'IN_HOUSE',
  ScriptOut = 'SCRIPT_OUT',
}

export type PreviewListItemDto = {
  __typename?: 'PreviewListItemDto'
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  prices?: Maybe<Array<Maybe<TempPriceDto>>>
}

export type PreviewListItemPage = Page & {
  __typename?: 'PreviewListItemPage'
  data: Array<PreviewListItemDto>
  totalCount: Scalars['Int']['output']
}

export type Price = {
  __typename?: 'Price'
  active: Scalars['Boolean']['output']
  businessId: Scalars['ID']['output']
  category?: Maybe<PriceCategory>
  createdAt: Scalars['DateTime']['output']
  currency?: Maybe<ConstantEntity>
  deleted: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  price?: Maybe<Scalars['BigDecimal']['output']>
  subCategory?: Maybe<Scalars['String']['output']>
  unit?: Maybe<ConstantEntity>
  updatedAt: Scalars['DateTime']['output']
}

export enum PriceCategory {
  Inventory = 'INVENTORY',
  LabTest = 'LAB_TEST',
  Procedure = 'PROCEDURE',
}

export type PriceDto = {
  __typename?: 'PriceDto'
  genderId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  maxTimeUnitSize?: Maybe<Scalars['BigDecimal']['output']>
  minTimeUnitSize?: Maybe<Scalars['BigDecimal']['output']>
  name?: Maybe<Scalars['String']['output']>
  packageTypeId?: Maybe<Scalars['ID']['output']>
  perPackageUnitsId?: Maybe<Scalars['ID']['output']>
  priceUnitsSize?: Maybe<Scalars['BigDecimal']['output']>
  speciesId?: Maybe<Scalars['ID']['output']>
  timeUnitId?: Maybe<Scalars['ID']['output']>
  timeUnitSize?: Maybe<Scalars['BigDecimal']['output']>
  /** @deprecated Field no longer supported */
  unit?: Maybe<Scalars['String']['output']>
  unitId: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
  value?: Maybe<Scalars['BigDecimal']['output']>
}

export type PricePage = Page & {
  __typename?: 'PricePage'
  data: Array<Price>
  totalCount: Scalars['Int']['output']
}

export type PriceType = {
  __typename?: 'PriceType'
  displayName?: Maybe<Scalars['String']['output']>
  displayName2?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  name?: Maybe<Scalars['String']['output']>
  shortName?: Maybe<Scalars['String']['output']>
}

export type PricedLabTest = {
  __typename?: 'PricedLabTest'
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  prices?: Maybe<Array<Maybe<PriceDto>>>
  typeId?: Maybe<Scalars['ID']['output']>
}

export type PricedLabTestPage = Page & {
  __typename?: 'PricedLabTestPage'
  data: Array<PricedLabTest>
  totalCount: Scalars['Int']['output']
}

export type PricedProcedurePage = Page & {
  __typename?: 'PricedProcedurePage'
  data: Array<ProcedureListDto>
  totalCount: Scalars['Int']['output']
}

export type ProblemBodySystemLogContainer = {
  __typename?: 'ProblemBodySystemLogContainer'
  bodySystemLogs?: Maybe<Array<Maybe<ProblemBodySystemLogCrcContainer>>>
  showAddedProblemLogsToast?: Maybe<Scalars['Boolean']['output']>
}

export type ProblemBodySystemLogCrcContainer = {
  __typename?: 'ProblemBodySystemLogCrcContainer'
  crc?: Maybe<Scalars['Long']['output']>
  entity?: Maybe<EnrichedProblemBodySystemLog>
}

export type ProblemCatalog = {
  __typename?: 'ProblemCatalog'
  enums: Array<ProblemEnum>
  problems: Array<ProblemNode>
}

export type ProblemCatalogScalar = {
  __typename?: 'ProblemCatalogScalar'
  catalog: Scalars['JSON']['output']
}

export type ProblemEnum = {
  __typename?: 'ProblemEnum'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  nameTranslation: Scalars['String']['output']
  order?: Maybe<Scalars['ID']['output']>
  values?: Maybe<Array<Maybe<ProblemEnum>>>
}

export type ProblemEnumLog = {
  __typename?: 'ProblemEnumLog'
  creationDate?: Maybe<Scalars['DateTime']['output']>
  creatorId?: Maybe<Scalars['ID']['output']>
  enumId?: Maybe<Scalars['ID']['output']>
  enumValueId?: Maybe<Scalars['ID']['output']>
  id?: Maybe<Scalars['ID']['output']>
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  modifierId?: Maybe<Scalars['ID']['output']>
  notes?: Maybe<Scalars['String']['output']>
  parentEnumValueId?: Maybe<Scalars['ID']['output']>
  problemLogId?: Maybe<Scalars['ID']['output']>
}

export type ProblemEnumLogCrcContainer = {
  __typename?: 'ProblemEnumLogCrcContainer'
  crc?: Maybe<Scalars['Long']['output']>
  entity?: Maybe<ProblemEnumLog>
}

export type ProblemLog = TimelineEntry & {
  __typename?: 'ProblemLog'
  bodySystemId?: Maybe<Scalars['ID']['output']>
  bodySystemLogNotes?: Maybe<Scalars['String']['output']>
  bodySystemLogStateId?: Maybe<Scalars['ID']['output']>
  businessId: Scalars['ID']['output']
  creationDate: Scalars['DateTime']['output']
  enums?: Maybe<Array<Maybe<ProblemEnumLog>>>
  id: Scalars['ID']['output']
  modificationDate: Scalars['DateTime']['output']
  name?: Maybe<Scalars['String']['output']>
  problemLogNotes?: Maybe<Scalars['String']['output']>
  soapId?: Maybe<Scalars['ID']['output']>
  stateId?: Maybe<Scalars['ID']['output']>
  timelineDate?: Maybe<Scalars['DateTime']['output']>
  vetId?: Maybe<Scalars['ID']['output']>
}

export type ProblemLogContainer = {
  __typename?: 'ProblemLogContainer'
  problemLog?: Maybe<ProblemLogCrcContainer>
}

export type ProblemLogCrcContainer = {
  __typename?: 'ProblemLogCrcContainer'
  crc?: Maybe<Scalars['Long']['output']>
  entity?: Maybe<EnrichedProblemLog>
}

export type ProblemNode = {
  __typename?: 'ProblemNode'
  bodySystemId: Scalars['ID']['output']
  breadcrumb?: Maybe<Scalars['String']['output']>
  children: Array<ProblemNode>
  chipOrder?: Maybe<Scalars['Int']['output']>
  enums: Array<ProblemToEnum>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  nameTranslation: Scalars['String']['output']
  onlDisabled?: Maybe<Scalars['Boolean']['output']>
  primaryId?: Maybe<Scalars['ID']['output']>
  removed?: Maybe<Scalars['Boolean']['output']>
  secondaryId?: Maybe<Scalars['ID']['output']>
  tertiaryId?: Maybe<Scalars['ID']['output']>
  type?: Maybe<ProblemType>
}

export type ProblemSearchDto = {
  __typename?: 'ProblemSearchDto'
  bodySystemId: Scalars['ID']['output']
  breadcrumb?: Maybe<Scalars['String']['output']>
  breadcrumbTranslation?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  nameTranslation: Scalars['String']['output']
  onlDisabled?: Maybe<Scalars['Boolean']['output']>
  primaryId?: Maybe<Scalars['ID']['output']>
  secondaryId?: Maybe<Scalars['ID']['output']>
  synonym?: Maybe<Scalars['String']['output']>
  synonymTranslation?: Maybe<Scalars['String']['output']>
  tertiaryId?: Maybe<Scalars['ID']['output']>
  type?: Maybe<ProblemType>
}

export type ProblemSearchPage = Page & {
  __typename?: 'ProblemSearchPage'
  data: Array<ProblemSearchDto>
  totalCount: Scalars['Int']['output']
}

export type ProblemState = NamedEntity & {
  __typename?: 'ProblemState'
  createdAt: Scalars['DateTime']['output']
  diagnosisState: Scalars['Boolean']['output']
  findingState: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  nameTranslation: Scalars['String']['output']
  order: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ProblemToEnum = {
  __typename?: 'ProblemToEnum'
  id: Scalars['ID']['output']
  order?: Maybe<Scalars['Int']['output']>
  parentEnumId?: Maybe<Scalars['ID']['output']>
}

export enum ProblemType {
  D = 'D',
  F = 'F',
}

export type ProcedureListDto = {
  __typename?: 'ProcedureListDto'
  genderId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  prices?: Maybe<Array<Maybe<PriceDto>>>
  speciesIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
}

export type Query = {
  __typename?: 'Query'
  activePriceBulkUpdateSession?: Maybe<SessionDto>
  activeRxWorkflowAllowance: ActiveRxAllowance
  allClientTasks: TaskPage
  allPaymentEntities: PaymentEntityPage
  allPendingActiveRxForSoap?: Maybe<Array<Maybe<Prescription>>>
  appointment: Appointment
  appointmentByConfirmationToken: Appointment
  appointmentReasons: Array<AppointmentReason>
  appointments: AppointmentPage
  appointmentsByDate: Array<Appointment>
  assignableEmployees: Array<Employee>
  business: Business
  businessAppointmentTypes?: Maybe<BusinessAppointmentTypePage>
  businesses: Array<Maybe<Business>>
  busyTime: BusyTime
  busyTimes: BusyTimePage
  calculateRefundAmount: RefundCalculation
  chargeSheet: ChargeSheet
  chargeSheetItemsCount: Scalars['Int']['output']
  charges: Charges
  chargesByLog?: Maybe<Charges>
  chargesList: GroupedLineItems
  chatToken?: Maybe<Scalars['String']['output']>
  chats: ChatPage
  client: Client
  clients: ClientPage
  constants: Constants
  conversation?: Maybe<ConversationDto>
  conversationAttachments?: Maybe<Array<Maybe<Attachment>>>
  conversations?: Maybe<NotificationPage>
  disableSoapToastShowing?: Maybe<Scalars['ID']['output']>
  employee: Employee
  employees: EmployeePage
  findExistingClients: Array<Maybe<Client>>
  generatedMessageConfig: GeneratedMessageConfig
  generatedMessagePreview: MessagePreview
  getAllActiveRxForSoap: Array<Prescription>
  getAllPendingActiveRxForChargeSheet: Array<Prescription>
  getEstimateList?: Maybe<EstimatePage>
  getEstimates?: Maybe<EstimatePage>
  getInRoomSpaceMonitor: SpaceMonitor
  /** @deprecated use query globalItemVariations(ids: [ID!]!): [GlobalItemVariation] */
  globalItemVariation: GlobalItemVariation
  globalItemVariations?: Maybe<Array<Maybe<GlobalItemVariation>>>
  groupRoleIds: Array<Maybe<Scalars['ID']['output']>>
  historyProblemLogs?: Maybe<HistoryProblemLogPage>
  inventoryPreview?: Maybe<PreviewListItemPage>
  invoice: Invoice
  invoiceDiscountReason: InvoiceDiscountReasonSettings
  invoiceLineItem: InvoiceLineItem
  invoiceLineItemByLog?: Maybe<InvoiceLineItem>
  invoiceLineItems: InvoiceLineItemPage
  invoices: InvoicePage
  invoicesByClient: InvoicePage
  invoicesByClientAndStates: InvoiceEntityPage
  invoicesEntities: InvoiceEntityPage
  labTestLogFileGroups: Array<LabTestLogFileGroup>
  labTestLogFileGroupsByPatientAndBusinesses: Array<LabTestLogFileGroup>
  labTestsPreview?: Maybe<PreviewListItemPage>
  latestAppointmentIfUnrated?: Maybe<Appointment>
  matchChewyPets: ChewyPetMatchData
  matchChewyUserByEmail?: Maybe<Array<Maybe<UserMatch>>>
  matchChewyUserByPersonId?: Maybe<Array<Maybe<UserMatch>>>
  me?: Maybe<Client>
  messagesForConversation?: Maybe<MessagePage>
  orderFilters: Array<OrderFilter>
  patient: Patient
  patientWaitlistStatuses: Array<PatientWaitlistStatus>
  patients: PatientPage
  payment: Payment
  payments: PaymentPage
  paymentsByClient: PaymentPage
  pendingActiveRxForOutstandingFinalization: OutstandingFinalizationStates
  pendingIdexxImagingForOutstandingFinalization: OutstandingFinalizationStates
  pendingLabTestsForOutstandingFinalization: OutstandingFinalizationStates
  pendingsForOutstandingFinalization: OutstandingFinalizationStates
  pendingsForOutstandingFinalizationWhenPostingInvoice: OutstandingFinalizationStates
  prescription: Prescription
  prescriptions: PrescriptionPage
  price: Price
  pricedInventoryVariations?: Maybe<BusinessVariationListDtoPage>
  pricedLabTests?: Maybe<PricedLabTestPage>
  pricedProcedures?: Maybe<PricedProcedurePage>
  prices: PricePage
  problemCatalog: ProblemCatalog
  problemCatalogScalar: ProblemCatalogScalar
  problemLogContainer?: Maybe<ProblemBodySystemLogContainer>
  procedurePreview?: Maybe<PreviewListItemPage>
  pushNotificationsRegistrationTokens: Array<Scalars['String']['output']>
  questions: Array<Question>
  reactiveRxUnlinkedClientDetails?: Maybe<RxUnlinkedClientDetails>
  refundInvoice: RefundInvoice
  reminder: Reminder
  reminderProtocols: ReminderProtocolPage
  reminderSettings: ReminderBusinessSettings
  reminders: ReminderPage
  resultOfReportGeneration?: Maybe<ReportGenerationLogDto>
  retailOrderLineItem: RetailOrderLineItem
  schedule: Schedule
  search: Search
  searchProblems: ProblemSearchPage
  soapActiveTemplate?: Maybe<SoapTemplate>
  soapFile?: Maybe<SoapFile>
  soapFileGroup?: Maybe<SoapFileGroup>
  soapFileGroups: Array<SoapFileGroup>
  soapTemplate?: Maybe<SoapTemplate>
  space?: Maybe<Space>
  ssoIdpSettings?: Maybe<SsoIdpSettings>
  task: Task
  taskTemplates: Array<TaskTemplate>
  tasks: TaskPage
  tasksForDate: TaskPage
  tasksMonthlySchedule: Array<Scalars['ID']['output']>
  templatePreview?: Maybe<ReminderTemplateSample>
  templateSample: ReminderTemplateSample
  timeline: TimelinePage
  unappliedPaymentsByClient: PaymentPage
  uncoveredSoapTemplateWidgets: Array<StaticSoapWidget>
  unpaidInvoices: InvoiceEntityPage
  upcomingClientTasks: TaskPage
  vaccinationDetails?: Maybe<VaccinationDetails>
  vaccinationHistoryDetails?: Maybe<VaccinationHistoryDetails>
  vital: Vital
  vitals: EnrichedVitalsPage
  wellnessPlanPaymentMethod: WPaymentMethod
  wellnessPlanPaymentMethods?: Maybe<WPaymentMethodPage>
  wellnessPlanPayments?: Maybe<WPaymentPage>
}

export type QueryActivePriceBulkUpdateSessionArgs = {
  entityType: EntityType
}

export type QueryActiveRxWorkflowAllowanceArgs = {
  businessId: Scalars['ID']['input']
  clientId: Scalars['ID']['input']
  patientId: Scalars['ID']['input']
}

export type QueryAllClientTasksArgs = {
  clientId: Scalars['ID']['input']
  inDoneState?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  taskStateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type QueryAllPaymentEntitiesArgs = {
  businessId: Scalars['ID']['input']
  clientId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
}

export type QueryAllPendingActiveRxForSoapArgs = {
  soapId: Scalars['ID']['input']
}

export type QueryAppointmentArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryAppointmentByConfirmationTokenArgs = {
  confirmationToken: Scalars['String']['input']
}

export type QueryAppointmentReasonsArgs = {
  businessId: Scalars['ID']['input']
}

export type QueryAppointmentsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  scheduledFrom?: InputMaybe<Scalars['DateTime']['input']>
  scheduledTo?: InputMaybe<Scalars['DateTime']['input']>
  updatedFrom?: InputMaybe<Scalars['DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryAppointmentsByDateArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>
  timezone?: InputMaybe<Scalars['String']['input']>
}

export type QueryAssignableEmployeesArgs = {
  availabilityFromDate: Scalars['DateTime']['input']
  availabilityToDate: Scalars['DateTime']['input']
  eventTypeId: Scalars['ID']['input']
}

export type QueryBusinessArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type QueryBusinessAppointmentTypesArgs = {
  businessId: Scalars['ID']['input']
  searchFilter?: InputMaybe<BusinessAppointmentTypeSearchInput>
}

export type QueryBusyTimeArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryBusyTimesArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  scheduledFrom?: InputMaybe<Scalars['DateTime']['input']>
  scheduledTo?: InputMaybe<Scalars['DateTime']['input']>
  updatedFrom?: InputMaybe<Scalars['DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryCalculateRefundAmountArgs = {
  input: RefundInvoiceInput
}

export type QueryChargeSheetArgs = {
  clientId: Scalars['ID']['input']
}

export type QueryChargeSheetItemsCountArgs = {
  clientId: Scalars['ID']['input']
}

export type QueryChargesArgs = {
  clientId: Scalars['ID']['input']
  soapId: Scalars['ID']['input']
}

export type QueryChargesByLogArgs = {
  logId: Scalars['ID']['input']
  logType: BillableEntityType
}

export type QueryChargesListArgs = {
  soapId: Scalars['ID']['input']
}

export type QueryChatsArgs = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type QueryClientArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryClientsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  updatedFrom?: InputMaybe<Scalars['DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryConversationArgs = {
  conversationId: Scalars['ID']['input']
}

export type QueryConversationAttachmentsArgs = {
  conversationId: Scalars['ID']['input']
}

export type QueryConversationsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  searchCriteria?: InputMaybe<SearchCriteria>
}

export type QueryDisableSoapToastShowingArgs = {
  soapId: Scalars['ID']['input']
}

export type QueryEmployeeArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryEmployeesArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  updatedFrom?: InputMaybe<Scalars['DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryFindExistingClientsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type QueryGeneratedMessageConfigArgs = {
  input: GeneratedMessageConfigInput
}

export type QueryGeneratedMessagePreviewArgs = {
  input: GeneratedMessagePreviewInput
  print?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryGetAllActiveRxForSoapArgs = {
  soapId: Scalars['ID']['input']
}

export type QueryGetAllPendingActiveRxForChargeSheetArgs = {
  businessId: Scalars['ID']['input']
  clientId: Scalars['ID']['input']
}

export type QueryGetEstimateListArgs = {
  businessId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  patientId: Scalars['ID']['input']
  soapId: Scalars['ID']['input']
}

export type QueryGetEstimatesArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  patientId?: InputMaybe<Scalars['ID']['input']>
  soapId?: InputMaybe<Scalars['ID']['input']>
  stateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type QueryGetInRoomSpaceMonitorArgs = {
  spaceId: Scalars['ID']['input']
}

export type QueryGlobalItemVariationArgs = {
  id: Scalars['ID']['input']
}

export type QueryGlobalItemVariationsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type QueryGroupRoleIdsArgs = {
  groupId: Scalars['ID']['input']
}

export type QueryHistoryProblemLogsArgs = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
  patientId: Scalars['ID']['input']
  problemId: Scalars['ID']['input']
  soapId: Scalars['ID']['input']
}

export type QueryInventoryPreviewArgs = {
  from: Scalars['Int']['input']
  sessionId: Scalars['ID']['input']
  to: Scalars['Int']['input']
}

export type QueryInvoiceArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryInvoiceDiscountReasonArgs = {
  businessId: Scalars['ID']['input']
}

export type QueryInvoiceLineItemArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryInvoiceLineItemByLogArgs = {
  logId: Scalars['ID']['input']
  logType: BillableEntityType
}

export type QueryInvoiceLineItemsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  updatedFrom?: InputMaybe<Scalars['DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryInvoicesArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  updatedFrom?: InputMaybe<Scalars['DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryInvoicesByClientArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  updatedFrom?: InputMaybe<Scalars['DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryInvoicesByClientAndStatesArgs = {
  input?: InputMaybe<InvoiceSearchInput>
}

export type QueryInvoicesEntitiesArgs = {
  input?: InputMaybe<InvoiceEntitySearchInput>
}

export type QueryLabTestLogFileGroupsArgs = {
  labTestLogId: Scalars['ID']['input']
}

export type QueryLabTestLogFileGroupsByPatientAndBusinessesArgs = {
  businessIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  patientId: Scalars['ID']['input']
}

export type QueryLabTestsPreviewArgs = {
  from: Scalars['Int']['input']
  sessionId: Scalars['ID']['input']
  to: Scalars['Int']['input']
}

export type QueryMatchChewyPetsArgs = {
  id: Scalars['ID']['input']
}

export type QueryMatchChewyUserByEmailArgs = {
  email: Scalars['String']['input']
}

export type QueryMatchChewyUserByPersonIdArgs = {
  personId: Scalars['ID']['input']
}

export type QueryMessagesForConversationArgs = {
  conversationId: Scalars['ID']['input']
  from?: InputMaybe<Scalars['Int']['input']>
  to?: InputMaybe<Scalars['Int']['input']>
}

export type QueryOrderFiltersArgs = {
  clientId: Scalars['ID']['input']
  patientId: Scalars['ID']['input']
}

export type QueryPatientArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryPatientsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  updatedFrom?: InputMaybe<Scalars['DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryPaymentArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryPaymentsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  updatedFrom?: InputMaybe<Scalars['DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryPaymentsByClientArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
}

export type QueryPendingActiveRxForOutstandingFinalizationArgs = {
  clientId: Scalars['ID']['input']
}

export type QueryPendingIdexxImagingForOutstandingFinalizationArgs = {
  clientId: Scalars['ID']['input']
  invoiceId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryPendingLabTestsForOutstandingFinalizationArgs = {
  clientId: Scalars['ID']['input']
  invoiceId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryPendingsForOutstandingFinalizationArgs = {
  clientId: Scalars['ID']['input']
}

export type QueryPendingsForOutstandingFinalizationWhenPostingInvoiceArgs = {
  clientId: Scalars['ID']['input']
  sectionIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryPrescriptionArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryPrescriptionsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  updatedFrom?: InputMaybe<Scalars['DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryPriceArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryPricedInventoryVariationsArgs = {
  categoryId: Scalars['ID']['input']
  from: Scalars['Int']['input']
  subcategoryId: Scalars['ID']['input']
  to: Scalars['Int']['input']
}

export type QueryPricedLabTestsArgs = {
  from: Scalars['Int']['input']
  labVendorId: Scalars['ID']['input']
  to: Scalars['Int']['input']
}

export type QueryPricedProceduresArgs = {
  categoryId: Scalars['ID']['input']
  from: Scalars['Int']['input']
  to: Scalars['Int']['input']
}

export type QueryPricesArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  category?: InputMaybe<PriceCategory>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  updatedFrom?: InputMaybe<Scalars['DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryProblemCatalogArgs = {
  patientId: Scalars['ID']['input']
}

export type QueryProblemCatalogScalarArgs = {
  patientId: Scalars['ID']['input']
}

export type QueryProblemLogContainerArgs = {
  soapId: Scalars['ID']['input']
}

export type QueryProcedurePreviewArgs = {
  from: Scalars['Int']['input']
  sessionId: Scalars['ID']['input']
  to: Scalars['Int']['input']
}

export type QueryPushNotificationsRegistrationTokensArgs = {
  clientId: Scalars['ID']['input']
}

export type QueryQuestionsArgs = {
  appointmentId: Scalars['ID']['input']
}

export type QueryReactiveRxUnlinkedClientDetailsArgs = {
  prescriptionId: Scalars['ID']['input']
}

export type QueryRefundInvoiceArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type QueryReminderArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryReminderProtocolsArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  orderItemOptions?: InputMaybe<Array<OrderItemOptionsInput>>
}

export type QueryReminderSettingsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryRemindersArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  patientId?: InputMaybe<Scalars['ID']['input']>
  scheduledFrom?: InputMaybe<Scalars['DateTime']['input']>
  scheduledTo?: InputMaybe<Scalars['DateTime']['input']>
  stateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  updatedFrom?: InputMaybe<Scalars['DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryResultOfReportGenerationArgs = {
  patientId?: InputMaybe<Scalars['ID']['input']>
  soapId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryRetailOrderLineItemArgs = {
  id: Scalars['ID']['input']
}

export type QuerySearchProblemsArgs = {
  limit: Scalars['Int']['input']
  locale: Scalars['String']['input']
  offset: Scalars['Int']['input']
  patientId?: InputMaybe<Scalars['ID']['input']>
  query: Scalars['String']['input']
}

export type QuerySoapActiveTemplateArgs = {
  businessId: Scalars['ID']['input']
}

export type QuerySoapFileArgs = {
  id: Scalars['ID']['input']
}

export type QuerySoapFileGroupArgs = {
  groupId: Scalars['ID']['input']
}

export type QuerySoapFileGroupsArgs = {
  businessIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  patientId: Scalars['ID']['input']
}

export type QuerySoapTemplateArgs = {
  id: Scalars['ID']['input']
}

export type QuerySpaceArgs = {
  id: Scalars['ID']['input']
}

export type QueryTaskArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryTasksArgs = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>
  assigneeId?: InputMaybe<Scalars['ID']['input']>
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  clientTask?: InputMaybe<Scalars['Boolean']['input']>
  dueDatetimeRange?: InputMaybe<DateTimeRangeInput>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  orderBy?: InputMaybe<TaskOrderByInput>
  patientId?: InputMaybe<Scalars['ID']['input']>
  stateIds?: InputMaybe<Array<Scalars['ID']['input']>>
  typeIds?: InputMaybe<Array<Scalars['ID']['input']>>
  updatedDatetimeRange?: InputMaybe<DateTimeRangeInput>
}

export type QueryTasksForDateArgs = {
  day: Scalars['DateTime']['input']
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
  timezone?: InputMaybe<Scalars['String']['input']>
}

export type QueryTasksMonthlyScheduleArgs = {
  month: Scalars['Int']['input']
  timezone: Scalars['String']['input']
  year: Scalars['Int']['input']
}

export type QueryTemplatePreviewArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  template?: InputMaybe<ReminderCommunicationTemplatePreviewInput>
}

export type QueryTemplateSampleArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  templateId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryTimelineArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  patientId: Scalars['ID']['input']
  sortDesc?: InputMaybe<Scalars['Boolean']['input']>
  to?: InputMaybe<Scalars['DateTime']['input']>
  types: Array<TimelineType>
}

export type QueryUnappliedPaymentsByClientArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
}

export type QueryUncoveredSoapTemplateWidgetsArgs = {
  searchFilter?: InputMaybe<SoapWidgetUncoveredContentFilterInput>
}

export type QueryUnpaidInvoicesArgs = {
  businessId: Scalars['ID']['input']
  clientId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
}

export type QueryUpcomingClientTasksArgs = {
  clientId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryVaccinationDetailsArgs = {
  procedureLogId: Scalars['ID']['input']
}

export type QueryVaccinationHistoryDetailsArgs = {
  historyId: Scalars['ID']['input']
  patientId: Scalars['ID']['input']
}

export type QueryVitalArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryVitalsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  patientId: Scalars['ID']['input']
}

export type QueryWellnessPlanPaymentMethodArgs = {
  id: Scalars['ID']['input']
}

export type QueryWellnessPlanPaymentMethodsArgs = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type QueryWellnessPlanPaymentsArgs = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
  patientId?: InputMaybe<Scalars['ID']['input']>
}

export type Question = {
  __typename?: 'Question'
  answers?: Maybe<Array<Maybe<Answer>>>
  id: Scalars['ID']['output']
  patient?: Maybe<Patient>
  questionText: Scalars['String']['output']
  rank?: Maybe<Scalars['Int']['output']>
}

export enum RxPrescriptionOrigin {
  Customer = 'CUSTOMER',
  Vet = 'VET',
}

export enum RxType {
  MedicalPrescription = 'MEDICAL_PRESCRIPTION',
  VetDiet = 'VET_DIET',
}

export type Recipient = {
  __typename?: 'Recipient'
  businessId?: Maybe<Scalars['ID']['output']>
  clientId?: Maybe<Scalars['ID']['output']>
  contactId?: Maybe<Scalars['ID']['output']>
  email?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
  type?: Maybe<Type>
}

export type RecipientDto = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  contactId?: InputMaybe<Scalars['ID']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  patientId?: InputMaybe<Scalars['ID']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  recipientType: Type
}

export type RecipientOrigin = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  contactId?: InputMaybe<Scalars['ID']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  patientId?: InputMaybe<Scalars['ID']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  recipientType?: InputMaybe<Scalars['String']['input']>
}

export enum RecurrenceType {
  Chronic = 'CHRONIC',
  Once = 'ONCE',
  Repeat = 'REPEAT',
}

export enum ReferenceEntityType {
  Soap = 'SOAP',
}

export type RefundCalculation = {
  __typename?: 'RefundCalculation'
  additionalDiscount?: Maybe<Scalars['BigDecimal']['output']>
  amount: Scalars['BigDecimal']['output']
  originalInvoice: Invoice
  sections: Array<RefundChargesSectionCalculation>
  serviceFeeAmount: Scalars['BigDecimal']['output']
  subtotal: Scalars['BigDecimal']['output']
  totalDiscount: Scalars['BigDecimal']['output']
  totalTax: Scalars['BigDecimal']['output']
}

export type RefundChargesSection = ChargesEntity & {
  __typename?: 'RefundChargesSection'
  business: Business
  client: Client
  event?: Maybe<Appointment>
  groupedItems: Array<GroupedRefundItems>
  id?: Maybe<Scalars['ID']['output']>
  patient: Patient
  soap?: Maybe<Soap>
  subTotal?: Maybe<Scalars['BigDecimal']['output']>
}

export type RefundChargesSectionCalculation = {
  __typename?: 'RefundChargesSectionCalculation'
  business: Business
  client: Client
  event?: Maybe<Appointment>
  groupedItems: Array<GroupedRefundItemsCalculation>
  id?: Maybe<Scalars['ID']['output']>
  patient: Patient
  soap?: Maybe<Soap>
}

export type RefundInvoice = InvoiceEntity & {
  __typename?: 'RefundInvoice'
  additionalDiscount?: Maybe<Scalars['BigDecimal']['output']>
  amount: Scalars['BigDecimal']['output']
  amountNoFee: Scalars['BigDecimal']['output']
  business: Business
  client: Client
  creationDate: Scalars['DateTime']['output']
  currency: ConstantEntity
  id: Scalars['ID']['output']
  internalNote?: Maybe<Scalars['String']['output']>
  invoiceNo?: Maybe<Scalars['String']['output']>
  items: Array<RefundInvoiceLineItem>
  modificationDate: Scalars['DateTime']['output']
  originalInvoice: Invoice
  originalInvoiceId: Scalars['ID']['output']
  paidAmount: Scalars['BigDecimal']['output']
  paidAmountNoFee: Scalars['BigDecimal']['output']
  patients: Array<Patient>
  payments: Array<Payment>
  postedBy: Employee
  sections: Array<RefundChargesSection>
  serviceFeeAmount: Scalars['BigDecimal']['output']
  state: ConstantEntity
  subtotal: Scalars['BigDecimal']['output']
  totalDiscount: Scalars['BigDecimal']['output']
  totalTax: Scalars['BigDecimal']['output']
}

export type RefundInvoiceInput = {
  internalNote: Scalars['String']['input']
  items: Array<RefundInvoiceLineItemInput>
  originalInvoiceId: Scalars['ID']['input']
  originalInvoiceModificationDate: Scalars['DateTime']['input']
}

export type RefundInvoiceLineItem = InvoiceLineItemEntity & {
  __typename?: 'RefundInvoiceLineItem'
  additionalDiscount?: Maybe<Scalars['BigDecimal']['output']>
  business: Business
  client: Client
  discount?: Maybe<Scalars['BigDecimal']['output']>
  dispensingFee?: Maybe<Scalars['BigDecimal']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  originalLineItem: InvoiceLineItem
  patient?: Maybe<Patient>
  price?: Maybe<Scalars['BigDecimal']['output']>
  refundQty?: Maybe<Scalars['BigDecimal']['output']>
  refundReason: ConstantEntity
  refundedInvoice: RefundInvoice
  restockQty?: Maybe<Scalars['BigDecimal']['output']>
  restockedAsPackage?: Maybe<Scalars['Boolean']['output']>
  subTotal?: Maybe<Scalars['BigDecimal']['output']>
  taxAmount?: Maybe<Scalars['BigDecimal']['output']>
}

export type RefundInvoiceLineItemCalculation = {
  __typename?: 'RefundInvoiceLineItemCalculation'
  additionalDiscount?: Maybe<Scalars['BigDecimal']['output']>
  discount?: Maybe<Scalars['BigDecimal']['output']>
  dispensingFee?: Maybe<Scalars['BigDecimal']['output']>
  originalLineItem: InvoiceLineItem
  refundAmount?: Maybe<Scalars['BigDecimal']['output']>
  serviceFeeAmount?: Maybe<Scalars['BigDecimal']['output']>
  taxAmount?: Maybe<Scalars['BigDecimal']['output']>
}

export type RefundInvoiceLineItemInput = {
  originalLineItemId: Scalars['ID']['input']
  refundQty: Scalars['BigDecimal']['input']
  refundReasonId: Scalars['ID']['input']
  restockQty?: InputMaybe<Scalars['BigDecimal']['input']>
  restockedAsPackage?: InputMaybe<Scalars['Boolean']['input']>
}

export type Reminder = TimelineEntry & {
  __typename?: 'Reminder'
  businessId: Scalars['ID']['output']
  /** @deprecated clientId field is no longer supported for reminders, reminders are only tied up to patient */
  clientId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  deleted?: Maybe<Scalars['Boolean']['output']>
  dueDatetime: Scalars['DateTime']['output']
  expireDatetime: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  patient?: Maybe<Patient>
  patientId: Scalars['ID']['output']
  /** @deprecated scheduledStartDatetime field is no longer supported, use dueDatetime instead */
  scheduledStartDatetime: Scalars['DateTime']['output']
  state: ConstantEntity
  timelineDate?: Maybe<Scalars['DateTime']['output']>
  /** @deprecated type field is no longer supported */
  type: ConstantEntity
  updatedAt: Scalars['DateTime']['output']
}

export type ReminderBusinessSettings = {
  __typename?: 'ReminderBusinessSettings'
  active: Scalars['Boolean']['output']
  defaultCommunicationMessage?: Maybe<Scalars['String']['output']>
  defaultReminderExpirationOffset?: Maybe<DateOffset>
  templates?: Maybe<Array<Maybe<ReminderCommunicationTemplate>>>
}

export type ReminderBusinessSettingsInput = {
  active: Scalars['Boolean']['input']
  defaultCommunicationMessage?: InputMaybe<Scalars['String']['input']>
  defaultReminderExpirationOffset?: InputMaybe<DateOffsetInput>
  templates?: InputMaybe<Array<InputMaybe<ReminderCommunicationTemplateInput>>>
}

export type ReminderCommunicationTemplate = {
  __typename?: 'ReminderCommunicationTemplate'
  active: Scalars['Boolean']['output']
  communicationMethodIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
  dueDateOffset: DateOffset
  id: Scalars['ID']['output']
  message?: Maybe<Scalars['String']['output']>
  subject?: Maybe<Scalars['String']['output']>
  textMessage?: Maybe<Scalars['String']['output']>
}

export type ReminderCommunicationTemplateInput = {
  active: Scalars['Boolean']['input']
  communicationMethodIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  dueDateOffset?: InputMaybe<DateOffsetInput>
  id?: InputMaybe<Scalars['ID']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  subject?: InputMaybe<Scalars['String']['input']>
  textMessage?: InputMaybe<Scalars['String']['input']>
}

export type ReminderCommunicationTemplatePreviewInput = {
  message?: InputMaybe<Scalars['String']['input']>
  subject?: InputMaybe<Scalars['String']['input']>
  textMessage?: InputMaybe<Scalars['String']['input']>
}

export enum ReminderItemOptionType {
  Due = 'DUE',
  Resolve = 'RESOLVE',
  Trigger = 'TRIGGER',
}

export enum ReminderOrderItemType {
  Diagnosis = 'DIAGNOSIS',
  GlobalInventoryItem = 'GLOBAL_INVENTORY_ITEM',
  GlobalInventoryItemVariation = 'GLOBAL_INVENTORY_ITEM_VARIATION',
  InventoryItem = 'INVENTORY_ITEM',
  InventoryItemVariation = 'INVENTORY_ITEM_VARIATION',
  LabTest = 'LAB_TEST',
  Price = 'PRICE',
  Procedure = 'PROCEDURE',
}

export type ReminderPage = Page & {
  __typename?: 'ReminderPage'
  data: Array<Reminder>
  totalCount: Scalars['Int']['output']
}

export type ReminderProtocol = {
  __typename?: 'ReminderProtocol'
  active: Scalars['Boolean']['output']
  ageMax?: Maybe<Age>
  ageMin?: Maybe<Age>
  businessId: Scalars['ID']['output']
  createdById: Scalars['ID']['output']
  creationDate: Scalars['DateTime']['output']
  deleted: Scalars['Boolean']['output']
  dueDateOffset: ReminderTimeOffset
  expirationDateOffset: ReminderTimeOffset
  genderRestrictions?: Maybe<Array<Maybe<GenderRestriction>>>
  groupId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  modificationDate: Scalars['DateTime']['output']
  modifiedById: Scalars['ID']['output']
  orderItemOptions?: Maybe<Array<Maybe<OrderItemOptions>>>
  reminderName: Scalars['String']['output']
  speciesIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
  weightMax?: Maybe<Weight>
  weightMin?: Maybe<Weight>
}

export type ReminderProtocolPage = Page & {
  __typename?: 'ReminderProtocolPage'
  data: Array<ReminderProtocol>
  totalCount: Scalars['Int']['output']
}

export type ReminderTemplateSample = {
  __typename?: 'ReminderTemplateSample'
  email?: Maybe<Scalars['String']['output']>
  textMessage?: Maybe<Scalars['String']['output']>
}

export type ReminderTimeOffset = {
  __typename?: 'ReminderTimeOffset'
  amount: Scalars['Int']['output']
  unit: Scalars['String']['output']
}

export type ReportCardAttachmentDto = {
  message?: InputMaybe<Scalars['String']['input']>
  sections?: InputMaybe<Array<InputMaybe<ReportSectionDto>>>
  soapId: Scalars['ID']['input']
}

export type ReportCardAttachmentsSection = MessagingConfigSection &
  ReportCardSection & {
    __typename?: 'ReportCardAttachmentsSection'
    attachments: Array<GeneratedMessageAttachmentsSubsection>
    checked: Scalars['Boolean']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    section: ReportCardSectionType
    subcategory?: Maybe<ReportCardSubcategoryType>
  }

export type ReportCardBaseSection = MessagingConfigSection &
  ReportCardSection & {
    __typename?: 'ReportCardBaseSection'
    checked: Scalars['Boolean']['output']
    children: Array<ReportCardChildSection>
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    section: ReportCardSectionType
    subcategory?: Maybe<ReportCardSubcategoryType>
  }

export type ReportCardChildSection = MessagingConfigSection &
  ReportCardSection & {
    __typename?: 'ReportCardChildSection'
    checked: Scalars['Boolean']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    section: ReportCardSectionType
    subcategory?: Maybe<ReportCardSubcategoryType>
  }

export type ReportCardConfig = {
  __typename?: 'ReportCardConfig'
  message?: Maybe<Scalars['String']['output']>
  sections: Array<ReportCardSection>
  subject?: Maybe<Scalars['String']['output']>
}

export type ReportCardConfigInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>
  soapId: Scalars['ID']['input']
  to?: InputMaybe<Scalars['DateTime']['input']>
}

export type ReportCardEmailDto = {
  message?: InputMaybe<Scalars['String']['input']>
  recipients: Array<RecipientDto>
  sections?: InputMaybe<Array<InputMaybe<ReportSectionDto>>>
  soapId: Scalars['ID']['input']
  subject: Scalars['String']['input']
}

export type ReportCardPreviewInput = {
  message?: InputMaybe<Scalars['String']['input']>
  sections?: InputMaybe<Array<ReportCardSectionInput>>
  soapId: Scalars['ID']['input']
  subject: Scalars['String']['input']
}

export type ReportCardSection = {
  checked: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  section: ReportCardSectionType
  subcategory?: Maybe<ReportCardSubcategoryType>
}

export type ReportCardSectionInput = {
  attachments?: InputMaybe<Array<GeneratedMessageSectionAttachmentsInput>>
  section: ReportCardSectionType
}

export enum ReportCardSectionType {
  Alerts = 'ALERTS',
  AllReminders = 'ALL_REMINDERS',
  Attachments = 'ATTACHMENTS',
  ClientAndPatientDetails = 'CLIENT_AND_PATIENT_DETAILS',
  Details = 'DETAILS',
  Diagnoses = 'DIAGNOSES',
  DischargeNotes = 'DISCHARGE_NOTES',
  MedicalNotes = 'MEDICAL_NOTES',
  NextAppointment = 'NEXT_APPOINTMENT',
  PatientQuestionsAnswers = 'PATIENT_QUESTIONS_ANSWERS',
  PhysicalExamFindings = 'PHYSICAL_EXAM_FINDINGS',
  Prescriptions = 'PRESCRIPTIONS',
  ProductsAndServicesOrdered = 'PRODUCTS_AND_SERVICES_ORDERED',
  ReasonForVisit = 'REASON_FOR_VISIT',
  Summary = 'SUMMARY',
  UpcomingReminders = 'UPCOMING_REMINDERS',
  Vitals = 'VITALS',
}

export enum ReportCardSubcategoryType {
  Assessment = 'ASSESSMENT',
  Objective = 'OBJECTIVE',
  Plan = 'PLAN',
  Subjective = 'SUBJECTIVE',
}

export type ReportGenerationLogDto = {
  __typename?: 'ReportGenerationLogDto'
  actionType: ActionType
  downloadAttachment?: Maybe<DownloadAttachmentDto>
  errorMessage?: Maybe<Scalars['String']['output']>
  status: ReportGenerationStatus
}

export enum ReportGenerationStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
}

export type ReportSectionDto = {
  checked: Scalars['Boolean']['input']
  children?: InputMaybe<Array<InputMaybe<ReportSectionDto>>>
  id: Scalars['ID']['input']
  subcategory?: InputMaybe<Scalars['String']['input']>
}

export enum Request {
  Authorization = 'AUTHORIZATION',
  Payment = 'PAYMENT',
}

export type RetailOrder = {
  __typename?: 'RetailOrder'
  discountAmount: Scalars['BigDecimal']['output']
  id: Scalars['ID']['output']
  modificationDate: Scalars['DateTime']['output']
  orderConfirmationId?: Maybe<Scalars['String']['output']>
  orderDate?: Maybe<Scalars['DateTime']['output']>
  paymentDetails: Array<RetailPaymentDetail>
  retailDeliveryFee: Scalars['BigDecimal']['output']
  sections: Array<ChargesSection>
  shippingAddress?: Maybe<ShippingAddress>
  shippingAmount: Scalars['BigDecimal']['output']
  state: ConstantEntity
  subTotal: Scalars['BigDecimal']['output']
  taxAmount: Scalars['BigDecimal']['output']
  totalPrice: Scalars['BigDecimal']['output']
}

export type RetailOrderLineItem = InvoiceLineItemEntity & {
  __typename?: 'RetailOrderLineItem'
  appointment?: Maybe<Appointment>
  autoshipFrequency?: Maybe<Scalars['Int']['output']>
  autoshipUnit?: Maybe<ConstantEntity>
  business: Business
  client: Client
  createdById?: Maybe<Scalars['ID']['output']>
  creationDate: Scalars['DateTime']['output']
  declined: Scalars['Boolean']['output']
  discountAmount?: Maybe<Scalars['BigDecimal']['output']>
  id: Scalars['ID']['output']
  inventoryCategoryId?: Maybe<Scalars['ID']['output']>
  invoice: Invoice
  logId?: Maybe<Scalars['ID']['output']>
  logState?: Maybe<ConstantEntity>
  logType: Scalars['String']['output']
  modificationDate: Scalars['DateTime']['output']
  modifiedById?: Maybe<Scalars['ID']['output']>
  movedToCart?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  notes?: Maybe<Scalars['String']['output']>
  origin?: Maybe<RxPrescriptionOrigin>
  originalPrice?: Maybe<Scalars['BigDecimal']['output']>
  partNumber: Scalars['String']['output']
  patient: Patient
  prescriptionType?: Maybe<PrescriptionType>
  price: Scalars['BigDecimal']['output']
  producerId?: Maybe<Scalars['ID']['output']>
  quantity: Scalars['BigDecimal']['output']
  retailDeliveryFee?: Maybe<Scalars['BigDecimal']['output']>
  sectionId?: Maybe<Scalars['ID']['output']>
  shippingAmount?: Maybe<Scalars['BigDecimal']['output']>
  soap?: Maybe<Soap>
  subTotal: Scalars['BigDecimal']['output']
  taxAmount: Scalars['BigDecimal']['output']
  totalPrice?: Maybe<Scalars['BigDecimal']['output']>
  viewOrderNumber?: Maybe<Scalars['Int']['output']>
  viewSectionOrderNumber?: Maybe<Scalars['Int']['output']>
}

export type RetailOrderLineItemProducerInput = {
  expectedModification: Scalars['DateTime']['input']
  id: Scalars['ID']['input']
  producerId: Scalars['ID']['input']
}

export type RetailPaymentDetail = {
  __typename?: 'RetailPaymentDetail'
  amountPaid: Scalars['BigDecimal']['output']
  paymentMethod: PaymentMethod
}

export type RxInput = {
  administrationCourseLength?: InputMaybe<Scalars['BigDecimal']['input']>
  administrationCourseType?: InputMaybe<AdminCourseType>
  administrationFrequencyId?: InputMaybe<Scalars['ID']['input']>
  administrationFrequencyNotes?: InputMaybe<Scalars['String']['input']>
  administrationInstructionsAutofill?: InputMaybe<Scalars['Boolean']['input']>
  administrationQuantity?: InputMaybe<Scalars['BigDecimal']['input']>
  administrationQuantityCustomUnit?: InputMaybe<Scalars['String']['input']>
  administrationQuantityUnitId?: InputMaybe<Scalars['ID']['input']>
  administrationRouteId?: InputMaybe<Scalars['ID']['input']>
  autoshipFrequency?: InputMaybe<Scalars['Int']['input']>
  autoshipUnitId?: InputMaybe<Scalars['ID']['input']>
  businessId?: InputMaybe<Scalars['ID']['input']>
  catalogMismatch?: InputMaybe<Scalars['Boolean']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  clientTasks?: InputMaybe<ChewyPrescriptionClientTasksInput>
  compoundingReason?: InputMaybe<Scalars['String']['input']>
  compoundingReasonId?: InputMaybe<Scalars['ID']['input']>
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  discussedWithClient?: InputMaybe<Scalars['Boolean']['input']>
  doctorId?: InputMaybe<Scalars['ID']['input']>
  eventId?: InputMaybe<Scalars['ID']['input']>
  globalFoodCatalogId?: InputMaybe<Scalars['ID']['input']>
  globalFoodCatalogVariationId?: InputMaybe<Scalars['ID']['input']>
  globalInventoryId?: InputMaybe<Scalars['ID']['input']>
  globalInventoryMappingId?: InputMaybe<Scalars['ID']['input']>
  globalVariationId?: InputMaybe<Scalars['ID']['input']>
  globalVariationMappingId?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  inventoryId?: InputMaybe<Scalars['ID']['input']>
  logId?: InputMaybe<Scalars['Int']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  numberRefills?: InputMaybe<Scalars['Int']['input']>
  orderNumber?: InputMaybe<Scalars['String']['input']>
  parentId?: InputMaybe<Scalars['ID']['input']>
  partName?: InputMaybe<Scalars['String']['input']>
  partNumber?: InputMaybe<Scalars['String']['input']>
  patientId?: InputMaybe<Scalars['ID']['input']>
  pdfUrl?: InputMaybe<Scalars['String']['input']>
  price?: InputMaybe<Scalars['BigDecimal']['input']>
  quantity?: InputMaybe<Scalars['BigDecimal']['input']>
  quantityDrugExpirationDate?: InputMaybe<Scalars['DateTime']['input']>
  quantityPerRefill?: InputMaybe<Scalars['BigDecimal']['input']>
  quantityPerRefillCustomUnit?: InputMaybe<Scalars['String']['input']>
  quantityPerRefillUnitId?: InputMaybe<Scalars['ID']['input']>
  quantityRefillExpirationDate?: InputMaybe<Scalars['DateTime']['input']>
  quantityUnitId?: InputMaybe<Scalars['ID']['input']>
  refillCancelDate?: InputMaybe<Scalars['DateTime']['input']>
  refillCancelPersonId?: InputMaybe<Scalars['ID']['input']>
  refillExpirationDate?: InputMaybe<Scalars['DateTime']['input']>
  refillNotes?: InputMaybe<Scalars['String']['input']>
  refillRemains?: InputMaybe<Scalars['Int']['input']>
  refillSeq?: InputMaybe<Scalars['Int']['input']>
  refillSeqNumber?: InputMaybe<Scalars['Int']['input']>
  rejectionReason?: InputMaybe<Scalars['String']['input']>
  signatureUrl?: InputMaybe<Scalars['String']['input']>
  signedDate?: InputMaybe<Scalars['DateTime']['input']>
  signerId?: InputMaybe<Scalars['ID']['input']>
  soapId?: InputMaybe<Scalars['ID']['input']>
  spiciesId?: InputMaybe<Scalars['ID']['input']>
  storeDefault?: InputMaybe<Scalars['Boolean']['input']>
  submitterId?: InputMaybe<Scalars['ID']['input']>
  totalQuantity?: InputMaybe<Scalars['BigDecimal']['input']>
  totalQuantityCustomUnit?: InputMaybe<Scalars['String']['input']>
  totalQuantityUnitId?: InputMaybe<Scalars['ID']['input']>
  variationId?: InputMaybe<Scalars['ID']['input']>
}

export type RxUnlinkedClientDetails = {
  __typename?: 'RxUnlinkedClientDetails'
  clientFirstName?: Maybe<Scalars['String']['output']>
  clientLastName?: Maybe<Scalars['String']['output']>
  patientName?: Maybe<Scalars['String']['output']>
}

export type SavedAmount = {
  __typename?: 'SavedAmount'
  businessId: Scalars['ID']['output']
  value: Scalars['Float']['output']
}

export type Schedule = {
  __typename?: 'Schedule'
  businessAvailability: Array<TimeSlot>
  employee: Array<Slot>
  staffAvailability: Array<StaffAvailability>
  unassigned: Array<Slot>
}

export type ScheduleBusinessAvailabilityArgs = {
  businessAppointmentTypeId?: InputMaybe<Scalars['ID']['input']>
  businessId: Scalars['ID']['input']
  dateRange?: InputMaybe<DateRangeInput>
  reasonId?: InputMaybe<Scalars['ID']['input']>
  timezone?: InputMaybe<Scalars['String']['input']>
}

export type ScheduleEmployeeArgs = {
  appointmentTypeId?: InputMaybe<Scalars['ID']['input']>
  businessId?: InputMaybe<Scalars['ID']['input']>
  dateTimeFrom: Scalars['DateTime']['input']
  dateTimeTo: Scalars['DateTime']['input']
  employeeId: Scalars['ID']['input']
  type: SlotType
}

export type ScheduleStaffAvailabilityArgs = {
  businessAppointmentTypeId?: InputMaybe<Scalars['ID']['input']>
  businessId: Scalars['ID']['input']
  currentEventId?: InputMaybe<Scalars['ID']['input']>
  dateRange?: InputMaybe<DateRangeInput>
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type ScheduleUnassignedArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  dateTimeFrom: Scalars['DateTime']['input']
  dateTimeTo: Scalars['DateTime']['input']
}

export type Search = {
  __typename?: 'Search'
  clients: ClientPage
}

export type SearchClientsArgs = {
  businessId?: InputMaybe<Scalars['ID']['input']>
  groupBusinessIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  groupSearchRequire?: InputMaybe<Scalars['Boolean']['input']>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  query: ClientsSearchQuery
}

export type SearchCriteria = {
  category?: InputMaybe<ConversationCategory>
  draftFilter?: InputMaybe<DraftFilter>
  lastMessageDateFrom?: InputMaybe<Scalars['DateTime']['input']>
  lastMessageDateTo?: InputMaybe<Scalars['DateTime']['input']>
  limit?: InputMaybe<Scalars['PageLimit']['input']>
  offset?: InputMaybe<Scalars['PageOffset']['input']>
  states?: InputMaybe<Array<StateFilter>>
  teamMembersIds?: InputMaybe<Array<Scalars['ID']['input']>>
  transports?: InputMaybe<Array<TransportFilter>>
}

export type SessionDto = {
  __typename?: 'SessionDto'
  creator?: Maybe<PersonShortDto>
  id: Scalars['ID']['output']
}

export type ShippingAddress = {
  __typename?: 'ShippingAddress'
  address: Address
  fullName?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  phone1?: Maybe<Scalars['String']['output']>
  primary?: Maybe<Scalars['Boolean']['output']>
}

export type ShortConversationDto = {
  __typename?: 'ShortConversationDto'
  assigneeId?: Maybe<Scalars['ID']['output']>
  assigneeName?: Maybe<Scalars['String']['output']>
  businessId?: Maybe<Scalars['ID']['output']>
  id?: Maybe<Scalars['ID']['output']>
  lastMessageDate?: Maybe<Scalars['DateTime']['output']>
  lastMessageUpdateDate?: Maybe<Scalars['DateTime']['output']>
  notifications?: Maybe<Array<Maybe<Notification>>>
  recipient?: Maybe<Recipient>
  state?: Maybe<ConversationState>
  /** @deprecated use state instead */
  status?: Maybe<ConversationStatus>
  title?: Maybe<Scalars['String']['output']>
  to?: Maybe<Scalars['String']['output']>
  transport?: Maybe<ConversationTransport>
}

export type SignupTokenData = {
  __typename?: 'SignupTokenData'
  selection?: Maybe<Array<PatientMembershipLevelSelection>>
  soldByPersonId?: Maybe<Scalars['ID']['output']>
  versionId: Scalars['ID']['output']
}

export type SimplePerson = Person & {
  __typename?: 'SimplePerson'
  active?: Maybe<Scalars['Boolean']['output']>
  businessRoles?: Maybe<Array<Maybe<BusinessRole>>>
  creationDate: Scalars['DateTime']['output']
  deleted?: Maybe<Scalars['Boolean']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  mobilePhone?: Maybe<Scalars['String']['output']>
  modificationDate: Scalars['DateTime']['output']
  photo?: Maybe<Scalars['String']['output']>
  photoThumbnail?: Maybe<Scalars['String']['output']>
  signatureUrl?: Maybe<Scalars['String']['output']>
}

export type Slot = {
  __typename?: 'Slot'
  endDateTime: Scalars['DateTime']['output']
  event?: Maybe<Event>
  startDateTime: Scalars['DateTime']['output']
}

export enum SlotType {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
}

export type Soap = {
  __typename?: 'Soap'
  assignedVetAssistantId?: Maybe<Scalars['ID']['output']>
  assignedVetId?: Maybe<Scalars['ID']['output']>
  assignedVetTechId?: Maybe<Scalars['ID']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  dischargeNotes?: Maybe<Scalars['String']['output']>
  finalized: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  medicalNotes?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type SoapFile = {
  __typename?: 'SoapFile'
  dateSigned?: Maybe<Scalars['DateTime']['output']>
  extension: Scalars['String']['output']
  fileUrl: Scalars['String']['output']
  groupId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  includeInKiosk: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  originalFileName: Scalars['String']['output']
  required: Scalars['Boolean']['output']
  signer?: Maybe<Scalars['String']['output']>
  typeId: Scalars['ID']['output']
}

export type SoapFileGroup = {
  __typename?: 'SoapFileGroup'
  authorId: Scalars['ID']['output']
  businessId: Scalars['ID']['output']
  creationDate: Scalars['DateTime']['output']
  date: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  diagnosedIn?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  patient: Patient
  /** @deprecated use patient.id instead */
  patientId: Scalars['ID']['output']
  soapFiles: Array<SoapFile>
  soapId?: Maybe<Scalars['ID']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type SoapTemplate = {
  __typename?: 'SoapTemplate'
  active: Scalars['Boolean']['output']
  businessId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  tabs: Array<SoapTemplateTab>
}

export type SoapTemplateInput = {
  active: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  tabs: Array<SoapTemplateTabInput>
}

export type SoapTemplateTab = {
  __typename?: 'SoapTemplateTab'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  order: Scalars['Int']['output']
  widgets: Array<SoapTemplateWidget>
}

export type SoapTemplateTabInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
  order: Scalars['Int']['input']
  widgets: Array<SoapTemplateWidgetInput>
}

export type SoapTemplateWidget = {
  __typename?: 'SoapTemplateWidget'
  active: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  marketplaceId?: Maybe<Scalars['ID']['output']>
  order: Scalars['Int']['output']
  soapWidgetId: Scalars['ID']['output']
}

export type SoapTemplateWidgetInput = {
  marketplaceId?: InputMaybe<Scalars['ID']['input']>
  order: Scalars['Int']['input']
  soapWidgetId: Scalars['ID']['input']
}

export type SoapWidget = {
  __typename?: 'SoapWidget'
  active: Scalars['Boolean']['output']
  marketplaceId?: Maybe<Scalars['ID']['output']>
  name: Scalars['String']['output']
  nameTranslation: Scalars['String']['output']
  soapWidgetId: Scalars['ID']['output']
}

export type SoapWidgetUncoveredContentFilterInput = {
  soapId: Scalars['ID']['input']
  stateIds: Array<Scalars['ID']['input']>
}

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SortedSpecies = NamedEntity & {
  __typename?: 'SortedSpecies'
  breeds: Array<Breed>
  createdAt: Scalars['DateTime']['output']
  humanReadable: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type Space = {
  __typename?: 'Space'
  active: Scalars['Boolean']['output']
  assignedDevices?: Maybe<Array<Maybe<SpaceMonitor>>>
  business: Business
  capacity: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  notes?: Maybe<Scalars['String']['output']>
  spaceType?: Maybe<ConstantEntity>
  updatedAt: Scalars['DateTime']['output']
}

export type SpaceMonitor = {
  __typename?: 'SpaceMonitor'
  assigned: Scalars['Boolean']['output']
  business: Business
  deviceName: Scalars['String']['output']
  monitorType: MonitorType
  space?: Maybe<Space>
  token: Scalars['String']['output']
  user?: Maybe<Employee>
}

export type SpaceMonitorState = {
  deviceName: Scalars['String']['output']
  receivedAt: Scalars['DateTime']['output']
}

export type SpaceNameInfoInput = {
  id: Scalars['ID']['input']
}

export enum SpayedNeuteredStatus {
  Intact = 'INTACT',
  Neutered = 'NEUTERED',
  Spayed = 'SPAYED',
}

export type Species = NamedEntity & {
  __typename?: 'Species'
  breeds: Array<Breed>
  createdAt: Scalars['DateTime']['output']
  humanReadable: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type SsoIdpSettings = {
  __typename?: 'SsoIdpSettings'
  business: Business
  createdAt: Scalars['DateTime']['output']
  creator: PersonInfo
  id: Scalars['ID']['output']
  modifier?: Maybe<PersonInfo>
  role: ConstantEntity
  ssoDefaultRoleId: Scalars['ID']['output']
  ssoIdpAppId: Scalars['String']['output']
  ssoIdpDomain: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type SsoIdpSettingsInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  ssoDefaultRoleId: Scalars['ID']['input']
  ssoIdpAppId: Scalars['String']['input']
  ssoIdpDomain: Scalars['String']['input']
}

export type StaffAvailability = {
  __typename?: 'StaffAvailability'
  personId: Scalars['ID']['output']
  timeSlots: Array<TimeSlot>
}

export enum State {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pw = 'PW',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
}

export type StateEntity = {
  __typename?: 'StateEntity'
  creationDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
}

export enum StateFilter {
  Archived = 'ARCHIVED',
  DeliveryFailed = 'DELIVERY_FAILED',
  Open = 'OPEN',
  Unread = 'UNREAD',
}

export type StaticSoapWidget = {
  __typename?: 'StaticSoapWidget'
  id: Scalars['ID']['output']
}

export type SubmitRetailOrderInput = {
  accountBalanceId?: InputMaybe<Scalars['String']['input']>
  paymentMethodId: Scalars['String']['input']
  retailOrderId: Scalars['ID']['input']
}

export type Subscription = {
  __typename?: 'Subscription'
  patient: Patient
  spaceMonitor: SpaceMonitorState
}

export type SubscriptionPatientArgs = {
  id: Scalars['ID']['input']
  updatesOnly?: InputMaybe<Scalars['Boolean']['input']>
}

export type SubscriptionSpaceMonitorArgs = {
  spaceId?: InputMaybe<Scalars['ID']['input']>
}

export type SummaryValue = {
  __typename?: 'SummaryValue'
  amount: Scalars['BigDecimal']['output']
  count: Scalars['Int']['output']
}

export type SurveyLink = {
  __typename?: 'SurveyLink'
  business?: Maybe<Business>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  creator?: Maybe<Employee>
  expirationDate?: Maybe<Scalars['DateTime']['output']>
  link?: Maybe<Scalars['String']['output']>
  surveyType?: Maybe<SurveyType>
}

export enum SurveyType {
  Qualtrics = 'QUALTRICS',
}

export type Task = {
  __typename?: 'Task'
  aggregatedCompletionDate?: Maybe<Scalars['DateTime']['output']>
  aggregatedState?: Maybe<TaskState>
  appointmentId?: Maybe<Scalars['ID']['output']>
  assigneeId?: Maybe<Scalars['ID']['output']>
  businessId: Scalars['ID']['output']
  chronic?: Maybe<Scalars['Boolean']['output']>
  client?: Maybe<Client>
  /** @deprecated client object is available directly */
  clientId?: Maybe<Scalars['ID']['output']>
  completionDate?: Maybe<Scalars['DateTime']['output']>
  contactId?: Maybe<Scalars['ID']['output']>
  createdAt: Scalars['DateTime']['output']
  deleted?: Maybe<Scalars['Boolean']['output']>
  dueDatetime: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  instances?: Maybe<Array<Maybe<Task>>>
  instancesByState: TaskPage
  instructions?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  notes?: Maybe<Scalars['String']['output']>
  parent?: Maybe<Task>
  patient?: Maybe<Patient>
  /** @deprecated patient object is available directly */
  patientId?: Maybe<Scalars['ID']['output']>
  prescription?: Maybe<Prescription>
  recurrenceEndDatetime?: Maybe<Scalars['DateTime']['output']>
  recurrenceMode?: Maybe<NamedTimeOffset>
  recurrencePeriod?: Maybe<TimeOffset>
  recurrenceStartDatetime?: Maybe<Scalars['DateTime']['output']>
  recurrenceType?: Maybe<RecurrenceType>
  state: ConstantEntity
  taskPath: Scalars['Path']['output']
  timesOfDay?: Maybe<DailyRecurrenceParams>
  type: ConstantEntity
  updatedAt: Scalars['DateTime']['output']
}

export type TaskInstancesByStateArgs = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
  stateId: Scalars['ID']['input']
}

export type TaskAutomationEntityType = {
  __typename?: 'TaskAutomationEntityType'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  placeholders?: Maybe<Array<ConstantEntity>>
}

export type TaskOrderByInput = {
  dueDatetime?: InputMaybe<Sort>
  name?: InputMaybe<Sort>
}

export type TaskPage = Page & {
  __typename?: 'TaskPage'
  data: Array<Task>
  totalCount: Scalars['Int']['output']
}

export type TaskState = NamedEntity & {
  __typename?: 'TaskState'
  colorVariant?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type TaskTemplate = {
  __typename?: 'TaskTemplate'
  bundleId?: Maybe<Scalars['ID']['output']>
  businessId: Scalars['ID']['output']
  creationDate: Scalars['DateTime']['output']
  customDueDateOffset?: Maybe<TimeOffset>
  customEndDateOffset?: Maybe<TimeOffset>
  daysOfWeek?: Maybe<Array<Maybe<Scalars['DateUnit']['output']>>>
  deleted: Scalars['Boolean']['output']
  dueDateOption: TaskTemplateOption
  dueTimeOption: TaskTemplateOption
  endDateOption?: Maybe<TaskTemplateOption>
  endTimeOption?: Maybe<TaskTemplateOption>
  forClient?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  instructions?: Maybe<Scalars['String']['output']>
  modificationDate: Scalars['DateTime']['output']
  name: Scalars['String']['output']
  notes?: Maybe<Scalars['String']['output']>
  occurrencesCount?: Maybe<Scalars['Int']['output']>
  recurrenceMode?: Maybe<NamedTimeOffset>
  recurrencePeriod?: Maybe<TimeOffset>
  specificDueTime?: Maybe<Scalars['LocalTime']['output']>
  specificEndTime?: Maybe<Scalars['LocalTime']['output']>
  taskAutomationEntityType: TaskAutomationEntityType
  taskAutomationTriggerStateId?: Maybe<Scalars['ID']['output']>
  teamId?: Maybe<Scalars['ID']['output']>
  teamMemberOption?: Maybe<TaskTemplateOption>
  teamOption: TaskTemplateOption
  timesOfDay?: Maybe<DailyRecurrenceParams>
  type: ConstantEntity
}

export type TaskTemplateInput = {
  bundleId?: InputMaybe<Scalars['ID']['input']>
  businessId: Scalars['ID']['input']
  customDueDateOffset?: InputMaybe<TimeOffsetInput>
  customEndDateOffset?: InputMaybe<TimeOffsetInput>
  daysOfWeek?: InputMaybe<Array<InputMaybe<Scalars['DateUnit']['input']>>>
  dueDateOptionId: Scalars['ID']['input']
  dueTimeOptionId: Scalars['ID']['input']
  endDateOptionId?: InputMaybe<Scalars['ID']['input']>
  endTimeOptionId?: InputMaybe<Scalars['ID']['input']>
  forClient?: InputMaybe<Scalars['Boolean']['input']>
  instructions?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  occurrencesCount?: InputMaybe<Scalars['Int']['input']>
  recurrenceModeId?: InputMaybe<Scalars['ID']['input']>
  recurrencePeriod?: InputMaybe<TimeOffsetInput>
  specificDueTime?: InputMaybe<Scalars['LocalTime']['input']>
  specificEndTime?: InputMaybe<Scalars['LocalTime']['input']>
  taskAutomationEntityTypeId: Scalars['ID']['input']
  taskAutomationTriggerStateId?: InputMaybe<Scalars['ID']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
  teamMemberOptionId?: InputMaybe<Scalars['ID']['input']>
  teamOptionId?: InputMaybe<Scalars['ID']['input']>
  timesOfDay?: InputMaybe<Array<InputMaybe<Scalars['LocalTime']['input']>>>
  typeId?: InputMaybe<Scalars['ID']['input']>
}

export type TaskTemplateOption = NamedEntity & {
  __typename?: 'TaskTemplateOption'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  enabled?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type TaskTemplateOptions = {
  __typename?: 'TaskTemplateOptions'
  options: Array<TaskTemplateOption>
  type?: Maybe<Scalars['String']['output']>
}

export type TaskTimeUnit = NamedEntity & {
  __typename?: 'TaskTimeUnit'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  unit: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type TempPriceDto = {
  __typename?: 'TempPriceDto'
  active: Scalars['Boolean']['output']
  currentPrice?: Maybe<Scalars['BigDecimal']['output']>
  deleted: Scalars['Boolean']['output']
  difference?: Maybe<Scalars['BigDecimal']['output']>
  id?: Maybe<Scalars['ID']['output']>
  markupPercent?: Maybe<Scalars['BigDecimal']['output']>
  name?: Maybe<Scalars['String']['output']>
  newPrice?: Maybe<Scalars['BigDecimal']['output']>
  percentDifference?: Maybe<Scalars['BigDecimal']['output']>
}

export type TermsAndConditionsFile = {
  __typename?: 'TermsAndConditionsFile'
  authorId?: Maybe<Scalars['ID']['output']>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  extension?: Maybe<Scalars['String']['output']>
  fileUrl?: Maybe<Scalars['String']['output']>
  generationInProgress?: Maybe<Scalars['Boolean']['output']>
  id?: Maybe<Scalars['ID']['output']>
  name?: Maybe<Scalars['String']['output']>
  typeId?: Maybe<Scalars['ID']['output']>
}

export type TimeOffset = {
  __typename?: 'TimeOffset'
  amount?: Maybe<Scalars['Int']['output']>
  unit?: Maybe<Scalars['String']['output']>
}

export type TimeOffsetInput = {
  amount?: InputMaybe<Scalars['Int']['input']>
  unitId: Scalars['ID']['input']
}

export type TimeSlot = {
  __typename?: 'TimeSlot'
  from: Scalars['DateTime']['output']
  to: Scalars['DateTime']['output']
}

export type TimelineEntry = {
  timelineDate?: Maybe<Scalars['DateTime']['output']>
}

export type TimelinePage = Page & {
  __typename?: 'TimelinePage'
  data?: Maybe<Array<Maybe<TimelineEntry>>>
  totalCount: Scalars['Int']['output']
}

export enum TimelineType {
  Appointment = 'APPOINTMENT',
  DiagnosisHistory = 'DIAGNOSIS_HISTORY',
  DiagnosisLog = 'DIAGNOSIS_LOG',
  Invoice = 'INVOICE',
  LabTest = 'LAB_TEST',
  Prescription = 'PRESCRIPTION',
  ProblemLog = 'PROBLEM_LOG',
  Reminder = 'REMINDER',
  Vaccination = 'VACCINATION',
  VaccinationHistory = 'VACCINATION_HISTORY',
}

export type Totals = {
  __typename?: 'Totals'
  discount: Scalars['BigDecimal']['output']
  shipping: Scalars['BigDecimal']['output']
  subTotal: Scalars['BigDecimal']['output']
  tax: Scalars['BigDecimal']['output']
  total: Scalars['BigDecimal']['output']
}

export enum TransportFilter {
  Boop = 'BOOP',
  Email = 'EMAIL',
  LogPhoneCall = 'LOG_PHONE_CALL',
  Sms = 'SMS',
}

export enum Type {
  Business = 'BUSINESS',
  Client = 'CLIENT',
  Contact = 'CONTACT',
  Direct = 'DIRECT',
}

export type UnassignedMonitorState = SpaceMonitorState & {
  __typename?: 'UnassignedMonitorState'
  deviceName: Scalars['String']['output']
  receivedAt: Scalars['DateTime']['output']
}

export type UpdateAppointmentInput = {
  appointmentCancellationReason?: InputMaybe<Scalars['String']['input']>
  appointmentCancellationReasonId?: InputMaybe<Scalars['ID']['input']>
  businessAppointmentType?: InputMaybe<BusinessAppointmentTypeShortInput>
  clientId?: InputMaybe<Scalars['ID']['input']>
  clientInstructions?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  patientId?: InputMaybe<Scalars['ID']['input']>
  resources?: InputMaybe<Array<EventResourceInput>>
  scheduledEndDatetime?: InputMaybe<Scalars['DateTime']['input']>
  scheduledStartDatetime?: InputMaybe<Scalars['DateTime']['input']>
  stateId?: InputMaybe<Scalars['ID']['input']>
  typeId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateAssignmentsDto = {
  assigned: Scalars['Boolean']['input']
  spaceId?: InputMaybe<Scalars['ID']['input']>
  token: Scalars['String']['input']
}

export type UpdateBodySystemLogBatchRequestInput = {
  bodySystemIds: Array<Scalars['ID']['input']>
  stateId: Scalars['ID']['input']
}

export type UpdateBoopChatInput = {
  isClosed?: InputMaybe<Scalars['Boolean']['input']>
  isRead?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateBusinessAppointmentTypeInput = {
  color?: InputMaybe<Scalars['String']['input']>
  defaultDuration: Scalars['LocalTime']['input']
  enabled: Scalars['Boolean']['input']
  onlineSchedulingAllowed: Scalars['Boolean']['input']
}

export type UpdateBusyTimeInput = {
  businessAppointmentType?: InputMaybe<BusinessAppointmentTypeShortInput>
  notes?: InputMaybe<Scalars['String']['input']>
  resources?: InputMaybe<Array<EventResourceInput>>
  scheduledEndDatetime?: InputMaybe<Scalars['DateTime']['input']>
  scheduledStartDatetime?: InputMaybe<Scalars['DateTime']['input']>
  stateId?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  typeId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateClientInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  address1?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  businessRoles?: InputMaybe<Array<InputMaybe<BusinessRoleInput>>>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  emergencyContact?: InputMaybe<EmergencyContactInput>
  externalId?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  homePhone?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  mobilePhone?: InputMaybe<Scalars['String']['input']>
  preferredContactMethodId?: InputMaybe<Scalars['ID']['input']>
  state?: InputMaybe<State>
  tagId?: InputMaybe<Scalars['ID']['input']>
  taxIdentificationNumber?: InputMaybe<Scalars['String']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type UpdateInvoiceLineItemInput = {
  declined?: InputMaybe<Scalars['Boolean']['input']>
  discountAmount?: InputMaybe<Scalars['BigDecimal']['input']>
  discountPerc?: InputMaybe<Scalars['BigDecimal']['input']>
  prepaid?: InputMaybe<Scalars['Boolean']['input']>
  producerId?: InputMaybe<Scalars['ID']['input']>
  quantity?: InputMaybe<Scalars['BigDecimal']['input']>
  taxed?: InputMaybe<Scalars['Boolean']['input']>
  usedQuantity?: InputMaybe<Scalars['BigDecimal']['input']>
}

export type UpdateMembershipPaymentInput = {
  clientId: Scalars['ID']['input']
  wplanLogId: Scalars['ID']['input']
}

export type UpdatePatientInput = {
  acquiredDate?: InputMaybe<Scalars['DateTime']['input']>
  active?: InputMaybe<Scalars['Boolean']['input']>
  allergies?: InputMaybe<Scalars['String']['input']>
  approximateDateOfBirth?: InputMaybe<Scalars['Boolean']['input']>
  breedIds?: InputMaybe<Array<Scalars['ID']['input']>>
  color?: InputMaybe<Scalars['String']['input']>
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>
  deceased?: InputMaybe<Scalars['Boolean']['input']>
  deceasedDate?: InputMaybe<Scalars['DateTime']['input']>
  diet?: InputMaybe<Scalars['String']['input']>
  environmentId?: InputMaybe<Scalars['ID']['input']>
  externalId?: InputMaybe<Scalars['String']['input']>
  fitnessLevelId?: InputMaybe<Scalars['ID']['input']>
  gender?: InputMaybe<Gender>
  id: Scalars['ID']['input']
  insurancePolicyNumber?: InputMaybe<Scalars['String']['input']>
  insuranceProvider?: InputMaybe<Scalars['String']['input']>
  license?: InputMaybe<Scalars['String']['input']>
  markingsTattoo?: InputMaybe<Scalars['String']['input']>
  microchipNumber?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  photo?: InputMaybe<Scalars['String']['input']>
  photoThumbnail?: InputMaybe<Scalars['String']['input']>
  purchasedFromId?: InputMaybe<Scalars['ID']['input']>
  rabiesTag?: InputMaybe<Scalars['String']['input']>
  rabiesTagExpiration?: InputMaybe<Scalars['DateTime']['input']>
  schedule?: InputMaybe<Scalars['String']['input']>
  serviceDesignationId?: InputMaybe<Scalars['ID']['input']>
  spayedNeuteredStatus?: InputMaybe<SpayedNeuteredStatus>
  speciesId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdatePatientInputOld = {
  acquiredDate?: InputMaybe<Scalars['DateTime']['input']>
  active?: InputMaybe<Scalars['Boolean']['input']>
  approximateDateOfBirth?: InputMaybe<Scalars['Boolean']['input']>
  breedIds?: InputMaybe<Array<Scalars['ID']['input']>>
  color?: InputMaybe<Scalars['String']['input']>
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>
  deceased?: InputMaybe<Scalars['Boolean']['input']>
  deceasedDate?: InputMaybe<Scalars['DateTime']['input']>
  externalId?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<Gender>
  insurancePolicyNumber?: InputMaybe<Scalars['String']['input']>
  insuranceProvider?: InputMaybe<Scalars['String']['input']>
  license?: InputMaybe<Scalars['String']['input']>
  markingsTattoo?: InputMaybe<Scalars['String']['input']>
  microchipNumber?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  spayedNeuteredStatus?: InputMaybe<SpayedNeuteredStatus>
  speciesId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateProblemBodySystemLogRequestInput = {
  bodySystemId: Scalars['ID']['input']
  crc: Scalars['Long']['input']
  id: Scalars['ID']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  problemLogs?: InputMaybe<Array<InputMaybe<CreateProblemLogRequestInput>>>
  removeExistingProblemLogs?: InputMaybe<Scalars['Boolean']['input']>
  soapId: Scalars['ID']['input']
  stateId: Scalars['ID']['input']
}

export type UpdateProblemEnumLogRequestInput = {
  crc: Scalars['Long']['input']
  enumId: Scalars['ID']['input']
  enumValueId: Scalars['ID']['input']
  id: Scalars['ID']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  parentEnumValueId?: InputMaybe<Scalars['ID']['input']>
  problemLogId: Scalars['ID']['input']
}

export type UpdateProblemLogRequestInput2 = {
  bodySystemLogId: Scalars['ID']['input']
  crc: Scalars['Long']['input']
  id: Scalars['ID']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  problemId: Scalars['ID']['input']
  stateId: Scalars['ID']['input']
}

export type UpdateReminderInput = {
  dueDatetime?: InputMaybe<Scalars['DateTime']['input']>
  expireDatetime?: InputMaybe<Scalars['DateTime']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  patientId?: InputMaybe<Scalars['ID']['input']>
  stateId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateRetailOrderInput = {
  expectedModificationDate: Scalars['DateTime']['input']
  retailOrderId: Scalars['ID']['input']
  shippingAddressId: Scalars['String']['input']
}

export type UpdateRetailOrderLineItemInput = {
  autoshipFrequency?: InputMaybe<Scalars['Int']['input']>
  autoshipUnitId?: InputMaybe<Scalars['ID']['input']>
  declined: Scalars['Boolean']['input']
  expectedModificationDate: Scalars['DateTime']['input']
  id: Scalars['ID']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  partNumber: Scalars['String']['input']
  producerId?: InputMaybe<Scalars['ID']['input']>
  quantity: Scalars['BigDecimal']['input']
  viewOrderNumber?: InputMaybe<Scalars['Int']['input']>
  viewSectionOrderNumber?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateSectionDiscountInput = {
  additionalDiscount: Scalars['BigDecimal']['input']
  expectedModificationDate: Scalars['DateTime']['input']
  id: Scalars['ID']['input']
}

export type UpdateSoapInput = {
  dischargeNotes?: InputMaybe<Scalars['String']['input']>
  medicalNotes?: InputMaybe<Scalars['String']['input']>
}

export type UpdateTaskInput = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>
  assigneeId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  contactId?: InputMaybe<Scalars['ID']['input']>
  dueDatetime?: InputMaybe<Scalars['DateTime']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  patientId?: InputMaybe<Scalars['ID']['input']>
  stateId?: InputMaybe<Scalars['ID']['input']>
  typeId?: InputMaybe<Scalars['ID']['input']>
}

export type UploadedAttachment = {
  __typename?: 'UploadedAttachment'
  description?: Maybe<Scalars['String']['output']>
  extension?: Maybe<Scalars['String']['output']>
  fileUrl: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type UserMatch = {
  __typename?: 'UserMatch'
  email: Scalars['String']['output']
  hasKyriosId: Scalars['Boolean']['output']
}

export type VaccinationDetails = TimelineEntry & {
  __typename?: 'VaccinationDetails'
  administeredBy?: Maybe<Employee>
  amount?: Maybe<Scalars['String']['output']>
  businessId: Scalars['ID']['output']
  dateGiven?: Maybe<Scalars['DateTime']['output']>
  dueDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  lotExpired?: Maybe<Scalars['DateTime']['output']>
  lotNumber?: Maybe<Scalars['String']['output']>
  manufacturedBy?: Maybe<Scalars['String']['output']>
  modificationDate: Scalars['DateTime']['output']
  notes?: Maybe<Scalars['String']['output']>
  procedureName: Scalars['String']['output']
  serialNumber?: Maybe<Scalars['String']['output']>
  timelineDate?: Maybe<Scalars['DateTime']['output']>
}

export type VaccinationHistoryDetails = TimelineEntry & {
  __typename?: 'VaccinationHistoryDetails'
  administeredIn?: Maybe<Scalars['String']['output']>
  businessId: Scalars['ID']['output']
  dateGiven?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  dueDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  modificationDate: Scalars['DateTime']['output']
  notes?: Maybe<Scalars['String']['output']>
  procedureName: Scalars['String']['output']
  timelineDate?: Maybe<Scalars['DateTime']['output']>
}

export type Value = {
  __typename?: 'Value'
  savedAmount?: Maybe<Scalars['BigDecimal']['output']>
}

export type Vital = {
  __typename?: 'Vital'
  bloodPressure?: Maybe<BigDecimalVitalValue>
  bodyConditionScore?: Maybe<IntegerVitalValue>
  businessId: Scalars['ID']['output']
  capillaryRefillTime?: Maybe<ConstantEntityVitalValue>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  creatorId?: Maybe<Scalars['ID']['output']>
  deleted: Scalars['Boolean']['output']
  dyspnea?: Maybe<BooleanVitalValue>
  etco2?: Maybe<BigDecimalVitalValue>
  fearAnxietyScore?: Maybe<IntegerVitalValue>
  finalized?: Maybe<Scalars['Boolean']['output']>
  heartRate?: Maybe<IntegerVitalValue>
  id: Scalars['ID']['output']
  isoSevoLevel?: Maybe<BigDecimalVitalValue>
  mentationId?: Maybe<ConstantEntityVitalValue>
  modifierId?: Maybe<Scalars['ID']['output']>
  mucousMembraneColorId?: Maybe<ConstantEntityVitalValue>
  mucousMembraneMoistureId?: Maybe<ConstantEntityVitalValue>
  muscleMassScore?: Maybe<IntegerVitalValue>
  o2Level?: Maybe<BigDecimalVitalValue>
  painScore?: Maybe<IntegerVitalValue>
  patientId: Scalars['ID']['output']
  pulseCharacterId?: Maybe<ConstantEntityVitalValue>
  recordedById?: Maybe<Scalars['ID']['output']>
  recordedDate?: Maybe<Scalars['DateTime']['output']>
  recumbencyId?: Maybe<ConstantEntityVitalValue>
  respirationRate?: Maybe<IntegerVitalValue>
  skinTurgorId?: Maybe<ConstantEntityVitalValue>
  soapId?: Maybe<Scalars['ID']['output']>
  spo2?: Maybe<BigDecimalVitalValue>
  temperature?: Maybe<BigDecimalVitalValue>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  weight?: Maybe<BigDecimalVitalValue>
}

export type VitalInput = {
  bloodPressure?: InputMaybe<BigDecimalVitalValueInput>
  bodyConditionScore?: InputMaybe<IntegerVitalValueInput>
  capillaryRefillTime?: InputMaybe<ConstantEntityVitalValueInput>
  dyspnea?: InputMaybe<BooleanVitalValueInput>
  etco2?: InputMaybe<BigDecimalVitalValueInput>
  fearAnxietyScore?: InputMaybe<IntegerVitalValueInput>
  heartRate?: InputMaybe<IntegerVitalValueInput>
  isoSevoLevel?: InputMaybe<BigDecimalVitalValueInput>
  mentationId?: InputMaybe<ConstantEntityVitalValueInput>
  mucousMembraneColorId?: InputMaybe<ConstantEntityVitalValueInput>
  mucousMembraneMoistureId?: InputMaybe<ConstantEntityVitalValueInput>
  muscleMassScore?: InputMaybe<IntegerVitalValueInput>
  o2Level?: InputMaybe<BigDecimalVitalValueInput>
  painScore?: InputMaybe<IntegerVitalValueInput>
  patientId: Scalars['ID']['input']
  pulseCharacterId?: InputMaybe<ConstantEntityVitalValueInput>
  recordedById?: InputMaybe<Scalars['ID']['input']>
  recordedDate?: InputMaybe<Scalars['DateTime']['input']>
  recumbencyId?: InputMaybe<ConstantEntityVitalValueInput>
  respirationRate?: InputMaybe<IntegerVitalValueInput>
  skinTurgorId?: InputMaybe<ConstantEntityVitalValueInput>
  soapId?: InputMaybe<Scalars['ID']['input']>
  spo2?: InputMaybe<BigDecimalVitalValueInput>
  temperature?: InputMaybe<BigDecimalVitalValueInput>
  weight?: InputMaybe<BigDecimalVitalValueInput>
}

export type VitalPage = Page & {
  __typename?: 'VitalPage'
  data: Array<Vital>
  totalCount: Scalars['Int']['output']
}

export type VitalValue = {
  notes?: Maybe<Scalars['String']['output']>
}

export type WPayment = {
  __typename?: 'WPayment'
  amount?: Maybe<Scalars['Float']['output']>
  amountReceived?: Maybe<Scalars['Float']['output']>
  businessId: Scalars['ID']['output']
  clientId: Scalars['ID']['output']
  completed?: Maybe<Scalars['Boolean']['output']>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  currencyId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  patient?: Maybe<Patient>
  patientId: Scalars['ID']['output']
  paymentMethod?: Maybe<WPaymentMethod>
  paymentMethodId?: Maybe<Scalars['ID']['output']>
  planId?: Maybe<Scalars['ID']['output']>
  planLogId?: Maybe<Scalars['ID']['output']>
  planName?: Maybe<Scalars['String']['output']>
  priceType?: Maybe<PriceType>
  status?: Maybe<Scalars['String']['output']>
  stripeInvoiceId?: Maybe<Scalars['String']['output']>
  stripePaymentIntentId?: Maybe<Scalars['String']['output']>
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>
  tier?: Maybe<Scalars['String']['output']>
  versionId?: Maybe<Scalars['ID']['output']>
  versionNumber?: Maybe<Scalars['Int']['output']>
}

export type WPaymentMethod = {
  __typename?: 'WPaymentMethod'
  billingDetails?: Maybe<Scalars['JSON']['output']>
  cardTypeId?: Maybe<Scalars['ID']['output']>
  confirmed?: Maybe<Scalars['Boolean']['output']>
  customerId?: Maybe<Scalars['ID']['output']>
  expMonth?: Maybe<Scalars['Int']['output']>
  expYear?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  last4?: Maybe<Scalars['String']['output']>
  stripeCustomerId?: Maybe<Scalars['String']['output']>
  stripePaymentMethodId?: Maybe<Scalars['String']['output']>
  stripeSetupIntentId?: Maybe<Scalars['String']['output']>
}

export type WPaymentMethodCreateResult = {
  __typename?: 'WPaymentMethodCreateResult'
  clientId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  publicKey?: Maybe<Scalars['String']['output']>
  setupIntent?: Maybe<Scalars['JSON']['output']>
}

export type WPaymentMethodPage = Page & {
  __typename?: 'WPaymentMethodPage'
  data: Array<Maybe<WPaymentMethod>>
  totalCount: Scalars['Int']['output']
}

export type WPaymentPage = Page & {
  __typename?: 'WPaymentPage'
  data?: Maybe<Array<Maybe<WPayment>>>
  totalCount: Scalars['Int']['output']
}

export type WPlan = {
  __typename?: 'WPlan'
  wplanBusinessId: Scalars['ID']['output']
  wplanCancellationDate?: Maybe<Scalars['DateTime']['output']>
  wplanClientId: Scalars['ID']['output']
  wplanId: Scalars['ID']['output']
  wplanLevel?: Maybe<Scalars['Int']['output']>
  wplanLogId: Scalars['ID']['output']
  wplanName: Scalars['String']['output']
  wplanSinceDate: Scalars['DateTime']['output']
  wplanStatus?: Maybe<Scalars['String']['output']>
  wplanTypeId: Scalars['ID']['output']
  wplanVersion: Scalars['Int']['output']
  wplanVersionId: Scalars['ID']['output']
}

export type WPlanClientSavings = {
  __typename?: 'WPlanClientSavings'
  potentialSavedAmountsInThisYear: Array<SavedAmount>
  savedAmountYTD?: Maybe<Scalars['Float']['output']>
  savedToday?: Maybe<Scalars['Float']['output']>
}

export type WPlanPrice = {
  __typename?: 'WPlanPrice'
  businessId: Scalars['ID']['output']
  createdById: Scalars['ID']['output']
  creationDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  modifiedById: Scalars['ID']['output']
  planId: Scalars['ID']['output']
  prevStripePriceId?: Maybe<Scalars['String']['output']>
  price?: Maybe<Scalars['BigDecimal']['output']>
  priceType?: Maybe<PriceType>
  priceTypeId?: Maybe<Scalars['ID']['output']>
  stripePriceId?: Maybe<Scalars['String']['output']>
  versionId: Scalars['ID']['output']
}

export type WPlanRenewalLog = {
  autoRenewal: Scalars['Boolean']['input']
  planId: Scalars['ID']['input']
}

export type WPlanVersion = {
  __typename?: 'WPlanVersion'
  active?: Maybe<Scalars['Boolean']['output']>
  basePlanHidden?: Maybe<Scalars['Boolean']['output']>
  creationDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  oneTimeFeeAmount?: Maybe<Scalars['Float']['output']>
  plans?: Maybe<Array<Maybe<WellnessPlan>>>
  versionNumber: Scalars['Int']['output']
}

export enum Way {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
}

export type WeeklySchedule = {
  __typename?: 'WeeklySchedule'
  dayOfWeek?: Maybe<Scalars['String']['output']>
  workingHours?: Maybe<TimeSlot>
}

export type Weight = {
  __typename?: 'Weight'
  amount: Scalars['BigDecimal']['output']
  unitId: Scalars['ID']['output']
}

export type WellnessCoverage = {
  __typename?: 'WellnessCoverage'
  potential?: Maybe<Value>
  real?: Maybe<Value>
}

export type WellnessPlan = {
  __typename?: 'WellnessPlan'
  autoRenewal?: Maybe<Scalars['Boolean']['output']>
  autoRenewalModificationDatetime?: Maybe<Scalars['DateTime']['output']>
  benefits?: Maybe<Array<Maybe<Benefit>>>
  businessId: Scalars['ID']['output']
  cancellationDate?: Maybe<Scalars['DateTime']['output']>
  createdById: Scalars['ID']['output']
  creationDate?: Maybe<Scalars['DateTime']['output']>
  hidden: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  level?: Maybe<Scalars['Int']['output']>
  loyaltyAccumulateRate?: Maybe<Scalars['BigDecimal']['output']>
  loyaltyRedeemRate?: Maybe<Scalars['BigDecimal']['output']>
  modificationDate?: Maybe<Scalars['DateTime']['output']>
  modifiedById?: Maybe<Scalars['ID']['output']>
  name: Scalars['String']['output']
  planType: PlanType
  planTypeId?: Maybe<Scalars['ID']['output']>
  price?: Maybe<Scalars['BigDecimal']['output']>
  priceType?: Maybe<PriceType>
  priceTypeId?: Maybe<Scalars['ID']['output']>
  prices?: Maybe<Array<Maybe<WPlanPrice>>>
  state?: Maybe<Scalars['String']['output']>
  statementDescriptor?: Maybe<Scalars['String']['output']>
  stripePriceId?: Maybe<Scalars['String']['output']>
  stripeProductId?: Maybe<Scalars['String']['output']>
  versionId: Scalars['ID']['output']
}

export type WellnessPriceType = NamedEntity & {
  __typename?: 'WellnessPriceType'
  createdAt: Scalars['DateTime']['output']
  displayName: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  shortName: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ClientChewyCheckoutInfoQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ClientChewyCheckoutInfoQuery = {
  __typename?: 'Query'
  client: {
    __typename?: 'Client'
    id: string
    shippingAddresses: Array<{
      __typename?: 'ShippingAddress'
      id: string
      fullName?: string | null
      primary?: boolean | null
      phone1?: string | null
      address: {
        __typename?: 'Address'
        city: string
        email1?: string | null
        id?: string | null
        postcode: string
        state: string
        street1: string
        street2?: string | null
      }
    }>
    paymentMethods: Array<
      | {
          __typename?: 'CardPaymentMethod'
          id: string
          lastFour?: string | null
          expirationMonth?: string | null
          expirationYear?: string | null
          cardHolderName?: string | null
          primary?: boolean | null
          paymentCardType: {
            __typename?: 'ConstantEntity'
            id: string
            name: string
          }
        }
      | {
          __typename?: 'ChewyAccountBalancePaymentMethod'
          id: string
          balance: any
        }
      | {
          __typename?: 'PayPalPaymentMethod'
          id: string
          email: string
          primary?: boolean | null
        }
    >
  }
}

export type MoveItemsToChewyCartMutationVariables = Exact<{
  retailOrderId: Scalars['ID']['input']
}>

export type MoveItemsToChewyCartMutation = {
  __typename?: 'Mutation'
  moveItemsToChewyCart: { __typename?: 'RetailOrder'; id: string }
}

export type SubmitChewyOrderMutationVariables = Exact<{
  retailOrderId: Scalars['ID']['input']
  paymentMethodId: Scalars['String']['input']
  accountBalanceId?: InputMaybe<Scalars['String']['input']>
}>

export type SubmitChewyOrderMutation = {
  __typename?: 'Mutation'
  submitRetailOrder: {
    __typename?: 'RetailOrder'
    id: string
    orderConfirmationId?: string | null
    orderDate?: any | null
    subTotal: any
    discountAmount: any
    shippingAmount: any
    taxAmount: any
    totalPrice: any
    retailDeliveryFee: any
    state: { __typename?: 'ConstantEntity'; id: string; name: string }
    sections: Array<{
      __typename?: 'ChargesSection'
      id?: string | null
      soapId?: string | null
      groupedItems: Array<
        | { __typename?: 'GroupedItem' }
        | { __typename?: 'InvoiceLineItem' }
        | {
            __typename?: 'RetailOrderLineItem'
            id: string
            subTotal: any
            partNumber: string
          }
      >
    }>
  }
}

export type UpdateRetailOrderMutationVariables = Exact<{
  retailOrderId: Scalars['ID']['input']
  shippingAddressId: Scalars['String']['input']
  expectedModificationDate: Scalars['DateTime']['input']
}>

export type UpdateRetailOrderMutation = {
  __typename?: 'Mutation'
  updateRetailOrder: { __typename?: 'RetailOrder'; id: string }
}

export const ClientChewyCheckoutInfoDocument = gql`
  query clientChewyCheckoutInfo($id: ID!) {
    client(id: $id) {
      id
      shippingAddresses {
        id
        fullName
        address {
          city
          email1
          id
          postcode
          state
          street1
          street2
        }
        primary
        phone1
      }
      paymentMethods {
        ... on CardPaymentMethod {
          id
          paymentCardType {
            id
            name
          }
          lastFour
          expirationMonth
          expirationYear
          cardHolderName
          primary
        }
        ... on ChewyAccountBalancePaymentMethod {
          id
          balance
        }
        ... on PayPalPaymentMethod {
          id
          email
          primary
        }
      }
    }
  }
`

/**
 * __useClientChewyCheckoutInfoQuery__
 *
 * To run a query within a React component, call `useClientChewyCheckoutInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientChewyCheckoutInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientChewyCheckoutInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientChewyCheckoutInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientChewyCheckoutInfoQuery,
    ClientChewyCheckoutInfoQueryVariables
  > &
    (
      | { variables: ClientChewyCheckoutInfoQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ClientChewyCheckoutInfoQuery,
    ClientChewyCheckoutInfoQueryVariables
  >(ClientChewyCheckoutInfoDocument, options)
}
export function useClientChewyCheckoutInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientChewyCheckoutInfoQuery,
    ClientChewyCheckoutInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ClientChewyCheckoutInfoQuery,
    ClientChewyCheckoutInfoQueryVariables
  >(ClientChewyCheckoutInfoDocument, options)
}
export function useClientChewyCheckoutInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClientChewyCheckoutInfoQuery,
        ClientChewyCheckoutInfoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ClientChewyCheckoutInfoQuery,
    ClientChewyCheckoutInfoQueryVariables
  >(ClientChewyCheckoutInfoDocument, options)
}
export type ClientChewyCheckoutInfoQueryHookResult = ReturnType<
  typeof useClientChewyCheckoutInfoQuery
>
export type ClientChewyCheckoutInfoLazyQueryHookResult = ReturnType<
  typeof useClientChewyCheckoutInfoLazyQuery
>
export type ClientChewyCheckoutInfoSuspenseQueryHookResult = ReturnType<
  typeof useClientChewyCheckoutInfoSuspenseQuery
>
export type ClientChewyCheckoutInfoQueryResult = Apollo.QueryResult<
  ClientChewyCheckoutInfoQuery,
  ClientChewyCheckoutInfoQueryVariables
>
export const MoveItemsToChewyCartDocument = gql`
  mutation moveItemsToChewyCart($retailOrderId: ID!) {
    moveItemsToChewyCart(retailOrderId: $retailOrderId) {
      id
    }
  }
`
export type MoveItemsToChewyCartMutationFn = Apollo.MutationFunction<
  MoveItemsToChewyCartMutation,
  MoveItemsToChewyCartMutationVariables
>

/**
 * __useMoveItemsToChewyCartMutation__
 *
 * To run a mutation, you first call `useMoveItemsToChewyCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveItemsToChewyCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveItemsToChewyCartMutation, { data, loading, error }] = useMoveItemsToChewyCartMutation({
 *   variables: {
 *      retailOrderId: // value for 'retailOrderId'
 *   },
 * });
 */
export function useMoveItemsToChewyCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveItemsToChewyCartMutation,
    MoveItemsToChewyCartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MoveItemsToChewyCartMutation,
    MoveItemsToChewyCartMutationVariables
  >(MoveItemsToChewyCartDocument, options)
}
export type MoveItemsToChewyCartMutationHookResult = ReturnType<
  typeof useMoveItemsToChewyCartMutation
>
export type MoveItemsToChewyCartMutationResult =
  Apollo.MutationResult<MoveItemsToChewyCartMutation>
export type MoveItemsToChewyCartMutationOptions = Apollo.BaseMutationOptions<
  MoveItemsToChewyCartMutation,
  MoveItemsToChewyCartMutationVariables
>
export const SubmitChewyOrderDocument = gql`
  mutation submitChewyOrder(
    $retailOrderId: ID!
    $paymentMethodId: String!
    $accountBalanceId: String
  ) {
    submitRetailOrder(
      input: {
        retailOrderId: $retailOrderId
        paymentMethodId: $paymentMethodId
        accountBalanceId: $accountBalanceId
      }
    ) {
      id
      orderConfirmationId
      orderDate
      state {
        id
        name
      }
      sections {
        id
        soapId
        groupedItems {
          ... on RetailOrderLineItem {
            id
            subTotal
            partNumber
          }
        }
      }
      subTotal
      discountAmount
      shippingAmount
      taxAmount
      totalPrice
      retailDeliveryFee
    }
  }
`
export type SubmitChewyOrderMutationFn = Apollo.MutationFunction<
  SubmitChewyOrderMutation,
  SubmitChewyOrderMutationVariables
>

/**
 * __useSubmitChewyOrderMutation__
 *
 * To run a mutation, you first call `useSubmitChewyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitChewyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitChewyOrderMutation, { data, loading, error }] = useSubmitChewyOrderMutation({
 *   variables: {
 *      retailOrderId: // value for 'retailOrderId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      accountBalanceId: // value for 'accountBalanceId'
 *   },
 * });
 */
export function useSubmitChewyOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitChewyOrderMutation,
    SubmitChewyOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubmitChewyOrderMutation,
    SubmitChewyOrderMutationVariables
  >(SubmitChewyOrderDocument, options)
}
export type SubmitChewyOrderMutationHookResult = ReturnType<
  typeof useSubmitChewyOrderMutation
>
export type SubmitChewyOrderMutationResult =
  Apollo.MutationResult<SubmitChewyOrderMutation>
export type SubmitChewyOrderMutationOptions = Apollo.BaseMutationOptions<
  SubmitChewyOrderMutation,
  SubmitChewyOrderMutationVariables
>
export const UpdateRetailOrderDocument = gql`
  mutation updateRetailOrder(
    $retailOrderId: ID!
    $shippingAddressId: String!
    $expectedModificationDate: DateTime!
  ) {
    updateRetailOrder(
      input: {
        retailOrderId: $retailOrderId
        shippingAddressId: $shippingAddressId
        expectedModificationDate: $expectedModificationDate
      }
    ) {
      id
    }
  }
`
export type UpdateRetailOrderMutationFn = Apollo.MutationFunction<
  UpdateRetailOrderMutation,
  UpdateRetailOrderMutationVariables
>

/**
 * __useUpdateRetailOrderMutation__
 *
 * To run a mutation, you first call `useUpdateRetailOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRetailOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRetailOrderMutation, { data, loading, error }] = useUpdateRetailOrderMutation({
 *   variables: {
 *      retailOrderId: // value for 'retailOrderId'
 *      shippingAddressId: // value for 'shippingAddressId'
 *      expectedModificationDate: // value for 'expectedModificationDate'
 *   },
 * });
 */
export function useUpdateRetailOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRetailOrderMutation,
    UpdateRetailOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateRetailOrderMutation,
    UpdateRetailOrderMutationVariables
  >(UpdateRetailOrderDocument, options)
}
export type UpdateRetailOrderMutationHookResult = ReturnType<
  typeof useUpdateRetailOrderMutation
>
export type UpdateRetailOrderMutationResult =
  Apollo.MutationResult<UpdateRetailOrderMutation>
export type UpdateRetailOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateRetailOrderMutation,
  UpdateRetailOrderMutationVariables
>
