import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FormControl, Grid, IconButton, InputLabel } from '@mui/material'
import {
  InternalDisplayPreference,
  PuiSelect,
  PuiTextArea,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import {
  CheckboxOptionState,
  internalDisplayPreferenceSelectItems,
  RadioOptionState,
} from '~/types'
import useFieldsChanged from '~/utils/useFieldsChanged'

import { validateSummarizeInOneLineField } from './utils'

interface SelectionOptionProps {
  InputComponent: React.JSXElementConstructor<any>
  namePlaceholder: string
  onChange: (option: CheckboxOptionState) => void
  onDelete?: () => void
  option: CheckboxOptionState | RadioOptionState
}

export type SelectionOptionHandle = {
  validate: () => boolean
}

export const SelectionOption = forwardRef<
  SelectionOptionHandle,
  SelectionOptionProps
>(({ option, InputComponent, namePlaceholder, onChange, onDelete }, ref) => {
  const { t } = useTranslation('Common')
  const { fields, validate } = useFields([
    {
      name: 'name',
      validators: ['required'],
      initialValue: option?.name || '',
    },
    {
      name: 'howToDisplay',
      label: t(
        'Dialogs:MULTIPLE_SELECT_DIALOG.CHOOSE_HOW_TO_DISPLAY_IN_RHAPSODY',
      ),
      type: 'select',
      validators: ['required'],
      initialValue: option?.internalDisplayPreference || '',
    },
    {
      name: 'summarizeInOneLine',
      label: t('Dialogs:MULTIPLE_SELECT_DIALOG.SUMMARIZE_ANSWER_IN_ONE_LINE'),
      initialValue: option?.internalName || '',
      validators: [
        {
          validator: validateSummarizeInOneLineField,
          validatorName: 'required',
        },
      ],
    },
  ])

  const { name, howToDisplay, summarizeInOneLine } = fields

  useFieldsChanged(() => {
    onChange({
      ...option,
      name: name.value,
      internalDisplayPreference: howToDisplay.value,
      internalName: summarizeInOneLine.value,
    })
  }, fields)

  useImperativeHandle(ref, () => ({
    validate,
  }))

  const showSummarizeInOneLine =
    howToDisplay.value === InternalDisplayPreference.SUMMARIZED

  const showDelete = Boolean(onDelete)

  return (
    <>
      <Grid item xs="auto">
        <InputComponent />
      </Grid>
      <Grid item xs>
        <Grid container>
          <Grid item xs>
            <PuiTextArea
              field={name}
              minRows={3}
              placeholder={namePlaceholder}
            />
          </Grid>
          {showDelete && (
            <Grid item pl={1} pt={1}>
              <IconButton
                size="small"
                sx={{ color: (theme) => theme.colors.grayGray2 }}
                onClick={onDelete}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item pl={4} xs={12}>
        <FormControl margin="none" sx={{ width: 400 }}>
          <InputLabel sx={{ marginRight: 0 }}>{howToDisplay.label}</InputLabel>
          <PuiSelect
            field={howToDisplay}
            items={internalDisplayPreferenceSelectItems}
          />
        </FormControl>
        {showSummarizeInOneLine && (
          <PuiTextField
            field={summarizeInOneLine}
            label={summarizeInOneLine.label}
            sx={{ width: 400, marginY: 0, marginLeft: 2 }}
          />
        )}
      </Grid>
    </>
  )
})
