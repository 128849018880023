import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { ErrorTooltip, Field, PuiCheckbox } from '@pbt/pbt-ui-components'

import { AlertBanner } from '~/components/elements/AlertBanner/AlertBanner'
import Typography from '~/components/elements/Typography/Typography'

const useStyles = makeStyles(
  (theme) => ({
    warningText: {
      color: theme.colors.important,
    },
    hasError: {
      color: theme.colors.errorColor,
    },
    tooltip: {
      maxWidth: 500,
      width: 500,
    },
  }),
  { name: 'AppointmentCancellationConsentMessage' },
)

export interface AppointmentCancellationConsentMessageProps {
  field: Field
}

const AppointmentCancellationConsentMessage = ({
  field,
}: AppointmentCancellationConsentMessageProps) => {
  const { t } = useTranslation(['Common'])
  const classes = useStyles()
  const hasError = field.errors.length > 0 && field.open
  return (
    <ErrorTooltip
      className={classes.tooltip}
      classes={{ errorTooltip: classes.tooltip }}
      message={field.message}
      open={hasError}
      placement="bottom"
    >
      <FormControl fullWidth error={!field.value} margin="normal">
        <AlertBanner
          ContainerProps={{ mt: 1, p: 0.5 }}
          content={
            <>
              <Typography.Paragraph className={classes.warningText}>
                {t('Common:CANCELLATION_POLICY_CONTENT')}
              </Typography.Paragraph>
              <PuiCheckbox
                checkboxClasses={{
                  root: classNames({
                    [classes.hasError]: hasError,
                  }),
                }}
                checked={field.value}
                label={t('Common:PET_PARENT_AWARE_OF_CANCELLATION_POLICY')}
                onChange={(event) => field.setValue(event.target.checked)}
              />
            </>
          }
          variant="warning"
        />
      </FormControl>
    </ErrorTooltip>
  )
}

export default AppointmentCancellationConsentMessage
