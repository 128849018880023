import { gql } from '@apollo/client'

import {
  CHARGE_SHEET_EVENTS,
  CHARGE_SHEET_SECTIONS_FIELDS,
  CLIENT_FIELDS,
} from './chargeSheet'
import { PATIENT_FIELDS } from './clientAndPatient'
import { INVOICE_SECTION_EVENT, RETAIL_ORDER_LINE_ITEM_FIELDS } from './finance'
import { PAYMENT_FIELDS } from './payments'

export const RETAIL_ORDER_FIELDS = gql`
  ${RETAIL_ORDER_LINE_ITEM_FIELDS}
  ${INVOICE_SECTION_EVENT}
  fragment RetailOrderFields on Invoice {
    id
    retailOrder {
      id
      discountAmount
      subTotal
      taxAmount
      totalPrice
      retailDeliveryFee
      shippingAmount
      orderConfirmationId
      orderDate
      modificationDate
      shippingAddress {
        id
      }
      paymentDetails {
        __typename
        paymentMethod {
          ... on CardPaymentMethod {
            __typename
            id
            paymentCardType {
              id
              name
            }
            lastFour
          }
          ... on PayPalPaymentMethod {
            __typename
            id
          }
          ... on ChewyAccountBalancePaymentMethod {
            __typename
            id
          }
        }
        amountPaid
      }
      state {
        id
        name
      }
      sections {
        id
        soap {
          id
          assignedVetId
          finalized
          updatedAt
        }
        patient {
          id
          name
        }
        soapId
        event {
          ...InvoiceSectionEvent
        }
        groupedItems {
          ...RetailOrderLineItemFields
        }
      }
    }
  }
`

const GRAND_TOTAL_FIELDS = gql`
  fragment GrandTotalFields on Invoice {
    id
    grandTotal {
      total
      subTotal
      tax
      discount
      shipping
    }
  }
`

export const INVOICE_FIELDS = (includeRetailOrderLineItems: boolean) => gql`
  ${PAYMENT_FIELDS}
  ${CHARGE_SHEET_SECTIONS_FIELDS(includeRetailOrderLineItems)}
  ${CHARGE_SHEET_EVENTS}
  ${PATIENT_FIELDS}
  ${CLIENT_FIELDS(includeRetailOrderLineItems)}
  ${includeRetailOrderLineItems ? RETAIL_ORDER_FIELDS : ''}
  ${includeRetailOrderLineItems ? GRAND_TOTAL_FIELDS : ''}
  fragment InvoiceFields on Invoice {
    id
    invoiceNo
    internalNote
    clientId
    balanceAfterPaid
    businessId
    type
    subtotal
    totalTax
    additionalDiscount
    discountReasonId
    discountReasonName
    amount
    amountNoFee
    state
    stateId
    dueToPay
    dueToPayNoFee
    paid
    paidAmount
    paidDate
    serviceFeeAmount
    showBalance
    prevBalance
    totalDiscount
    modificationDate
    innerModificationDate
    refunds {
      id
      state {
        id
        name
      }
      payments {
        ...PaymentFields
      }
    }
    pendingRefundInvoice {
      id
    }
    refundDisabledReason {
      id
      name
    }
    client {
      ...ClientFields
    }
    events {
      ...ChargeSheetEvents
    }
    groups: sections {
      ...ChargeSheetSectionFields
    }
    patients {
      ...PatientFields
    }
    persons {
      id
      firstName
      lastName
    }
    payments {
      ...PaymentFields
    }
    ${includeRetailOrderLineItems ? `...RetailOrderFields` : ``}
    ${includeRetailOrderLineItems ? `...GrandTotalFields` : ``}
  }
`
