import React, { useMemo } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog } from '@pbt/pbt-ui-components'

import { useGetPrescriptionStateType } from '~/store/hooks/prescription'
import { getWorkflowType } from '~/utils/prescription'

import ChewyPrescriptionReadOnly from '../ChewyPrescriptionReadOnly'
import PrescriptionActions from '../prescription-editor/actions/PrescriptionActions'
import usePrescriptionEditor, {
  PrescriptionEditorProps,
} from '../prescription-editor/hooks/usePrescriptionEditor'
import PrescriptionBody from '../prescription-editor/PrescriptionBody'
import PrescriptionHeader from '../prescription-editor/PrescriptionHeader'
import {
  PrescriptionActionContext,
  PrescriptionActionContextType,
} from '../PrescriptionContext'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    buttonContainer: {
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
      backgroundColor: theme.colors.tableBackground,
    },
    hidden: {
      display: 'none',
    },
  }),
  { name: 'BasePrescriptionDialog' },
)

interface BasePrescriptionDialogProps
  extends PrescriptionEditorProps,
    Pick<BasePuiDialogProps, 'open'> {}

const BasePrescriptionDialog = ({
  open,
  ...props
}: BasePrescriptionDialogProps) => {
  const classes = useStyles()

  const {
    getHasUnsavedChanges,
    isAutomateChewyRxTasksEnabled,
    isChewyActiveRxReadOnly,
    isChewyReactiveRxReadOnly,
    isChewyReadOnly,
    ...prescriptionBodyProps
  } = usePrescriptionEditor(props)

  const {
    activeWorkflow,
    disableDrafts,
    clientId,
    currentDoctorHasDvmLicense,
    handleSave,
    isChewyCatalogItemNotLinked,
    isDvmLicenseRequired,
    isEdit,
    isTemplatesLoaded,
    outsideSoap,
    patientId,
    prescription,
    isCompoundingReasonRequired,
    isRetailOrderLineItem,
    fields: { automaticallyCreateTask },
  } = prescriptionBodyProps

  const { isApproved } = useGetPrescriptionStateType()(prescription.stateId)

  const actionContext = useMemo<PrescriptionActionContextType>(
    () => ({
      disableDrafts,
      automaticallyCreateTaskField: automaticallyCreateTask,
      isAutomateChewyRxTasksEnabled,
      isRetailOrderLineItem,
      isChewyCatalogItemNotLinked,
      isCompounded: Boolean(isCompoundingReasonRequired),
      isDvmLicenseRequired,
      isEdit,
      prescriptionSignerId: prescription.signerId || prescription.signer?.id,
      onApprove: () => handleSave({ isApprove: true }),
      onDecline: (declineReason: string) => handleSave({ declineReason }),
      onEmail: () => handleSave({ email: true }),
      onPrint: () => handleSave({ print: true }),
      onSave: handleSave,
      onSaveDraft: () => handleSave({ isSaveDraft: true }),
      patientId,
      stateId: prescription.stateId,
      isChewyActiveRxReadOnly,
    }),
    [
      disableDrafts,
      automaticallyCreateTask,
      isAutomateChewyRxTasksEnabled,
      isChewyCatalogItemNotLinked,
      isCompoundingReasonRequired,
      isDvmLicenseRequired,
      isEdit,
      prescription,
      handleSave,
      isChewyActiveRxReadOnly,
    ],
  )

  const { isChewyActiveRx } = getWorkflowType(activeWorkflow)

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: () => handleSave({ isActiveRxSaveDraft: isChewyActiveRx }),
      }}
      actions={
        isTemplatesLoaded && !isChewyReactiveRxReadOnly ? (
          <PrescriptionActionContext.Provider value={actionContext}>
            <PrescriptionActions
              currentDoctorHasDvmLicense={currentDoctorHasDvmLicense}
              outsideSoap={outsideSoap}
              workflow={activeWorkflow}
            />
          </PrescriptionActionContext.Provider>
        ) : null
      }
      aria-labelledby="prescription-dialog"
      classes={{
        paper: classes.paper,
        actions:
          isChewyActiveRxReadOnly && !isApproved ? classes.hidden : undefined,
      }}
      hasUnsavedChanges={getHasUnsavedChanges}
      header={
        !isChewyReactiveRxReadOnly && (
          <PrescriptionHeader
            clientId={clientId}
            patientId={patientId}
            prescription={prescription}
          />
        )
      }
      open={open}
      scroll="paper"
      onClose={props.onClose}
    >
      {isChewyReadOnly ? (
        <ChewyPrescriptionReadOnly
          clientId={clientId}
          isChewyReactiveRxReadOnly={isChewyReactiveRxReadOnly}
          p={2}
          patientId={patientId}
          prescription={prescription}
        />
      ) : (
        <PrescriptionBody {...prescriptionBodyProps} />
      )}
    </PuiDialog>
  )
}

export default BasePrescriptionDialog
