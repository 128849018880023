import React, { useRef, useState } from 'react'
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreHoriz,
} from '@mui/icons-material'
import { IconButton, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ButtonWithLoader,
  ClassesType,
  PuiPopperProps,
} from '@pbt/pbt-ui-components'

import ActionsPopper, { PopperAction } from '../ActionsPopper'

const useStyles = makeStyles(
  (theme) => ({
    icon: {
      width: 20,
      height: 20,
    },
    arrow: {
      fontSize: '2rem',
    },
    iconButton: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.5),
    },
  }),
  { name: 'ActionsButton' },
)

export interface ActionsButtonProps {
  ButtonComponent?: React.JSXElementConstructor<any>
  Icon?: React.JSXElementConstructor<any>
  actions?: (() => PopperAction[]) | PopperAction[]
  children?: React.ReactNode
  className?: string
  classes?: ClassesType<typeof useStyles>
  closeOnTooltipsEvents?: PuiPopperProps['closeOnTooltipsEvents']
  disabled?: boolean
  isLoading?: boolean
  loading?: boolean
  placement?: PuiPopperProps['placement']
  rootClassName?: string
  useIconButton?: boolean
  useNormalButton?: boolean
}

const ActionsButton = ({
  useIconButton = true,
  useNormalButton = false,
  ButtonComponent,
  Icon = MoreHoriz,
  actions: actionsProp,
  classes: classesProp,
  isLoading,
  placement,
  className,
  closeOnTooltipsEvents = false,
  ...rest
}: ActionsButtonProps) => {
  const classes = useStyles({ classes: classesProp })

  const [popperOpen, setPopperOpen] = useState(false)

  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleTogglePopper = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setPopperOpen(!popperOpen)
  }

  const handleClosePopper = () => {
    setPopperOpen(false)
  }

  const getActions = () =>
    typeof actionsProp === 'function' ? actionsProp() : actionsProp

  return (
    <>
      {isLoading ? (
        <Skeleton height={20} variant="circular" width={20} />
      ) : useNormalButton ? (
        <ButtonWithLoader
          className={className}
          endIcon={
            popperOpen ? (
              <KeyboardArrowUp className={classes.arrow} />
            ) : (
              <KeyboardArrowDown className={classes.arrow} />
            )
          }
          ref={buttonRef}
          onClick={handleTogglePopper}
          {...rest}
        />
      ) : useIconButton ? (
        <IconButton
          className={classNames(className, classes.iconButton)}
          ref={buttonRef}
          size="large"
          onClick={handleTogglePopper}
          {...rest}
        >
          <Icon className={classes.icon} />
        </IconButton>
      ) : ButtonComponent ? (
        <ButtonComponent
          className={className}
          ref={buttonRef}
          onClick={handleTogglePopper}
          {...rest}
        />
      ) : null}
      {popperOpen && (
        <ActionsPopper
          actions={getActions()}
          anchorEl={buttonRef.current}
          closeOnTooltipsEvents={closeOnTooltipsEvents}
          placement={placement}
          onClose={handleClosePopper}
        />
      )}
    </>
  )
}

export default ActionsButton
