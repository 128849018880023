import React from 'react'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import { ClassesType } from '@pbt/pbt-ui-components'

import { Invoice as GraphqlInvoice } from '~/api/graphql/generated/types'
import StaticTable, {
  StaticTableProps,
} from '~/components/common/lists/table/StaticTable'
import { TableAmountCell } from '~/components/dashboard/clients/balance/table/common/TableAmountCell'
import {
  getInvoiceDate,
  getInvoiceDueToPayNoFee,
} from '~/components/dashboard/invoices/invoiceUtils'
import i18nPortal from '~/locales/i18n'
import { Invoice } from '~/types'

import { TableDateCell } from '../common/TableDateCell'
import { InvoiceTableDoctorCell } from './InvoiceTableDoctorCell'
import { InvoiceTableEventTypeCell } from './InvoiceTableEventTypeCell'
import { InvoiceTableInvoiceNoCell } from './InvoiceTableInvoiceNoCell'
import { InvoiceTablePatientNameCell } from './InvoiceTablePatientNameCell'
import { InvoiceTableStatusCell } from './InvoiceTableStatusCell'

export const CREDIT_ADJUSTMENT_ROW_HEIGHT = 40

const useStyles = makeStyles(
  (theme) => ({
    cell: {
      padding: theme.spacing(0, 1),
    },
    hidden: {
      display: 'none',
    },
    row: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
      height: CREDIT_ADJUSTMENT_ROW_HEIGHT,
    },
    tableHead: {
      border: theme.constants.tableBorder,
    },
    titleCell: {
      '&:first-child': {
        paddingLeft: theme.spacing(5.25),
      },
      fontWeight: 500,
    },
    removeBorder: {
      border: 0,
    },
  }),
  { name: 'UnpaidInvoicesTable' },
)

export interface UnpaidInvoicesTableProps {
  classes?: StaticTableProps<any>['classes']
  hasMissingSelectedInvoiceError: boolean
  isLoading: boolean
  onCheck: (invoiceId: string) => void
  selectedInvoiceIds: string[]
  unpaidInvoices: GraphqlInvoice[]
}

type RowItem = Invoice & {
  isChecked: boolean
  onCheck: (invoiceId: string) => void
}

const getColumns = ({
  classes,
  hasError,
}: {
  classes: ClassesType<typeof useStyles>
  hasError: boolean
}) => [
  {
    id: 'date',
    prop: ({ isChecked, onCheck, ...invoice }: RowItem) => (
      <TableDateCell
        date={getInvoiceDate(invoice)}
        hasError={hasError}
        id={invoice.id}
        isChecked={isChecked}
        onCheck={onCheck}
      />
    ),
    label: i18nPortal.t('Common:DATE_TIME'),
    className: classNames(classes.titleCell, classes.removeBorder),
  },
  {
    id: 'number',
    prop: (invoice: RowItem) => <InvoiceTableInvoiceNoCell invoice={invoice} />,
    label: i18nPortal.t('Common:INVOICE'),
    className: classes.removeBorder,
  },
  {
    id: 'doctor',
    prop: (invoice: RowItem) => <InvoiceTableDoctorCell invoice={invoice} />,
    label: i18nPortal.t('Common:DOCTOR'),
    className: classes.removeBorder,
  },
  {
    id: 'patientName',
    prop: (invoice: RowItem) => (
      <InvoiceTablePatientNameCell invoice={invoice} />
    ),
    label: i18nPortal.t('Common:PATIENT'),
    className: classes.removeBorder,
  },
  {
    id: 'eventType',
    prop: (invoice: RowItem) => <InvoiceTableEventTypeCell invoice={invoice} />,
    label: i18nPortal.t('Common:APPOINTMENT_ONE'),
    className: classes.removeBorder,
  },
  {
    id: 'status',
    prop: (invoice: RowItem) => <InvoiceTableStatusCell invoice={invoice} />,
    label: i18nPortal.t('Common:STATUS'),
    className: classes.removeBorder,
  },
  {
    id: 'dueToPayNoFee',
    prop: (invoice: RowItem) => {
      const dueToPayNoFee = getInvoiceDueToPayNoFee(
        invoice as Invoice | GraphqlInvoice,
      )
      return <TableAmountCell amount={dueToPayNoFee} />
    },
    label: i18nPortal.t('Common:TOTAL'),
    className: classes.removeBorder,
  },
]

export const UnpaidInvoicesTable = ({
  classes: classesProp,
  hasMissingSelectedInvoiceError,
  isLoading,
  unpaidInvoices,
  selectedInvoiceIds,
  onCheck,
}: UnpaidInvoicesTableProps) => {
  const classes = useStyles()

  const rows = unpaidInvoices.map((invoice) => ({
    ...invoice,
    isChecked: R.includes(invoice.id, selectedInvoiceIds),
    onCheck,
  }))

  return (
    <StaticTable
      classes={{
        cell: classes.cell,
        row: classes.row,
        header: classes.tableHead,
        titleCell: classes.titleCell,
        ...classesProp,
      }}
      columns={getColumns({
        classes,
        hasError: hasMissingSelectedInvoiceError,
      })}
      rows={isLoading ? (Array.from({ length: 4 }, () => []) as any) : rows}
    />
  )
}
