import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { fetchBusiness } from '~/store/actions/businesses'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getBusiness } from '~/store/reducers/businesses'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getBusinessIsGroup } from '~/utils/businessUtils'

export const useDocumentDetailsDisabled = (
  documentBusinessId: string | Nil,
) => {
  const dispatch = useDispatch()
  const documentBusiness = useSelector(getBusiness(documentBusinessId))
  const currentBusinessId = useSelector(getCurrentBusinessId)

  const isDocumentBusinessGroup = getBusinessIsGroup(documentBusiness)

  const isGroupDocumentsSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.GROUP_DOCUMENTS_SHARING),
  )

  const isCurrentBusinessChild = currentBusinessId !== documentBusinessId

  useEffect(() => {
    if (documentBusinessId && isCurrentBusinessChild) {
      dispatch(fetchBusiness(documentBusinessId))
    }
  }, [documentBusinessId, isCurrentBusinessChild])

  return (
    isGroupDocumentsSharingEnabled &&
    isDocumentBusinessGroup &&
    isCurrentBusinessChild
  )
}
