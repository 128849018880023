import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { LanguageUtils, Nil } from '@pbt/pbt-ui-components'

import {
  fetchClientPreferences,
  getClientPreferences,
} from '~/store/duck/clientPreferences'
import {
  getPreferenceAttitudeTowardsStaff,
  getPreferenceCadenceOfVisits,
  getPreferenceClientPerformedTreatmentsLocations,
  getPreferenceDayOfWeek,
  getPreferenceExtentOfCare,
  getPreferenceFinancialComfort,
  getPreferenceGenderIdentity,
  getPreferenceNeedsExtendedAppointmentTime,
  getPreferencePaymentMethod,
  getPreferenceServicePreferences,
  getPreferenceTimeOfDay,
} from '~/store/reducers/constants'
import { TimetableEvent } from '~/types'
import { ClientPreferencesDisplayData } from '~/types/entities/clientPreferences'

import {
  DisplayLocationName,
  useFormPreferencesDisplay,
} from '../useFormPreferencesDisplay'

export const useGetClientPreferencesViewData = (
  clientId: string | Nil,
  appointment?: TimetableEvent | Nil,
): ClientPreferencesDisplayData => {
  const { t } = useTranslation(['Clients', 'Common'])
  const dispatch = useDispatch()

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientPreferences({ id: clientId }))
    }
  }, [clientId])

  const genderIdentityConstants = useSelector(getPreferenceGenderIdentity)
  const attitudeTowardsStaffConstants = useSelector(
    getPreferenceAttitudeTowardsStaff,
  )
  const paymentMethodConstants = useSelector(getPreferencePaymentMethod)
  const financialComfortConstants = useSelector(getPreferenceFinancialComfort)
  const extentOfCareConstants = useSelector(getPreferenceExtentOfCare)
  const servicePreferenceConstants = useSelector(
    getPreferenceServicePreferences,
  )
  const locationOfPerformedTreatmentConstants = useSelector(
    getPreferenceClientPerformedTreatmentsLocations,
  )
  const timeOfDayConstants = useSelector(getPreferenceTimeOfDay)
  const needsExtendedAppointmentTimeConstants = useSelector(
    getPreferenceNeedsExtendedAppointmentTime,
  )
  const cadenceOfVisitConstants = useSelector(getPreferenceCadenceOfVisits)
  const dayOfWeekConstants = useSelector(getPreferenceDayOfWeek)

  const preferences = useSelector(getClientPreferences(clientId))

  const forms = useFormPreferencesDisplay(
    DisplayLocationName.CLIENT,
    appointment,
  )
  const hasForms = forms && forms.length > 0

  const hasPersonal =
    preferences?.genderIdentity ||
    preferences?.genderIdentityOther ||
    preferences?.attitudeTowardsStaff ||
    preferences?.topicsToAvoid
  const hasFinancial =
    preferences?.paymentMethod ||
    preferences?.financialComfort ||
    preferences?.concernForNonpayment !== undefined ||
    preferences?.financialComment
  const hasCare =
    preferences?.extentOfCare ||
    preferences?.servicePreference ||
    preferences?.doctors?.length ||
    preferences?.spaces?.length ||
    preferences?.locationOfPerformedTreatment ||
    preferences?.careComment
  const hasScheduling =
    preferences?.dayOfWeek?.length ||
    preferences?.timeOfDay?.length ||
    preferences?.cadenceOfVisit?.length ||
    preferences?.needsExtendedAppointmentTime ||
    preferences?.clientChronicallyLate !== undefined ||
    preferences?.clientChronicallyNoShow !== undefined ||
    preferences?.schedulingComment

  const personalDisplay = hasPersonal
    ? {
        categoryTitle: t('Clients:CLIENT_PREFERENCES.PERSONAL.TITLE'),
        subCategory: [
          ...(preferences.genderIdentity
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.PERSONAL.GENDER_IDENTITY',
                  ),
                  subCategoryPreferences:
                    LanguageUtils.getConstantTranslatedName(
                      preferences.genderIdentity.id,
                      genderIdentityConstants,
                    ),
                },
              ]
            : []),
          ...(preferences.genderIdentityOther
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.PERSONAL.GENDER_IDENTITY_OTHER',
                  ),
                  subCategoryPreferences: preferences.genderIdentityOther,
                },
              ]
            : []),
          ...(preferences.attitudeTowardsStaff
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.PERSONAL.ATTITUDE_TOWARDS_STAFF',
                  ),
                  subCategoryPreferences:
                    LanguageUtils.getConstantTranslatedName(
                      preferences.attitudeTowardsStaff.id,
                      attitudeTowardsStaffConstants,
                    ),
                },
              ]
            : []),
          ...(preferences.topicsToAvoid
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.PERSONAL.TOPICS_TO_AVOID',
                  ),
                  subCategoryPreferences: preferences.topicsToAvoid,
                },
              ]
            : []),
        ],
      }
    : undefined

  const financialDisplay = hasFinancial
    ? {
        categoryTitle: t('Clients:CLIENT_PREFERENCES.FINANCIAL.TITLE'),
        subCategory: [
          ...(preferences.paymentMethod
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.FINANCIAL.PAYMENT_METHOD',
                  ),
                  subCategoryPreferences:
                    LanguageUtils.getConstantTranslatedName(
                      preferences.paymentMethod.id,
                      paymentMethodConstants,
                    ),
                },
              ]
            : []),
          ...(preferences.financialComfort
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.FINANCIAL.FINANCIAL_COMFORT',
                  ),
                  subCategoryPreferences:
                    LanguageUtils.getConstantTranslatedName(
                      preferences.financialComfort.id,
                      financialComfortConstants,
                    ),
                },
              ]
            : []),
          ...(Object.prototype.hasOwnProperty.call(
            preferences,
            'concernForNonpayment',
          )
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.FINANCIAL.CONCERN_FOR_NONPAYMENT',
                  ),
                  subCategoryPreferences: preferences.concernForNonpayment
                    ? [
                        t(
                          'Clients:CLIENT_PREFERENCES.FINANCIAL.CONCERN_FOR_NONPAYMENT_TRUE',
                        ),
                      ]
                    : [
                        t(
                          'Clients:CLIENT_PREFERENCES.FINANCIAL.CONCERN_FOR_NONPAYMENT_FALSE',
                        ),
                      ],
                },
              ]
            : []),
          ...(preferences.financialComment
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.FINANCIAL.COMMENT',
                  ),
                  subCategoryPreferences: [preferences.financialComment] || [],
                },
              ]
            : []),
        ],
      }
    : undefined

  const careDisplay = hasCare
    ? {
        categoryTitle: t('Clients:CLIENT_PREFERENCES.CARE.TITLE'),
        subCategory: [
          ...(preferences.extentOfCare
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.CARE.EXTENT_OF_CARE',
                  ),
                  subCategoryPreferences:
                    LanguageUtils.getConstantTranslatedName(
                      preferences.extentOfCare.id,
                      extentOfCareConstants,
                    ),
                },
              ]
            : []),
          ...(preferences.servicePreference
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.CARE.SERVICE_PREFERENCES',
                  ),
                  subCategoryPreferences:
                    LanguageUtils.getConstantTranslatedName(
                      preferences.servicePreference.id,
                      servicePreferenceConstants,
                    ),
                },
              ]
            : []),
          ...(preferences.doctors
            ? [
                {
                  subCategoryTitle: t('Clients:CLIENT_PREFERENCES.CARE.DOCTOR'),
                  subCategoryPreferences: preferences.doctors
                    .filter((doctor) => doctor.firstName || doctor.lastName)
                    .map(
                      (doctor) =>
                        `${doctor.firstName ?? ''} ${doctor.lastName ?? ''}`,
                    ),
                },
              ]
            : []),
          ...(preferences.spaces
            ? [
                {
                  subCategoryTitle: t('Clients:CLIENT_PREFERENCES.CARE.SPACES'),
                  subCategoryPreferences: preferences.spaces
                    .filter((space) => space?.name)
                    .map((space) => space?.name!),
                },
              ]
            : []),
          ...(preferences.locationOfPerformedTreatment
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.CARE.LOCATION_OF_PERFORMED_TREATMENTS',
                  ),
                  subCategoryPreferences:
                    LanguageUtils.getConstantTranslatedName(
                      preferences.locationOfPerformedTreatment.id,
                      locationOfPerformedTreatmentConstants,
                    ),
                },
              ]
            : []),
          ...(preferences.careComment
            ? [
                {
                  subCategoryPreferences: preferences.careComment,
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.CARE.COMMENT',
                  ),
                },
              ]
            : []),
        ],
      }
    : undefined

  const schedulingDisplay = hasScheduling
    ? {
        categoryTitle: t('Clients:CLIENT_PREFERENCES.SCHEDULING.TITLE'),
        subCategory: [
          ...(preferences.dayOfWeek
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.SCHEDULING.DAY_OF_WEEK',
                  ),
                  subCategoryPreferences: preferences.dayOfWeek.map((day) =>
                    LanguageUtils.getConstantTranslatedName(
                      day.id,
                      dayOfWeekConstants,
                    ),
                  ),
                },
              ]
            : []),
          ...(preferences.timeOfDay
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.SCHEDULING.TIME_OF_DAY',
                  ),
                  subCategoryPreferences: preferences.timeOfDay.map((time) =>
                    LanguageUtils.getConstantTranslatedName(
                      time.id,
                      timeOfDayConstants,
                    ),
                  ),
                },
              ]
            : []),
          ...(Object.prototype.hasOwnProperty.call(
            preferences,
            'clientChronicallyLate',
          )
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.SCHEDULING.CHRONICALLY_LATE',
                  ),
                  subCategoryPreferences: preferences.clientChronicallyLate
                    ? [t('Common:YES')]
                    : [t('Common:NO')],
                },
              ]
            : []),
          ...(Object.prototype.hasOwnProperty.call(
            preferences,
            'clientChronicallyNoShow',
          )
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.SCHEDULING.CHRONICALLY_NO_SHOW',
                  ),
                  subCategoryPreferences: preferences.clientChronicallyNoShow
                    ? [t('Common:YES')]
                    : [t('Common:NO')],
                },
              ]
            : []),
          ...(preferences.needsExtendedAppointmentTime
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.SCHEDULING.NEED_EXTENDED_APPT_TIME',
                  ),
                  subCategoryPreferences:
                    LanguageUtils.getConstantTranslatedName(
                      preferences.needsExtendedAppointmentTime.id,
                      needsExtendedAppointmentTimeConstants,
                    ),
                },
              ]
            : []),
          ...(preferences.cadenceOfVisit
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.SCHEDULING.CADENCE_OF_VISITS',
                  ),
                  subCategoryPreferences: preferences.cadenceOfVisit.map(
                    (cadence) =>
                      LanguageUtils.getConstantTranslatedName(
                        cadence.id,
                        cadenceOfVisitConstants,
                      ),
                  ),
                },
              ]
            : []),
          ...(preferences.schedulingComment
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:CLIENT_PREFERENCES.SCHEDULING.COMMENT',
                  ),
                  subCategoryPreferences: [preferences.schedulingComment] || [],
                },
              ]
            : []),
        ],
      }
    : undefined

  const formsDisplay = hasForms
    ? {
        categoryTitle: t('Common:FORMS'),
        formEntries: forms,
      }
    : undefined

  return {
    personal: personalDisplay,
    financial: financialDisplay,
    care: careDisplay,
    scheduling: schedulingDisplay,
    forms: formsDisplay,
  }
}
