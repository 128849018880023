import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Grid, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  ClassesType,
  PermissionArea,
  Text,
} from '@pbt/pbt-ui-components'

import { PrescriptionStateLabel } from '~/components/common/labels/PrescriptionStateLabel'
import { useCanSubmitApprovedChewyActiveRx } from '~/components/dashboard/prescription/prescription-editor/hooks/useCanSubmitApprovedChewyActiveRx'
import {
  getIsFetchingChewyItems,
  getPrescriptionChewyItems,
  getPrescriptionError,
} from '~/store/duck/prescriptions'
import { usePatientWeight } from '~/store/hooks/patient'
import { useGetPrescriptionStateType } from '~/store/hooks/prescription'
import { getCRUDByArea, getCurrentUserId } from '~/store/reducers/auth'
import { getMemberIsLoading } from '~/store/reducers/members'
import { getPatient } from '~/store/reducers/patients'

import { PrescriptionActionExpandedContext } from '../../PrescriptionContext'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      padding: theme.spacing(1, 2),
    },
    saveButton: {},
    declineButton: {},
    stateLabel: {
      marginRight: 'auto',
    },
  }),
  { name: 'PrescriptionChewyActiveRxActions' },
)

export interface PrescriptionChewyActiveRxActionsProps {
  classes: ClassesType<typeof useStyles>
}

export const PrescriptionChewyActiveRxActions = ({
  classes: classesProp,
}: PrescriptionChewyActiveRxActionsProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Tooltips'])

  const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))
  const prescriptionError = useSelector(getPrescriptionError)
  const chewyItems = useSelector(getPrescriptionChewyItems)
  const isFetchingChewyItems = useSelector(getIsFetchingChewyItems)
  const currentUserId = useSelector(getCurrentUserId)!
  const memberIsLoading = useSelector(getMemberIsLoading)
  const canSubmitApprovedChewyActiveRx = useCanSubmitApprovedChewyActiveRx()

  const {
    disableDrafts,
    automaticallyCreateTaskField,
    canApproveAndSubmitChewyRx,
    isCompounded,
    isEdit,
    isLoadingOrUpdating,
    isSending,
    needsDvmLicenseEntry,
    needsDvmLicenseEntryTooltip,
    prescriptionSignerId,
    stateId,
    patientId,
    onSave,
    isChewyActiveRxReadOnly,
  } = useContext(PrescriptionActionExpandedContext)

  const patient = useSelector(getPatient(patientId))
  const patientWeight = usePatientWeight(patientId)
  const noWeightOrDob = !patientWeight || !patient?.dateOfBirth
  const weightOrDobTooltip = noWeightOrDob
    ? t('Tooltips:PRESCRIPTION_NEED_WEIGHT_AND_DOB')
    : null
  const approveAndSubmitTooltip =
    needsDvmLicenseEntryTooltip || weightOrDobTooltip

  const { isApproved } = useGetPrescriptionStateType()(stateId)

  const isDisabled =
    (isEdit && R.isNil(chewyItems)) ||
    Boolean(prescriptionError) ||
    isFetchingChewyItems

  if (isChewyActiveRxReadOnly) {
    return null
  }

  const handleSaveActiveRx = () => {
    onSave({ isActiveRxSaveDraft: true })
  }

  // 1. When “Approve without sending to Chewy” is clicked, then signerId is set to current user
  // 2. When “Send to Chewy” is clicked, then submitterId is set to current user
  // 3. When “Approve and Send to Chewy” is clicked, then both signerId and submitterId will be set to current user
  // 4. If the assigned doctor clicks “Approve without sending to Chewy”, then signerId and doctorId would be the same
  // 5. If the assigned doctor clicks “Approve and Send to Chewy”, then signerId, doctorId and submitterId would be the same
  // 6. If someone other than assigned doctor clicks “Approve without sending to Chewy”, then signerId will be different than doctorId  (Approve on behalf)
  // 7. After case 6, if another person clicks “Send to Chewy”, then doctorId, signerId, submitterId are different.
  const handleApproveAndSubmitActiveRx = () => {
    const signerId =
      prescriptionSignerId && (isApproved || isCompounded)
        ? prescriptionSignerId
        : currentUserId

    onSave({
      isActiveRxApproveAndSubmit: true,
      submitterId: currentUserId,
      signatureInfo: {
        signerId,
        signature: null,
      },
    })
  }

  const handleApproveActiveRx = () => {
    onSave({
      isActiveRxApprove: true,
      signatureInfo: {
        signerId: currentUserId,
        signature: null,
      },
    })
  }

  const disableSaveOrApproveWithTask =
    disableDrafts && automaticallyCreateTaskField.value

  return (
    <Grid display="flex" flexDirection="column" gap={1}>
      {stateId && (
        <Box alignItems="center" display="flex">
          <Text strong mr={1} variant="subheading3">{`${t(
            'Common:STATUS',
          )}:`}</Text>
          <PrescriptionStateLabel
            className={classes.stateLabel}
            stateId={stateId}
          />
        </Box>
      )}
      <Grid display="flex" gap={2}>
        {!isApproved && (
          <Tooltip
            title={
              disableSaveOrApproveWithTask
                ? t('Tooltips:PRESCRIPTION_DISABLED_DRAFTS_TOOLTIP')
                : ''
            }
          >
            <span>
              <ButtonWithLoader
                className={classNames(classes.button)}
                disabled={
                  !permissions.update ||
                  disableSaveOrApproveWithTask ||
                  isLoadingOrUpdating ||
                  isDisabled
                }
                key="activeRxSaveDraft"
                loading={isSending}
                onClick={handleSaveActiveRx}
              >
                {t('Common:SAVE_DRAFT_AND_CLOSE_ACTION')}
              </ButtonWithLoader>
            </span>
          </Tooltip>
        )}
        {isApproved
          ? canSubmitApprovedChewyActiveRx && (
              <span>
                <ButtonWithLoader
                  className={classNames(classes.button)}
                  disabled={
                    isLoadingOrUpdating || needsDvmLicenseEntry || isDisabled
                  }
                  key="activeRxSubmitApproved"
                  loading={isSending || memberIsLoading}
                  onClick={handleApproveAndSubmitActiveRx}
                >
                  {t('Common:SEND_TO_CHEWY')}
                </ButtonWithLoader>
              </span>
            )
          : canApproveAndSubmitChewyRx && (
              <>
                <Tooltip placement="top" title={approveAndSubmitTooltip}>
                  <span>
                    <ButtonWithLoader
                      className={classNames(classes.button)}
                      disabled={
                        isLoadingOrUpdating ||
                        needsDvmLicenseEntry ||
                        noWeightOrDob ||
                        isDisabled
                      }
                      key="activeRxSubmit"
                      loading={isSending || memberIsLoading}
                      onClick={handleApproveAndSubmitActiveRx}
                    >
                      {t('Common:APPROVE_AND_SUBMIT_ACTION')}
                    </ButtonWithLoader>
                  </span>
                </Tooltip>

                <Tooltip
                  placement="top"
                  title={
                    needsDvmLicenseEntry
                      ? needsDvmLicenseEntryTooltip
                      : disableSaveOrApproveWithTask
                        ? t('Tooltips:PRESCRIPTION_DISABLED_APPROVAL_TOOLTIP')
                        : ''
                  }
                >
                  <span>
                    <ButtonWithLoader
                      className={classNames(classes.button)}
                      color="secondary"
                      disabled={
                        disableSaveOrApproveWithTask ||
                        isLoadingOrUpdating ||
                        needsDvmLicenseEntry ||
                        isDisabled
                      }
                      key="activeRxApprove"
                      loading={isSending || memberIsLoading}
                      onClick={handleApproveActiveRx}
                    >
                      {t('Common:APPROVE_WITHOUT_SENDING_ACTION')}
                    </ButtonWithLoader>
                  </span>
                </Tooltip>
              </>
            )}
      </Grid>
    </Grid>
  )
}
