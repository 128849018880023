import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Nil, Patient, User } from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import {
  PathChargeSheetRegexMap,
  PathClientRegexMap,
  PathClientRegexNameMap,
  PathCommunicationsRegexMap,
  PathInvoiceRegexMap,
  PathLabTestRegexMap,
  PathSoapRegexMap,
  PathTimetableRegexMap,
  RouteNameMap,
  TimetableRoute,
} from '~/constants/routes'
import { getLabTest } from '~/store/duck/labTestsDashboard'
import { getConversationById } from '~/store/reducers/conversations'
import { getInvoiceV3 } from '~/store/reducers/invoiceV3'
import { getPatient } from '~/store/reducers/patients'
import { getClientId, getPatientId } from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import { RouteTitleMeta } from '~/types'
import { InvoiceV3 } from '~/types/entities/invoiceV3'

import { testAndMatch } from '.'
import { getRouteTitleByPath } from './routes'

const getEntityRouteTitle = (
  main: string,
  tab: string | Nil,
  client?: User | Nil,
  patient?: Patient | Nil,
) => {
  const entityStr =
    patient || client ? `: ${[patient?.name, client?.lastName].join(' ')}` : ''

  return [main, tab ? ` ${tab}` : '', entityStr].join('')
}

const getEntityHistoryTitle = (
  main: string,
  client?: User,
  patient?: Patient,
) =>
  client
    ? patient
      ? `${main}: ${[patient?.name, client?.lastName].join(' ')}`
      : `${main}: ${[client?.firstName, client?.lastName].join(' ')}`
    : undefined

const getTimetableRouteTitleMeta = (
  path: string,
): RouteTitleMeta | undefined => {
  const { match, matches } = testAndMatch(
    path,
    RegExp(PathTimetableRegexMap.TIMETABLE),
  )

  const [type, tab, date] = matches as [TimetableRoute, TimetableRoute, string]

  return match
    ? {
        title: `${RouteNameMap[type]}${
          tab && RouteNameMap[tab] ? ` ${RouteNameMap[tab]}` : ''
        }${date ? `: ${date}` : ''}`,
        historyTitle: RouteNameMap[type],
      }
    : undefined
}

function useClientAndPatientRouteTitleMeta(): RouteTitleMeta | undefined {
  const location = useLocation()
  const { t } = useTranslation('Common')

  const path = location.pathname + location.search

  const matchedRegex = Object.values(PathClientRegexMap).find((regex: string) =>
    RegExp(regex).test(path),
  )

  const {
    match,
    matches: [clientId, patientId],
  } = testAndMatch(path, matchedRegex ? RegExp(matchedRegex) : null)

  const tabName = matchedRegex && PathClientRegexNameMap[matchedRegex]

  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))

  const routeTitle =
    match &&
    getEntityRouteTitle(
      patientId ? t('Common:PATIENT') : t('Common:CLIENT'),
      tabName,
      client,
      patient,
    )

  return routeTitle
    ? {
        title: routeTitle,
        historyTitle: getEntityHistoryTitle(
          tabName || (patient ? t('Common:PATIENT') : t('Common:CLIENT')),
          client,
          patient,
        ),
      }
    : undefined
}

function useSoapRouteTitleMeta(): RouteTitleMeta | undefined {
  const location = useLocation()
  const { t } = useTranslation('Common')

  const path = location.pathname + location.search

  const match = RegExp(PathSoapRegexMap.SOAP).test(path)
  // ignore create url
  const isCreate =
    RegExp(PathSoapRegexMap.SOAP_CREATE).test(path) ||
    RegExp(PathSoapRegexMap.SOAP_ADD).test(path)

  const soapClientId = useSelector(getClientId)
  const soapPatientId = useSelector(getPatientId)
  const client = useSelector(getUser(soapClientId))
  const patient = useSelector(getPatient(soapPatientId))

  const routeTitle =
    match &&
    !isCreate &&
    getEntityRouteTitle(t('Common:SOAP'), null, client, patient)

  return routeTitle
    ? {
        title: routeTitle,
        historyTitle: getEntityHistoryTitle(t('Common:SOAP'), client, patient),
      }
    : undefined
}

function useLabTestRouteTitleMeta(): RouteTitleMeta | undefined {
  const location = useLocation()
  const { t } = useTranslation('Common')

  const path = location.pathname + location.search

  const {
    match,
    matches: [labTestIdentifier],
  } = testAndMatch(path, RegExp(PathLabTestRegexMap.LAB_TEST))

  const labTest = useSelector(getLabTest(labTestIdentifier))

  const clientId = labTest?.client
  const patientId = labTest?.patient

  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))

  const routeTitle =
    match && getEntityRouteTitle(t('Common:LAB_TEST'), null, client, patient)

  return routeTitle
    ? {
        title: routeTitle,
        historyTitle: null,
      }
    : undefined
}

function useChargeScheetRouteTitleMeta(): RouteTitleMeta | undefined {
  const location = useLocation()
  const { t } = useTranslation('Common')

  const path = location.pathname + location.search

  const {
    match,
    matches: [clientId],
  } = testAndMatch(path, RegExp(PathChargeSheetRegexMap.CHARGE_SHEET))

  const client = useSelector(getUser(clientId))

  const routeTitle =
    match && getEntityRouteTitle(t('Common:CHARGE_SHEET'), null, client)

  return routeTitle
    ? {
        title: routeTitle,
        historyTitle: getEntityHistoryTitle(t('Common:CHARGE_SHEET'), client),
      }
    : undefined
}

function useInvoiceRouteTitleMeta(): RouteTitleMeta | undefined {
  const location = useLocation()
  const { t } = useTranslation('Common')

  const path = location.pathname + location.search

  const {
    match,
    matches: [invoiceId],
  } = testAndMatch(path, RegExp(PathInvoiceRegexMap.INVOICE))

  const invoice = useSelector(getInvoiceV3(invoiceId)) as InvoiceV3
  const client = useSelector(getUser(invoice?.clientId))
  const routeTitle =
    match &&
    getEntityRouteTitle(
      `${t('Common:INVOICE')} ${invoice?.invoiceNo || ''}`,
      null,
      client,
    )

  return routeTitle
    ? {
        title: routeTitle,
        historyTitle: null,
      }
    : undefined
}

function useCommunicationsRouteTitleMeta(): RouteTitleMeta | undefined {
  const location = useLocation()
  const { t } = useTranslation('Common')

  const path = location.pathname + location.search

  const {
    match,
    matches: [communicationId],
  } = testAndMatch(path, RegExp(PathCommunicationsRegexMap.COMMUNICATIONS))

  const transportNames = {
    [ConversationTransport.Email]: t('Common:EMAIL'),
    [ConversationTransport.Sms]: t('Common:TEXT_MESSAGE'),
    [ConversationTransport.Boop]: `${t('Common:BOOP_SYSTEM_NAME')} ${t(
      'Common:CONVERSATION',
    )}`,
    [ConversationTransport.LogPhoneCall]: t('Common:LOG_PHONE_CALL'),
  }

  const conversation = useSelector(getConversationById(communicationId))
  const client = useSelector(getUser(conversation?.client))

  const routeTitle =
    match &&
    conversation?.transport &&
    Object.prototype.hasOwnProperty.call(conversation, 'client') &&
    getEntityHistoryTitle(transportNames[conversation?.transport], client)

  return routeTitle
    ? {
        title: routeTitle,
        historyTitle: routeTitle,
      }
    : undefined
}

const useRouteTitleMeta = () => {
  const location = useLocation()
  const path = location.pathname + location.search

  const clientAndPatientRouteTitleMeta = useClientAndPatientRouteTitleMeta()
  const soapRouteTitleMeta = useSoapRouteTitleMeta()
  const labTestRouteTitleMeta = useLabTestRouteTitleMeta()
  const timetableRouteTitleMeta = useMemo(
    () => getTimetableRouteTitleMeta(path),
    [path],
  )
  const chargeSheetRouteTitleMeta = useChargeScheetRouteTitleMeta()
  const invoiceRouteTitleMeta = useInvoiceRouteTitleMeta()
  const communicationsRouteTitleMeta = useCommunicationsRouteTitleMeta()

  const routeAreaMeta =
    timetableRouteTitleMeta ||
    labTestRouteTitleMeta ||
    soapRouteTitleMeta ||
    clientAndPatientRouteTitleMeta ||
    chargeSheetRouteTitleMeta ||
    invoiceRouteTitleMeta ||
    communicationsRouteTitleMeta

  const routeTitle = useMemo(() => getRouteTitleByPath(path), [path])

  return (
    routeAreaMeta || {
      title: routeTitle,
      historyTitle: routeTitle,
    }
  )
}

export default useRouteTitleMeta
