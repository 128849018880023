import {
  ApiError,
  Nil,
  UnsavedWellnessPlanVersion,
  WellnessPlan,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import {
  WellnessPlanBoopSignUpMessageContext,
  WellnessPlanCancellationData,
  WellnessPlanGlobalBenefitGroup,
  WellnessPlanPaymentMethod,
  WellnessPlanSavings,
  WellnessPlanSavingsData,
  WellnessPlanSettings,
  WellnessPlanValidationError,
  WellnessPlanWelcomeEmailContext,
} from '~/types'

import {
  ACTIVE_WELLNESS_PLAN_VERSION_CHANGED,
  CANCEL_WELLNESS_PLAN,
  CANCEL_WELLNESS_PLAN_FAILURE,
  CANCEL_WELLNESS_PLAN_OLD,
  CANCEL_WELLNESS_PLAN_OLD_FAILURE,
  CANCEL_WELLNESS_PLAN_OLD_SUCCESS,
  CANCEL_WELLNESS_PLAN_OLD_VALIDATION_FAILURE,
  CANCEL_WELLNESS_PLAN_SUCCESS,
  CLEAR_CANCEL_WELLNESS_PLAN_ERROR,
  CLEAR_CANCEL_WELLNESS_PLAN_OLD_VALIDATION_ERROR,
  CLEAR_CANCEL_WELLNESS_PLAN_SUCCESS,
  CLEAR_WELLNESS_PLAN_ERROR,
  CLEAR_WELLNESS_PLAN_ERROR_STATUS,
  CLEAR_WELLNESS_PLAN_SIGN_UP_TOKEN,
  CLEAR_WELLNESS_PLAN_VERSION_TEMPLATE,
  CLEAR_WELLNESS_PLAN_VERSION_VALIDATION_ERROR,
  CREATE_WELLNESS_PLAN_PAYMENT_METHOD,
  CREATE_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE,
  CREATE_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS,
  CREATE_WELLNESS_PLAN_VERSION,
  CREATE_WELLNESS_PLAN_VERSION_FAILURE,
  CREATE_WELLNESS_PLAN_VERSION_SUCCESS,
  CREATE_WELLNESS_PLAN_VERSION_VALIDATION_FAILURE,
  DELETE_WELLNESS_PLAN_VERSION,
  DELETE_WELLNESS_PLAN_VERSION_FAILURE,
  DELETE_WELLNESS_PLAN_VERSION_SUCCESS,
  EDIT_WELLNESS_PLAN_VERSION,
  EDIT_WELLNESS_PLAN_VERSION_FAILURE,
  EDIT_WELLNESS_PLAN_VERSION_SUCCESS,
  EDIT_WELLNESS_PLAN_VERSION_VALIDATION_FAILURE,
  EDIT_WELLNESS_PLANS_SETTINGS,
  EDIT_WELLNESS_PLANS_SETTINGS_FAILURE,
  EDIT_WELLNESS_PLANS_SETTINGS_SUCCESS,
  FETCH_ACTIVE_WELLNESS_PLAN_VERSION,
  FETCH_ACTIVE_WELLNESS_PLAN_VERSION_FAILURE,
  FETCH_ACTIVE_WELLNESS_PLAN_VERSION_SUCCESS,
  FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE,
  FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE_FAILURE,
  FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE_SUCCESS,
  FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST,
  FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST_SUCCESS,
  FETCH_WELLNESS_PLAN,
  FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE,
  FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE_FAILURE,
  FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE_SUCCESS,
  FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE,
  FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE_FAILURE,
  FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE_SUCCESS,
  FETCH_WELLNESS_PLAN_FAILURE,
  FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS,
  FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS_FAILURE,
  FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS_SUCCESS,
  FETCH_WELLNESS_PLAN_SAVINGS,
  FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE,
  FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE_FAILURE,
  FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE_SUCCESS,
  FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP,
  FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP_FAILURE,
  FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP_SUCCESS,
  FETCH_WELLNESS_PLAN_SAVINGS_FAILURE,
  FETCH_WELLNESS_PLAN_SAVINGS_SUCCESS,
  FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN,
  FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN_FAILURE,
  FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN_SUCCESS,
  FETCH_WELLNESS_PLAN_SUCCESS,
  FETCH_WELLNESS_PLAN_VERSION,
  FETCH_WELLNESS_PLAN_VERSION_FAILURE,
  FETCH_WELLNESS_PLAN_VERSION_SUCCESS,
  FETCH_WELLNESS_PLAN_VERSION_TEMPLATE,
  FETCH_WELLNESS_PLAN_VERSION_TEMPLATE_FAILURE,
  FETCH_WELLNESS_PLAN_VERSION_TEMPLATE_SUCCESS,
  FETCH_WELLNESS_PLAN_WELCOME_MESSAGE,
  FETCH_WELLNESS_PLAN_WELCOME_MESSAGE_FAILURE,
  FETCH_WELLNESS_PLAN_WELCOME_MESSAGE_SUCCESS,
  FETCH_WELLNESS_PLANS_LIST,
  FETCH_WELLNESS_PLANS_LIST_FAILURE,
  FETCH_WELLNESS_PLANS_LIST_SUCCESS,
  FETCH_WELLNESS_PLANS_SETTINGS,
  FETCH_WELLNESS_PLANS_SETTINGS_FAILURE,
  FETCH_WELLNESS_PLANS_SETTINGS_SUCCESS,
  FETCH_WP_CANCELLATION_INFO,
  FETCH_WP_CANCELLATION_INFO_FAILURE,
  FETCH_WP_CANCELLATION_INFO_SUCCESS,
  GET_WELLNESS_PLAN_PAYMENT_METHOD,
  GET_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE,
  GET_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS,
  REPLACE_WELLNESS_PLAN_PAYMENT_METHOD,
  REPLACE_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE,
  REPLACE_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS,
  SEND_DEFAULT_WELCOME_EMAIL,
  SEND_DEFAULT_WELCOME_EMAIL_FAILURE,
  SEND_DEFAULT_WELCOME_EMAIL_SUCCESS,
  SEND_WELCOME_EMAIL,
  SEND_WELCOME_EMAIL_FAILURE,
  SEND_WELCOME_EMAIL_SUCCESS,
  TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN,
  TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN_FAILURE,
  TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN_SUCCESS,
  UPDATE_WELLNESS_PLANS_VERSIONS,
} from './types/wellnessPlans'

export const fetchWellnessPlansList = ({
  from,
  to,
  query,
  businessId,
}: {
  businessId?: string
  from?: number
  query?: string | Nil
  to?: number
} = {}) => ({
  type: FETCH_WELLNESS_PLANS_LIST,
  from,
  to,
  query,
  businessId,
})
export const fetchWellnessPlansListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_WELLNESS_PLANS_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchWellnessPlansListFailure = (error: ApiError) => ({
  type: FETCH_WELLNESS_PLANS_LIST_FAILURE,
  error,
})

export const fetchWellnessPlanVersion = (
  id: string,
  withGlobalBenefits?: boolean,
) => ({
  type: FETCH_WELLNESS_PLAN_VERSION,
  id,
  withGlobalBenefits,
})
export const fetchWellnessPlanVersionFailure = (error: ApiError) => ({
  type: FETCH_WELLNESS_PLAN_VERSION_FAILURE,
  error,
})
export const fetchWellnessPlanVersionSuccess = (
  wellnessPlanVersion: WellnessPlanVersion,
) => ({
  type: FETCH_WELLNESS_PLAN_VERSION_SUCCESS,
  wellnessPlanVersion,
})

export const fetchActiveWellnessPlanVersion = (businessId: string) => ({
  type: FETCH_ACTIVE_WELLNESS_PLAN_VERSION,
  businessId,
})
export const fetchActiveWellnessPlanVersionFailure = (error: ApiError) => ({
  type: FETCH_ACTIVE_WELLNESS_PLAN_VERSION_FAILURE,
  error,
})
export const fetchActiveWellnessPlanVersionSuccess = (
  wellnessPlanVersionId: string,
) => ({
  type: FETCH_ACTIVE_WELLNESS_PLAN_VERSION_SUCCESS,
  wellnessPlanVersionId,
})

export const fetchMoreItemsForWellnessPlansList = ({
  from,
  to,
  query,
  businessId,
}: {
  businessId?: string
  from: number
  query?: string | Nil
  to: number
}) => ({
  type: FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST,
  from,
  to,
  query,
  businessId,
})
export const fetchMoreItemsForWellnessPlansListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForWellnessPlansListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST_FAILURE,
  error,
})

export const createWellnessPlanVersion = (
  wellnessPlanVersion: UnsavedWellnessPlanVersion,
) => ({
  type: CREATE_WELLNESS_PLAN_VERSION,
  wellnessPlanVersion,
})
export const createWellnessPlanVersionSuccess = (
  wellnessPlanVersionId: string,
) => ({
  type: CREATE_WELLNESS_PLAN_VERSION_SUCCESS,
  wellnessPlanVersionId,
})
export const createWellnessPlanVersionFailure = (error: ApiError) => ({
  type: CREATE_WELLNESS_PLAN_VERSION_FAILURE,
  error,
})
export const createWellnessPlanVersionValidationFailure = (
  error: WellnessPlanValidationError,
) => ({
  type: CREATE_WELLNESS_PLAN_VERSION_VALIDATION_FAILURE,
  error,
})

export const editWellnessPlanVersion = (
  wellnessPlanVersion: WellnessPlanVersion,
) => ({
  type: EDIT_WELLNESS_PLAN_VERSION,
  wellnessPlanVersion,
})
export const editWellnessPlanVersionSuccess = () => ({
  type: EDIT_WELLNESS_PLAN_VERSION_SUCCESS,
})
export const editWellnessPlanVersionFailure = (error: ApiError) => ({
  type: EDIT_WELLNESS_PLAN_VERSION_FAILURE,
  error,
})
export const editWellnessPlanVersionValidationFailure = (
  error: WellnessPlanValidationError,
) => ({
  type: EDIT_WELLNESS_PLAN_VERSION_VALIDATION_FAILURE,
  error,
})

export const deleteWellnessPlanVersion = (wellnessPlanVersionId: string) => ({
  type: DELETE_WELLNESS_PLAN_VERSION,
  wellnessPlanVersionId,
})
export const deleteWellnessPlanVersionSuccess = (
  wellnessPlanVersionId: string,
) => ({
  type: DELETE_WELLNESS_PLAN_VERSION_SUCCESS,
  wellnessPlanVersionId,
})
export const deleteWellnessPlanVersionFailure = (error: ApiError) => ({
  type: DELETE_WELLNESS_PLAN_VERSION_FAILURE,
  error,
})

export const updateWellnessPlansVersions = (
  wellnessPlansVersions: Record<string, WellnessPlanVersion>,
) => ({
  type: UPDATE_WELLNESS_PLANS_VERSIONS,
  wellnessPlansVersions,
})

export const fetchWellnessPlanVersionTemplate = (cloneFromId?: string) => ({
  type: FETCH_WELLNESS_PLAN_VERSION_TEMPLATE,
  cloneFromId,
})
export const fetchWellnessPlanVersionTemplateSuccess = (
  template: WellnessPlanVersion,
) => ({
  type: FETCH_WELLNESS_PLAN_VERSION_TEMPLATE_SUCCESS,
  template,
})
export const fetchWellnessPlanVersionTemplateFailure = (error: ApiError) => ({
  type: FETCH_WELLNESS_PLAN_VERSION_TEMPLATE_FAILURE,
  error,
})

export const clearWellnessPlanVersionValidationError = () => ({
  type: CLEAR_WELLNESS_PLAN_VERSION_VALIDATION_ERROR,
})

export const fetchWellnessPlansSettings = (businessId: string) => ({
  type: FETCH_WELLNESS_PLANS_SETTINGS,
  businessId,
})
export const fetchWellnessPlansSettingsSuccess = (
  businessId: string,
  settings: WellnessPlanSettings,
) => ({
  type: FETCH_WELLNESS_PLANS_SETTINGS_SUCCESS,
  businessId,
  settings,
})
export const fetchWellnessPlansSettingsFailure = (error: ApiError) => ({
  type: FETCH_WELLNESS_PLANS_SETTINGS_FAILURE,
  error,
})

export const editWellnessPlansSettings = (
  businessId: string,
  settings: WellnessPlanSettings,
) => ({
  type: EDIT_WELLNESS_PLANS_SETTINGS,
  businessId,
  settings,
})
export const editWellnessPlansSettingsSuccess = (
  businessId: string,
  settings: WellnessPlanSettings,
) => ({
  type: EDIT_WELLNESS_PLANS_SETTINGS_SUCCESS,
  businessId,
  settings,
})
export const editWellnessPlansSettingsFailure = (error: ApiError) => ({
  type: EDIT_WELLNESS_PLANS_SETTINGS_FAILURE,
  error,
})

export const fetchWellnessPlan = (id: string) => ({
  type: FETCH_WELLNESS_PLAN,
  id,
})
export const fetchWellnessPlanFailure = (error: ApiError) => ({
  type: FETCH_WELLNESS_PLAN_FAILURE,
  error,
})
export const fetchWellnessPlanSuccess = (wellnessPlan: WellnessPlan) => ({
  type: FETCH_WELLNESS_PLAN_SUCCESS,
  wellnessPlan,
})

export const fetchWellnessPlanGlobalBenefitGroups = () => ({
  type: FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS,
})
export const fetchWellnessPlanGlobalBenefitGroupsFailure = (
  error: ApiError,
) => ({
  type: FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS_FAILURE,
  error,
})
export const fetchWellnessPlanGlobalBenefitGroupsSuccess = (
  globalBenefitGroups: WellnessPlanGlobalBenefitGroup[],
) => ({
  type: FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS_SUCCESS,
  globalBenefitGroups,
})

export const activeWellnessPlanVersionChanged = (
  wellnessPlanVersionId: string,
) => ({
  type: ACTIVE_WELLNESS_PLAN_VERSION_CHANGED,
  wellnessPlanVersionId,
})

export const fetchWellnessPlanSignUpToken = (
  clientId: string,
  businessId: string,
) => ({
  type: FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN,
  clientId,
  businessId,
})
export const fetchWellnessPlanSignUpTokenFailure = (error: ApiError) => ({
  type: FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN_FAILURE,
  error,
})
export const fetchWellnessPlanSignUpTokenSuccess = (token: string) => ({
  type: FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN_SUCCESS,
  token,
})

export const clearWellnessPlanSignUpToken = () => ({
  type: CLEAR_WELLNESS_PLAN_SIGN_UP_TOKEN,
})

export const cancelWellnessPlanOld = (
  clientId: string,
  patientId: string,
  planLogIds: string[],
  reason?: string,
  notes?: string,
  confirmToken?: string,
  // eslint-disable-next-line max-params
) => ({
  type: CANCEL_WELLNESS_PLAN_OLD,
  clientId,
  patientId,
  planLogIds,
  reason,
  notes,
  confirmToken,
})
export const cancelWellnessPlanOldFailure = (error: ApiError) => ({
  type: CANCEL_WELLNESS_PLAN_OLD_FAILURE,
  error,
})
export const cancelWellnessPlanOldSuccess = (
  patientId: string,
  plans: WellnessPlan[],
) => ({
  type: CANCEL_WELLNESS_PLAN_OLD_SUCCESS,
  patientId,
  plans,
})
export const cancelWellnessPlanOldValidationFailure = (error: ApiError) => ({
  type: CANCEL_WELLNESS_PLAN_OLD_VALIDATION_FAILURE,
  error,
})
export const clearCancelWellnessPlanOldValidationError = () => ({
  type: CLEAR_CANCEL_WELLNESS_PLAN_OLD_VALIDATION_ERROR,
})

export const fetchWpCancellationInfo = (
  clientId: string | Nil,
  patientId: string | Nil,
  cancelId: string,
) => ({
  type: FETCH_WP_CANCELLATION_INFO,
  clientId,
  patientId,
  cancelId,
})
export const fetchWpCancellationInfoFailure = (error: ApiError) => ({
  type: FETCH_WP_CANCELLATION_INFO_FAILURE,
  error,
})
export const fetchWpCancellationInfoSuccess = (
  patientId: string | Nil,
  plans: WellnessPlan[],
) => ({
  type: FETCH_WP_CANCELLATION_INFO_SUCCESS,
  patientId,
  plans,
})

export const cancelWellnessPlan = (
  clientId: string,
  patientId: string,
  cancelData: WellnessPlanCancellationData,
  confirmToken?: string,
) => ({
  type: CANCEL_WELLNESS_PLAN,
  clientId,
  patientId,
  cancelData,
  confirmToken,
})
export const cancelWellnessPlanFailure = (error: ApiError) => ({
  type: CANCEL_WELLNESS_PLAN_FAILURE,
  error,
})
export const cancelWellnessPlanSuccess = (
  patientId: string,
  plans: WellnessPlan[],
) => ({
  type: CANCEL_WELLNESS_PLAN_SUCCESS,
  patientId,
  plans,
})

export const createWellnessPlanPaymentMethod = (clientId: string) => ({
  type: CREATE_WELLNESS_PLAN_PAYMENT_METHOD,
  clientId,
})
export const createWellnessPlanPaymentMethodFailure = (error: ApiError) => ({
  type: CREATE_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE,
  error,
})
export const createWellnessPlanPaymentMethodSuccess = (
  data: WellnessPlanPaymentMethod,
) => ({
  type: CREATE_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS,
  data,
})

export const getWellnessPlanPaymentMethod = (id: string) => ({
  type: GET_WELLNESS_PLAN_PAYMENT_METHOD,
  id,
})
export const getWellnessPlanPaymentMethodFailure = (error: ApiError) => ({
  type: GET_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE,
  error,
})
export const getWellnessPlanPaymentMethodSuccess = (
  data: WellnessPlanPaymentMethod,
) => ({
  type: GET_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS,
  data,
})

export const replaceWellnessPlanPaymentMethod = (
  membershipId: string,
  paymentMethodId: string,
) => ({
  type: REPLACE_WELLNESS_PLAN_PAYMENT_METHOD,
  membershipId,
  paymentMethodId,
})
export const replaceWellnessPlanPaymentMethodFailure = (error: ApiError) => ({
  type: REPLACE_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE,
  error,
})
export const replaceWellnessPlanPaymentMethodSuccess = () => ({
  type: REPLACE_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS,
})

export const fetchWellnessPlanSavings = (clientId: string) => ({
  type: FETCH_WELLNESS_PLAN_SAVINGS,
  clientId,
})
export const fetchWellnessPlanSavingsFailure = (error: ApiError) => ({
  type: FETCH_WELLNESS_PLAN_SAVINGS_FAILURE,
  error,
})
export const fetchWellnessPlanSavingsSuccess = (
  clientId: string,
  savings: WellnessPlanSavings,
) => ({
  type: FETCH_WELLNESS_PLAN_SAVINGS_SUCCESS,
  clientId,
  savings,
})

export const fetchWellnessPlanWelcomeMessage = (
  businessId: string,
  templateText: string,
) => ({
  type: FETCH_WELLNESS_PLAN_WELCOME_MESSAGE,
  businessId,
  templateText,
})
export const fetchWellnessPlanWelcomeMessageFailure = (error: ApiError) => ({
  type: FETCH_WELLNESS_PLAN_WELCOME_MESSAGE_FAILURE,
  error,
})
export const fetchWellnessPlanWelcomeMessageSuccess = (template: string) => ({
  type: FETCH_WELLNESS_PLAN_WELCOME_MESSAGE_SUCCESS,
  template,
})

export const fetchCurrentWellnessPlanWelcomeMessage = (businessId: string) => ({
  type: FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE,
  businessId,
})
export const fetchCurrentWellnessPlanWelcomeMessageFailure = (
  error: ApiError,
) => ({
  type: FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE_FAILURE,
  error,
})
export const fetchCurrentWellnessPlanWelcomeMessageSuccess = (
  template: string,
) => ({
  type: FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE_SUCCESS,
  template,
})

export const fetchWellnessPlanBoopSignUpTemplate = (
  businessId: string,
  templateText: string,
) => ({
  type: FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE,
  businessId,
  templateText,
})
export const fetchWellnessPlanBoopSignUpTemplateFailure = (
  error: ApiError,
) => ({
  type: FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE_FAILURE,
  error,
})
export const fetchWellnessPlanBoopSignUpTemplateSuccess = (
  template: string,
) => ({
  type: FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE_SUCCESS,
  template,
})

export const fetchWellnessPlanBoopSignUpMessage = (
  businessId: string,
  signUpMessageContext: WellnessPlanBoopSignUpMessageContext,
) => ({
  type: FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE,
  businessId,
  signUpMessageContext,
})
export const fetchWellnessPlanBoopSignUpMessageFailure = (error: ApiError) => ({
  type: FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE_FAILURE,
  error,
})
export const fetchWellnessPlanBoopSignUpMessageSuccess = (message: string) => ({
  type: FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE_SUCCESS,
  message,
})

export const sendDefaultWelcomeEmail = (
  clientId: string,
  email: string,
  selection: { patientId: string; planId: string }[],
) => ({
  type: SEND_DEFAULT_WELCOME_EMAIL,
  clientId,
  email,
  selection,
})
export const sendDefaultWelcomeEmailSuccess = () => ({
  type: SEND_DEFAULT_WELCOME_EMAIL_SUCCESS,
})
export const sendDefaultWelcomeEmailFailure = (error: ApiError) => ({
  type: SEND_DEFAULT_WELCOME_EMAIL_FAILURE,
  error,
})

export const sendWelcomeEmail = (
  messageData: WellnessPlanWelcomeEmailContext,
) => ({
  type: SEND_WELCOME_EMAIL,
  messageData,
})
export const sendWelcomeEmailSuccess = (conversationsList: string[]) => ({
  type: SEND_WELCOME_EMAIL_SUCCESS,
  conversationsList,
})
export const sendWelcomeEmailFailure = (error: ApiError) => ({
  type: SEND_WELCOME_EMAIL_FAILURE,
  error,
})

export const fetchWellnessPlanSavingsDataForSoap = (appointmentId: string) => ({
  type: FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP,
  appointmentId,
})
export const fetchWellnessPlanSavingsDataForSoapFailure = (
  error: ApiError,
) => ({
  type: FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP_FAILURE,
  error,
})
export const fetchWellnessPlanSavingsDataForSoapSuccess = (
  savingsData: WellnessPlanSavingsData,
) => ({
  type: FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP_SUCCESS,
  savingsData,
})

export const fetchWellnessPlanSavingsDataForInvoice = (invoiceId: string) => ({
  type: FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE,
  invoiceId,
})
export const fetchWellnessPlanSavingsDataForInvoiceFailure = (
  error: ApiError,
) => ({
  type: FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE_FAILURE,
  error,
})
export const fetchWellnessPlanSavingsDataForInvoiceSuccess = (
  savingsData: WellnessPlanSavingsData,
) => ({
  type: FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE_SUCCESS,
  savingsData,
})

export const toggleAutoRenewalPlan = (
  planId: string,
  autoRenewal: boolean,
) => ({
  type: TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN,
  planId,
  autoRenewal,
})
export const toggleAutoRenewalPlanFailure = (error: ApiError) => ({
  type: TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN_FAILURE,
  error,
})
export const toggleAutoRenewalPlanSuccess = (
  versionId: string | Nil,
  plan: WellnessPlan,
) => ({
  type: TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN_SUCCESS,
  versionId,
  plan,
})

export const clearWellnessPlanError = () => ({
  type: CLEAR_WELLNESS_PLAN_ERROR,
})
export const clearWellnessPlanErrorStatus = () => ({
  type: CLEAR_WELLNESS_PLAN_ERROR_STATUS,
})
export const clearWellnessPlanVersionTemplate = () => ({
  type: CLEAR_WELLNESS_PLAN_VERSION_TEMPLATE,
})
export const clearCancelWellnessPlanSuccess = () => ({
  type: CLEAR_CANCEL_WELLNESS_PLAN_SUCCESS,
})
export const clearCancelWellnessPlanError = () => ({
  type: CLEAR_CANCEL_WELLNESS_PLAN_ERROR,
})

export const WELLNESS_PLANS_SENSITIVE_ACTIONS = [
  {
    type: CANCEL_WELLNESS_PLAN_OLD,
    sensitiveData: ['confirmToken'],
  },
  {
    type: FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN_SUCCESS,
    sensitiveData: ['token'],
  },
]
