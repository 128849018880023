import React from 'react'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import { getIsRetailOrderLineItem } from '~/components/dashboard/invoices/invoiceUtils'
import ChewyPrescriptionStateLabel from '~/components/dashboard/prescription/prescription-editor/ChewyPrescriptionStateLabel'
import InventoryItemDispenseButton from '~/components/dashboard/soap/rail/summary/orders/buttons/InventoryItemDispenseButton'
import LabTestStateItemButton from '~/components/dashboard/soap/rail/summary/orders/buttons/LabTestStateItemButton'
import PrescriptionItemDispenseButton from '~/components/dashboard/soap/rail/summary/orders/buttons/PrescriptionItemDispenseButton'
import PrescriptionItemPrescribeButton from '~/components/dashboard/soap/rail/summary/orders/buttons/PrescriptionItemPrescribeButton'
import ProcedureCompleteButton from '~/components/dashboard/soap/rail/summary/orders/buttons/ProcedureItemCompleteButton'
import { OrderType } from '~/constants/SOAPStates'
import { useGetInventoryStateId } from '~/store/hooks/orders'
import { InvoiceLineItem, Order } from '~/types'
import {
  convertInvoiceLineItemToSoapLog,
  convertRetailOrderLineItemToSoapLog,
} from '~/utils/orderUtils'
import { getPrescriptionType } from '~/utils/prescription'

const useStyles = makeStyles(
  () => ({
    container: {
      minWidth: 88,
    },
  }),
  { name: 'OrderItemButtonStatus' },
)

export interface OrderItemButtonStatusProps {
  disabled?: boolean
  order: Order | InvoiceLineItem | RetailOrderLineItem
  size?: 'default' | 'small' | 'big'
}

const OrderItemButtonStatus = ({
  disabled,
  order,
  size,
}: OrderItemButtonStatusProps) => {
  const classes = useStyles()

  const isRetailOrderLineItem = getIsRetailOrderLineItem(order)
  const getInventoryStateId = useGetInventoryStateId()
  const { isInHouse, isChewyActiveRx } = isRetailOrderLineItem
    ? { isInHouse: false, isChewyActiveRx: true }
    : getPrescriptionType(order.prescriptionType, order.origin)

  const ButtonItemComponentMap: Record<string, any> = {
    [OrderType.INVENTORY]: !isRetailOrderLineItem && (
      <InventoryItemDispenseButton
        classes={{ fab: classes.container }}
        disabled={disabled}
        getInventoryStateId={getInventoryStateId}
        order={order}
      />
    ),
    [OrderType.LAB_TEST]: !isRetailOrderLineItem && (
      <LabTestStateItemButton
        classes={{ form: classes.container }}
        disabled={disabled}
        order={order}
      />
    ),
    [OrderType.PRESCRIPTION]: (
      <>
        {isInHouse && !isRetailOrderLineItem ? (
          <PrescriptionItemDispenseButton disabled={disabled} order={order} />
        ) : isChewyActiveRx ? (
          <ChewyPrescriptionStateLabel
            prescription={
              R.has('logId', order)
                ? isRetailOrderLineItem
                  ? convertRetailOrderLineItemToSoapLog(order)
                  : convertInvoiceLineItemToSoapLog(order)
                : (order as Order)
            }
            variant={size}
          />
        ) : (
          // Right now the "Prescribe" button will not appear, as Charges table are composed by invoice line items,
          // which don't include some ordered items such as tasks and outside prescriptions
          <PrescriptionItemPrescribeButton disabled={disabled} order={order} />
        )}
      </>
    ),
    [OrderType.PROCEDURE]: !isRetailOrderLineItem && (
      <ProcedureCompleteButton disabled={disabled} order={order} />
    ),
    DEFAULT: null,
  }
  const { type, logType } = isRetailOrderLineItem
    ? { type: order.logType as OrderType, logType: order.logType as OrderType }
    : order
  return (
    ButtonItemComponentMap[type || logType] ?? ButtonItemComponentMap.DEFAULT
  )
}

export default OrderItemButtonStatus
