import React from 'react'
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { SoapForm } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    moreIconButton: {
      padding: theme.spacing(1, 0.5),
    },
  }),
  { name: 'MoreFormInfoDropdown' },
)

interface MoreFormInfoDropdownProps {
  actionsAnchorElement: HTMLElement | null
  activeActionItem: string | null
  form: SoapForm
  setActionsAnchorElement: (element: HTMLElement | null) => void
  setActiveActionItem: (item: string | null) => void
}

const MoreFormInfoDropdown = ({
  actionsAnchorElement,
  activeActionItem,
  setActionsAnchorElement,
  setActiveActionItem,
  form,
}: MoreFormInfoDropdownProps) => {
  const classes = useStyles()

  const handleToggleActions = (event: React.MouseEvent) => {
    event.stopPropagation()

    const targetButton = (event.target as HTMLElement).closest('button')
    setActionsAnchorElement(actionsAnchorElement ? null : targetButton)
    setActiveActionItem(activeActionItem ? null : form.id)
  }

  return (
    <Grid container alignItems="center" justifyContent="center" wrap="nowrap">
      <IconButton
        classes={{
          root: classes.moreIconButton,
        }}
        onClick={(event) => handleToggleActions(event)}
      >
        <MoreHorizIcon />
      </IconButton>
    </Grid>
  )
}

export default MoreFormInfoDropdown
