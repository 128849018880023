import { LanguageUtils } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

export const BaseRoute = {
  PRACTICES: '/admin/general/practices',
  COMMUNICATIONS: '/communications',
  TASKS: '/tasks-dashboard',
  SOAP: '/soap',
}

export enum SoapTabRoute {
  PROBLEMS = 'problems',
}

export enum ClientAndPatientRoute {
  SNAPSHOTS = 'snapshots',
  RECORDS = 'records',
  BALANCE = 'balance',
  MEMBERSHIP = 'membership',
  COMMUNICATIONS = 'communications',
}

export enum TimetableRoute {
  SCHEDULER = 'scheduler',
  WHITEBOARD = 'whiteboard',
  SCHEDULE = 'schedule',
  BOARDING = 'boarding',
  DEFAULT = 'default',
  TEAM = 'team',
  TYPE = 'type',
  TREATMENTS = 'treatments',
}

export enum AdminRoute {
  AVAILABILITY = 'availability',
  BUNDLES = 'bundles',
  CONTACTS = 'contacts',
  DOCUMENTS = 'documents',
  INVENTORIES_ADJUSTMENTS = 'inventories/adjustments',
  INVENTORIES_CATALOG = 'inventories/catalog',
  INVENTORIES_ON_HAND = 'inventories/on_hand',
  INVENTORIES_SHIPMENTS = 'inventories/shipments',
  INVENTORIES = 'inventories',
  LAB_TESTS = 'lab-tests',
  MARKETPLACE = 'marketplace',
  MEMBERS = 'members',
  PDMP_FILE_EXPORT = 'pdmp-file-export',
  PRACTICES = 'practices',
  PROCEDURES = 'procedures',
  QUESTIONS = 'questions',
  REMINDER_PROTOCOLS = 'reminder-protocols',
  SPACES = 'spaces',
  WELLNESS_PLANS = 'wellness-plans',
}

export enum AnalyticsReportsRoute {
  ACCOUNTING = 'accounting',
  CLIENT_AND_PATIENT_STATUS = 'client-and-patient-status',
  CLIENTS_LIST = 'client-list',
  CONTROLLED_SUBSTANCE_LOG = 'controlled-substance-log',
  CUSTOMER_ACQUISITION_AND_LIFETIME_VALUE = 'customer-acquisition-and-lifetime-value',
  DAILY_RECONCILIATION_OLD = 'daily-reconciliation-old',
  FORWARD_BOOKINGS = 'forward-bookings',
  GROUP_PERFORMANCE = 'group-performance',
  GROUP_PRICE_LISTS = 'group-price-lists',
  INVENTORY_USAGE = 'inventory-usage',
  INVOICES_AND_PAYMENTS = 'invoices-and-payments',
  MAGIC_QUADRANT = 'magic-quadrant',
  MARKETING_DASHBOARD = 'marketing-dashboard',
  MARKETING_DATA_QUALITY = 'marketing-data-quality',
  MEDICAL_INSIGHTS = 'medical-insights',
  MONTHLY_SALES_SUMMARY = 'monthly-sales-summary',
  OPEN_SOAPS_AND_INVOICES = 'open-soaps-and-invoices',
  PRACTICE_PERFORMANCE = 'practice-performance',
  PRODUCTION = 'production',
  RABIES_VACCINE_HISTORY = 'rabies-vaccine-history',
  REFERRAL_BUSINESS = 'referral-business',
  REMINDERS_COMPLIANCE = 'reminders-compliance',
  SALES_SUMMARY_EXCLUDING_UNPAID_OTC = 'sales-summary-excluding-unpaid-otc',
  SCHEDULE_MANAGEMENT = 'schedule-management',
  TIME_TRACKING = 'time-tracking',
  WELLNESS_PLANS = 'wellness-plans',
  DAILY_RECONCILIATION_NEW = 'daily-reconciliation-new',
  BANK_RECONCILIATION = 'bank-reconciliation',
}

const ClientAndPatientRouteNameMap = {
  [ClientAndPatientRoute.SNAPSHOTS]: i18n.t(
    'Clients:SNAPSHOTS_AND_RECORDS.PATIENT_SNAPSHOTS_LABEL',
  ),
  [ClientAndPatientRoute.RECORDS]: i18n.t(
    'Clients:SNAPSHOTS_AND_RECORDS.PATIENT_RECORDS_LABEL',
  ),
  [ClientAndPatientRoute.BALANCE]: i18n.t('Common:BALANCE'),
  [ClientAndPatientRoute.MEMBERSHIP]: i18n.t('Common:MEMBERSHIP'),
  [ClientAndPatientRoute.COMMUNICATIONS]: i18n.t('Common:COMMUNICATIONS'),
}

const TimetableRouteNameMap = {
  [TimetableRoute.SCHEDULER]: i18n.t('Menu:MAIN.SCHEDULER'),
  [TimetableRoute.WHITEBOARD]: i18n.t('Menu:MAIN.WHITEBOARD'),
  [TimetableRoute.SCHEDULE]: null,
  [TimetableRoute.BOARDING]: i18n.t('Common:BOARDING_APPOINTMENT_TYPE_NOUN'),
  [TimetableRoute.DEFAULT]: null,
  [TimetableRoute.TEAM]: i18n.t('Common:TEAM'),
  [TimetableRoute.TYPE]: i18n.t('Common:TYPE_ONE'),
  [TimetableRoute.TREATMENTS]: i18n.t('Common:TREATMENTS'),
}

const AdminRouteNameMap = {
  [AdminRoute.AVAILABILITY]: i18n.t('Common:SHIFTS_AND_PRACTICE_HOURS'),
  [AdminRoute.BUNDLES]: i18n.t('Menu:ADMIN_CATALOG.BUNDLES'),
  [AdminRoute.CONTACTS]: i18n.t('Common:CONTACT_OTHER'),
  [AdminRoute.DOCUMENTS]: i18n.t('Common:DOCUMENT_OTHER'),
  [AdminRoute.INVENTORIES_ADJUSTMENTS]: i18n.t(
    'Menu:ADMIN.INVENTORIES_ADJUSTMENTS',
  ),
  [AdminRoute.INVENTORIES_CATALOG]: i18n.t('Menu:ADMIN.INVENTORIES_CATALOG'),
  [AdminRoute.INVENTORIES_ON_HAND]: i18n.t('Menu:ADMIN.INVENTORIES_ON_HAND'),
  [AdminRoute.INVENTORIES_SHIPMENTS]: i18n.t(
    'Menu:ADMIN.INVENTORIES_SHIPMENTS',
  ),
  [AdminRoute.INVENTORIES]: i18n.t('Common:INVENTORY'),
  [AdminRoute.LAB_TESTS]: i18n.t('Common:LAB_TESTS'),
  [AdminRoute.MARKETPLACE]: i18n.t('Common:MARKETPLACE'),
  [AdminRoute.MEMBERS]: i18n.t('Common:MEMBERS'),
  [AdminRoute.PDMP_FILE_EXPORT]: i18n.t(
    'Menu:ADMIN_CATALOG.PRESCRIPTION_DRUG_MONITORING_PROGRAM_FILE_EXPORT',
  ),
  [AdminRoute.PRACTICES]: i18n.t('Common:PRACTICE_OTHER'),
  [AdminRoute.PROCEDURES]: i18n.t('Common:PROCEDURE_OTHER'),
  [AdminRoute.QUESTIONS]: i18n.t('Common:QUESTION_OTHER'),
  [AdminRoute.REMINDER_PROTOCOLS]: i18n.t('Common:REMINDER_PROTOCOLS'),
  [AdminRoute.SPACES]: i18n.t('Common:SPACE_OTHER'),
  [AdminRoute.WELLNESS_PLANS]: i18n.t('Common:WELLNESS_PLANS'),
}

const AnalyticsReportsRouteNameMap = {
  [AnalyticsReportsRoute.CLIENT_AND_PATIENT_STATUS]: i18n.t(
    'Menu:ANALYTICS.CLIENT_AND_PATIENT_STATUS',
  ),
  [AnalyticsReportsRoute.CLIENTS_LIST]: i18n.t('Menu:ANALYTICS.CLIENT_LIST'),
  [AnalyticsReportsRoute.CONTROLLED_SUBSTANCE_LOG]: i18n.t(
    'Menu:ANALYTICS.CONTROLLED_SUBSTANCE_LOG',
  ),
  [AnalyticsReportsRoute.CUSTOMER_ACQUISITION_AND_LIFETIME_VALUE]: i18n.t(
    'Menu:ANALYTICS.CUSTOMER_ACQUISITION_AND_LIFETIME_VALUE',
  ),
  [AnalyticsReportsRoute.DAILY_RECONCILIATION_OLD]: i18n.t(
    'Menu:ANALYTICS.DAILY_RECONCILIATION_OLD',
  ),
  [AnalyticsReportsRoute.FORWARD_BOOKINGS]: i18n.t(
    'Menu:ANALYTICS.FORWARD_BOOKINGS',
  ),
  [AnalyticsReportsRoute.GROUP_PERFORMANCE]: i18n.t(
    'Menu:ANALYTICS.GROUP_PERFORMANCE',
  ),
  [AnalyticsReportsRoute.GROUP_PRICE_LISTS]: i18n.t(
    'Menu:ANALYTICS.GROUP_PRICE_LISTS',
  ),
  [AnalyticsReportsRoute.INVENTORY_USAGE]: i18n.t(
    'Menu:ANALYTICS.INVENTORY_USAGE',
  ),
  [AnalyticsReportsRoute.MAGIC_QUADRANT]: i18n.t(
    'Menu:ANALYTICS.MAGIC_QUADRANT',
  ),
  [AnalyticsReportsRoute.MARKETING_DASHBOARD]: i18n.t(
    'Menu:ANALYTICS.MARKETING_DASHBOARD',
  ),
  [AnalyticsReportsRoute.MARKETING_DATA_QUALITY]: i18n.t(
    'Menu:ANALYTICS.MARKETING_DATA_QUALITY',
  ),
  [AnalyticsReportsRoute.MEDICAL_INSIGHTS]: i18n.t(
    'Menu:ANALYTICS.MEDICAL_INSIGHTS',
  ),
  [AnalyticsReportsRoute.MONTHLY_SALES_SUMMARY]: i18n.t(
    'Menu:ANALYTICS.MONTHLY_SALES_SUMMARY',
  ),
  [AnalyticsReportsRoute.OPEN_SOAPS_AND_INVOICES]: i18n.t(
    'Menu:ANALYTICS.OPEN_SOAPS_AND_INVOICES',
  ),
  [AnalyticsReportsRoute.PRACTICE_PERFORMANCE]: i18n.t(
    'Menu:ANALYTICS.PRACTICE_PERFORMANCE',
  ),
  [AnalyticsReportsRoute.PRODUCTION]: i18n.t('Menu:ANALYTICS.PRODUCTION'),
  [AnalyticsReportsRoute.RABIES_VACCINE_HISTORY]: i18n.t(
    'Menu:ANALYTICS.RABIES_VACCINE_HISTORY',
  ),
  [AnalyticsReportsRoute.REMINDERS_COMPLIANCE]: i18n.t(
    'Menu:ANALYTICS.REMINDERS_COMPLIANCE',
  ),
  [AnalyticsReportsRoute.SCHEDULE_MANAGEMENT]: i18n.t(
    'Menu:ANALYTICS.SCHEDULE_MANAGEMENT',
  ),
  [AnalyticsReportsRoute.TIME_TRACKING]: i18n.t('Menu:ANALYTICS.TIME_TRACKING'),
  [AnalyticsReportsRoute.WELLNESS_PLANS]: i18n.t('Common:WELLNESS_PLANS'),
  [AnalyticsReportsRoute.REFERRAL_BUSINESS]: i18n.t(
    'Menu:ANALYTICS.REFERRAL_BUSINESS',
  ),
  [AnalyticsReportsRoute.INVOICES_AND_PAYMENTS]: i18n.t(
    'Menu:ANALYTICS.INVOICES AND PAYMENTS',
  ),
  [AnalyticsReportsRoute.ACCOUNTING]: i18n.t('Menu:ANALYTICS.ACCOUNTING'),
  [AnalyticsReportsRoute.DAILY_RECONCILIATION_NEW]: i18n.t(
    'Menu:ANALYTICS.DAILY_RECONCILIATION',
  ),
  [AnalyticsReportsRoute.BANK_RECONCILIATION]: i18n.t(
    'Menu:ANALYTICS.BANK_RECONCILIATION',
  ),
  [AnalyticsReportsRoute.SALES_SUMMARY_EXCLUDING_UNPAID_OTC]: i18n.t(
    'Menu:ANALYTICS.SALES_SUMMARY_EXCLUDING_UNPAID_OTC',
  ),
}

export const RouteNameMap = {
  ...AdminRouteNameMap,
  ...AnalyticsReportsRouteNameMap,
  ...ClientAndPatientRouteNameMap,
  ...TimetableRouteNameMap,
}

export const PathClientRegexMap = {
  CLIENT_AND_PATIENT_RECORDS:
    '^/client/([A-Za-z0-9]+)/patient/([A-Za-z0-9]+)/records',
  CLIENT_AND_PATIENT_SNAPSHOTS:
    '^/client/([A-Za-z0-9]+)/patient/([A-Za-z0-9]+)/snapshots',
  CLIENT_BALANCE: '^/balance/([A-Za-z0-9]+)/?',
  CLIENT_COMMUNICATIONS: '^/communications/client/([A-Za-z0-9]+)/?',
  CLIENT_MEMBERSHIP: '^/membership/([A-Za-z0-9]+)/?',
}

export const PathSoapRegexMap = {
  SOAP: '^/soap',
  SOAP_CREATE: '^/soap/create',
  SOAP_ADD: '^/soap/add',
}

export const PathLabTestRegexMap = {
  LAB_TEST: '^/lab-tests-dashboard/([^?]*)/?',
}

export const PathChargeSheetRegexMap = {
  CHARGE_SHEET: '^/chargesheet/([^?]*)/?',
}

export const PathInvoiceRegexMap = {
  INVOICE: '^/invoice/([^?]*)/?',
}

export const PathCommunicationsRegexMap = {
  COMMUNICATIONS: '^/communications/([^?]*)/?',
}

export const PathTimetableRegexMap = {
  TIMETABLE: '^/(scheduler|whiteboard)(?:(?:/([^/]*))?\\?date=(.[^&]*))?',
}

export const PathRegexHandlerList = [
  ...Object.values(PathClientRegexMap),
  ...Object.values(PathSoapRegexMap),
  ...Object.values(PathLabTestRegexMap),
  ...Object.values(PathTimetableRegexMap),
]

export const PathClientRegexNameMap = {
  [PathClientRegexMap.CLIENT_AND_PATIENT_RECORDS]:
    RouteNameMap[ClientAndPatientRoute.RECORDS],
  [PathClientRegexMap.CLIENT_AND_PATIENT_SNAPSHOTS]:
    RouteNameMap[ClientAndPatientRoute.SNAPSHOTS],
  [PathClientRegexMap.CLIENT_BALANCE]:
    RouteNameMap[ClientAndPatientRoute.BALANCE],
  [PathClientRegexMap.CLIENT_MEMBERSHIP]:
    RouteNameMap[ClientAndPatientRoute.MEMBERSHIP],
  [PathClientRegexMap.CLIENT_COMMUNICATIONS]:
    RouteNameMap[ClientAndPatientRoute.COMMUNICATIONS],
}

const handleSearch = ([entity]: string[]) =>
  i18n.t('Search:ENTITY_SEARCH_RESULTS', {
    entityName: LanguageUtils.capitalize(
      decodeURIComponent(entity?.replace(/\+/g, ' ') || ''),
    ),
  })

const removeBusinessId = (originalName: string) => {
  let name = originalName
  if (name.includes('?businessId=')) {
    name = originalName.match(/[^?]*/)?.[0] as string
  }
  return name.replace(/-/g, ' ')
}

const handleReports = ([originalName]: string[]) => {
  const name = removeBusinessId(originalName)
  return `${i18n.t('Common:REPORT_OTHER')}: ${LanguageUtils.capitalize(name)}`
}
const handleAdmin = ([originalName]: string[]) => {
  const name = removeBusinessId(originalName)
  return `${i18n.t('Common:ADMIN')}: ${LanguageUtils.capitalize(name)}`
}

export const PathRegexNameMap = {
  '\\?(?:.+&)?query=([^&]*)': handleSearch,
  '^/admin/general/([^/]*)/?': handleAdmin,
  '^/admin/scheduling/([^/]*)/?': handleAdmin,
  '^/admin/catalog/([^/]*)/?': handleAdmin,
  '^/admin/(.*)': handleAdmin,
  '^/analytics/(.*)': handleReports,
  '^/clients': i18n.t('Common:CLIENTS_AND_PATIENTS'),
  '^/lab-tests-dashboard': i18n.t('Common:LAB_TESTS'),
  '^/imaging-dashboard': i18n.t('Common:IMAGING_REQUESTS'),
  '^/tasks-dashboard': i18n.t('Common:TASKS'),
  '^/communications($|\\?)': i18n.t('Common:COMMUNICATIONS'),
  '^/landing': i18n.t('Menu:MAIN.DASHBOARD'),
}

export enum ActionAnchors {
  IDENTIFIED_PROBLEMS = '#identifiedProblems',
}
