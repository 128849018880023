import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  BackButton,
  ButtonWithLoader,
  LanguageUtils,
  Text,
} from '@pbt/pbt-ui-components'

import PrePaidButton from '~/components/common/buttons/PrePaidButton'
import RangeButton from '~/components/common/buttons/RangeButton'
import {
  addOrderInitialUsedQuantity,
  normalizeOrderUsedQuantity,
} from '~/utils/orderUtils'

import {
  addRange,
  dropPrepaidState,
  formatMoneyRange,
  getExtendedPrice,
  getHighValue,
  getLowValue,
  isEveryItemOfSingleQuantity,
  isRange,
  mergeOrderedItems,
  restorePrepaidState,
  restrictUsedQuantity,
  setHighValue,
  setLowValue,
} from '../../invoices/invoiceUtils'
import ListWithFilters from './ListWithFilters'
import SelectableListItem from './SelectableListItem'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      overflowY: 'auto',
      padding: theme.spacing(0, 2),
    },
    buttonContainer: {
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    button: {
      minWidth: 196,
      height: 40,
    },
    noItemsText: {
      color: theme.colors.tabLabel,
      opacity: 0.5,
    },
    table: {
      borderCollapse: 'separate',
      borderSpacing: theme.spacing(0, 1),
    },
    selectionContainer: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
      border: theme.constants.tableBorder,
      borderRadius: 2,
      height: 195,
      overflow: 'auto',
    },
    totalPriceLabel: {
      marginRight: theme.spacing(1),
      fontWeight: 500,
    },
    headerCell: {
      border: 'none',
      padding: theme.spacing(0, 1),
      color: theme.colors.tabLabel,
      fontSize: '1.4rem',
    },
    headerExpansionCell: {
      border: 'none',
      padding: theme.spacing(0, 1),
      color: theme.colors.tabLabel,
      fontSize: '1.2rem',
      lineHeight: '1em',
      fontWeight: 300,
    },
    headerCellQty: {
      paddingLeft: theme.spacing(3),
    },
  }),
  { name: 'SelectableListWithFilters' },
)

const defaultAddItem = (oldItems, newItems) => oldItems.concat(newItems)
const defaultDeleteItem = (oldItems, newItems, getUniqueId) => {
  const newIds = R.map(getUniqueId, newItems)
  return oldItems.filter((item) => !R.includes(getUniqueId(item), newIds))
}
const defaultGetUniqId = (item) => item.id
const isSomeItemWithRange = (items) =>
  items.some(({ quantity }) => isRange(quantity))

const SelectableListWithFilters = forwardRef(function SelectableListWithFilters(
  {
    ListWithFiltersComponent = ListWithFilters,
    ItemComponent,
    selectedItems: initialSelectedItems,
    isLoading,
    listItems,
    isReceivingListItems,
    isSelectableItem,
    showRange,
    showPrePaid,
    showUsedPaidLabels,
    showPriceUnits,
    showBackButton,
    showPrice,
    showQuantity,
    showTotalPrice,
    totalPriceLabel,
    proceedButtonLabel,
    onBack,
    onProceed,
    addItem = defaultAddItem,
    deleteItem = defaultDeleteItem,
    addBundle,
    deleteBundle,
    getUniqId = defaultGetUniqId,
    getAllowDecimalQuantity,
    clientId,
    patientId,
    onCheckPrescribable,
    onUncheckPrescribable,
    onEditPrescribable,
    singleItemOnly,
    onChange,
    includeOnHandQuantity,
    ...rest
  },
  ref,
) {
  const classes = useStyles(rest)
  const { t } = useTranslation('Common')

  const selectedItems = initialSelectedItems || []

  const [orderedItems, setOrderedItems] = useState(selectedItems)
  const [isRangeEnabled, setIsRangeEnabled] = useState(
    !isEveryItemOfSingleQuantity(orderedItems),
  )
  const [isPrePaidEnabled, setIsPrePaidEnabled] = useState(
    R.any(R.propEq('prepaid', true), orderedItems),
  )

  const listRef = useRef()
  const isMultipleSelect = !singleItemOnly

  const resultItems = isMultipleSelect ? orderedItems : orderedItems[0]

  useEffect(() => {
    onChange?.(resultItems)
  }, [resultItems])

  useEffect(() => {
    if (!R.isEmpty(orderedItems)) {
      setIsRangeEnabled(showRange && isSomeItemWithRange(orderedItems))
      if (isSomeItemWithRange(orderedItems) && !showRange) {
        const newItems = orderedItems.map((item) => ({
          ...item,
          quantity: getHighValue(item.quantity),
        }))
        setOrderedItems(newItems)
      }
    }
  }, [orderedItems])

  useEffect(() => {
    setOrderedItems(R.uniqBy(getUniqId, [...orderedItems, ...selectedItems]))
  }, [initialSelectedItems])

  const checkedItemsIds = R.map(getUniqId, orderedItems)

  const isChecked = useCallback(
    (item) =>
      item.items
        ? item.items.length > 0 && item.items.every(isChecked)
        : R.includes(getUniqId(item), checkedItemsIds),
    [checkedItemsIds],
  )

  const onCheckItem = useCallback(
    (itemProp, { isPrescribable } = {}) => {
      const hasSubItems = itemProp.items?.length > 0
      const item = hasSubItems
        ? itemProp
        : addOrderInitialUsedQuantity(itemProp, {
            isPrePaidEnabled,
            isEstimate: showRange,
          })

      if (isPrescribable && onCheckPrescribable) {
        onCheckPrescribable(item, itemProp)
      } else {
        const newOrderedItems = hasSubItems
          ? item.items.map((bundleItem) =>
              addOrderInitialUsedQuantity(bundleItem, {
                isPrePaidEnabled,
                isEstimate: showRange,
              }),
            )
          : [item]

        setOrderedItems((prevOrderedItems) =>
          isMultipleSelect
            ? addItem(prevOrderedItems, newOrderedItems)
            : addItem([], newOrderedItems),
        )

        if (hasSubItems && addBundle) {
          addBundle(item)
        }
      }
    },
    [onCheckPrescribable, isPrePaidEnabled],
  )

  const onUncheckItem = useCallback(
    (item, { isPrescribable } = {}) => {
      if (isPrescribable && onUncheckPrescribable && item.id) {
        onUncheckPrescribable(item)
      } else {
        const newOrderedItems = item.items || [item]

        setOrderedItems((prevOrderedItems) =>
          deleteItem(prevOrderedItems, newOrderedItems, getUniqId),
        )
        if (item.items && deleteBundle) {
          deleteBundle(item)
        }
      }
    },
    [onUncheckPrescribable],
  )

  const onEditItem = (item, { isPrescribable, isDrug, isFood }) => {
    // when editing item we need to delete existing record
    onUncheckItem(item, { isPrescribable: false })
    onEditPrescribable(item, { isPrescribable, isDrug, isFood })
  }

  const onUpdateItem = useCallback((item) => {
    const newOrderedItems = item.items || [item]

    setOrderedItems(mergeOrderedItems(getUniqId, newOrderedItems))
  }, [])

  const onCheckAll = useCallback(
    (order, checked) => {
      // add or remove `group` field which is needed for backend do distinguish
      // 'select all' from 'select one' cases
      const modifiedOrder = {
        ...order,
        items: (order.items || []).map((item) => ({
          ...item,
          group: item.bundleId || item.group,
        })),
      }

      if (checked) {
        onCheckItem(modifiedOrder)
      } else {
        onUncheckItem(modifiedOrder)
      }
    },
    [onCheckItem, onUncheckItem],
  )

  const updateQuantity = (quantity, orderedItem) => {
    const index = orderedItems.indexOf(orderedItem)

    const newItem = {
      ...orderedItem,
      quantity,
    }

    const newUsedQuantity = orderedItem.usedQuantity
      ? restrictUsedQuantity(orderedItem.usedQuantity, newItem)
      : 0

    const normalizedOrder = normalizeOrderUsedQuantity(
      newUsedQuantity,
      newItem,
      {
        isEstimate: showRange,
        isPrePaidEnabled,
      },
    )

    const newOrderedItems = R.update(index, normalizedOrder, orderedItems)
    setOrderedItems(newOrderedItems)
  }

  const updateQuantityLow = (lowQuantity, orderedItem) => {
    const newQuantity = setLowValue(orderedItem.quantity, lowQuantity)
    updateQuantity(newQuantity, orderedItem)
  }

  const updateQuantityHigh = (highQuantity, orderedItem) => {
    const newQuantity = setHighValue(orderedItem.quantity, highQuantity)
    updateQuantity(newQuantity, orderedItem)
  }

  const onUpdateUsedQuantity = (usedQuantity, orderedItem) => {
    const index = orderedItems.indexOf(orderedItem)
    const newQuantity = restrictUsedQuantity(usedQuantity, orderedItem)
    const newOrderedItems = R.update(
      index,
      { ...orderedItem, usedQuantity: newQuantity },
      orderedItems,
    )
    setOrderedItems(newOrderedItems)
  }

  const togglePrePaid = (prepaid, orderedItem) => {
    const index = orderedItems.indexOf(orderedItem)
    const newItem = {
      ...orderedItem,
      prepaid,
    }
    const newOrderedItems = R.update(index, newItem, orderedItems)
    setOrderedItems(newOrderedItems)
  }

  const hasNoSelectedItems = orderedItems.length === 0

  const toggleRange = () => {
    if (isRangeEnabled) {
      const newItems = orderedItems.map((item) => ({
        ...item,
        quantity: getLowValue(item.quantity),
      }))
      setOrderedItems(newItems)
    }
    setIsRangeEnabled(!isRangeEnabled)
  }

  const toggleBundlePrePaid = () => {
    const toggledState = !isPrePaidEnabled
    setIsPrePaidEnabled(toggledState)

    if (toggledState) {
      setOrderedItems(restorePrepaidState(orderedItems))
    } else {
      setOrderedItems(dropPrepaidState(orderedItems))
    }
  }

  const tryProceed = () => {
    if (listRef.current.validate()) {
      onProceed(resultItems)
    }
  }

  const getTotalPrice = () =>
    orderedItems.reduce((acc, item) => addRange(acc, getExtendedPrice(item)), 0)

  useImperativeHandle(ref, () => ({
    onCheckItem,
    onUncheckItem,
    onUpdateItem,
    getItems: () => orderedItems,
    clearItems: () => setOrderedItems([]),
  }))

  return (
    <Grid
      container
      className={classes.container}
      direction="column"
      wrap="nowrap"
    >
      <ListWithFiltersComponent
        ItemComponent={ItemComponent}
        clientId={clientId}
        includeOnHandQuantity={includeOnHandQuantity}
        isCheckedItem={isChecked}
        isLoading={isReceivingListItems}
        isSelectableItem={isSelectableItem}
        items={listItems}
        patientId={patientId}
        ref={listRef}
        onCheckAll={onCheckAll}
        onCheckItem={onCheckItem}
        onEditItem={onEditItem}
        onUncheckItem={onUncheckItem}
        onUpdateItem={onUpdateItem}
        {...rest}
      />
      {isMultipleSelect && (
        <Grid container item className={classes.selectionContainer}>
          <Grid container item>
            <Text
              strong
              className={classNames({
                [classes.noItemsText]: orderedItems.length === 0,
              })}
              ml={1}
              variant="subheading3"
            >
              {selectedItems?.length > 0
                ? t('Common:SELECTED_ITEM_OR_ITEMS')
                : t('Common:ADD_ITEM_OR_ITEMS')}
            </Text>
          </Grid>
          <Table className={classes.table}>
            {orderedItems.length > 0 && (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerCell}>
                      {t('Common:ITEM')}
                    </TableCell>
                    {showQuantity && !isRangeEnabled && !isPrePaidEnabled && (
                      <TableCell className={classes.headerCell}>
                        {t('Common:QUANTITY')}
                        {showRange && !isPrePaidEnabled && (
                          <RangeButton
                            inline
                            isSingleQuantity={!isRangeEnabled}
                            toggleRange={toggleRange}
                          />
                        )}
                        {showPrePaid && !isRangeEnabled && (
                          <PrePaidButton
                            inline
                            isPrePaid={isPrePaidEnabled}
                            togglePrePaid={toggleBundlePrePaid}
                          />
                        )}
                      </TableCell>
                    )}
                    {showQuantity && isRangeEnabled && (
                      <>
                        <TableCell className={classes.headerCell}>
                          {t('Common:QUANTITY_LOW')}
                        </TableCell>
                        <TableCell className={classes.headerCell}>
                          {t('Common:QUANTITY_HIGH')}
                        </TableCell>
                      </>
                    )}
                    {showQuantity && isPrePaidEnabled && (
                      <TableCell className={classes.headerCell} colSpan={3}>
                        {t('Common:QUANTITY')}
                        <PrePaidButton
                          inline
                          isPrePaid={isPrePaidEnabled}
                          togglePrePaid={toggleBundlePrePaid}
                        />
                      </TableCell>
                    )}
                    {showPrice && (
                      <TableCell className={classes.headerCell}>
                        {t('Common:EXTENDED_PRICE')}
                      </TableCell>
                    )}
                    {showPriceUnits && (
                      <TableCell className={classes.headerCell}>
                        {t('Common:UNIT')}
                      </TableCell>
                    )}
                    <TableCell className={classes.headerCell} />
                  </TableRow>
                  {showQuantity && isPrePaidEnabled && !showUsedPaidLabels && (
                    <TableRow>
                      <TableCell className={classes.headerExpansionCell} />
                      <TableCell className={classes.headerExpansionCell}>
                        {t('Common:PRE-PAID')}
                      </TableCell>
                      <TableCell className={classes.headerExpansionCell}>
                        {t('Common:FIRST_VISIT')}
                      </TableCell>
                      <TableCell className={classes.headerExpansionCell}>
                        {t('Common:TOTAL')}
                      </TableCell>
                    </TableRow>
                  )}
                  {showQuantity && isPrePaidEnabled && showUsedPaidLabels && (
                    <TableRow>
                      <TableCell className={classes.headerExpansionCell} />
                      <TableCell className={classes.headerExpansionCell}>
                        {t('Common:PRE-PAID')}
                      </TableCell>
                      <TableCell
                        className={classNames(
                          classes.headerCellQty,
                          classes.headerExpansionCell,
                        )}
                      >
                        {t('Common:PAYMENTS.PAID')}
                      </TableCell>
                      <TableCell
                        className={classNames(
                          classes.headerCellQty,
                          classes.headerExpansionCell,
                        )}
                      >
                        {t('Common:USED')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  {orderedItems.map((item) => (
                    <SelectableListItem
                      allowDecimalQuantity={
                        getAllowDecimalQuantity
                          ? getAllowDecimalQuantity(item)
                          : true
                      }
                      isPrePaidEnabled={isPrePaidEnabled}
                      isRangeEnabled={isRangeEnabled}
                      item={item}
                      key={getUniqId(item)}
                      name={LanguageUtils.getTranslatedFieldName(item)}
                      showPrice={showPrice}
                      showPriceUnits={showPriceUnits}
                      showQuantity={showQuantity}
                      showUsedPaidLabels={showUsedPaidLabels}
                      onDelete={onUncheckItem}
                      onTogglePrePaid={togglePrePaid}
                      onUpdateQuantity={updateQuantity}
                      onUpdateQuantityHigh={updateQuantityHigh}
                      onUpdateQuantityLow={updateQuantityLow}
                      onUpdateUsedQuantity={onUpdateUsedQuantity}
                    />
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </Grid>
      )}
      <Grid
        container
        item
        alignItems="center"
        className={classes.buttonContainer}
      >
        <Grid container item xs alignItems="flex-end">
          {showBackButton && (
            <Grid item mr={3}>
              <BackButton label={t('Common:BACK_ACTION')} onClick={onBack} />
            </Grid>
          )}
          <Grid item>
            <ButtonWithLoader
              className={classes.button}
              disabled={hasNoSelectedItems || isLoading}
              loading={isLoading}
              onClick={tryProceed}
            >
              {proceedButtonLabel}
            </ButtonWithLoader>
          </Grid>
        </Grid>
        {showTotalPrice && orderedItems.length > 0 && (
          <Grid item>
            <Text
              className={classes.totalPriceLabel}
              display="inline"
              variant="body2"
            >
              {totalPriceLabel}
            </Text>
            <Text inline>{formatMoneyRange(getTotalPrice())}</Text>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
})

export default SelectableListWithFilters
