import { MembershipPaymentStatus } from '~/constants/paymentTypes'
import { BenefitPlanType } from '~/constants/wellnessPlansConstants'

import { WellnessPlanPriceType } from './wellnessPlans'

export enum PaymentProcessor {
  BRAINTREE = 'BRAINTREE',
  STRIPE = 'STRIPE',
  NONE = 'NONE',
}

export type MembershipPayment = {
  amount: number
  amountReceived: number | null
  amountRefunded: number | null
  availableRefundPayment?: number
  businessId: string
  clientCreditRefunded: number | null
  clientId: string
  creationDate: string
  id: string
  patient: string
  patientId: string
  paymentMethod: MembershipPaymentMethod
  paymentMethodId: string
  planId: string
  planLogId: string
  planName: string
  planType: BenefitPlanType
  planTypeId: string
  priceType: WellnessPlanPriceType
  refundable: boolean
  status: MembershipPaymentStatus
  stripeSubscriptionId: string | null
  subscriptionInfo: SubscriptionInfo
  transactionDate: string
}

export type MembershipPaymentMethod = {
  billingDetails: Record<string, any>
  cardTypeId: string
  clientId: string
  confirmed: boolean
  customerId: string
  expMonth: number
  expYear: number
  id: string
  last4: string
  stripeCustomerId: string
  stripePaymentMethodId: string
  stripeSetupIntentId: string
}

export type RefundMembershipData = {
  notes: string
  paymentId: string
  refundAmount: number
}

export type SubscriptionInfo = {
  id: string
  paymentProcessor: PaymentProcessor
}
