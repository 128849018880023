import React from 'react'
import { useSelector } from 'react-redux'
import { Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ErrorTooltip } from '@pbt/pbt-ui-components'

import {
  AuthorizationError,
  getIsWorkingOutsidePracticeHoursError,
} from '~/store/reducers/auth'

const useStyles = makeStyles(
  (theme) => ({
    errorTooltip: {
      width: 369,
      backgroundColor: theme.colors.loginPageSecondaryText,
      maxWidth: 'unset',
    },
    errorArrow: {
      color: theme.colors.loginPageSecondaryText,
    },
    errorTooltipMobile: {
      backgroundColor: theme.colors.loginPageSecondaryText,
    },
  }),
  { name: 'AuthorizationErrorTooltip' },
)

interface AuthorizationErrorTooltipProps {
  children: React.ReactElement
  error: AuthorizationError | null
}

const AuthorizationErrorTooltip = ({
  children,
  error,
}: AuthorizationErrorTooltipProps) => {
  const classes = useStyles()

  const isWorkingOutsidePracticeHoursError = useSelector(
    getIsWorkingOutsidePracticeHoursError,
  )

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const workingOutsidePracticeHours = error
    ? isWorkingOutsidePracticeHoursError
    : false

  const errorTooltipClass = workingOutsidePracticeHours
    ? isMobile
      ? classes.errorTooltipMobile
      : classes.errorTooltip
    : ''

  return workingOutsidePracticeHours ? (
    <ErrorTooltip
      classes={{
        errorTooltip: errorTooltipClass,
        errorArrow: classes.errorArrow,
      }}
      message={error?.message}
      open={Boolean(error)}
    >
      {children}
    </ErrorTooltip>
  ) : (
    children
  )
}

export default AuthorizationErrorTooltip
