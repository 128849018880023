import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Theme, useTheme } from '@mui/material'
import { curry } from 'ramda'
import {
  Defaults,
  PrimitiveTableColumn,
  useInterval,
} from '@pbt/pbt-ui-components'

import MembershipPaymentMethodCell from '~/components/common/lists/primitive-table/cells/MembershipPaymentMethodCell'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import DialogNames from '~/constants/DialogNames'
import {
  MembershipPaymentStatus,
  PriceTypeLabel,
} from '~/constants/paymentTypes'
import { getHasOpenDialogs } from '~/store/duck/dialogs'
import {
  fetchMembershipPayments,
  fetchMoreMembershipPayments,
  fetchRefundMembershipPaymentInfo,
  getMembershipPaymentsIsLoading,
  getMembershipPaymentsList,
  getMembershipPaymentsTotalCount,
  getMultipleMembershipPayments,
  refreshMembershipPayments,
} from '~/store/duck/membershipPayments'
import { MembershipPayment } from '~/types'
import { isRefundableMembershipPayment } from '~/utils/membershipPaymentUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import MembershipPatientCell from './MembershipPatientCell'
import MembershipPaymentDateCell from './MembershipPaymentDateCell'
import PaidCell from './PaidCell'
import StatusCell from './StatusCell'

export const getMembershipPaymentRowColoring = (
  theme: Theme,
  payment: MembershipPayment,
) => {
  if (payment.status === MembershipPaymentStatus.NEXT) {
    return theme.colors.important
  }
  return null
}

interface MembershipTablePaymentsViewProps {
  clientId: string
}

const MembershipTablePaymentsView = ({
  clientId,
}: MembershipTablePaymentsViewProps) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const list = useSelector(getMembershipPaymentsList)
  const membershipPayments = useSelector(getMultipleMembershipPayments(list))
  const totalCount = useSelector(getMembershipPaymentsTotalCount)
  const hasOpenDialogs = useSelector(getHasOpenDialogs)
  const { t } = useTranslation(['Common', 'Constants'])

  const [openMembershipPaymentsDetailsDialog] = useDialog(
    DialogNames.MEMBERSHIP_PAYMENT_DETAILS,
  )

  const columns: PrimitiveTableColumn<MembershipPayment>[] = [
    {
      label: t('Common:DATE_TIME'),
      noTypography: true,
      prop: MembershipPaymentDateCell,
      width: 4,
    },
    {
      label: t('Common:PAYMENTS.PAYMENT_METHOD'),
      prop: MembershipPaymentMethodCell,
      width: 3,
    },
    {
      label: t('Common:PATIENT'),
      prop: MembershipPatientCell,
      width: 3,
    },
    {
      label: t('Common:PLAN'),
      prop: 'tier',
      width: 4,
    },
    {
      label: t('Common:PAYMENTS.BILLED'),
      prop: (item) => PriceTypeLabel[item?.priceType?.name] || '',
      width: 3,
    },
    {
      label: t('Common:STATUS'),
      prop: StatusCell,
      noTypography: true,
      width: 2,
    },
    {
      label: t('Common:PAYMENTS.PAID'),
      prop: PaidCell,
      width: 2,
    },
  ]

  const getActualRowColoring = curry(getMembershipPaymentRowColoring)(theme)

  useEffect(() => {
    if (clientId) {
      dispatch(
        fetchMembershipPayments(
          0,
          Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          clientId,
        ),
      )
    }
  }, [clientId])

  useInterval(() => {
    if (!hasOpenDialogs && clientId) {
      dispatch(
        refreshMembershipPayments(
          0,
          Math.max(membershipPayments.length, 0),
          clientId,
        ),
      )
    }
  }, Defaults.BALANCE_PAGE_UPDATE_INTERVAL)

  const isItemLoaded = (index: number) => Boolean(membershipPayments[index])

  const loadMoreItems = (startIndex: number) => {
    dispatch(
      fetchMoreMembershipPayments(
        startIndex,
        Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        clientId,
      ),
    )
  }

  const onRefundInfoFetched = (
    paymentId: MembershipPayment['id'],
    subscriptionInfo: MembershipPayment['subscriptionInfo'],
  ) => {
    openMembershipPaymentsDetailsDialog({
      clientId,
      paymentId,
      subscriptionInfo,
    })
  }

  const setCallbackAfterFetchRefundInfoOn = useCloseAfterCreation(
    onRefundInfoFetched,
    getMembershipPaymentsIsLoading,
  )

  const handleItemClick = (membershipPayment: MembershipPayment) => {
    setCallbackAfterFetchRefundInfoOn(
      membershipPayment.id,
      membershipPayment.subscriptionInfo,
    )
    const isRefundable = isRefundableMembershipPayment(membershipPayment)

    if (isRefundable) {
      dispatch(
        fetchRefundMembershipPaymentInfo(
          membershipPayment.id,
          membershipPayment.subscriptionInfo,
        ),
      )
    }
  }

  return (
    <PrimitiveTableWithSearchHighlights
      useWindowScroll
      RowComponentProps={{ clamp: 1 }}
      columns={columns}
      expandable={false}
      getRowColoring={getActualRowColoring}
      isItemLoaded={isItemLoaded}
      list={membershipPayments}
      loadMoreItems={loadMoreItems}
      totalCount={totalCount}
      onItemClick={handleItemClick}
    />
  )
}

export default MembershipTablePaymentsView
