import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Fab } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import { getIsRetailOrderLineItem } from '~/components/dashboard/invoices/invoiceUtils'
import FeatureToggle from '~/constants/featureToggle'
import { PrescriptionType } from '~/constants/prescription'
import { OrderType, PrescriptionItemState } from '~/constants/SOAPStates'
import { partialEditOrder } from '~/store/actions/orders'
import { editChargeSheetOrder } from '~/store/duck/clientFinanceData'
import { useOrderStateId } from '~/store/hooks/orders'
import {
  getFeatureToggle,
  getPrescriptionStates,
} from '~/store/reducers/constants'
import { InvoiceLineItem, Order } from '~/types'
import { getOrderStateKey, OrderStateKeys } from '~/utils/orderUtils'

const useStyles = makeStyles(
  (theme) => ({
    fab: {
      height: 33,
      width: '100%',
      fontSize: 12,
      fontWeight: 500,
      padding: theme.spacing(0, 1),
    },
    fabSelected: {
      '&&&&:hover': {
        backgroundColor: theme.colors.selectedOption,
      },
      backgroundColor: theme.colors.selectedOption,
      color: theme.colors.primaryText,
    },
  }),
  { name: 'PrescriptionItemPrescribeButton' },
)

export interface PrescriptionItemPrescriptionButtonProps {
  disabled?: boolean
  order: Order | InvoiceLineItem | RetailOrderLineItem
}

const PrescriptionItemPrescribeButton = ({
  order,
  disabled,
}: PrescriptionItemPrescriptionButtonProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const PrescriptionStates = useSelector(getPrescriptionStates)
  const stateId = useOrderStateId(order)
  const isRetailOrderLineItem = getIsRetailOrderLineItem(order)

  const stateKey = R.has(OrderStateKeys.INVENTORY_LOG_STATUS, order)
    ? getOrderStateKey(
        isRetailOrderLineItem
          ? PrescriptionType.CHEWY // RetailOrderLineItems all through Chewy
          : order.prescriptionType,
      )
    : OrderStateKeys.STATE

  const OrderedPrescriptionState = Utils.findConstantIdByName(
    PrescriptionItemState.ORDERED,
    PrescriptionStates,
  )
  const PrescribedState = Utils.findConstantIdByName(
    PrescriptionItemState.PRESCRIBED,
    PrescriptionStates,
  )

  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )

  const onPrescribe = () => {
    const stateValue =
      PrescribedState === stateId ? OrderedPrescriptionState : PrescribedState
    const logId = R.prop('logId', order)
    const soapLogModificationDate = R.prop('soapLogModificationDate', order)

    if (isChargeSheetEnabled && soapLogModificationDate && logId) {
      dispatch(
        editChargeSheetOrder({
          id: logId,
          type: OrderType.PRESCRIPTION,
          order: {
            stateId: stateValue,
          },
          soapLogModificationDate,
        }),
      )
    } else {
      dispatch(
        partialEditOrder({
          id: order.id,
          type: isRetailOrderLineItem
            ? (order.logType as OrderType)
            : (order.type as OrderType),
          [stateKey]: stateValue,
        }),
      )
    }
  }

  return (
    <Fab
      className={classNames(classes.fab, {
        [classes.fabSelected]: stateId === PrescribedState,
      })}
      color="primary"
      disabled={disabled}
      type="button"
      variant="extended"
      onClick={onPrescribe}
    >
      {stateId === PrescribedState
        ? t('Common:PRESCRIBED')
        : t('Common:PRESCRIBE_ACTION')}
    </Fab>
  )
}

export default PrescriptionItemPrescribeButton
