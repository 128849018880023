import { useSelector } from 'react-redux'
import { Nil, Utils } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import {
  getDisplayLocations,
  getFeatureToggle,
} from '~/store/reducers/constants'
import { TimetableEvent } from '~/types'

export enum DisplayLocationName {
  'CLIENT' = 'Client preferences',
  'PATIENT' = 'Patient preferences',
}

export const useFormPreferencesDisplay = (
  displayLocation: DisplayLocationName,
  appointment?: TimetableEvent | Nil,
) => {
  const DisplayLocations = useSelector(getDisplayLocations)
  const displayLocationId = Utils.findConstantByName(
    displayLocation,
    DisplayLocations,
  )
  const forms =
    appointment?.documentInstances?.filter((form) =>
      form.displayLocationIds?.includes(displayLocationId),
    ) || []

  const isSOAPConsentFormsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SOAP_CONSENT_FORMS),
  )
  const showFormInfo = Boolean(appointment) && isSOAPConsentFormsEnabled

  return showFormInfo ? forms : undefined
}
