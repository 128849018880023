import React from 'react'
import { useTranslation } from 'react-i18next'

import AccentLabel, {
  AccentLabelProps,
} from '~/components/common/labels/AccentLabel'
import { FormStatus, SoapForm } from '~/types'

export interface FormStateLabelProps extends AccentLabelProps {
  form: SoapForm
}

const FormStateLabel = ({ form, ...rest }: FormStateLabelProps) => {
  const { t } = useTranslation('Common')

  const FormLabelMap = {
    [FormStatus.COMPLETED]: t('Common:STATUS_COMPLETED'),
    [FormStatus.DUE]: t('Common:STATUS_DUE'),
  }
  return (
    <AccentLabel
      colorVariant={
        form.status === FormStatus.COMPLETED ? 'completed' : 'warning'
      }
      name={FormLabelMap[form.status]}
      {...rest}
    />
  )
}

export default FormStateLabel
