import {
  DocumentElementType,
  InternalDisplayPreference,
} from '@pbt/pbt-ui-components'

import { SoapForm } from '~/types'

export const getFormSelectedOptions = (form: SoapForm) => {
  const selectedOptions = form.documentElements
    ?.map((docElement) => {
      if (
        docElement.type === DocumentElementType.SINGLE_CHECKBOX &&
        docElement.selected
      ) {
        return docElement.option
      }
      if (docElement.type === DocumentElementType.MULTI_SELECT) {
        return docElement.options.filter((option) =>
          docElement.selectedOptionIds?.includes(option.id),
        )
      }
      if (docElement.type === DocumentElementType.SINGLE_SELECT) {
        return docElement.options.filter(
          (option) => docElement.selectedOptionId === option.id,
        )
      }
      return null
    })
    .flat()

  const outputStrings = selectedOptions
    ?.map((option) => {
      if (!option) return null
      if (
        option.internalDisplayPreference ===
        InternalDisplayPreference.AS_WRITTEN
      ) {
        return option.name
      }
      if (
        option.internalDisplayPreference ===
        InternalDisplayPreference.SUMMARIZED
      ) {
        return option.internalName
      }
      return null
    })
    .filter((item) => item)

  return outputStrings
}
