import React, { useCallback, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import LeaveConfirmationDialog from '~/components/common/dialog/LeaveConfirmationDialog'
import DialogNames from '~/constants/DialogNames'
import { CRUDOrderOptions } from '~/types'

import ChewyPrescriptionReadOnly from '../ChewyPrescriptionReadOnly'
import {
  PrescriptionActionContext,
  PrescriptionActionContextType,
} from '../PrescriptionContext'
import PrescriptionActions from './actions/PrescriptionActions'
import usePrescriptionEditor, {
  PrescriptionEditorProps,
} from './hooks/usePrescriptionEditor'
import PrescriptionBody from './PrescriptionBody'
import PrescriptionHeader from './PrescriptionHeader'

const useStyles = makeStyles(
  (theme) => ({
    buttonContainer: {
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
      backgroundColor: theme.colors.tableBackground,
    },
  }),
  { name: 'BasePrescription' },
)

const BasePrescription = (props: PrescriptionEditorProps) => {
  const classes = useStyles()

  const [ignoreUnsavedChanges, setIgnoreUnsavedChanges] = useState(false)

  const {
    isChewyReadOnly,
    isChewyReactiveRxReadOnly,
    ...prescriptionBodyProps
  } = usePrescriptionEditor(props)

  const {
    disableDrafts,
    activeWorkflow,
    clientId,
    currentDoctorHasDvmLicense,
    fields: { automaticallyCreateTask },
    handleSave,
    hasUnsavedData,
    isAutomateChewyRxTasksEnabled,
    isChewyCatalogItemNotLinked,
    isCompoundingReasonRequired,
    isDvmLicenseRequired,
    isEdit,
    isTemplatesLoaded,
    outsideSoap,
    patientId,
    prescription,
    taskId,
    isChewyActiveRxReadOnly,
    isRetailOrderLineItem,
  } = prescriptionBodyProps

  const beforeSave = useCallback(
    (options?: CRUDOrderOptions) => {
      setIgnoreUnsavedChanges(true)
      handleSave({
        ...options,
        afterSaveCallback: () => setIgnoreUnsavedChanges(false),
      })
    },
    [handleSave],
  )

  const actionContext = useMemo<PrescriptionActionContextType>(
    () => ({
      disableDrafts,
      automaticallyCreateTaskField: automaticallyCreateTask,
      isAutomateChewyRxTasksEnabled,
      isChewyCatalogItemNotLinked,
      isCompounded: Boolean(isCompoundingReasonRequired),
      isDvmLicenseRequired,
      isEdit,
      isRetailOrderLineItem,
      prescriptionSignerId: prescription.signerId || prescription.signer?.id,
      onApprove: () => beforeSave({ isApprove: true }),
      onDecline: (declineReason: string) => beforeSave({ declineReason }),
      onEmail: () => beforeSave({ email: true }),
      onPrint: () => beforeSave({ print: true }),
      onSave: beforeSave,
      onSaveDraft: () => beforeSave({ isSaveDraft: true }),
      patientId,
      stateId: prescription.stateId,
      isChewyActiveRxReadOnly,
    }),
    [
      disableDrafts,
      automaticallyCreateTask,
      isAutomateChewyRxTasksEnabled,
      isChewyCatalogItemNotLinked,
      isCompoundingReasonRequired,
      isDvmLicenseRequired,
      isEdit,
      prescription,
      beforeSave,
      isChewyActiveRxReadOnly,
    ],
  )

  if (isChewyReadOnly) {
    return (
      <ChewyPrescriptionReadOnly
        clientId={clientId}
        isChewyReactiveRxReadOnly={isChewyReactiveRxReadOnly}
        patientId={patientId}
        prescription={prescription}
        px={2}
      />
    )
  }

  return (
    <>
      <Grid px={2}>
        <PrescriptionHeader
          clientId={clientId}
          patientId={patientId}
          prescription={prescription}
          taskId={taskId}
        />
      </Grid>
      <PrescriptionBody {...prescriptionBodyProps} />
      {isTemplatesLoaded ? (
        <Grid className={classes.buttonContainer} px={2} py={3}>
          <PrescriptionActionContext.Provider value={actionContext}>
            <PrescriptionActions
              currentDoctorHasDvmLicense={currentDoctorHasDvmLicense}
              outsideSoap={outsideSoap}
              workflow={activeWorkflow}
            />
          </PrescriptionActionContext.Provider>
        </Grid>
      ) : null}
      <LeaveConfirmationDialog
        navigateOnCancel
        dialogName={DialogNames.EXPANDER_CONFIRM_CLOSE}
        hasUnsavedData={ignoreUnsavedChanges ? false : hasUnsavedData}
        onProceed={() => handleSave({ isSaveDraft: true })}
      />
    </>
  )
}

export default BasePrescription
