import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'
import {
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Eye as ViewIcon,
} from '@pbt/pbt-ui-components/src/icons'

import ActionsPopper from '~/components/common/ActionsPopper'
import PuiDataTable from '~/components/common/lists/pui-data-table/PuiDataTable'
import DialogNames from '~/constants/DialogNames'
import DocumentDialogStates from '~/constants/DocumentDialogStates'
import { PDF } from '~/constants/extensions'
import { deleteForm } from '~/store/actions/timetable'
import { useIsSoapLocked } from '~/store/hooks/soap'
import {
  getAppointmentId,
  getIsCurrentContextSoap,
  getIsFinalized,
  getSOAPisFetching,
  getSOAPisLoading,
} from '~/store/reducers/soap'
import { getTimetableEventForms } from '~/store/reducers/timetable'
import { Document, FormStatus } from '~/types'
import { getDeleteConfirmMessage } from '~/utils'
import { downloadFile } from '~/utils/file'
import useDialog from '~/utils/useDialog'

import { getFormSelectedOptions } from '../../getFormSelectedOptions'
import SoapWidget from '../SoapWidget'
import useFormsWidgetColumns from './useFormsWidgetColumns'

const ROW_HEIGHT = 40

const useStyles = makeStyles(
  (theme) => ({
    addSection: {
      borderTop: theme.constants.tableBorder,
    },
    addItem: {
      width: 'auto',
      padding: theme.spacing(1, 1, 1, 2),
    },
    nameCell: {
      paddingLeft: theme.spacing(2),
    },
    cell: {
      padding: theme.spacing(0, 2),
      minHeight: ROW_HEIGHT,
      overflow: 'hidden',
      fontSize: '1.4rem',
    },
    cellBordered: {
      borderRight: theme.constants.tableBorder,
    },
    spacingCell: {
      padding: theme.spacing(0, 2),
      fontSize: '1.4rem',
    },
    nameHeaderCell: {
      paddingLeft: theme.spacing(2),
    },
  }),
  { name: 'FormsWidget' },
)

const FormsWidget = () => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Soap'])
  const dispatch = useDispatch()

  const isLocked = useIsSoapLocked(false)
  const forms = useSelector(getTimetableEventForms) || []
  const isLoading = useSelector(getSOAPisLoading)
  const isFetching = useSelector(getSOAPisFetching)
  const isSoapFinalized = useSelector(getIsFinalized)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)
  const appointmentId = useSelector(getAppointmentId)

  const isReadOnly = isSoapFinalized || !isCurrentContextSoap

  const [openDeleteAttachmentAlert, closeDeleteAttachmentAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const [openDocumentDialog] = useDialog(DialogNames.DOCUMENT)

  const [actionsAnchorElement, setActionsAnchorElement] =
    useState<HTMLElement | null>(null)
  const [activeActionItemId, setActiveActionItemId] = useState<string | null>(
    null,
  )
  const activeActionItem = forms.find((form) => form.id === activeActionItemId)

  const columns = useFormsWidgetColumns({
    actionsAnchorElement,
    setActionsAnchorElement,
    setActiveActionItem: setActiveActionItemId,
    classes,
    activeActionItem: activeActionItemId,
  })

  const onDeleteRequested = () => {
    openDeleteAttachmentAlert({
      message: getDeleteConfirmMessage(t('Common:FORM_NOUN').toLowerCase()),
      cancelButtonText: t('Common:NO_KEEP'),
      okButtonText: t('Common:YES_DELETE'),
      onCancel: () => closeDeleteAttachmentAlert(),
      onOk: () => {
        if (activeActionItemId && appointmentId) {
          dispatch(deleteForm(activeActionItemId, appointmentId))
        }
        closeDeleteAttachmentAlert()
      },
    })
  }

  const onViewRequested = () => {
    openDocumentDialog({
      previewOnly: true,
      document: {
        extension: PDF,
        name: activeActionItem?.name,
        fileUrl: activeActionItem?.pdfUrl,
      } as Document,
      step: DocumentDialogStates.PREVIEW,
      disableCloseAfterUpdate: true,
      PreviewProps: {
        hideTopButtons: true,
        view: true,
        showChangeFile: false,
        hideEmail: true,
        hidePrint: true,
      },
    })
  }
  const onDownloadRequested = () => {
    const url = activeActionItem?.pdfUrl
    if (url) {
      downloadFile({
        url,
        name: activeActionItem?.name,
        extension: PDF,
      })
    }
  }

  const isPdfGeneratedForActiveItem = Boolean(activeActionItem?.pdfUrl)

  const formActions = [
    ...(isPdfGeneratedForActiveItem
      ? [
          {
            id: 'forms_view_action',
            label: t('Common:VIEW_ACTION'),
            Icon: ViewIcon,
            onClick: onViewRequested,
          },
          {
            id: 'forms_download_action',
            label: t('Common:DOWNLOAD_ACTION'),
            Icon: DownloadIcon,
            onClick: onDownloadRequested,
          },
        ]
      : []),
    ...(isLocked || activeActionItem?.status === FormStatus.COMPLETED
      ? []
      : [
          {
            id: 'forms_delete_action',
            label: t('Common:DELETE_ACTION'),
            Icon: DeleteIcon,
            onClick: onDeleteRequested,
          },
        ]),
  ]

  const numOptions = forms
    .map((form) => getFormSelectedOptions(form))
    .flat().length
  const numViewportItems = forms ? forms.length + numOptions : 0

  return (
    <SoapWidget id="forms-widget" title={t('Common:FORMS')}>
      <Grid container item direction="column">
        {(forms && forms.length > 0) || !isReadOnly ? (
          <PuiDataTable
            borderless
            densed
            dynamicSize
            columns={columns}
            emptyPlaceholder={
              <Text align="center" m={2} variant="body2">
                {t('Soap:APPOINTMENT_FORMS.NO_FORMS')}
              </Text>
            }
            isLoading={isLoading || isFetching}
            items={forms}
            loadMoreItems={() => {}}
            minRowHeight={ROW_HEIGHT}
            minViewPortHeight={numViewportItems * ROW_HEIGHT}
            rowHeight={ROW_HEIGHT}
            totalCount={forms ? forms.length : 0}
          />
        ) : (
          <Text p={2}>{t('Soap:APPOINTMENT_FORMS.NO_FORMS')}</Text>
        )}
      </Grid>
      <ActionsPopper
        actions={formActions}
        anchorEl={actionsAnchorElement}
        disablePortal={false}
        id="estm_actions"
        onClose={() => {
          setActionsAnchorElement(null)
          setActiveActionItemId(null)
        }}
      />
    </SoapWidget>
  )
}

export default FormsWidget
