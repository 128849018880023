import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { LanguageUtils, Nil } from '@pbt/pbt-ui-components'

import {
  getGender,
  getPreferenceCautions,
  getPreferenceDrugs,
  getPreferenceLabWorkFrequencies,
  getPreferenceMedicalAlerts,
  getPreferencePatientPerformedTreatmentsLocations,
  getPreferencePremedicationVaccineReactions,
  getPreferenceSedations,
  getPreferenceVenipunctures,
} from '~/store/reducers/constants'
import {
  fetchPatientPreferences,
  getPatientPreferences,
} from '~/store/reducers/patientPreferences'
import { PatientPreferencesDisplayData, TimetableEvent } from '~/types'

import {
  DisplayLocationName,
  useFormPreferencesDisplay,
} from '../useFormPreferencesDisplay'

export const useGetPatientPreferencesViewData = (
  patientId: string | Nil,
  appointment?: TimetableEvent | Nil,
): PatientPreferencesDisplayData => {
  const { t } = useTranslation(['Clients'])
  const dispatch = useDispatch()

  useEffect(() => {
    if (patientId) {
      dispatch(fetchPatientPreferences({ id: patientId }))
    }
  }, [patientId])

  const preferences = useSelector(getPatientPreferences(patientId))

  const cautionConstants = useSelector(getPreferenceCautions)
  const medicalAlertConstants = useSelector(getPreferenceMedicalAlerts)
  const drugPreferenceConstants = useSelector(getPreferenceDrugs)
  const genderOfStaffConstants = useSelector(getGender)
  const venipunctureSiteConstants = useSelector(getPreferenceVenipunctures)
  const vaccineReactionRequiringPremedicationWithConstants = useSelector(
    getPreferencePremedicationVaccineReactions,
  )
  const locationOfPerformedTreatmentsConstants = useSelector(
    getPreferencePatientPerformedTreatmentsLocations,
  )
  const requiresSedationForConstants = useSelector(getPreferenceSedations)
  const labWorkFrequencyConstants = useSelector(getPreferenceLabWorkFrequencies)

  const forms = useFormPreferencesDisplay(
    DisplayLocationName.PATIENT,
    appointment,
  )
  const hasForms = forms && forms.length > 0

  const hasImportant =
    preferences?.caution?.length ||
    preferences?.medicalAlert?.length ||
    preferences?.drugSensitivityComment ||
    preferences?.importantComment
  const hasGeneral =
    preferences?.treats ||
    preferences?.namePronunciation ||
    preferences?.drugPreference?.length ||
    preferences?.staffGender ||
    preferences?.generalComment
  const hasMedical =
    preferences?.venipuncture ||
    preferences?.premedicationVaccineReaction?.length ||
    preferences?.performedTreatmentsLocation ||
    preferences?.sedation?.length ||
    preferences?.labWorkFrequency?.length ||
    preferences?.medicalComment

  const importantDisplay = hasImportant
    ? {
        categoryTitle: t('Clients:PATIENT_PREFERENCE_TITLES.IMPORTANT.TITLE'),
        subCategory: [
          ...(preferences.caution
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.IMPORTANT.CAUTION',
                  ),
                  subCategoryPreferences: preferences.caution.map((e) =>
                    LanguageUtils.getConstantTranslatedName(
                      e.id,
                      cautionConstants,
                    ),
                  ),
                },
              ]
            : []),
          ...(preferences.medicalAlert
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.IMPORTANT.MEDICAL_ALERT',
                  ),
                  subCategoryPreferences: preferences.medicalAlert.map((e) =>
                    LanguageUtils.getConstantTranslatedName(
                      e.id,
                      medicalAlertConstants,
                    ),
                  ),
                },
              ]
            : []),
          ...(preferences.drugSensitivityComment
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.IMPORTANT.DRUG_SENSITIVITY_COMMENT',
                  ),
                  subCategoryPreferences: preferences.drugSensitivityComment,
                },
              ]
            : []),
          ...(preferences.importantComment
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.IMPORTANT.COMMENT',
                  ),
                  subCategoryPreferences: preferences.importantComment,
                },
              ]
            : []),
        ],
      }
    : undefined
  const generalDisplay = hasGeneral
    ? {
        categoryTitle: t('Clients:PATIENT_PREFERENCE_TITLES.GENERAL.TITLE'),
        subCategory: [
          ...(preferences.treats
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.GENERAL.TREATS',
                  ),
                  subCategoryPreferences: preferences.treats,
                },
              ]
            : []),
          ...(preferences.namePronunciation
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.GENERAL.NAME_PRONUNCIATION',
                  ),
                  subCategoryPreferences: preferences.namePronunciation,
                },
              ]
            : []),
          ...(preferences.drugPreference
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.GENERAL.DRUG_PREFERENCE',
                  ),
                  subCategoryPreferences: preferences.drugPreference.map((e) =>
                    LanguageUtils.getConstantTranslatedName(
                      e.id,
                      drugPreferenceConstants,
                    ),
                  ),
                },
              ]
            : []),
          ...(preferences.staffGender
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.GENERAL.STAFF_GENDER',
                  ),
                  subCategoryPreferences:
                    LanguageUtils.getConstantTranslatedName(
                      preferences.staffGender.id,
                      genderOfStaffConstants,
                    ),
                },
              ]
            : []),
          ...(preferences.generalComment
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.GENERAL.COMMENT',
                  ),
                  subCategoryPreferences: preferences.generalComment,
                },
              ]
            : []),
        ],
      }
    : undefined

  const medicalDisplay = hasMedical
    ? {
        categoryTitle: t('Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.TITLE'),
        subCategory: [
          ...(preferences.venipuncture
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.VENIPUNCTURE',
                  ),
                  subCategoryPreferences:
                    LanguageUtils.getConstantTranslatedName(
                      preferences.venipuncture.id,
                      venipunctureSiteConstants,
                    ),
                },
              ]
            : []),
          ...(preferences.premedicationVaccineReaction
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.PREMEDICATION_VACCINE_REACTIONS',
                  ),
                  subCategoryPreferences:
                    preferences.premedicationVaccineReaction.map((e) =>
                      LanguageUtils.getConstantTranslatedName(
                        e.id,
                        vaccineReactionRequiringPremedicationWithConstants,
                      ),
                    ),
                },
              ]
            : []),
          ...(preferences.performedTreatmentsLocation
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.PERFORMED_TREATMENTS_LOCATION',
                  ),
                  subCategoryPreferences:
                    LanguageUtils.getConstantTranslatedName(
                      preferences.performedTreatmentsLocation.id,
                      locationOfPerformedTreatmentsConstants,
                    ),
                },
              ]
            : []),
          ...(preferences.sedation
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.SEDATION',
                  ),
                  subCategoryPreferences: preferences.sedation.map((e) =>
                    LanguageUtils.getConstantTranslatedName(
                      e.id,
                      requiresSedationForConstants,
                    ),
                  ),
                },
              ]
            : []),
          ...(preferences.labWorkFrequency
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.LAB_WORK_FREQUENCY',
                  ),
                  subCategoryPreferences: preferences.labWorkFrequency.map(
                    (e) =>
                      LanguageUtils.getConstantTranslatedName(
                        e.id,
                        labWorkFrequencyConstants,
                      ),
                  ),
                },
              ]
            : []),
          ...(preferences.medicalComment
            ? [
                {
                  subCategoryTitle: t(
                    'Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.COMMENT',
                  ),
                  subCategoryPreferences: preferences.medicalComment,
                },
              ]
            : []),
        ],
      }
    : undefined

  const formsDisplay = hasForms
    ? {
        categoryTitle: t('Common:FORMS'),
        formEntries: forms,
      }
    : undefined

  return {
    important: importantDisplay,
    general: generalDisplay,
    medical: medicalDisplay,
    forms: formsDisplay,
  }
}
