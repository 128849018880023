import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import { PreferencesChip } from '~/components/common/PreferencesChip'
import DialogNames from '~/constants/DialogNames'
import { getPatientPreferencesIsLoading } from '~/store/reducers/patientPreferences'
import { TimetableEvent } from '~/types'
import useDialog from '~/utils/useDialog'

import { useGetPatientPreferencesViewData } from './useGetPatientPreferencesViewData'

export interface PatientPreferencesChipProps {
  appointment?: TimetableEvent | Nil
  className?: string
  patientId: string | Nil
}

export function PatientPreferencesChip({
  className,
  patientId,
  appointment,
}: PatientPreferencesChipProps) {
  const { t } = useTranslation('Clients')
  const patientPreferencesIsLoading = useSelector(
    getPatientPreferencesIsLoading,
  )
  const [openPatientPreferencesDialog] = useDialog(
    DialogNames.PATIENT_PREFERENCES,
  )

  const onEditPreferences = () => {
    if (!patientPreferencesIsLoading) {
      openPatientPreferencesDialog({ patientId })
    }
  }

  const preferences = useGetPatientPreferencesViewData(patientId, appointment)

  return (
    <PreferencesChip
      className={className}
      headerText={t('Clients:PATIENT_SECTION.PATIENT_PREFERENCES')}
      preferences={preferences}
      onEditPreferences={onEditPreferences}
    />
  )
}
