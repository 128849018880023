import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormControlLabel, Grid, Radio } from '@mui/material'

import Typography from '~/components/elements/Typography/Typography'
import DialogNames from '~/constants/DialogNames'
import {
  DocumentElementStateItem,
  internalDisplayPreferenceLabel,
  RadioGroupState,
  RadioOptionState,
} from '~/types'
import useDialog from '~/utils/useDialog'

import { DocumentElementAccordion } from './DocumentElementAccordion'

interface DocumentRadioGroupProps {
  documentElement: RadioGroupState
  isDragging?: boolean
  onDelete?: () => void
  onUpdate?: (documentElement: DocumentElementStateItem) => void
}

export const DocumentRadioGroup = ({
  documentElement,
  isDragging,
  onUpdate,
  onDelete,
}: DocumentRadioGroupProps) => {
  const { t } = useTranslation('Common')

  const [openDocumentRadioGroupDialog] = useDialog(
    DialogNames.DOCUMENT_RADIO_GROUP_DIALOG,
  )

  const getDisplayPreference = (option: RadioOptionState) =>
    option?.internalName ||
    internalDisplayPreferenceLabel[option?.internalDisplayPreference]

  return (
    <Box mb={2} px={3}>
      <DocumentElementAccordion
        isDragging={isDragging}
        title={t('Common:MULTIPLE_CHOICE')}
        onDelete={onDelete}
        onEdit={
          onUpdate
            ? () =>
                openDocumentRadioGroupDialog({
                  onSave: onUpdate,
                  documentElement,
                })
            : undefined
        }
      >
        <Grid container p={2}>
          <Grid item pb={1} xs={12}>
            <Typography.H2>{documentElement.label || ''}</Typography.H2>
          </Grid>
          {documentElement.options?.map((option) => {
            const displayPreference = getDisplayPreference(option)

            return (
              <Grid item key={option.sequenceNumber} xs={12}>
                <Grid item>
                  <FormControlLabel
                    control={<Radio checked={false} sx={{ paddingY: 0 }} />}
                    label={option?.name || ''}
                  />
                </Grid>
                {Boolean(displayPreference) && (
                  <Grid item pb={1} pl={4} xs={12}>
                    <Typography.Paragraph color="grayGray2">
                      {`${t('Common:RHAPSODY_DISPLAY')}: ${displayPreference}`}
                    </Typography.Paragraph>
                  </Grid>
                )}
              </Grid>
            )
          })}
        </Grid>
      </DocumentElementAccordion>
    </Box>
  )
}
