import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material'
import { Grid, TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { LanguageUtils, Text } from '@pbt/pbt-ui-components'
import { ChewySubmark } from '@pbt/pbt-ui-components/src/icons'

import {
  getItemCoveredByWplan,
  removeChewyPrefix,
} from '~/components/dashboard/invoices/invoiceUtils'
import MembershipIcon from '~/components/dashboard/soap/order/MembershipIcon'
import { FINANCE_TABLE_CELL_HEIGHT } from '~/constants/financeTable'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { InvoiceLineItem } from '~/types'
import { getPrescriptionType } from '~/utils/prescription'

const useStyles = makeStyles(
  (theme) => ({
    declined: {
      color: theme.colors.lowAccentText,
    },
    cellTextWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    groupedCell: {
      borderBottom: theme.constants.tabBorder,
    },
    chewyIcon: {
      height: '1.6rem',
      width: '1.6rem',
      marginRight: theme.spacing(0.625),
    },
    icon: {
      color: theme.colors.lowAccentText,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    membershipIcon: {
      width: 24,
      height: 24,
      marginRight: theme.spacing(0.5),
    },
    selectedRowText: {
      color: theme.colors.tabSelected,
    },
  }),
  { name: 'FinanceItemNameCellBody' },
)

export interface FinanceItemNameCellBodyProps {
  handleSelectItem: (item: InvoiceLineItem) => void
  isExpanded: boolean
  isGrouped: boolean
  isReadOnly: boolean
  item: InvoiceLineItem
  nested?: boolean
  selectedItem: InvoiceLineItem | undefined
  showAsDisabled?: Boolean
  tableCellClassName: string
  tableCellWithBorder?: string
  toggleExpand: (item: InvoiceLineItem) => void
}

const FinanceItemNameCellBody = ({
  handleSelectItem,
  isGrouped,
  isExpanded,
  isReadOnly,
  showAsDisabled = false,
  item,
  nested,
  selectedItem,
  tableCellClassName,
  tableCellWithBorder,
  toggleExpand,
}: FinanceItemNameCellBodyProps) => {
  const classes = useStyles()

  const { t } = useTranslation(['Common', 'Invoices'])

  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  const isWplanItem = Boolean(item.wplanLogId)
  const coveredByWplan = getItemCoveredByWplan(item)

  const titleClasses = classNames({
    [classes.selectedRowText]: selectedItem && item.id === selectedItem.id,
    [classes.declined]: item.declined,
  })

  const statusLabel = item.declined
    ? `(${t('Common:DECLINED')})`
    : coveredByWplan
      ? t('Invoices:TABLE.BODY.COVERED_BY_WELLNESS_PLAN')
      : ''

  const { isChewyActiveRx, isChewyReactiveRx } = getPrescriptionType(
    item.prescriptionType,
    item.origin,
  )

  const isChewyCheckoutActiveRx =
    (isChewyActiveRx || isChewyReactiveRx) && isChewyCheckoutEnabled

  let fieldName = `${LanguageUtils.getTranslatedFieldName(item)} ${statusLabel}`

  if (isChewyCheckoutActiveRx) {
    fieldName = removeChewyPrefix(fieldName)
  }

  return isGrouped ? (
    <>
      <Grid
        container
        alignItems="center"
        className={classNames(tableCellClassName)}
        height={FINANCE_TABLE_CELL_HEIGHT}
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid
          container
          item
          alignItems="center"
          flexWrap="nowrap"
          onClick={() => (toggleExpand ? toggleExpand(item) : undefined)}
        >
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          <Dotdotdot
            clamp={2}
            truncationChar={statusLabel ? `... ${statusLabel}` : '...'}
            useNativeClamp={false}
          >
            <Text
              strong
              className={titleClasses}
              variant={showAsDisabled ? 'lowAccent2' : 'body2'}
            >
              {`${item.groupName} ${statusLabel}`}
            </Text>
          </Dotdotdot>
        </Grid>
        {!isReadOnly && (
          <MoreHorizIcon
            className={classes.icon}
            id={`charge-widget-select-item-${item.id}_${item.logId}`}
            onClick={() => {
              handleSelectItem(item)
            }}
          />
        )}
      </Grid>
    </>
  ) : (
    <TableCell className={classNames(tableCellClassName, tableCellWithBorder)}>
      <Grid
        container
        alignItems="center"
        height={FINANCE_TABLE_CELL_HEIGHT}
        justifyContent="space-between"
        pl={nested ? 3 : 0}
        wrap="nowrap"
      >
        <Grid container alignItems="flex-start" wrap="nowrap">
          {isWplanItem && !item.declined && (
            <MembershipIcon
              className={classes.membershipIcon}
              limitReached={!coveredByWplan}
            />
          )}
          <Grid item>
            <div className={classes.cellTextWrapper}>
              {isChewyCheckoutActiveRx && (
                <ChewySubmark className={classes.chewyIcon} />
              )}
              <Dotdotdot clamp={2}>
                <Text
                  className={titleClasses}
                  variant={showAsDisabled ? 'lowAccent2' : 'body2'}
                >
                  {fieldName}
                </Text>
              </Dotdotdot>
            </div>
          </Grid>
        </Grid>
        {!isReadOnly && (
          <MoreHorizIcon
            className={classes.icon}
            id={`charge-widget-select-item-${item.id}_${item.logId}`}
            onClick={() => {
              handleSelectItem(item)
            }}
          />
        )}
      </Grid>
    </TableCell>
  )
}

export default FinanceItemNameCellBody
