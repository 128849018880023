import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import { PreferencesChip } from '~/components/common/PreferencesChip'
import DialogNames from '~/constants/DialogNames'
import { getClientPreferencesIsLoading } from '~/store/duck/clientPreferences'
import { TimetableEvent } from '~/types'
import useDialog from '~/utils/useDialog'

import { useGetClientPreferencesViewData } from './useGetClientPreferencesViewData'

export interface ClientPreferencesChipProps {
  appointment?: TimetableEvent | Nil
  className?: string
  clientId: string | Nil
}

function ClientPreferencesChip({
  className,
  clientId,
  appointment,
}: ClientPreferencesChipProps) {
  const { t } = useTranslation('Clients')

  const clientPreferencesIsLoading = useSelector(getClientPreferencesIsLoading)
  const [openClientPreferencesDialog] = useDialog(
    DialogNames.CLIENT_PREFERENCES,
  )

  const onEditPreferences = () => {
    if (!clientPreferencesIsLoading) {
      openClientPreferencesDialog({ clientId })
    }
  }

  const clientPreferences = useGetClientPreferencesViewData(
    clientId,
    appointment,
  )

  return (
    <PreferencesChip
      className={className}
      headerText={t('Clients:CLIENT_PREFERENCES.DISPLAY.HEADER')}
      preferences={clientPreferences}
      onEditPreferences={onEditPreferences}
    />
  )
}

export { ClientPreferencesChip }
